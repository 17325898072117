import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import Link from "components/ExtendLink";
import Avatar from "react-avatar";
import { useDispatch } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import UserName from "../Reusable/UserName";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
const _ = require("lodash");
const { ExportCSVButton } = CSVExport;

function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
/*const List = (props, context) => (
  <BootstrapTable
    wrapperClasses="table-responsive"
    keyField="_id"
    noDataIndication="No Data Found"
    data={props.currentMonthReport}
    filter={filterFactory()}
    columns={[
      {
        dataField: props.currentMonthReport,
        text: "Name",
        formatter: fullname,
        sort: true,
        filter: textFilter(),
        filterValue: (cell, row) => {
          return cell.user.fname + " " + cell.user.lname;
        },
        headerFormatter: priceFormatter
      }
    ]}
  />
);*/

const CareproviderList = (props, context) => {
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(0);
  const { fname = "ERROR", lname = "ERROR", date = "" } = props;

  function fullname(cell, row) {
    if (moment(row.joinedDate).month() + 1 == selectedMonth) {
      //new user
      return (
        <div>
          {row.fullName} <label class="badge badge-gradient-info">NEW</label>
        </div>
      );
    }
    return row.fullName;
  }
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Row style={{ marginTop: "5%" }}>
        <Col>
          <button className="btn btn-success float-left" onClick={handleClick}>
            <i className="mdi mdi-file-excel" /> Export to CSV
          </button>
        </Col>
      </Row>
    );
  };
  return (
    <div>
      <ToolkitProvider
        keyField="_id"
        data={props.currentMonthReport}
        columns={[
          {
            dataField: props.currentMonthReport ? "fullName" : "",
            text: "Name",
            sort: true,
            formatter: fullname,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              if (moment(row.joinedDate).month() + 1 == selectedMonth) {
                return row.fullName + " (NEW)";
              }
              return row.fullName;
            },
          },
          {
            dataField: props.currentMonthReport ? "joinedDate" : "",
            text: "Joined Date",
            sort: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.joinedDate;
            },
          },
          {
            dataField: props.currentMonthReport ? "firstReading" : "",
            text: "First Upload This Month",
            sort: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.firstReading;
            },
          },
          {
            dataField: props.currentMonthReport ? "lastReading" : "",
            text: "Last Upload This Month",
            sort: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.lastReading;
            },
          },
          {
            dataField: props.currentMonthReport ? "dateDifference" : "",
            text: "RPM Billable CPT Code",
            sort: true,
            formatter: (cell, row) => {
              if (row.dateDifference >= 16) {
                return (
                  <div>
                    <button
                      className="btn btn-sm btn-success text-white"
                      id={`popover5${row.id}`}
                      type="button"
                    >
                      CPT Code 99454
                    </button>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="right"
                      target={`popover5${row.id}`}
                    >
                      <PopoverHeader>Description</PopoverHeader>
                      <PopoverBody>
                        Patient have met the condition at least 16 days on the
                        system and should have records of activity every month{" "}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                );
              }
            },
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              if (row.dateDifference >= 16) {
                return "CPT Code 99454";
              }
              return "";
            },
          },
          // {
          //   dataField: props.currentMonthReport
          //   ? "organization" : "",
          //   text: "Organization",
          //   sort: true,
          //   headerFormatter: priceFormatter,
          //   csvFormatter: (cell, row, rowIndex) => {
          //       return row.organization;
          //   }
          // },
          {
            dataField: props.currentMonthReport ? "subOrganization" : "",
            text: "subOrganization",
            sort: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.subOrganization;
            },
          },
          {
            dataField: props.currentMonthReport ? "bday" : "",
            text: "DOB",
            sort: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.bday;
            },
          },
        ]}
        exportCSV={{ fileName: `month.csv` }}
      >
        {(toolKitProp) => (
          <div>
            <Row>
              <Col md={6}>
                <MyExportCSV {...toolKitProp.csvProps}>Export CSV</MyExportCSV>
              </Col>
            </Row>
            <hr />
            <div className="scrollbar-table-cls">
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication="No Data Found"
              filter={filterFactory()}
              {...toolKitProp.baseProps}
            />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default CareproviderList;
