import moment from "moment";
import React from "react";
import _ from "lodash";
import { filter, map, size, uniq, find } from "lodash";
import ReactLoading from "react-loading";
import readingSubTypes from "store/reducers/readingSubType.json";

import { Col, Row } from "reactstrap";

function percentage(value, totalValue) {
  return ((value / totalValue) * 100).toFixed(2);
}
function ReadingSubTypeSummary(props) {
  const profileOwner = props.profileOwner;
  const profile = props.profile;

  if (!profileOwner && !profile) {
    return (
      <ReactLoading type="spin" className="text-center mx-auto" color="blue" />
    );
  }

  if (size(profile.devices) == 0) {
    return (
      <div className="col-12" style={{padding:15}}>
        <h3 className="text-center">No Current Device Assigned</h3>
      </div>
    );
  }
  var readingSubType = readingSubTypes.filter((item) => item.description !== "Weight");
 
  return readingSubType.map((value, i) => {
    // this added code for heart rate take from puls ox filter(HeartRateFromPulsOx)
    if (props.isBloodPressureReading && value && value._id == '5be56cbe3863cd0647d79896') {
      return;
    }
    // this added code for heart rate take from puls ox filter(HeartRateFromPulsOx)
    var readings = undefined;
    var readingValue = undefined
    var checkThis = undefined
    var result = _.includes(value.abbrev, "BT_");
    var subType = value.name;
    var description = value.description;
    var reading = value.reading;
    var mViolation = [];
    var weightNotification = 0;
    var weightEvent = 0;
    var weightChange = 0;
    let getOnlyRelatedViolation = 0;
    let totalWeightReading = 0;
    if (result) {
      mViolation = filter(props.violations, function (o) {
        var checkReading = _.find(props.bodytraceReading, {"_id": o.reading ? o.reading._id : ""});
        if(checkReading != undefined && checkReading){
          if(o.ruleType == 'Weight'){
            return o.isBodytraceReading && o.readingSubType == subType.replace("Pulse","Heart Rate") && o.reading;
          }else {
            if(subType != 'Weight'){
              return o.isBodytraceReading && o.readingSubType == subType.replace("Pulse","Heart Rate") && o.reading && o.ruleType =="Vitals";
            }
          }
        }
      });
      readings = _.filter(props.bodytraceReading, (reading) => {
        if (_.includes(value.abbrev, "WS") && reading.values.unit == 0) {
          return false;
        } else if (value.name.toLowerCase() in reading.values) {
          return true;
        }
        return false;
      });
      readingValue = _.map(readings, "values");
      checkThis = _.map(readingValue, (readingIndex, i) => {
        return _.get(readingValue[i], value.name.toLowerCase());
      });
      getOnlyRelatedViolation = filter(props.violations, function (o) {
        var checkReading = _.find(props.bodytraceReading, {"_id": o.reading ? o.reading._id : ""});
        if(checkReading != undefined && checkReading){
          if(o.ruleType == 'Weight'){
            return o.isBodytraceReading && o.reading;
          }else {
            if(subType != 'Weight'){
              return o.isBodytraceReading && o.readingSubType == subType.replace("Pulse","Heart Rate") && o.reading;
            }
          }
        }
      });
    
    } else {
      mViolation = filter(props.violations, function (o) {
        var checkReading = _.find(props.readings, {"_id": o.reading ? o.reading._id : ""});
        if(checkReading != undefined && checkReading){
          if(subType == 'Heart Rate'){
            return !o.isBodytraceReading && o.readingSubType == subType && o.reading && o.reading.readingType == reading;
          }
          else{
            if(o.ruleType == 'Weight' && description == 'Weight Difference'){
              return !o.isBodytraceReading && o.reading && o.reading.readingType == reading;
            }else{
              if(description != 'Weight Difference'){
                return !o.isBodytraceReading && o.readingSubType == subType && o.reading;
              }
            }
          }
        }
      });
      readings = _.filter(props.readings, {
        readingType: value.reading
      });
      readingValue = _.map(readings, "value");
      checkThis = _.map(readingValue, (readingIndex) => {
        return readingIndex[value.index];
      });
      getOnlyRelatedViolation = filter(props.violations, function (o) {
        var checkReading = _.find(props.readings, {"_id": o.reading ? o.reading._id : ""});
        if(checkReading != undefined && checkReading){
          if(subType == 'Heart Rate'){
            return !o.isBodytraceReading && o.readingSubType == subType && o.reading && o.reading.readingType == reading;
          }
          else{
            if(o.ruleType == 'Weight' && description == 'Weight Difference'){
              return !o.isBodytraceReading && o.reading && o.reading.readingType == reading;
            }else{
              if(description != 'Weight Difference'){
                return !o.isBodytraceReading && o.readingSubType == subType && o.reading;
              }
            }
          }
        }
      });
      totalWeightReading = size(checkThis);
    }
    if(description == 'Weight Difference' || subType == 'Weight'){
      _.filter(readings, (reading) => {
        // Checking Which reading has Events or Exceptions are generated
        const eventCount = _.filter(mViolation, el => el.reading && el.reading._id.toString() === reading._id.toString() && el.name.toLowerCase().indexOf("event") !== -1);
        const exceptionCount = _.filter(mViolation, el => el.reading && el.reading._id.toString() === reading._id.toString() && el.name.toLowerCase().indexOf("event") === -1);
        if(size(eventCount) == 0 && size(exceptionCount) == 0){
          weightChange = weightChange + 1;
        }
        if(size(eventCount) > 0){
          weightEvent = weightEvent + 1;
        }
        if(size(exceptionCount) > 0){
          weightNotification = weightNotification + 1;
        }
      });
      totalWeightReading = size(checkThis);
    }
    var checkNan = isNaN(_.min(checkThis));
    if (readings.length === 0 || checkNan || readings.length === 0 ) return;
    if (getOnlyRelatedViolation.length > 0 || checkThis.length > 0) {
      const smsViolationsSize = size(
        getOnlyRelatedViolation.filter(function (i) {
          return (
            (i.patientNotifications && i.patientNotifications.includes("sms")) ||
            (i.providerNotifications && i.providerNotifications.includes("sms")) ||
            (i.staffNotifications && i.staffNotifications.includes("sms"))
          );
        })
      );
      const inboxViolationsSize = size(
        getOnlyRelatedViolation.filter(function (i) {
          return (
            (i.patientNotifications && i.patientNotifications.includes("inbox")) ||
            (i.providerNotifications && i.providerNotifications.includes("inbox")) ||
            (i.staffNotifications && i.staffNotifications.includes("inbox"))
          );
        })
      );
      const emailViolationsSize = size(
        getOnlyRelatedViolation.filter(function (i) {
          return (
            (i.patientNotifications && i.patientNotifications.includes("email")) ||
            (i.providerNotifications && i.providerNotifications.includes("email")) ||
            (i.staffNotifications && i.staffNotifications.includes("email"))
          );
        })
      );
      const voiceViolationsSize = size(
        getOnlyRelatedViolation.filter(function (i) {
          return (
            (i.patientNotifications && i.patientNotifications.includes("voice")) ||
            (i.providerNotifications && i.providerNotifications.includes("voice")) ||
            (i.staffNotifications && i.staffNotifications.includes("voice"))
          );
        })
      );
      var matchingExpectationsBreached = _.filter(props.expectation,
        function (o) {
          return (
            o && o.readingSubType.toLowerCase() == _.replace(_.replace(value.name, "Pulse", "Heart Rate").toLowerCase(), "bpm", "Heart Rate").toLowerCase() &&
            !o.name.toLowerCase().includes("Event".toLowerCase()) 
          );
        }
      );
      var expectationList = matchingExpectationsBreached;
      // Checked all of the readings to see which had a low threshold.
      var lowExpectation = _.flatMap(_(expectationList)
        .filter((o) => o && o.condition == "<=" || o.condition == "between")
        .flatMap((_value, i) => _.pick(_value, "valueProperty"))
        .value(), "valueProperty");

      var highExpectation = _.flatMap(_(expectationList)
        .filter((o) => o && o.condition == ">=" || o.condition == "between")
        .flatMap((_value, i) => _.pick(_value, "valueProperty"))
        .value(), "valueProperty");

      var lowMinThreshold = _.maxBy(_.map(lowExpectation, i => i.minValue || i.value), function (o) { return o && parseInt(o) });
      var highMaxThreshold = _.minBy(_.map(highExpectation, i => i.maxValue || i.value), function (o) { return o && parseInt(o) });
      var lowThreshold = _.filter(checkThis, (reading, i) => {
        if(value.abbrev == 'TM'){
          reading = ((reading * 9) / 5 + 32).toFixed(2);
        }
        if (lowMinThreshold != undefined) {
          return lowMinThreshold != undefined ? parseFloat(reading) < parseFloat(lowMinThreshold) : parseFloat(reading) < parseFloat(lowMinThreshold);
        } else {
          return false;
        }
      });
      // Checked all of the readings to see which had a high threshold.
      var highThreshold = _.filter(checkThis, (reading, i) => {
        if(value.abbrev == 'TM'){
          reading = ((reading * 9) / 5 + 32).toFixed(2);
        }
        if (highMaxThreshold != undefined) {
          return highMaxThreshold != undefined ? parseFloat(reading) > parseFloat(highMaxThreshold) : parseFloat(reading) > parseFloat(highMaxThreshold);
        } else {
          return false;
        }
      });
      var lastSevenDays = _.size(checkThis);
      var countLowThreshold = Math.round(
        (_.size(lowThreshold) / lastSevenDays) * 100
      );
      var countHighThreshold = Math.round(
        (_.size(highThreshold) / lastSevenDays) * 100
      );
      var goodThreshold = Math.round(
        100 -
        (parseFloat(countLowThreshold) + parseFloat(countHighThreshold))
      );
      var goodReading = Math.round(
        lastSevenDays - (_.size(lowThreshold) + _.size(highThreshold))
      );
      var readingName = _.replace(value.name, "Pulse", "Heart Rate");
      var weightNotificationPer = Math.round(
          (weightNotification / lastSevenDays) * 100
      );
      var weightEventPer = Math.round(
          (weightEvent / lastSevenDays) * 100
      );
      var weightChangePer = Math.round(
          (weightChange / lastSevenDays) * 100
      ); 
      var symbolName = ["Systolic","Diastolic","Heart Rate","Weight"];
      return (
        <div className="my-5 break-page-reading-type">
          <hr style={{ borderTop: "3px solid #5c5959" }} />
          <h3 className="text-center font-weight-bold">            
            <div>
              {readingName} ({value.description})
            </div>
          </h3>
          <Row>
            <Col>
              <InterventionDetails 
              violations={mViolation}
              readings={readings} />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="text-black text-dark text-center mx-auto"
                    >
                      { symbolName.includes(value.name) ? (value.name != 'Weight' ? value.name + " Vital Range Results" : "Unhealthy Weight Change Results" ) : value.name.replace("Pulse","Heart Rate") + " "+ moment(props.start).format("MMMM") + " Out of Range Results" }
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td>{(value.description != 'Weight Difference' && value.name != 'Weight') ? readingName + ' Low' : 'Unhealthy Weight Change Notification '} %</td>
                    <td>
                      {value.description != 'Weight Difference' && value.name != 'Weight' && (expectationList !== undefined && expectationList.length > 0) && (
                        <div> {countLowThreshold || 0}% {"(" + _.size(lowThreshold) + ")"}</div>
                      )}
                      {value.description != 'Weight Difference' && value.name != 'Weight' && (expectationList == undefined || expectationList.length == 0) && (
                        <div style={{color:"red"}}> Not included in Care Plan</div>
                      )}
                      {(value.description == 'Weight Difference' || value.name == 'Weight') && (
                         <div> {weightNotificationPer || 0}% {"(" + weightNotification + ")"}</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{(value.description != 'Weight Difference' && value.name != 'Weight') ? readingName + ' High' : 'Unhealthy Weight Change Event '} %</td>
                    <td>
                      {value.description != 'Weight Difference' && value.name != 'Weight' && (expectationList !== undefined && expectationList.length > 0) && (
                        <div> {countHighThreshold || 0}% {"(" + _.size(highThreshold) + ")"}</div>
                      )}
                      {value.description != 'Weight Difference' && value.name != 'Weight' && (expectationList == undefined || expectationList.length == 0) && (
                        <div style={{color:"red"}}> Not included in Care Plan</div>
                      )}
                      {(value.description == 'Weight Difference' || value.name == 'Weight') && (
                        <div>{weightEventPer || 0}% {"(" + weightEvent + ")"}</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{(value.description != 'Weight Difference' && value.name != 'Weight') ? readingName + ' Good' : 'No Unhealthy Weight Change '}  %</td>
                    <td>
                      {value.description != 'Weight Difference' && value.name != 'Weight' && (expectationList !== undefined && expectationList.length > 0) && (
                        <div> {goodThreshold || 0}% {"(" + goodReading + ")"}</div>
                      )}
                      {value.description != 'Weight Difference' && value.name != 'Weight' && (expectationList == undefined || expectationList.length == 0) && (
                        <div style={{color:"red"}}> Not included in Care Plan</div>
                      )}
                      {(value.description == 'Weight Difference' || value.name == 'Weight') && (
                        <div>{weightChangePer || 0}% {"(" + weightChange + ")"}</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{(value.description != 'Weight Difference' && value.name != 'Weight') ? 'Total Readings' : 'Total Weight Readings'}</td>
                    <td>{(value.description != 'Weight Difference' && value.name != 'Weight') ?  (checkThis ? _.size(checkThis) : 0) : totalWeightReading }</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="text-black text-dark text-center mx-auto"
                    >
                      {readingName} Intervention Totals
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td>SMS Notification</td>
                    <td>{smsViolationsSize}</td>
                  </tr>
                  <tr>
                    <td>Inbox Notification</td>
                    <td>{inboxViolationsSize}</td>
                  </tr>
                  <tr>
                    <td>Email Notification</td>
                    <td>{emailViolationsSize}</td>
                  </tr>
                  <tr>
                    <td>Voice Notification</td>
                    <td>{voiceViolationsSize}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }            
  });
}
export default ReadingSubTypeSummary;

function InterventionDetails(props) {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="text-black text-dark text-center mx-auto">
            Exception Date
          </th>
          <th className="text-black text-dark text-center mx-auto">
            Notifications Sent
          </th>
          <th className="text-black text-dark text-center mx-auto">Name</th>
          <th className="text-black text-dark text-center mx-auto">
            Condition
          </th>
          <th className="text-black text-dark text-center mx-auto">Value</th>
        </tr>
      </thead>    
      <tbody className="text-center">
        {props.violations.length > 0 ? (
          props.violations.map((violation) => {
            var value = 0;
            if(violation.reading && !violation.reading.imei){
              if(violation.reading && violation.reading.readingValue && violation.reading.readingValue.BPM){
                value = violation.reading && violation.reading.readingValue  ? violation.reading.readingValue.BPM : <div className="text-center"> - </div>;
              } else {
                value = violation.reading && violation.reading.readingValue && violation.ruleType != 'Weight'  ? (violation.readingSubType != 'Weight' ? (violation.readingSubType != "Body Temperature" ? violation.reading.readingValue[
                  violation.readingSubType.toLowerCase().replace("heart rate","pulseRate").replace("oxygen saturation","spo2").replace("body temperature","temperature").replace("bpm","BPM")
                ] : (Math.round(violation.reading.value * 9) / 5 + 32))  : (Math.round(violation.reading.value * 2.20462))) : (violation.ruleType == 'Weight' ? (Math.round(violation.reading.value * 2.20462)) : <div className="text-center"> - </div>);
              }
            } else {
              value = violation.reading && violation.reading.values  ? violation.reading.values[
                violation.readingSubType.toLowerCase().replace("heart rate","pulse").replace("oxygen saturation","spo2").replace("body temperature","temperature").replace("bpm","BPM").replace("ws","weight")
              ] : <div className="text-center"> - </div>;
            }
            return (
              <tr>
                <td>{moment(violation.createdAt).format("MM/DD/YYYY")}</td>
                <td className="text-left">
                  <p>
                    Patient:{" "}
                    {(violation.patientNotifications && violation.patientNotifications.length > 0)
                      ? violation.patientNotifications
                      : "none"}
                  </p>
                  <p>
                    Provider:{" "}
                    {(violation.providerNotifications && violation.providerNotifications.length > 0)
                      ? violation.providerNotifications
                      : "none"}
                  </p>
                  <p>
                    Staff:{" "}
                    {(violation.staffNotifications && violation.staffNotifications.length > 0)
                      ? violation.staffNotifications
                      : "none"}
                  </p>
                </td>
                <td className="text-left">{violation.name}</td>
                <td >
                  {violation.ruleExpectation.condition}
                  {violation.ruleExpectation.valueProperty 
                    ? violation.ruleExpectation.condition == "between" ? " - " + violation.ruleExpectation.valueProperty[0].minValue +" TO " + violation.ruleExpectation.valueProperty[0].maxValue : violation.ruleExpectation.valueProperty[0].value  
                    : (violation.ruleExpectation.gainLossPerDay ? "Pound loss/gain per Day - " + violation.ruleExpectation.gainLossPerDay : <div className="text-center"> - </div>)}
                </td>
                <td>
                  {value}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="5" style={{ color: "red" }}>
              No Exceptions
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
