import React from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
const QuestionList = (props) => {
  return (
    <Card>
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Options</th>
              <th>Answer</th>
              {props.actions.length !== 0 ? <th>Action</th> : ""}
            </tr>
          </thead>

          {props.question_list != undefined &&
            props.question_list.map((value, key) => {
              return (
                <>
                  <tbody>
                    <tr>
                      <td scope="row">
                        <span key={key}>{value.question}</span>
                      </td>
                      <td>
                        <span>
                          <ol className="ps-3">
                            {value.answers.map((value, key) => {
                              return (
                                <>
                                  <li key={key}>{value.answer}</li>
                                </>
                              );
                            })}
                          </ol>
                        </span>
                      </td>
                      <td>
                        <span>
                          <ol className="ps-3">
                            {value.answers.map((value, key) => {
                              return value.isCurrectAnswer === true ? (
                                <>
                                  <li key={key}>{value.answer}</li>
                                </>
                              ) : (
                                ""
                              );
                            })}
                          </ol>
                        </span>
                      </td>
                      {props.actions.length !== 0 ? (
                        <td>
                          {props.actions.map((val) => {
                            return (
                              <>
                                <Button
                                  size="sm"
                                  style={{ borderRadius: 7 }}
                                  className={"secondary-add-button"}
                                  onClick={() => {
                                    val.click(key);
                                  }}
                                >
                                  {val.label}
                                </Button>
                                <>&nbsp;&nbsp;&nbsp;</>
                              </>
                            );
                          })}
                        </td>
                      ) : (
                        ""
                      )}
                      {/* {props.actions.length !== 0 ? (
                        <td>
                          <Button
                            size="sm"
                            style={{ borderRadius: 7 }}
                            className={"secondary-add-button"}
                            onClick={() => {
                              props.actions.model(key);
                            }}
                          >
                            Edit
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            size="sm"
                            style={{ borderRadius: 7 }}
                            className={"secondary-add-button"}
                            onClick={() => {
                              props.removeQuestion(key);
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      ) : (
                        ""
                      )} */}
                    </tr>
                  </tbody>
                </>
              );
            })}
        </Table>
      </CardBody>
    </Card>
  );
};

export default QuestionList;
