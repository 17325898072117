import React from 'react';
import Lottie from 'react-lottie';

const LottieLoader = (props) => {
    const { source, Lheight, Lwidth, loadLoop, autoplayLoop,title,subTitle,titleSmall,subTitleSmall,subTitleExtraSmall,extraSubTitleExtraSmall } = props;
    const defaultOptions = {
        loop: loadLoop,
        autoplay: autoplayLoop,        
        animationData: source,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <Lottie options={defaultOptions} height={Lheight} width={Lwidth} />
            {title &&
                <h3>{title}</h3>
            }            
            {subTitle &&
                <h5 style={{textAlign:"center"}}>{subTitle}</h5>
            }
            {titleSmall &&
                <h5 style={{textAlign:"center"}}>{titleSmall}</h5>
            }            
            {subTitleSmall &&
                <h6 style={{textAlign:"center"}}>{subTitleSmall}</h6>
            }                       
            {subTitleExtraSmall &&
                <h6 style={{textAlign:"center", fontSize:13}}>{subTitleExtraSmall}</h6>
            }
            {extraSubTitleExtraSmall &&
                <h6 style={{textAlign:"center", fontSize:10}}>{extraSubTitleExtraSmall}</h6>
            }            
        </>
    );
};
export default LottieLoader;
