import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import RuleList from "./RuleList";
import Heading from "./Heading";
import PickRuleModal from "./PickRuleModal";
const AppContext = React.createContext();
class Sharing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      showAlertSuccess: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    if(!this.props.profile.isStaff) {
      this.props.dispatch({
        type: "DO_GET_CAREPROVIDER_EXPECTATIONS",
        payload: { careproviderId: this.props.profile._id }
      });
      this.props.dispatch({
        type: "DO_GET_CONDITIONS",
        payload: { careproviderId: this.props.profile._id }
      });
    }
  }

  render() {
    const name = this.props.profile.fname.replace(/\s+/g, "-").toLowerCase() + "-" + this.props.profile.lname.replace(/\s+/g, "-").toLowerCase();
    return (
      <div>
        {/*<Row>
          <a
            target="_blank"
            onClick={this.toggleModal}
            className="text-white ml-auto btn btn-info btn-rounded btn-fw"
          >
            Create a New Expectation
          </a>
        </Row>*/}
        <Row>
          <Col sm="12" className="grid-margin">
            {this.props.careProviderExpectations && (
              <RuleList
                myRules={this.props.careProviderExpectations}
                userId={this.props.profile._id}
              />
            )}
          </Col>
        </Row>
        {this.props.profile && (
          <PickRuleModal
            modal={this.state.modal}
            toggleModal={this.toggleModal}
            id={this.props.profile._id}
            name={name}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    careProviderExpectations
  } = careprovider;
  const profile = user.profile;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    careProviderExpectations,
    profile
  };
};

export default connect(mapStateToProps)(Sharing);
