import React from "react";
import Link from "components/ExtendLink";
const SelectationTab = (props) => (
  <div
    className="card-content"
    style={props.style}
  >
    <div className="card-body cleartfix onboarding-card-fixed-height remove-padding-onbiarding">
      <div className="media align-items-stretch">
        <div className="align-self-center">
          <i className="icon-pencil primary font-large-2 mr-2" />
        </div>
        <div className="media-body text-center">
          <h4 style={{fontSize:17}}>{props.title}</h4>
          <h1>{props.displayCount}</h1>
        </div>
      </div>
    </div>
  </div>
  
);

export default SelectationTab;
