import React, { Component } from "react";
import { connect } from "react-redux";

import StaffClient from "./StaffClient/";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
class ClientStaff extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      userToSend: this.props.currentUserId,
      body: "",
      subject: "",
      isPublic: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.deleteInbox = this.deleteInbox.bind(this);
    this.readInbox = this.readInbox.bind(this);
  }
  handleUsers = selectedOption => {
    this.setState({ userToSend: selectedOption });

    
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  resetForm() {
    this.setState({
      modal: !this.state.modal,
      body: "",
      subject: "",
      isPublic: false
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
  }
  deleteInbox(id) {
    this.props.dispatch({
      type: "DO_DELETE_USER_NOTES",
      payload: {
        id: id,
        isClient: true
      }
    });
    this.props.dispatch({
      type: "DO_GET_USER_NOTES",
      payload: { userid: this.props.currentUserId, isClient: true }
    });
  }
  readInbox(id) {
    this.props.dispatch({
      type: "DO_READ_NOTES",
      payload: {
        id: id,
        isClient: true
      }
    });
    this.props.dispatch({
      type: "DO_GET_NOTES",
      isClient: true
    });
  }
  submit(e) {
    e.preventDefault();

    if (this.props.hideNotesButton) {
      this.props.dispatch({
        type: "DO_SAVE_USER_NOTES",
        payload: {
          user: this.state.userToSend,
          body: this.state.body,
          subject: this.state.subject,
          isPublic: this.state.isPublic,
          patientPath: true,
          isClient: true
        }
      });
      this.props.dispatch({
        type: "DO_GET_USER_NOTES",
        payload: {
          userid: this.props.currentUserId,
          patientPath: true,
          isClient: true
        }
      });
    } else {
      this.props.dispatch({
        type: "DO_SAVE_USER_NOTES",
        payload: {
          user: this.state.userToSend,
          body: this.state.body,
          subject: this.state.subject,
          isPublic: this.state.isPublic,
          isClient: true
        }
      });
      this.props.dispatch({
        type: "DO_GET_USER_NOTES",
        payload: { userid: this.props.currentUserId, isClient: true }
      });
    }

    this.resetForm();
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_STAFF",
      payload: { isClient: true, isAll: true }
    });
  }

  render() {
    return <StaffClient clientStaff={this.props.clientStaff} isClient isAll />;
  }
}

function mapStateToProps({ careprovider, user, client }) {
  const {
    notes,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage
  } = careprovider;
  const isCareProvider = user.userDetails.isCareProvider;
  const { clientStaff } = client;
  return {
    notes,
    isCareProvider,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    clientStaff
  };
}
export default connect(mapStateToProps)(ClientStaff);
