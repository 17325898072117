import io from 'socket.io-client';

// Set the root URL based on the environment
let rootURL;
if (process.env.NODE_ENV === "development") {
    rootURL = "http://localhost:3005"; // Development environment URL
} else {
    rootURL = "https://dev.portal.myvitalview.com";
}

// Initialize socket connection
const socket = io(rootURL);

// Handle socket connection errors
socket.on('error', (error) => {
    console.error('Socket error:', error);
    // Handle the error, e.g., retry connection, notify user, etc.
});

/**
 * Register a user with the socket server
 * @param {string} userId - The ID of the user to register
 */
export const registerUser = (userId) => {
    try {
        setTimeout(() => {
            socket.emit('register', userId);
        }, 2000); // Delay the registration by 2 seconds
    } catch (error) {
        console.error('Error registering user:', error);
        // Handle error appropriately
    }
};

/**
 * Emit a message read status event
 * @param {object} data - The message data
 * @param {string} recepient - The ID of the recipient
 */
export const messageReadStatus = (data, recepient) => {
    try {
        socket.emit('messageReadStatus', {
            data: data,
            recepient: recepient
        });
    } catch (error) {
        console.error('Error sending message read status:', error);
        // Handle error appropriately
    }
};

/**
 * Disconnect the socket connection
 */
export const disconnectUser = () => {
    try {
        socket.disconnect();
    } catch (error) {
        console.error('Error disconnecting socket:', error);
        // Handle error appropriately
    }
};

/**
 * Reconnect the socket connection
 */
export const connectUser = () => {
    try {
        socket.connect();
    } catch (error) {
        console.error('Error reconnecting socket:', error);
        // Handle error appropriately
    }
};

/**
 * Listen for incoming messages
 * @param {function} callback - The callback function to handle incoming messages
 */
export const onReceiveMessage = (callback) => {
    try {
        socket.on('receiveMessage', (data) => {
            callback(data);
        });
    } catch (error) {
        console.error('Error listening for receiveMessage:', error);
        // Handle error appropriately
    }
};

/**
 * Listen for user disconnection events
 * @param {function} callback - The callback function to handle user disconnection events
 */
export const onUserDisconnected = (callback) => {
    try {
        socket.on('userDisconnected', (data) => {
            callback(data);
        });
    } catch (error) {
        console.error('Error listening for userDisconnected:', error);
        // Handle error appropriately
    }
};

/**
 * Send a message through the socket
 * @param {string} message - The message content
 * @param {string} to - The recipient ID
 * @param {string} senderId - The sender ID
 */
export const sendSocketMessage = (message, to, senderId) => {
    try {
        socket.emit('sendMessage', { message, to, senderId });
    } catch (error) {
        console.error('Error sending message:', error);
        // Handle error appropriately
    }
};

export default socket;
