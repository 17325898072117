import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import "./../../css/css/message_chat.css";
import ChatMessage from "./ChatMessage";
import moment from "moment";
import LottieLoader from "../LottieLoader";
import lottieLoaderFile from '../../files/animation/loading.json';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import _ from "lodash";
import {
  onReceiveMessage,
  onUserDisconnected,
  sendSocketMessage
} from '../ChatTextMessage/socket.js';

const ChatTextMessage = (props) => {
  const dispatch = useDispatch();
  const { patientId, isLoadingChatList, allChatMessages } = props;
// Removing duplicates by 'createdAt' property
  const allChatMessagesUpdated = _.uniqBy(allChatMessages, 'createdAt');
  const [message, setMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMessages, setFilteredMessages] = useState([]); // State to hold filtered messages
  const chatMessagesRef = useRef(null);
  // Logic to change section    
  // useEffect(() => {
  //   onReceiveMessage((data) => {
  //     const objectExists = _.some(allChatMessagesUpdated, { _id: data._id });
  //     if (!objectExists) {
  //       //console.log("RECEIVED MESSAGES :" + JSON.stringify(data));
  //       dispatch({
  //         type: "DO_CHAT_MESSAGE",
  //         payload: data
  //       });
  //     } else {
  //       console.log("RECEIVED MESSAGES IS EXIST IN ARRAY");
  //     }
  //   });   
  // }, [dispatch]);

  // Function to scroll chat messages to the bottom
  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTo({
        top: chatMessagesRef.current.scrollHeight,
        behavior: "smooth" // Use smooth behavior for scroll animation
      });
    }
  };

  // Effect hook to fetch initial data and unread count on component mount
  useEffect(() => {
    dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    fetchData();
  }, [dispatch]);

  // Effect hook to scroll to bottom when chat messages update
  useEffect(() => {
    scrollToBottom();
  }, [allChatMessagesUpdated]);

  // Function to fetch chat messages for a specific patient
  const fetchData = () => {
    if (patientId) {
      dispatch({
        type: "DO_GET_ALL_CHAT_MESSAGES",
        payload: {
          patient: patientId,
          tz: moment.tz.guess()
        }
      });
    }
  };

  // Function to handle input change in search field
  const handleChangeSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.trim() !== '') {
      // Implement your search logic here, for example:
      // This will filter messages containing the searchTerm
      // Case insensitive search
      const filteredMessages = allChatMessagesUpdated.filter(message =>
        message.body.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredMessages(filteredMessages); // Update state with filtered messages
    } else {
      const filteredMessages  = allChatMessagesUpdated;
      setFilteredMessages(filteredMessages);
    }

  };

  // Effect hook to scroll to bottom after rendering messages or search results
  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToBottom();
    }, 100); // Adjust timeout delay as needed

    return () => clearTimeout(timeout);
  }, [allChatMessagesUpdated, filteredMessages]); // Trigger scroll when messages or search results update

  // Function to clear search and display all messages
  const clearSearch = () => {
    setSearchTerm('');
    setFilteredMessages([]);
  };

  // Function to handle sending a message
  const onFormSubmit = e => {
    e.preventDefault();
    if (message.trim() !== '') {
      dispatch({
        type: "DO_SEND_CHAT_MESSAGE",
        payload: {
          patientId: patientId,
          //senderId: props.userId,
          message: message.trim()
        }
      });
      setMessage('');
      sendSocketMessage(message, patientId, props.userId);
    }
  }
  return (
    <div className="col-md-12 mx-auto">
      <div className="col-12 col-lg-12 col-xl-12">
        {!isLoadingChatList && allChatMessagesUpdated && allChatMessagesUpdated.length > 0 && (
          <>
            {/* Search input field */}
            <div className="flex-grow-0 py-3 px-4 border-top padding-margin-sent">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control input-date-filter remove-bottom-margin"
                  placeholder="Search messages"
                  value={searchTerm}
                  onChange={handleChangeSearch}
                />
                {searchTerm !== '' && (
                  <button className="btn-primary clear-search-btn" onClick={clearSearch}>
                    <i class="mdi mdi-close" style={{ fontSize: 18 }}></i>Clear Search
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        <div className="position-relative">
          <div className="chat-messages p-4" ref={chatMessagesRef}>
            {isLoadingChatList && (
              <LottieLoader
                loadLoop={true}
                autoplayLoop={true}
                source={lottieLoaderFile}
                Lheight={100}
                Lwidth={100}
                subTitle={"Please Wait ..."}
              />
            )}
            {!isLoadingChatList && (!allChatMessagesUpdated || _.size(allChatMessagesUpdated) === 0) && (
              <div className="text-center py-5">
                <LottieLoader
                  loadLoop={false}
                  autoplayLoop={true}
                  source={lottieEmptyFile}
                  Lheight={300}
                  Lwidth={300}
                  title={"Sorry! No message found :("}
                  subTitle={"We can't find any messages."}
                />
              </div>
            )}
            {!isLoadingChatList && allChatMessagesUpdated && allChatMessagesUpdated.length > 0 && (
              <>
                {searchTerm !== '' && filteredMessages.length === 0 ? (
                  <div className="text-center py-5">
                    <LottieLoader
                      loadLoop={false}
                      autoplayLoop={true}
                      source={lottieEmptyFile}
                      Lheight={150}
                      Lwidth={150}
                      subTitle={"No messages found as " + searchTerm}
                    />
                  </div>
                ) : (
                  (filteredMessages.length > 0 ? filteredMessages : allChatMessagesUpdated ? allChatMessagesUpdated : []).map((model) => (
                    (model && model.sender && (
                      <ChatMessage
                        key={model._id}
                        isSender={props.userId === model.sender._id}
                        dataModel={model}
                        name={model.sender.fname + " " + model.sender.lname}
                        image={model.sender.profileImage}
                      />
                    ))             
                  ))
                )}
              </>
            )}
          </div>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className="flex-grow-0 py-3 px-4 border-top padding-margin-sent">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-date-filter remove-bottom-margin"
                placeholder="Type your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                className={"btn-primary sent-message-click " + (message.trim() !== '' ? "" : "disable")}
                type="submit">
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatTextMessage;
