import React, { useState } from "react";
import { useFetch2, formatDuration } from "components/utils/authenticatedHook";
import { useFetch } from "components/utils/fetchHook";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Row,
  FormGroup,
  Label,
  Input,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormText,
  FormFeedback,
  ModalFooter
} from "reactstrap";
import classnames from "classnames";
import Heading from "./Heading";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { map, size } from "lodash";

import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import SweetAlert from "react-bootstrap-sweetalert";
import ConditionTable from "./ConditionTable";
function Condition() {
  const allPatients = useSelector(state => state.careprovider.usersOfferedAccess);
  const patients = map(allPatients, "receivedFrom");
  const { register, handleSubmit, errors } = useForm();
  const {
    response: responseGet,
    error: errorGet,
    isLoading: isLoadingGet
  } = useFetch(`/careprovider/conditions`);
  // const {
  //   response: responseGet2,
  //   error: errorGet2,
  //   isLoading: isLoadingGet2
  // } = useFetch(`/careprovider/sharing/can-access`);
  const [{ response, error, isLoading }, doFetch, sendError] = useFetch2(null);

  const responseGetData = responseGet ? responseGet.data : responseGet;

  const [tab, setTab] = useState(1);
  const [modal, setModal] = useState(false);
  const [sweetalert, setSweetalert] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(false);
  const [patientsPlaceholder, setPatientsPlaceholder] = useState();
  const responseData = response ? response.data : response;

  const combineResponse =
    size(responseData) > 0 ? responseData : responseGetData;
  const onSubmit = data => {
    const patients = patientsPlaceholder
      ? patientsPlaceholder.patientsAssigned
      : [];
    const payload = { ...data, patients };
    const valueCheck = doFetch({
      url: "/condition",
      method: "POST",
      body: JSON.stringify(payload)
    });
    setModal(!modal);
  };
  const onSubmitUpdate = data => {
    const patients = patientsPlaceholder
      ? patientsPlaceholder.patientsAssigned
      : [];
    const payload = { ...data, patients };
    const valueCheck = doFetch({
      url: "/condition",
      method: "PUT",
      body: JSON.stringify(payload)
    });
    setModalEdit(!modalEdit);
  };
  const updateCondition = (data, patients) => {
    setModalEdit(!modalEdit);
    setValueEdit(data);
    const patientIds = map(data.patients, "_id");
    const originalPatients = patients;
    const newPatients = data.patients;
    const patientValue = originalPatients.filter(value => {
      const patientId = value._id;
      return patientIds.includes(patientId);
    });
    setPatientsPlaceholder({
      patientsAssigned: patientIds,
      patientsPlaceholder: patientValue
    });
  };
  const deleteCondition = data => {
    // const valueCheck = doFetch({
    //   url: `/condition?_id=${data}`,
    //   method: "DELETE"
    // });
    setSweetalert(true);
    setDeleteId(data);
  };
  const deleteConditionNow = deleteId => {
    const valueCheck = doFetch({
      url: `/condition?_id=${deleteId}`,
      method: "DELETE"
    });
    setSweetalert(false);
    setDeleteId(null);
  };
  const clearError = () => {
    sendError({ error: null, message: null });
  };
  const handleUsers = selectedOption => {
    const patientIds = map(selectedOption, "_id");
    setPatientsPlaceholder({
      patientsAssigned: patientIds,
      patientsPlaceholder: selectedOption
    });
  };

  if (isLoadingGet) {
    return (
      <ReactLoading type="spin" className="text-center mx-auto" color="blue" />
    );
  }
  return (
    <div>
      <div className="row">
        <Heading
          title="CONDITIONS"
          description=""
          backbutton="d-none"
          mainbutton="d-block"
          actionMainButton={() => {
            setModal(!modal);
            setPatientsPlaceholder(null);
          }}
        >
          <span className="page-title-icon bg-gradient-danger   text-white mr-2">
            <i className="mdi mdi-account-convert" />
          </span>
        </Heading>

        <div className="col-12 grid-margin">
          <div className="card overflow-hidden">{/* Shrey Added */}
              <Nav tabs className="justify-content-center">{/* Shrey Added */}
                <NavItem>
                  <NavLink
                    className="active"
                    onClick={() => {
                      setTab("1");
                    }}
                  >
                    CONDITIONS
                  </NavLink>
                </NavItem>
              </Nav>
            <div className="card-body project-tab pt-2">
              <Row>
                <h5 className="mb-4" />
                {isLoading && (
                  <ReactLoading
                    type="spin"
                    className="text-center mx-auto"
                    color="blue"
                  />
                )}
                <Col md={12} className="row-pading-remove-condition">
                  <ConditionTable
                    patients={patients}
                    conditions={combineResponse}
                    updateCondition={updateCondition}
                    deleteCondition={deleteCondition}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          Create A Condition
        </ModalHeader>
        <ModalBody className="bg-white">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="exampleSelect">Condition</Label>
              <Input
                className="input-date-filter remove-bottom-margin"
                type="text"
                name="condition"
                innerRef={register}
                required={true}
                onClick={clearError}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">Description</Label>
              <Input
                className="input-date-filter remove-bottom-margin"
                type="text"
                name="description"
                innerRef={register}
                required={true}
                onClick={clearError}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">
                Pick one or more Patient to Assign to this Condition
              </Label>
              <Select
                value={
                  patientsPlaceholder
                    ? patientsPlaceholder.patientsPlaceholder
                    : ""
                }
                onChange={handleUsers}
                options={patients}
                isMulti={true}
                getOptionLabel={option =>
                  option["fname"] + " " + option["lname"]
                }
                getOptionValue={option => option}
              />
              <FormText color="muted">
                You can also assign patient after creating condition
              </FormText>
              <FormFeedback>
                {error && error.message ? error.message : ""}
              </FormFeedback>
            </FormGroup>
            <button type="submit" className="btn btn-block btn-info">
              {isLoading ? (
                <ReactLoading
                  type="spin"
                  height={"24px"}
                  width={"24px"}
                  className="text-center mx-auto"
                  color="white"
                />
              ) : (
                "Send"
              )}
            </button>
          </Form>
        </ModalBody>
      </Modal>
      <SweetAlert
        warning
        showCancel
        show={sweetalert}
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => deleteConditionNow(deleteId)}
        onCancel={() => setSweetalert(false)}
      >
        This will delete the condition and patients belong to it
      </SweetAlert>
      <Modal isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)}>
        <ModalHeader toggle={() => setModalEdit(!modalEdit)}>
          Update A Condition
        </ModalHeader>
        <ModalBody className="bg-white">
          <Form onSubmit={handleSubmit(onSubmitUpdate)}>
            <FormGroup>
              <Label for="exampleSelect">Condition</Label>
              <Input
                className="input-date-filter remove-bottom-margin"
                type="hidden"
                name="_id"
                defaultValue={valueEdit._id}
                innerRef={register}
                required={true}
                onClick={clearError}
              />
              <Input
                className="input-date-filter remove-bottom-margin"
                type="text"
                name="condition"
                defaultValue={valueEdit.condition}
                innerRef={register}
                required={true}
                onClick={clearError}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">Description</Label>
              <Input
                className="input-date-filter remove-bottom-margin"
                rows="6"
                type="textarea"                
                name="description"
                innerRef={register}
                defaultValue={valueEdit.description}
                required={true}
                onClick={clearError}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">
                Removing a patient from the option will automatically removed
                them from the list of this condition
              </Label>
              <Select
                value={
                  patientsPlaceholder
                    ? patientsPlaceholder.patientsPlaceholder
                    : ""
                }
                onChange={handleUsers}
                options={patients}
                isMulti={true}
                getOptionLabel={option =>
                  option["fname"] + " " + option["lname"]
                }
                getOptionValue={option => option}
              />
              <FormText color="muted" />
              <FormFeedback>
                {error && error.message ? error.message : ""}
              </FormFeedback>
            </FormGroup>
            <button type="submit" className="btn btn-block btn-info">
              {isLoading ? (
                <ReactLoading
                  type="spin"
                  height={"24px"}
                  width={"24px"}
                  className="text-center mx-auto"
                  color="white"
                />
              ) : (
                "Send"
              )}
            </button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Condition;
