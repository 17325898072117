import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import {
  createFilter,
  createBlacklistFilter
} from "redux-persist-transform-filter";
import rootSaga from "./sagas";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const sagaMiddleware = createSagaMiddleware();

const initialState = {};

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "careprovider"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware, thunk))
);

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

const configureStore = () => {
  return { persistor, store };
};
export function getPersistor() {
  return persistor;
}
export default configureStore;
