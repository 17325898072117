import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import onBoarding from "../../files/ic_no_device_reprot.svg";
import FaxReportList from "./DevicesList";
import DevicesList from "./DevicesList";
const _ = require("lodash");
class FaxReport extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      activeTab: "1",
      onDisplayAllCcmOnboarding: true,
      onDisplayNeedToRequestCCMOrderFromProvider: false,
      onDisplayRequestedCCMOrderFromProvider: false, 
      loading: true,
      catagoryID: undefined
    };
  }
  
  componentDidMount() {   
    this.fetchData();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 grid-margin">          
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center nav-header-border-radius">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      DEVICE REPORT
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row style={{marginTop:25}}>                  
                  <DevicesList
                      fetchDataFromServer={click => this.fetchData = click}
                      catagoryID={this.state.catagoryID}
                      profileOwner={this.props.profileOwner}
                      userId={this.props.userId}
                    />
                </Row>                
              </div>
            </div>
          </div>
        </div>       
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    onBoardingCCM
  } = careprovider;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    onBoardingCCM,
    userId,
    profileOwner
  };
};

export default connect(mapStateToProps)(FaxReport);
