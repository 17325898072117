import classnames from "classnames";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import NavigationPrompt from "../../Library/NavigationPrompt";

import moment from "moment";
import userImage from "../../../files/user.png";
import ProfileDetail from "./ProfileDetail";
const _ = require("lodash");

class Settings extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      getUserStatistics: "",
      activeTab: "1",
      processSubTypes: "",
      dateRange: 7,
      endDate: moment().add(1, "d").format("YYYY-MM-DD"),
      startDate: moment().subtract(1, "M").format("YYYY-MM-DD"),
      startDateForPM: moment().startOf("month").format("MMMM DD, YYYY"),
      endDateForPM: moment().endOf("month").format("MMMM DD, YYYY"),
      currentUserId: "",
      showPrivacyPolicy: false,
      showHipaaPolicy: false
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    return (
      <div>
        <NavigationPrompt when={this.props.timerStarts}>
          {({ isActive, onConfirm, onCancel }) => (
            <SweetAlert
              warning
              title="You are leaving this page!"
              showCancel
              confirmBtnText="Leave!"
              cancelBtnText="Stay"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="info"
              show={isActive}
              onCancel={onCancel}
              onConfirm={() => {
                this.stopTimer(onConfirm);
              }}
            >
              Click yes to save the tracked time and leave this page
            </SweetAlert>
          )}
        </NavigationPrompt>
        <div className="row">
          <div className="col-12 grid-margin">
            <section>
              <div className="sttabs tabs-style-iconbox">
                <nav>
                  <ul>
                    <li
                      className={classnames({
                        tabcurrent: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <a className="char font-bold urgent-tabs">
                        <img
                          src={userImage}
                          width="80px"
                          className="img-circle"
                          alt
                        />
                        <br />
                        <span>
                          {" "}
                          <br /> PROFILE
                        </span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="content-wrap">
                  <section
                    id="section-iconbox-1"
                    className={classnames({
                      contentcurrent: this.state.activeTab === "1"
                    })}
                  >
                    <ProfileDetail
                      currentUserId={this.props.userId}
                      profile={this.props.userDetails}
                    />
                  </section>
                </div>
                {/* /content */}
              </div>
              {/* /tabs */}
            </section>
          </div>
        </div>
      </div>
    );
    return <div />;
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    ongoingTestingFrequencyRequest,
    readingType,
  } = careprovider;
  const userId = user.userDetails._id;
  const { isCareProvider } = user.userDetails;
  const userDetails = user.userDetails;
  const role = user.userDetails.role;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    userDetails,
    readingType,
    userId,
    isCareProvider,
    ongoingTestingFrequencyRequest,
  };
};

export default connect(mapStateToProps)(Settings);
