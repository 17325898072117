import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  CustomInput
} from "reactstrap";
import classnames from "classnames";
import ProfileDetail from "../Settings/ProfileDetail";
import '../../css/css/style.css'
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useFetch2, formatDuration } from "components/utils/authenticatedHook";
function SharedDetails(props) {
  const {
    row,
    users,
    userInputChange,
    expectationInputChange,
    expectations
  } = props;
  const dontChange = true;
  const [{ response, error, isLoading }, doFetch, sendError] = useFetch2(null);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const {
    autoAddExpectations = false,
    autoAddPatients = false,
    autoAddAllPatients = false,
    _id: userId
  } = row;
  useEffect(() => {
    dispatch({
      type: "DO_REMOVE_PROFILE_DATA"
    });
  }, [dontChange]);
  useEffect(() => {
    return () => {
      dispatch({
        type: "DO_REMOVE_PROFILE_DATA"
      });
    };
  }, []);
  const submitAutoAdd = e => {
    const target = e.target;
    const isCheck = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    doFetch({
      url: `/staff/${row.user._id}/auto-addfunction?${name}=${isCheck}`,
      method: "GET"
    });
  };

  const updateAutoAddAllPatients = staffId => {
    // const target = event.target;
    // const isCheck = target.type === "checkbox" ? target.checked : target.value;
    // const name = target.name;
    dispatch({
      type: "DO_ADD_ALL_PATIENTS",
      payload: { staffId: staffId }
    });

    setTimeout(() => {
      dispatch({
        type: "DO_GET_STAFF"
      });
    }, 500);
    // this.setState({
    //   [name]: value
    // });
    for(let i = 0; i < users.length; i++) {
      let patientId = users[i].receivedFrom
        ? users[i].receivedFrom._id
        : users[i]._id;
        document.getElementById(patientId).checked = true;
    }
  };




  return (
    <div>
      <Nav tabs className="justify-content-center nav-header-border-radius">
        <NavItem>
          <NavLink
            className={activeTab == "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            ASSIGN PATIENTS
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab == "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            {row.lname} {row.fname} PROFILE
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {" "}
          <Row>
            <Col>
              <Card body className="shared-account-card">
                <CardTitle className="text-center">
                  Pick Patient to Assign
                </CardTitle>
                <div className="text-center">
                  <CustomInput
                    type="switch"
                    id="autoAddPatient"
                    defaultChecked={autoAddPatients}
                    name="autoAddPatients"
                    onChange={submitAutoAdd}
                    label="Auto Add New Patients Created"
                  />
                </div>

                <Form>
                  <FormGroup>
                    <Row>
                    <CustomInput
                      type="checkbox"
                      id="addAll"
                      onChange={e => updateAutoAddAllPatients(row.user._id)}
                      label="Add All"
                    />
                    </Row>
                    <Row>
                      {_.size(users) > 0 &&
                        users.map((object, i) => {
                          const patientId = object.receivedFrom
                            ? object.receivedFrom._id
                            : object._id;
                          const fname = object.receivedFrom
                            ? object.receivedFrom.fname
                            : object.fname;
                          const lname = object.receivedFrom
                            ? object.receivedFrom.lname
                            : object.lname;
                          const isUserFound = _.includes(
                            row.assignedUsers,
                            patientId
                          );
                          return (
                            <Col xs="2">
                              <CustomInput
                                type="checkbox"
                                defaultChecked={isUserFound}
                                id={patientId}
                                name={patientId}
                                onChange={e => userInputChange(e, row._id)}
                                label={fname + " " + lname}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </FormGroup>
                </Form>
              </Card>
            </Col>            
          </Row>
        </TabPane>
        <TabPane tabId="2">
          {" "}
          <Row className="pt-2" style={{margin:15}}>
            {activeTab === "1" && (
              <ProfileDetail
                hideTitle={true}
                enableUserEdit={false}
                currentUserId={row.user._id}
              />
            )}
            {activeTab === "2" && (
              <ProfileDetail
                hideTitle={true}
                enableUserEdit={false}
                currentUserId={row.user._id}
              />
            )}
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default SharedDetails;
