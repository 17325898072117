import React from "react";
import moment from "moment";
import isNoSignature from "../../files/ic_no_signature.svg";
import { Label } from "reactstrap";
import SignatureWithName from "../Reusable/SignatureWithName";

function CCMQuestionaire(props) {
  const ccmAnswers = props.ccmAnswers;
  const ccmModel = props.ccmModel;
  const signatureImage = props.ccmModel.lastModifyBy ? props.ccmModel.lastModifyBy.signatureImage : props.ccmModel.createdBy ? props.ccmModel.createdBy.signatureImage : undefined;
  const name = props.ccmModel.lastModifyBy ? props.ccmModel.lastModifyBy.lname+" "+props.ccmModel.lastModifyBy.fname : props.ccmModel.createdBy ? props.ccmModel.createdBy.lname+" "+props.ccmModel.createdBy.fname : undefined;
  var signatureLink = signatureImage ? signatureImage : undefined;
  if (signatureLink && !signatureLink.indexOf("http") == 0) {
      signatureLink = "/" + signatureLink
  }
  const ccmAnswersRows = ccmAnswers.map((answer) => {
    return (
      <tr>
        <td>{answer.question.question ? answer.question.question : answer.questionName}</td>
        <td>{answer.answer}</td>
        <td>{answer.note}</td>
        <td>{answer.createdBy.fname + " " + answer.createdBy.lname}</td>
        <td>{answer.lastModifyBy ? answer.lastModifyBy.fname + " " + answer.lastModifyBy.lname : "None"}</td>
        <td>{moment(answer.createdAt).format("MMMM DD, YYYY")}</td>
      </tr>
    );
  });
  return (
    <div>
      <table class="table table-bordered page-break-ka-after mb-4">
        <thead>
          <tr>
            <th colSpan="6" className="text-center text-dark">
              <h3 class="font-weight-bold">CCM Questionnaire</h3>
            </th>
          </tr>
          {ccmAnswersRows && (
            <tr>
              <th className="text-center">Question</th>
              <th className="text-center">Answer</th>
              <th className="text-center">Note</th>
              <th className="text-center">Created By</th>
              <th className="text-center">Last Modify By</th>
              <th className="text-center">Date</th>
            </tr>
          )}
        </thead>
        <tbody className="text-left">
          {ccmAnswers ? (
            ccmAnswersRows
          ) : (
            <tr>
              <th
                colSpan="3"
                className="text-black text-danger text-center mx-auto"
              >
                No Record Found
              </th>
            </tr>
          )}
        </tbody>
      </table>
      <SignatureWithName
        signature={signatureLink}
        isBorder={true}
        name={name}
      />
      <hr style={{ borderTop: "3px solid #5c5959" }} />
    </div>
  );
}

export default CCMQuestionaire;
