import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Row,
  Col
} from "reactstrap";

import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { primaryInsuranceProviders } from "../../../constants/general.constant";
import _ from "lodash";
import roleType from "../../../store/reducers/roleTypes.json";
const CreateUserModal = (props) => {
  const [emailAvailable, setEmailAvailable] = useState({
    validate: true,
    message: ""
  });
  const [usernameAvailable, setUsernameAvailable] = useState({
    validate: true,
    message: ""
  });
  const [isSubmitted, setIsSubmitted] = useState(false)
  const userDetails = props && props.userDetails ? props.userDetails : null;
  const invalidFiled = [];
  const customValidateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    const requiredFields = ["email", "username", "password", "fname", "lname", "bday", "confirmpassword", "phone"];
    requiredFields.forEach(field => {
      if ((userDetails && _.isEmpty(userDetails[field]) && isSubmitted)) {
        isValid = false;
        invalidFiled.push(field)
      }
    });
    if(isValid){
      props.submitUser(e)
    }
    return;
  }
  const roleOptions = roleType.filter((item) => item.type == "Patient");
  return (
    <Modal
      isOpen={props.modalUser}
      toggle={props.toggleUser}
      backdrop="static"
      size="lg"
      className="bastaLang modal-md"
    >
      <ModalHeader toggle={props.toggleUser}>
        Create a Patient Account
      </ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <Form onSubmit={customValidateForm}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">
                  MemberID (Reference ID OF CLIENT'S DB)
                </Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="memberId"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Email</Label>
                <Input
                  className="remove-bottom-margin input-date-filter"
                  style={{borderBottomStyle : (!emailAvailable.validate || (userDetails && _.isEmpty(userDetails.email) && isSubmitted)) ? 'groove' : 'hidden'}}
                  type="email"
                  name="email"
                  onChange={props.handleInputChange}
                  onBlur={(e) => {
                    axios
                      .get("/check-availability?email=" + e.target.value)
                      .then(function (response) {
                        setEmailAvailable({ validate: true, message: "" });
                      })
                      .catch(function (error) {
                        const { response = {} } = error;
                        const { data = {} } = response;
                        const { message = "" } = data;

                        setEmailAvailable({
                          validate: false,
                          message: message
                        });
                      });
                  }}
                  invalid={!emailAvailable.validate || (userDetails && _.isEmpty(userDetails.email) && isSubmitted)}
                  rows={10}
                />
                {(userDetails && _.isEmpty(userDetails.email) && isSubmitted) ? <FormFeedback>Email is a required</FormFeedback> : ''}
                <FormFeedback>{emailAvailable.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Username</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  style={{borderBottomStyle : (!usernameAvailable.validate || (userDetails && _.isEmpty(userDetails.username) && isSubmitted)) ? 'groove' : 'hidden'}}
                  type="text"
                  name="username"
                  autoComplete="false"
                  onBlur={(e) => {
                    if(e.target.value){
                      axios
                      .get("/check-availability?username=" + e.target.value)
                      .then(function (response) {
                        // handle success
                        setUsernameAvailable({ validate: true, message: "" });
                      })
                      .catch(function (error) {
                        const { response = {} } = error;
                        const { data = {} } = response;
                        const { message = "" } = data;

                        setUsernameAvailable({
                          validate: false,
                          message: message
                        });
                      });
                    }
                  }}
                  invalid={!usernameAvailable.validate || (userDetails && _.isEmpty(userDetails.username) && isSubmitted)}
                  onChange={props.handleInputChange}
                  rows={10}
                />
                {(userDetails && _.isEmpty(userDetails.username) && isSubmitted) ? <FormFeedback>Username is a required</FormFeedback> :  <FormFeedback>{usernameAvailable.message}</FormFeedback>}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Role *</Label>
                <select
                  name="role"
                  className="form-control"
                  onChange={props.handleRole}
                  required
                >
                <option value="">
                  Select Role
                </option>
                {roleOptions.map((option) => (
                  <option value={option.value}>{option.role}</option>
                ))}
                </select>
              </FormGroup>
            </Col>
            <Col md={6}>
            <FormGroup>
            <Label for="exampleText">Password</Label>
            <Input
              className="input-date-filter remove-bottom-margin"
              style={{borderBottomStyle : (userDetails && _.isEmpty(userDetails.password) && isSubmitted) ? 'groove' : 'hidden'}}
              type="password"
              name="password"
              onChange={props.handleInputChange}
              rows={10}
              invalid={(userDetails && _.isEmpty(userDetails.password) && isSubmitted)}
            />
            {(userDetails && _.isEmpty(userDetails.password) && isSubmitted) ? <FormFeedback>Password is a required</FormFeedback> : ''}
            </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Confirm Password</Label>
                <Input
                  invalid={props.errorPassword || (userDetails && _.isEmpty(userDetails.confirmpassword) && isSubmitted)}
                  style={{borderBottomStyle : (userDetails && _.isEmpty(userDetails.confirmpassword) && isSubmitted) ? 'groove' : 'hidden'}}
                  type="password"
                  className="form-control form-control-lg input-date-filter remove-bottom-margin"
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  onChange={props.handleInputChange}
                  rows={10}
                />
                {(userDetails && _.isEmpty(userDetails.confirmpassword) && isSubmitted) ? <FormFeedback>Confirm password is a required</FormFeedback> : ''}
                {props.errorPassword && (
                  <FormFeedback>Password does not match</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">First Name</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="fname"
                  onChange={props.handleInputChange}
                  rows={10}
                  invalid={(userDetails && _.isEmpty(userDetails.fname) && isSubmitted)}
                  style={{borderBottomStyle : (userDetails && _.isEmpty(userDetails.fname) && isSubmitted) ? 'groove' : 'hidden'}}
                />
                {(userDetails && _.isEmpty(userDetails.fname) && isSubmitted) ? <FormFeedback>First name is a required</FormFeedback> : ''}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Last Name</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="lname"
                  onChange={props.handleInputChange}
                  rows={10}
                  invalid={(userDetails && _.isEmpty(userDetails.lname) && isSubmitted)}
                  style={{borderBottomStyle : (userDetails && _.isEmpty(userDetails.lname) && isSubmitted) ? 'groove' : 'hidden'}}
                />
                {(userDetails && _.isEmpty(userDetails.lname) && isSubmitted) ? <FormFeedback>Last name is a required</FormFeedback> : ''}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Nickname</Label>
                <Input
                  type="text"
                  name="nickname"
                  required={false}
                  onChange={props.handleInputChange}
                  rows={10}
                  className="input-date-filter remove-bottom-margin"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleText">SubOrganization</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="subOrganization"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">DOB</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="date"
                  name="bday"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={props.handleInputChange}
                  rows={10}
                  invalid={(userDetails && _.isEmpty(userDetails.bday) && isSubmitted)}
                  style={{borderBottomStyle : (userDetails && _.isEmpty(userDetails.bday) && isSubmitted) ? 'groove' : 'hidden'}}
                />
                {(userDetails && _.isEmpty(userDetails.bday) && isSubmitted) ? <FormFeedback>DOB is a required</FormFeedback> : ''}
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Phone</Label>
                <Input
                  className="remove-bottom-margin"
                  type="text"
                  name="phone"
                  onChange={props.handleInputChange}
                  rows={10}
                  invalid={(userDetails && _.isEmpty(userDetails.phone) && isSubmitted)}
                  style={{borderBottomStyle : (userDetails && _.isEmpty(userDetails.phone) && isSubmitted) ? 'groove' : 'hidden'}}
                />
                {(userDetails && _.isEmpty(userDetails.phone) && isSubmitted) ? <FormFeedback>Phone number is a required</FormFeedback> : ''}
              </FormGroup>
              </Col>
            <Col md={6}>
            <FormGroup>
            <Label for="exampleText">Home Number</Label>
            <Input
              className="input-date-filter remove-bottom-margin"
              type="text"
              name="homeNumber"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Work Number</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="workNumber"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Address</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="address"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">City</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="city"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">State</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="state"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Zip Code</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="zip"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Height (ft)</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="height"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="exampleText">Weight (lb)</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="weight"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              </Col>
              <Col md={6}>
              <div className="form-group">
                <label>Gender</label>
                <select
                  name="gender"
                  className="form-control"
                  defaultValue="Male"
                  onChange={props.handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              </Col>
              <Col md={6}>
              <div className="form-group">
                <label>Primary Insurance Provider</label>
                <select
                  name="insuranceProvider"
                  className="form-control"
                  defaultValue=""
                  onChange={props.handleInputChange}
                >
                {primaryInsuranceProviders && primaryInsuranceProviders.length > 0 &&
                  primaryInsuranceProviders.map((item) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))
                }
                </select>
              </div>
              </Col>
              <Col md={6}>
              <div className="form-group">
                <label>Enrolled Program(s)</label>
                <select
                  name="enrolledPrograms"
                  className="form-control"
                  defaultValue=""
                  onChange={props.handleInputChange}
                >
                  <option value="">None</option>
                  <option value="CCM">CCM</option>
                  <option value="RPM">RPM</option>
                  <option value="CCM&RPM">CCM & RPM</option>
                </select>
              </div>
              </Col>
              <Col md={6}>
              <div className="form-group">
                <label>Client Expectations to Follow</label>
                <select
                  name="expectationsToFollow"
                  className="form-control"
                  defaultValue=""
                  onChange={props.handleInputChange}
                >
                  <option value="">No Adherence Logic</option>
                  <option value="CCM Only">CCM Only</option>
                  <option value="Adherence on Hold">Adherence on Hold</option>
                  <option value="MVV">MVV Adherence</option>
                  <option value="PICC">ANS Adherence</option>
                  <option value="Care Provider">
                    Care Provider Adherence
                  </option>
                </select>
              </div>
            </Col>
          </Row>
          <button type="submit" className="btn btn-block btn-info" onClick={() => setIsSubmitted(true)}>
            SAVE
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreateUserModal;
