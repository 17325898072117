import React from "react";
import { withRouter } from "react-router-dom";

const Footer = props => (
  <div>
    {props.time && (
      <div className="footer fixed-bottom bg-dark">
        <div className="justify-content-center justify-content-sm-between text-center text-white">
          Time Monitored - {props.mode}{" "}
          <div>
            Patient Name Monitored: {props.user.receivedFrom.fname}{" "}
            {props.user.receivedFrom.lname}
          </div>
          <h2>{props.time}</h2>
        </div>
      </div>
    )}
  </div>
);

export default withRouter(Footer);
