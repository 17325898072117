import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import Swal from 'sweetalert2';
const { ExportCSVButton } = CSVExport;
const _ = require("lodash");
function fullname(cell, row) {
  return (
    <span>
      <img
        src="https://cdn-icons-png.flaticon.com/512/194/194915.png"
        className="mr-2"
        alt="image"
      />
      {capitalize(row.fname)} {capitalize(row.lname)}
    </span>
  );
}
function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

function formatDate(cell, row) {
  var tz = moment.tz.guess();
  return moment(row.deviceReadingDate).tz(tz).format("MMMM DD, YYYY hh:mm A");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function readingValue(reading, row, rowIndex, formatExtraData) {
  const { unitMeasurement, bodyTraceWS, isBloodPressureReading } = formatExtraData;
  const data = _.map(formatExtraData.subType, function (v, i) {
    if(isBloodPressureReading && v._id == '5be56cbe3863cd0647d79896'){
      return;
     }
    let theValue;
    if (v.name == "Mean Arterial") {
      theValue = Math.round((1 / 3) * reading[0] + (2 / 3) * reading[1]);
    } else {
      if (v.name === "Temperature") {
        let tempValue;

        if (unitMeasurement.umTemperature === "C") {
          tempValue = reading[i] + unitMeasurement.umTemperature;
        } else {
          tempValue =
            ((reading[i] * 9) / 5 + 32).toFixed(2) +
            unitMeasurement.umTemperature;
        }
        theValue = tempValue;
      } else if (v.name === "Glucose") {
        let event;
        switch (reading[1]) {
          case 0:
            event = " (No Event)";
            break;
          case 3:
            event = " (After Meal)";
            break;
          case 4:
            event = " (Before Meal)";
            break;
          case 5:
            event = " (Control Solution)";
            break;
          default:
            event = " (No Event)";
            break;
        }
        if (unitMeasurement.umGlucose === "mg/dL") {
          theValue = Math.round(reading[i]) + unitMeasurement.umGlucose + event;
          const insulinDosage = row.insulinDosage
            ? ` Insulin Dosage: ${row.insulinDosage}`
            : "";
          theValue += insulinDosage;
        } else {
          theValue =
            Math.round(reading[i] / 18) + unitMeasurement.umGlucose + event;
          const insulinDosage = row.insulinDosage
            ? ` Insulin Dosage: ${row.insulinDosage}`
            : "";
          theValue += insulinDosage;
        }
      } else if (v.name === "Weight") {
        let weightValue;

        if (bodyTraceWS) {
          weightValue = reading[i] + unitMeasurement.umWeight;
        } else {
          if (unitMeasurement.umWeight === "kg") {
            weightValue = Math.round(reading[i]) + unitMeasurement.umWeight;
          } else {
            weightValue =
              Math.round(reading[i] * 2.2046) + unitMeasurement.umWeight;
          }
        }

        theValue = weightValue;
      } else if (v.name == "BMI") {
        if (row.readingValue) {
          const {
            bmi = null,
            bmiDescription = null,
            weight
          } = row.readingValue;

          theValue = bmi ? `${bmi} (${bmiDescription})` : "";
        }
      } else if (v.name == "Systolic" || v.name == "Diastolic") {
        let pressureValue;

        if (unitMeasurement.umBloodPressure === "mmHg") {
          pressureValue =
            Math.round(reading[i]) + unitMeasurement.umBloodPressure;
        } else {
          pressureValue =
            Math.round(reading[i] * 0.133322) + unitMeasurement.umBloodPressure;
        }
        theValue = pressureValue;
      } else {
        theValue = Math.round(reading[i]) + v.unit;
      }
    }
    return (
      <div style={{padding:4}}>
        <b>{v.name} :</b> {theValue}
      </div>
    );
  });
  return data;
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      <button type="button" className="btn btn-info btn-fw">
        Comment
      </button>
    </div>
  );
}
function deleteButton(cell, row, index, formatExtraData) {  
  return (
    <>
    <button
        type="button"
        className="btn btn-info ml-2 delete-note-click"
        onClick={() => {
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              formatExtraData.delete(row._id)
            }
          })
        }}
      >
      <i class="mdi mdi-delete"></i> Delete
      </button>   
    </>
  );
}
const TableReading = React.forwardRef((props, ref) => {   
  const { fname = "ERROR", lname = "ERROR", date = "", exoportCSV } = props;
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      // <div className="text-right">
      //   <button className="btn btn-success " onClick={handleClick}>
      //     <i className="mdi mdi-file-excel" /> Export to CSV
      //   </button>
      // </div>
      <div>
        <input
          ref={ref}
          hidden
          className="btn btn-success"
          onClick={handleClick}
        ></input>
      </div>
    );
  };
  const NoDataIndication = () => (
    <div>
      <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={250}
          Lwidth={250}
          titleSmall={"Sorry! No patient found :("}
          subTitleSmall={"We can't find any patient."}
        />             
    </div>
  );
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      {/* <h1 className="text-center mt-5">
        {fname} {lname} Reading Data
      </h1> */}
      <ToolkitProvider
        keyField="_id"
        data={_.orderBy(props.reading, ['deviceReadingDate'], ['desc'])}
        columns={[
          {
            dataField: "ddd",
            text: "Date",
            sort: true,
            filter: textFilter({
              className: 'input-date-filter',
            }),
            formatter: formatDate,
            csvFormatter: (cell, row, rowIndex) => {
              return formatDate(cell, row);
            },
            headerFormatter: priceFormatter
          },
          {
            dataField: "value",
            text: "Value",
            sort: true,
            filter: textFilter({
              className: 'input-date-filter',
            }),
            formatter: readingValue,
            csvFormatter: (reading, row, rowIndex, formatExtraData) => {
              const getDate = readingValue(
                reading,
                row,
                rowIndex,
                formatExtraData
              );
              const { children = [] } = getDate ? getDate[0].props : [];
              const mapProps = _.map(getDate, "props");
              const mapChildren = _.map(mapProps, "children");
              const fixFormat = _.map(mapChildren, (c) => {
                return c.join("");
              });
              return fixFormat.join(", ");
            },
            headerFormatter: priceFormatter,
            formatExtraData: {
              subType: props.subType,
              readingValue: props.readingValue,
              unitMeasurement: props.unitMeasurement,
              bodyTraceWS: props?.bodyTraceWS ? props.bodyTraceWS : false,
              isBloodPressureReading: props.isBloodPressureReading
            }
          },

          {
            dataField: "source",
            text: "Source",
            sort: true,
            filter: textFilter({
              className: 'input-date-filter',
            }),
            formatter: (cell, row) => {
              const { description = "-" } = cell || "-";
              return description;
            },

            csvFormatter: (reading, row, rowIndex, formatExtraData) => {
              const { description = "-" } = reading || "-";
              return description;
            },
            headerFormatter: priceFormatter,
            formatExtraData: {
              subType: props.subType,
              readingValue: props.readingValue,
              unitMeasurement: props.unitMeasurement
            }
          }, {
            dataField: "delete",
            text: "Action",
            sort: false,
            formatter: deleteButton,
            formatExtraData: {
              delete: props.deleteReading
            },
            hidden: props.deleteReading ? false : true,
          }
        ]}
        exportCSV={{ fileName: `${fname} ${lname} Readings ${date}.csv` }}
      >
        {(toolKitProp) => (
          <div>
            <MyExportCSV {...toolKitProp.csvProps}>Export CSV</MyExportCSV>
            <hr />
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication={ () => <NoDataIndication /> }
              hover
              condensed
              bordered
              filter={filterFactory()}
              {...toolKitProp.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
});

export default TableReading;
