import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import ChildAddConditionSection from "./ChildAddConditionSection";
import ErrorDisplay from "../Reusable/ErrorDisplay";

let renderCount = 0;

export default function AddConditionSection({ control, register, setValue,reset, errors, getValues, registerOptions, isEditable, props }) {
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "condition"
    });

    renderCount++;

    function getStype() {
        let names = [];
        if (isEditable) names.push('input-box-style-editbale');
        if (!isEditable) names.push('disabled', 'input-box-style-editbale-disable');
        return names.join(' ');
    }
    function getStypeForRadio() {
        let names = [];
        if (!isEditable) names.push('disabled', 'input-box-style-editbale-disable-radio');
        return names.join(' ');
    }
    function getLogsCss() {
        let names = [];
        if (!props.saveCCMDetails) names.push('logs-view');
        names.push('App')
        return names.join(' ');
    }

    useEffect(() => {
        reset(props.latestSubmission && props.latestSubmission.condition ? props.latestSubmission.condition : undefined);
    }, [props.latestSubmission && props.latestSubmission.condition ? props.latestSubmission.condition : undefined]);

    return (
        <>
            <div>
                {fields.map((item, index) => {
                    return (
                        <>
                            <div style={{ overflowX: "auto" }} key={item.id}>
                                <div style={{ minWidth: "680px" }}>
                                    <div className="common-heading-wrapper delete-condition-view">
                                        <h4 className="common-heading-title">
                                            Chronic Condition #{index + 3}—Goals and Interventions{" "}
                                        </h4>
                                        <div>
                                            {isEditable && (
                                                <button
                                                    style={{ float: 'right', marginRight: 10 }}
                                                    onClick={() => remove(index)}
                                                    className="btn btn-info ml-2 delete-condtion-click-cls float-right" >
                                                    <i class="mdi mdi-close" style={{
                                                        fontSize: 20
                                                    }}></i>
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <table class="table table-bordered table-responsive pdf-third-table">
                                        <tbody>
                                            <tr>
                                                <td scope="row" style={{ width: "25%", fontWeight: "700" }}>Chronic condition #{index + 3}</td>
                                                <td scope="row" style={{ width: "75%", padding: "0px" }}>
                                                    <div>
                                                        {isEditable && (
                                                            <>
                                                                <textarea
                                                                    rows={2}
                                                                    className={getStype()}
                                                                    defaultValue={props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] && props.latestSubmission.condition[index].chronicCondition ? props.latestSubmission.condition[index].chronicCondition : ""}
                                                                    name={`condition.${index}.chronicCondition`}
                                                                    ref={register(registerOptions.requiredField)}
                                                                />
                                                                {errors?.condition?.[index] && errors?.condition?.[index].chronicCondition && errors?.condition?.[index].chronicCondition.message && (
                                                                    <ErrorDisplay message={errors?.condition?.[index].chronicCondition?.message} />
                                                                )}
                                                            </>
                                                        )}
                                                        {!isEditable && (
                                                            <p className="mb-0 view-mode-display-value">
                                                                {props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].chronicCondition : ""}
                                                            </p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "700" }}>Prognosis</td>
                                                <td style={{ padding: "0px" }}>
                                                    <div>
                                                        {isEditable && (
                                                            <>
                                                                <textarea
                                                                    className={getStype()}
                                                                    name={`condition.${index}.prognosis`}
                                                                    defaultValue={props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].prognosis : ""}
                                                                    ref={register(registerOptions.requiredField)}
                                                                />
                                                                {errors?.condition?.[index] && errors?.condition?.[index].prognosis && errors?.condition?.[index].prognosis.message && (
                                                                    <ErrorDisplay message={errors?.condition?.[index].prognosis?.message} />
                                                                )}
                                                            </>
                                                        )}
                                                        {!isEditable && (
                                                            <p className="mb-0 view-mode-display-value">                                                                
                                                                {props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].prognosis : ""}
                                                            </p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "700" }}>
                                                    Symptom management (Include any educational resources
                                                    provided.)
                                                </td>
                                                <td style={{ padding: "0px" }}>
                                                    <div>
                                                        {isEditable && (
                                                            <>
                                                                <textarea
                                                                    className={getStype()}
                                                                    defaultValue={props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].symptomManagement : ""}
                                                                    name={`condition.${index}.symptomManagement`}
                                                                    ref={register(registerOptions.requiredField)}
                                                                />
                                                                {errors?.condition?.[index] && errors?.condition?.[index].symptomManagement && errors?.condition?.[index].symptomManagement.message && (
                                                                    <ErrorDisplay message={errors?.condition?.[index].symptomManagement?.message} />
                                                                )}
                                                            </>
                                                        )}
                                                        {!isEditable && (
                                                            <p className="mb-0 view-mode-display-value">                                                                
                                                                {props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].symptomManagement : ""}
                                                            </p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "700" }}>
                                                    Measurable treatment goals
                                                </td>
                                                <td style={{ padding: "0px" }}>
                                                    <div>
                                                        {isEditable && (
                                                            <>
                                                                <textarea
                                                                    className={getStype()}
                                                                    defaultValue={props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].measurableTreatmentGoals : ""}
                                                                    name={`condition.${index}.measurableTreatmentGoals`}
                                                                    ref={register(registerOptions.requiredField)}
                                                                />
                                                                {errors?.condition?.[index] && errors?.condition?.[index].measurableTreatmentGoals && errors?.condition?.[index].measurableTreatmentGoals.message && (
                                                                    <ErrorDisplay message={errors?.condition?.[index].measurableTreatmentGoals?.message} />
                                                                )}
                                                            </>
                                                        )}
                                                        {!isEditable && (
                                                            <p className="mb-0 view-mode-display-value">
                                                                {props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].measurableTreatmentGoals : ""}
                                                            </p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "700" }}>
                                                    Planned interventions
                                                </td>
                                                <td style={{ padding: "0px" }}>
                                                    <div>
                                                        {isEditable && (
                                                            <>
                                                                <textarea
                                                                    className={getStype()}
                                                                    defaultValue={props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].plannedInterventions : ""}
                                                                    name={`condition.${index}.plannedInterventions`}
                                                                    ref={register(registerOptions.requiredField)}
                                                                />
                                                                {errors?.condition?.[index] && errors?.condition?.[index].plannedInterventions && errors?.condition?.[index].plannedInterventions.message && (
                                                                    <ErrorDisplay message={errors?.condition?.[index].plannedInterventions?.message} />
                                                                )}
                                                            </>
                                                        )}
                                                        {!isEditable && (
                                                            <p className="mb-0 view-mode-display-value">
                                                                {props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].plannedInterventions : ""}
                                                            </p>
                                                        )}
                                                    </div></td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "700" }}>
                                                    Coordination of care
                                                </td>
                                                <td style={{ padding: "0px" }}>
                                                    <div>
                                                        {isEditable && (
                                                            <>
                                                                <textarea
                                                                    className={getStype()}
                                                                    defaultValue={props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].coordinationOfCare : ""}
                                                                    name={`condition.${index}.coordinationOfCare`}
                                                                    ref={register(registerOptions.requiredField)}
                                                                />
                                                                {errors?.condition?.[index] && errors?.condition?.[index].coordinationOfCare && errors?.condition?.[index].coordinationOfCare.message && (
                                                                    <ErrorDisplay message={errors?.condition?.[index].coordinationOfCare?.message} />
                                                                )}
                                                            </>
                                                        )}
                                                        {!isEditable && (
                                                            <p className="mb-0 view-mode-display-value">
                                                                {props.latestSubmission && props.latestSubmission.condition && props.latestSubmission.condition[index] ? props.latestSubmission.condition[index].coordinationOfCare : ""}
                                                            </p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    );
                })}
                {isEditable && (
                    <div className="add-condition-div ">
                        <button
                            type="submit"
                            style={{ float: 'right' }}
                            onClick={() => {
                                append({ name: "append" });
                            }}
                            className="btn btn-info ml-2 add-Condition-click-cls" >
                            <i class="mdi mdi-plus" style={{
                                fontSize: 20
                            }}></i>
                            Add Chronic Condition
                        </button>
                    </div>
                )}                
            </div>
        </>
    );
}
