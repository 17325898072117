import classnames from "classnames";
import React, { Component } from "react";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import onBoarding from "../../files/ic_on_boarding.png";
import SelectationTab from "./SelectationTab";
import OnBoardingCCMList from "./OnBoardingCCMList";
const _ = require("lodash");
class OnBoardingCCM extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      activeTab: "1",
      onDisplayAllCcmOnboarding: true,
      onDisplayNeedToRequestCCMOrderFromProvider: false,
      onDisplayRequestedCCMOrderFromProvider: false, 
      loading: true,
      catagoryID: undefined
    };
  }
  
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_ALL_DEVICE"
    });
    this.onFetchReadingCount();    
  }
  onFetchReadingCount(){
    this.props.dispatch({
      type: "DO_GET_ON_BOARDING_CCM_COUNT"
    });
  }

  onDisplayAllCcmOnboarding() {
    this.onFetchReadingCount();        
    this.setState({
      onDisplayAllCcmOnboarding: true,
      onDisplayNeedToRequestCCMOrderFromProvider: false,
      onDisplayRequestedCCMOrderFromProvider: false,     
      catagoryID: undefined
    }, () => {
      this.fetchData();
    });
  }

  onDisplayNeedToRequestCCMOrderFromProvider() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAllCcmOnboarding: false,
      onDisplayNeedToRequestCCMOrderFromProvider: true,
      onDisplayRequestedCCMOrderFromProvider: false,
      catagoryID: "65081c5b4e8e9173654eb40b"
    }, () => {
      this.fetchData();
    });
  }
  // Requested CCM Order from Provider
  onDisplayRequestedCCMOrderFromProvider() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAllCcmOnboarding: false,
      onDisplayNeedToRequestCCMOrderFromProvider: false,
      onDisplayRequestedCCMOrderFromProvider: true,  
      catagoryID: "65081cba4e8e9173654eb40c"
    }, () => {
      this.fetchData();
    });
  }

  render() {   
    var mAllCCMOnBoarding = { backgroundColor: "#198ae3", color: "white" };     
    var mNeedToRequestCCMOrderFromProvider = { backgroundColor: "white", color: "black" };
    var mRequestedCCMOrderFromProvider = { backgroundColor: "white", color: "black" };     
    
    if (this.state.onDisplayNeedToRequestCCMOrderFromProvider) {
      // Display Requested RPM
      mAllCCMOnBoarding = { backgroundColor: "white", color: "black" };
      mNeedToRequestCCMOrderFromProvider = { backgroundColor: "#198ae3", color: "white" };
      mRequestedCCMOrderFromProvider = { backgroundColor: "white", color: "black" };      
    } else if (this.state.onDisplayRequestedCCMOrderFromProvider) {
      // Display Pre Shipment
      mAllCCMOnBoarding = { backgroundColor: "white", color: "black" };
      mNeedToRequestCCMOrderFromProvider = { backgroundColor: "white", color: "black" };
      mRequestedCCMOrderFromProvider = { backgroundColor: "#198ae3", color: "white" };      
    }
    return (
      <div>
        <div className="row">
          <div className="col-12 grid-margin">   {/* Shrey Added */}       
            <div className="card overflow-hidden">{/* Shrey Added */}       
                <Nav tabs className="justify-content-center">{/* Shrey Added */}       
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <img className="img-tabsfluid" src={onBoarding} style={{marginRight:5}}/>
                      CCM On-Boarding
                    </NavLink>
                  </NavItem>
                </Nav>
              <div className="card-body project-tab pt-2">
                <Row>
                  <Col className="grid-margin col-sm-12 mx-auto">
                    {!this.state.loading && (
                      <ReactLoading className="mx-auto" color={"#007bff"} />
                    )}
                    {this.state.loading && (
                      <Row className="mx-auto">   
                        <Col className="mt-4 col-sm-2-half col-12 p-2 col-sm-4 col-md-4 c0l-lg-4 col-xl-4"> {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayAllCcmOnboarding()
                            }
                          >
                            <SelectationTab
                              title={"All Patients"}
                              displayCount={this.props.onBoardingCCM && this.props.onBoardingCCM.mAllCCMOnBoardingSize ? this.props.onBoardingCCM.mAllCCMOnBoardingSize : 0}
                              style={mAllCCMOnBoarding}
                              />           
                          </div>
                        </Col>                     
                        <Col className="mt-4 col-sm-2-half col-12 p-2 col-sm-4 col-md-4 c0l-lg-4 col-xl-4"> {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayNeedToRequestCCMOrderFromProvider()
                            }
                          >
                            <SelectationTab
                              title={"Requested CCM Order from Provider"}
                              displayCount={this.props.onBoardingCCM && this.props.onBoardingCCM.mNeedToRequestCCMOrderFromProviderSize ? this.props.onBoardingCCM.mNeedToRequestCCMOrderFromProviderSize : 0}
                              style={mNeedToRequestCCMOrderFromProvider}
                              />           
                          </div>
                        </Col>
                        
                        <Col className="mt-4 col-sm-2-half col-12 p-2 col-sm-4 col-md-4 c0l-lg-4 col-xl-4"> {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayRequestedCCMOrderFromProvider()
                            }
                          >
                            <SelectationTab
                              title={"Need to Update Patient’s Profile"}
                              displayCount={this.props.onBoardingCCM && this.props.onBoardingCCM.mRequestedCCMOrderFromProviderSize ? this.props.onBoardingCCM.mRequestedCCMOrderFromProviderSize : 0}
                              style={mRequestedCCMOrderFromProvider}
                              />           
                          </div>
                        </Col>                        
                      </Row>
                    )}
                  </Col>
                  <OnBoardingCCMList
                      fetchDataFromServer={click => this.fetchData = click}
                      catagoryID={this.state.catagoryID}
                      profileOwner={this.props.profileOwner}
                      userId={this.props.userId}
                    />
                </Row>                
              </div>
            </div>
          </div>
        </div>       
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    onBoardingCCM
  } = careprovider;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    onBoardingCCM,
    userId,
    profileOwner
  };
};

export default connect(mapStateToProps)(OnBoardingCCM);
