import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
// import configureStore from "./store/configureStore";
// const { store } = configureStore();

export function initializePush(topic) {
  // const unsubscribe = store.subscribe(listener);

  // function select(state) {
  //   return state.user.userDetails._id || false;
  // }

  // function listener() {
  //   let token = select(store.getState());
  // }
  let baseURL;
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://localhost:3000/api";
  } else {
    baseURL = "https://dev.portal.myvitalview.com/api";
  }
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging
      .getToken()
      .then((token) => {
        fetch(baseURL + "/patient/subscribe-topic/" + token + "/" + topic, {
          method: "POST",
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              throw (
                "Error subscribing to topic: " +
                response.status +
                " - " +
                response.text()
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
        //you probably want to send your new found FCM token to the
        //application server so that they can send any push
        //notification to you.
      })
      .catch((error) => {
        if (error.code === "messaging/permission-blocked") {
          //Please Unblock Notification Request Manually
        } else {
          //Error Occurred
        }
      });
    messaging.onMessage((payload) => {
      //this is the function that gets triggered when you receive a
      //push notification while you’re on the page. So you can
      //create a corresponding UI for you to have the push
      //notification handled.
    });
  }

  initializePush = function () {};
}
