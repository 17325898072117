import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { map, orderBy, groupBy, size, keyBy } from "lodash";
import moment from "moment";
import LineGraph from "./LineGraph";
export const DexcomData = ({ dexcomData, startDate, endDate }) => {
  var events = null;
  try {
    events = JSON.parse(dexcomData).events;
  } catch (exception) {
    events = null;
  }
  if (!dexcomData || size(dexcomData) === 0 || !events) {
    return (
      <Col sm="6" className="mt-4">
        <Card style={{ backgroundColor: "#fffff" }}>
          <CardBody>
            <h4 className="text-center">DEXCOM EVENTS</h4>
            <div className="text-center mb-2"></div>
            {/* <div className="text-center">
              <a
                target="_blank"
                className="text-white ml-auto btn btn-sm btn-success"
              >
                Show More Details
              </a>
            </div> */}
            <div className="text-center">NO RECORDS</div>
            <div />
          </CardBody>
        </Card>
      </Col>
    );
  }
  const eventOrder = orderBy(events, ["systemTime"]);

  const allDates = map(eventOrder, "systemTime");
  //   const formatDates = map(allDates, v => {
  //     return moment(v).format();
  //   });

  const groupEvents = groupBy(eventOrder, "eventType");
  let groupDataSet = [];
  const healthArray = [
    "HighSymptoms",
    "LowSymptoms",
    "Cycle",
    "Illness",
    "Stress"
  ];
  for (const groupEvent in groupEvents) {
    let color = "green";
    switch (groupEvent) {
      case "carbs":
        color = "green";
        break;
      case "insulin":
        color = "blue";
        break;
      case "health":
        color = "red";
        break;
      case "exercise":
        color = "#5e50f9";
        break;
      default:
        break;
    }
    const data = groupEvents[groupEvent];

    const { eventType, unit } = data[0];
    const allDates = map(data, "systemTime");
    const formatDates = map(allDates, v => {
      return moment(v).format();
    });
    let mapData;
    if (groupEvent === "health") {
      const healthArray = [
        "highSymptoms",
        "lowSymptoms",
        "cycle",
        "illness",
        "stress"
      ];
      mapData = map(data, v => {
        const {
          eventType: eventType2,
          eventSubType,
          value,
          unit: unit2,
          eventStatus,
          systemTime
        } = v;
        return {
          y: healthArray.indexOf(eventSubType),
          eventDescription: `Event SubType: ${eventSubType},Event Status: ${eventStatus}`
        };
      });
    } else {
      mapData = map(data, v => {
        const {
          eventType: eventType2,
          eventSubType,
          value,
          unit: unit2,
          eventStatus,
          systemTime
        } = v;
        return {
          y: value || 0,
          eventDescription: `Event SubType: ${eventSubType},Event Status: ${eventStatus}`
        };
      });
    }

    const unitValue = unit ? `(${unit})` : "";
    groupDataSet.push({
      dataSet: [
        {
          label: `${eventType.toUpperCase()} ${unitValue}`,
          fill: false,
          pointRadius: 4,
          pointHoverRadius: 4,
          backgroundColor: color,
          pointRadius: 5,
          pointHoverRadius: 5,
          borderColor: color,
          pointBackgroundColor: color,
          data: mapData
        }
      ],
      allDates: allDates,
      eventType: groupEvent
    });
  }

  return (
    <Col sm="6" className="mt-4">
      <Card style={{ backgroundColor: "#fffff" }}>
        <CardBody>
          <h4 className="text-center">DEXCOM EVENTS</h4>
          <div className="text-center mb-2"></div>
          {/*<div className="text-center">
            <a
              target="_blank"
              className="text-white ml-auto btn btn-sm btn-success"
            >
              Show More Details
            </a>
          </div>*/}
          {map(groupDataSet, v => {
            const arrayTextLabel = v.eventType === "health" ? healthArray : [];
            const showTextLabel = v.eventType === "health" ? true : false;
            return (
              <LineGraph
                readingDate={v.allDates}
                readingDataSet={v.dataSet}
                hidePrint="d-none"
                yAxisTextLabel={showTextLabel}
                textArray={arrayTextLabel}
                dontRender="true"
                title={`(${moment(startDate)
                  .clone()
                  .format("MMMM DD, YYYY")} - ${moment(endDate)
                  .subtract(1, "d")
                  .format("MMMM DD, YYYY")})`}
              />
            );
          })}

          <div />
        </CardBody>
      </Card>
    </Col>
  );
};
