import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import YouShared from "./YouShared";
import Heading from "./Heading";
import RequestAccessModal from "./RequestAccessModal";
import OfferAccessModal from "./OfferAccessModal";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
const AppContext = React.createContext();
class Sharing extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "2",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      showWarning: false,
      deleteId: null
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModal2 = this.toggleModal2.bind(this);
    this.submitOffer = this.submitOffer.bind(this);
    this.submitRequestAccess = this.submitRequestAccess.bind(this);
    this.emailOfferChange = this.emailOfferChange.bind(this);
    this.emailAccessChange = this.emailAccessChange.bind(this);
    this.onSubmitOffer = this.onSubmitOffer.bind(this);
    this.onSubmitRequestAccess = this.onSubmitRequestAccess.bind(this);
    this.deletePermission = this.deletePermission.bind(this);
    this.proceedDelete = this.proceedDelete.bind(this);
    this.approvePermission = this.approvePermission.bind(this);
    this.toggleWarning = this.toggleWarning.bind(this);
  }
  onSubmitOffer(e) {
    e.preventDefault();
    this.props.dispatch({
      type: "DO_SUBMIT_OFFER_READING",
      payload: { email: this.state.emailOffer }
    });
  }

  onSubmitRequestAccess(e) {
    e.preventDefault();
    this.props.dispatch({
      type: "DO_SUBMIT_REQUEST_ACCESS_READING",
      payload: { email: this.state.emailAccess }
    });
  }
  submitOffer() {
    this.props.dispatch({
      type: "DO_SUBMIT_OFFER_READING",
      payload: { email: this.state.emailOffer }
    });
  }
  submitRequestAccess() {
    this.props.dispatch({
      type: "DO_SUBMIT_REQUEST_ACCESS_READING",
      payload: { email: this.state.emailAccess }
    });
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleWarning() {
    this.setState({
      showWarning: !this.state.showWarning
    });
  }
  toggleModal2() {
    this.setState({
      modal2: !this.state.modal2
    });
  }
  emailOfferChange(event) {
    this.setState({
      emailOffer: event.target.value
    });
  }
  emailAccessChange(event) {
    this.setState({
      emailAccess: event.target.value
    });
  }
  proceedDelete() {
    if (this.state.deleteId) {
      this.props.dispatch({
        type: "DO_DELETE_PERMISSION",
        payload: { id: this.state.deleteId }
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_USERS_YOU_SHARED"
        });
        this.props.dispatch({
          type: "DO_GET_USERS_YOU_HAVE_ACCESS"
        });
      }, 400);
    }
    this.setState({ deleteId: null, showWarning: false });
  }
  deletePermission(id) {
    this.setState({ deleteId: id, showWarning: true });
  }
  approvePermission(id) {
    this.props.dispatch({
      type: "DO_APPROVE_PERMISSION",
      payload: { id: id }
    });
    setTimeout(() => {
      this.props.dispatch({
        type: "DO_GET_USERS_YOU_SHARED"
      });
      this.props.dispatch({
        type: "DO_GET_USERS_YOU_HAVE_ACCESS"
      });
    }, 400);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_YOU_SHARED"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_YOU_HAVE_ACCESS"
    });
    if (!this.props.userDetails.isCareProvider) {
      this.setState({ activeTab: "1" });
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <Heading
            toggleModal={this.toggleModal}
            toggleModal2={this.toggleModal2}
            userDetails={this.props.userDetails}
          />
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center nav-header-border-radius">
                  {!this.props.userDetails.isCareProvider && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1"
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        USERS YOU ARE SHARING WITH
                      </NavLink>
                    </NavItem>
                  )}

                  {this.props.userDetails.isCareProvider && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2"
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        PATIENTS SHARING READING TO YOU
                      </NavLink>
                    </NavItem>
                  )}

                  {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3"
                      })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      USERS SHARING READING TO YOUR ORGANIZATION
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12" className="grid-margin">
                        {this.props.usersGivenAccessMyReading && (
                          <YouShared
                            usersGivenAccessMyReading={
                              this.props.usersGivenAccessMyReading
                            }
                            userId={this.props.userId}
                            deletePermission={this.deletePermission}
                            approvePermission={this.approvePermission}
                            receivedFrom={true}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        {this.props.usersOfferedAccess && (
                          <YouShared
                            usersGivenAccessMyReading={
                              this.props.usersOfferedAccess
                            }
                            userId={this.props.userId}
                            deletePermission={this.deletePermission}
                            approvePermission={this.approvePermission}
                            receivedFrom={false}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  {/* <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        {this.props.usersOfferedAccessOrganization && (
                          <YouShared
                            usersGivenAccessMyReading={
                              this.props.usersOfferedAccessOrganization
                            }
                            userId={this.props.userId}
                            deletePermission={this.deletePermission}
                            approvePermission={this.approvePermission}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane> */}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
        <RequestAccessModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          emailAccessChange={this.emailAccessChange}
          onSubmitRequestAccess={this.onSubmitRequestAccess}
          submitRequestAccess={this.submitRequestAccess}
        />
        <OfferAccessModal
          modal2={this.state.modal2}
          toggleModal2={this.toggleModal2}
          submitOffer={this.submitOffer}
          onSubmitOffer={this.onSubmitOffer}
          emailOfferChange={this.emailOfferChange}
        />
        <SweetAlert
          warning
          showCancel
          show={this.state.showWarning}
          confirmBtnText="Yes, delete the sharing permission!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.proceedDelete}
          onCancel={this.toggleWarning}
        >
          This will delete the sharing of readings permission
        </SweetAlert>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={() => {
            this.setState({
              modal: false,
              modal2: false
            });
            this.props.dispatch({
              type: "GENERIC_ERROR",
              payload: { isGenericSuccess: false }
            });
          }}
          confirmBtnBsStyle="info"
          timeout={1000}
        >
          Submitted Successfully
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersGivenAccessMyReading,
    usersOfferedAccess,
    usersOfferedAccessOrganization
  } = careprovider;
  const userId = user.userDetails._id;
  const userDetails = user.userDetails;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersGivenAccessMyReading,
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    userId,
    userDetails
  };
};

export default connect(mapStateToProps)(Sharing);
