import React from "react";
import userImage from "../../files/user.png";

const ErrorDisplay = (props) => {
  return (
    <div>      
      <p className="display-error-messages">{props && props.message ? props.message : "-"}</p>
    </div>
  );
};

export default ErrorDisplay;
