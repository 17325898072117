import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
  Container
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";

import ReactLoading from "react-loading";
import UserName from "./UserName";
const DeleteUserComponent = (props) => {
  const { deleteModal, initiateReset, section, userDetails, deleteWarning } =
    props;
  const [modal, setModal] = useState(deleteModal);
  const [refresh, setRefresh] = useState(false);
  const [userId, setUserId] = useState(userDetails.userId);
  const [name, setName] = useState(userDetails.fname + " " + userDetails.lname);
  const [warning, setWarning] = useState(false);
  const [warningEnable, setWarningEnable] = useState(false);
  const [warningDisable, setWarningDisable] = useState(false);
  const [surewarning, setSureWarning] = useState(false);
  const dispatch = useDispatch();
  let dispatchActon = "";
  if (section === "patientList") {
    dispatchActon = "DO_GET_USERS_YOU_HAVE_ACCESS";
  }
  if(section === "clientPatientList"){
    dispatchActon = "DO_GET_ALL_PATIENT";
  }
  if (section === "careproviderList") {
    dispatchActon = "DO_GET_CAREPROVIDERS";
  }
  if(section === "sharedFamilyList"){
    dispatchActon = "DO_GET_SHARED_ACCOUNT";
  }
  const proceedDisableUser = (name, userId) => {
    setWarningDisable(false);
    setModal(true);
    dispatch({
      type: "DO_DISABLE_USER",
      payload: {
        user: userId
      }
    });
    setRefresh(true);
    setTimeout(() => {
      if(dispatchActon === "DO_GET_ALL_PATIENT"){
        dispatch({
          type: dispatchActon,
          payload: { page: (props.dataSize == 1) ? props.page - 1 : props.page, limit: props.limit, selectedApi: props.selectedApi }
        });
      }else if(dispatchActon === "DO_GET_SHARED_ACCOUNT"){
        dispatch({
          type: dispatchActon,
          payload: { page: (props.dataSize == 1) ? props.page - 1 : props.page, limit: props.limit }
        });
      }
      else{
        dispatch({
          type: dispatchActon,
          payload: { client: true }
        });
      }
      setTimeout(() => {
        initiateReset();
      }, 1200);
    }, 200);
  };
  const disableUser = () => {
    setWarningDisable(true);
    setModal(true);
  };
  const enableUser = () => {
    setWarningEnable(true);
    setModal(true);
  };
  const proceedEnableUser = (name, userId) => {
    setWarningEnable(false);
    dispatch({
      type: "DO_ENABLE_USER",
      payload: {
        user: userId
      }
    });
    setRefresh(true);
    setTimeout(() => {
      if(dispatchActon === "DO_GET_ALL_PATIENT"){
        dispatch({
          type: dispatchActon,
          payload: { page: (props.dataSize == 1) ? props.page - 1 : props.page, limit: props.limit, selectedApi: props.selectedApi }
        });
      }else if(dispatchActon === "DO_GET_SHARED_ACCOUNT"){
        dispatch({
          type: dispatchActon,
          payload: { page: (props.dataSize == 1) ? props.page - 1 : props.page, limit: props.limit }
        });
      }else{
        dispatch({
          type: dispatchActon,
          payload: { client: true }
        });
      }
      setTimeout(() => {
        initiateReset();
      }, 1200);
    }, 200);
  };
  const deleteUser = () => {
    setWarning(true);
  };
  const proceedWarningDelete = (name, userId) => {
    setWarning(false);
    setSureWarning(true);
    setModal(true);
  };
  const proceedDelete = (name, userId) => {
    setWarning(false);
    setSureWarning(false);
    dispatch({
      type: "DO_DELETE_USER",
      payload: {
        user: userId
      }
    });
    setRefresh(true);
    setTimeout(() => {
      if(dispatchActon === "DO_GET_ALL_PATIENT"){
        dispatch({
          type: dispatchActon,
          payload: { page: props.page, limit: props.limit }
        });
      }else if(dispatchActon === "DO_GET_SHARED_ACCOUNT"){
        dispatch({
          type: dispatchActon,
          payload: { page: (props.dataSize == 1) ? props.page - 1 : props.page, limit: props.limit }
        });
      }else{
        dispatch({
          type: dispatchActon,
          payload: { client: true }
        });
      }
      setTimeout(() => {
        initiateReset();
      }, 1200);
    }, 1200);
  };

  return (
    <>
      {/* <Col md="3" className="text-center px-1 py-1" onClick={initiateReset}>
        <Card body className="px-1">
          <CardText>
            <img
              src="https://image.flaticon.com/icons/svg/1390/1390343.svg"
              width="140"
              height="140"
              border="0"
              className="img-fluid"
            />
            <div className="text-center">DELETE/DEACTIVATE USER</div>
          </CardText>
        </Card>
      </Col> */}

      <Modal isOpen={deleteModal} size="md" backdrop="static" centered>
        <ModalHeader toggle={initiateReset}>ACTION </ModalHeader>
        <ModalBody className="bg-white p-0 pt-2">
          {/* {JSON.stringify(userDetails)} */}
          <div className="mx-auto text-center">
            <UserName
              user={userDetails}
              noMargin={true}
              imgStyling={{ width: "90px" }}
            ></UserName>{" "}
            <div className="btn-group">
              {userDetails.isActive && !deleteWarning && (
                <>
                  <Button onClick={disableUser} color="warning">
                    <i className="mdi mdi-account-off"></i> DEACTIVATE
                  </Button>
                </>
              )}
              {!userDetails.isActive && !deleteWarning && (
                <>
                  <Button color="success" onClick={enableUser}>
                    ENABLE
                  </Button>
                </>
              )}
              {deleteWarning && (
                <Button
                  color="danger"
                  onClick={deleteUser}
                  className="text-white"
                >
                  DELETE
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <SweetAlert
        warning
        showCancel
        show={warning}
        confirmBtnText="Yes, delete the user now!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => proceedWarningDelete(name, userId)}
        onCancel={() => setWarning(false)}
      >
        This will delete or wipeout all data related to the user {name}
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        show={surewarning}
        confirmBtnText="Proceed!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you very sure?"
        onConfirm={() => proceedDelete(name, userId)}
        onCancel={() => {
          setWarning(false);
          setSureWarning(false);
        }}
      >
        This will really delete or wipeout all data related to the user {name}
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        show={warningDisable}
        confirmBtnText="Proceed!"
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="default"
        title={`Are you sure to disable ${name}?`}
        onConfirm={() => proceedDisableUser(name, userId)}
        onCancel={() => {
          setWarningDisable(false);
        }}
      >
        Disabling or Deactiviting will immediately pause its schedule
        expectations and unable to do things like login and other stuff
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        show={warningEnable}
        confirmBtnText="Enable!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={`Are you sure to enable back ${name}?`}
        onConfirm={() => proceedEnableUser(name, userId)}
        onCancel={() => {
          setWarningEnable(false);
        }}
      >
        Enable or Activiting will immmediately resume its schedule expectations
        and able to do things like login and other stuff.
      </SweetAlert>
    </>
  );
};

export default DeleteUserComponent;
