import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback
} from "reactstrap";
const ResetPassword = props => (
  <Modal
    isOpen={props.modalReset}
    toggle={props.toggleReset}
    backdrop="static"
    className="bastaLang modal-md"
  >
    <ModalHeader toggle={props.toggleReset}>
      CHANGE PASSWORD OF {props.fullname}
    </ModalHeader>

    <ModalBody style={{ backgroundColor: "white" }}>
      <Form onSubmit={props.submitReset}>
        <FormGroup>
          <Label for="exampleText">New Password</Label>
          <Input
            type="password"
            name="password"
            pattern=".{6,}"
            required
            title="6 characters minimum"
            onChange={props.handleInputChange}
            rows={10}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleText">Confirm New Password</Label>
          <Input
            invalid={props.errorPassword}
            type="password"
            className="form-control form-control-lg"
            placeholder="Confirm Password"
            name="confirmpassword"
            pattern=".{6,}"
            required
            title="6 characters minimum"
            onChange={props.handleInputChange}
            rows={10}
          />

          {props.errorPassword && (
            <FormFeedback>Password does not match</FormFeedback>
          )}
        </FormGroup>

        <button type="submit" className="btn btn-block btn-info">
          CHANGE PASSWORD
        </button>
      </Form>
    </ModalBody>
  </Modal>
);

export default ResetPassword;
