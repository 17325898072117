import React from 'react';
import moment from "moment";
import userImage from "../../files/user.png";

const ChatMessage = (pops) => {
    const style = pops.isSender ? "chat-message-right" : "chat-message-left";
    const background = pops.isSender ? "bg-light-sender" : "bg-light-receiver";
    const profile = pops.image != undefined && pops.image != "" ? window.location.origin + "/" + pops.image : userImage;
    return (
        <>
            {pops.dataModel && (
                <div className={style + " pb-4"}>
                    <div className="display-center">
                        <img
                            src={profile}
                            className="rounded-circle mr-1"
                            alt={pops.name}
                            width="40"
                            height="40"
                        />
                    </div>
                    <div className={"flex-shrink-1 rounded py-2 px-3 ml-3 right-date-time " + background}>
                        <div className="font-weight-bold mb-1 display-center-message-time message-time">
                            {pops.name}
                            <div className="text-muted small text-nowrap mt-2 float-right padding-margin-zero message-time">
                                {moment(pops.dataModel.createdAt).format('MMMM DD, YYYY HH:mm')}
                            </div>
                        </div>
                        {pops.dataModel.body}
                    </div>
                </div>
            )}
        </>        
    );
};

export default ChatMessage;