import React from "react";
import Link from "components/ExtendLink";
const Heading = props => (
  <div className="col-12">
    <div className="page-header">
      {props.title && (
        <h3 className="page-title">
          {props.children}

          {props.title}
        </h3>
      )}

      <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <span />

            <i className="mdi mdi-alert-circle-outline icon-sm text-danger align-middle" />
          </li>
        </ul>
      </nav>
    </div>
    <span
      className={`align-items-center purchase-popup mb-0 ${props.backbutton}`}
    >
      <Link
        to="/expectations"
        className="text-white mr-auto btn btn-gradient-dark btn-icon-text"
      >
        <i className="mdi mdi-arrow-left btn-icon-prepend" />
        Back
      </Link>
    </span>
    <span
      className={`align-items-center purchase-popup mb-0 ${props.mainbutton}`}
    >
      <a
        target="_blank"
        onClick={props.toggleModal}
        className="text-white ml-auto btn btn-info btn-rounded btn-fw"
      >
        Create a New Staff
      </a>
    </span>
  </div>
);

export default Heading;
