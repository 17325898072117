const moment = require("moment-timezone");
export const vitalsBody = {
  name: "",
  message: "",
  messageCareprovider: "",
  readingType: "",
  readingSubType: "",
  readingSubTypeId: "",
  condition: "",
  notifySelf: [],
  notifyReadingOwner: [],
  notifyStaff: [],
  sharedUsers: [],
  conditionGroup: [],
  valueProperty: [],
  timezone: moment.tz.guess()
};
export const weightBody = {
  name: "",
  message: "",
  messageCareprovider: "",
  readingType: "5bb306382598931ffbd1b625",
  readingSubType: "WS",
  readingSubTypeId: "5be56d0b3863cd0647d79898",
  gainLossPerDay: "",
  notifySelf: [],
  notifyReadingOwner: [],
  notifyStaff: [],
  sharedUsers: [],
  conditionGroup: [],
  timezone: moment.tz.guess()
};
export const TrendingViolationBody = {
  name: "",
  message: "",
  messageCareprovider: "",
  rule: "",
  numberOfTimes: "",
  repeatPer: "",
  notifySelf: [],
  notifyReadingOwner: [],
  sharedUsers: [],
  conditionGroup: [],
  timezone: moment.tz.guess()
};
export const TimeAdherenceBody = {
  name: "",
  message: "",
  messageCareprovider: "",
  readingType: "",
  readingSubType: "",
  readingSubTypeId: "",
  time: [],
  notifySelf: [],
  notifyReadingOwner: [],
  notifyStaff: [],
  sharedUsers: [],
  conditionGroup: [],
  timezone: moment.tz.guess()
};
export const AdherenceBody = {
  name: "",
  message: "",
  messageCareprovider: "",
  readingType: "",
  readingSubType: "",
  readingSubTypeId: "",
  repeatPerDay: "",
  notifySelf: [],
  notifyReadingOwner: [],
  notifyStaff: [],
  sharedUsers: [],
  conditionGroup: [],
  timezone: moment.tz.guess()
};
export const ReminderBody = {
  name: "",
  message: "",
  messageCareprovider: "",
  readingType: "",
  readingSubType: "",
  readingSubTypeId: "",
  time: [],
  notifySelf: [],
  notifyReadingOwner: [],
  notifyStaff: [],
  sharedUsers: [],
  conditionGroup: [],
  timezone: moment.tz.guess()
};
