import React, { PureComponent } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Alert } from "reactstrap";

import {
  AdherenceBody,
  TimeAdherenceBody,
  vitalsBody,
  ReminderBody,
  TrendingViolationBody
} from "./configBody";
import VitalsDetails from "./VitalsDetails";
import TrendingViolationDetails from "./TrendingViolationDetails";
import TimeAdherenceDetails from "./TimeAdherenceDetails";
import ReminderDetails from "./ReminderDetails";
import AdherenceDetails from "./AdherenceDetails";
import WeightRuleDetails from "./WeightRuleDetails";
import moment from "moment-timezone";
import map from "lodash/map";
import QuestionnaireDetails from "./QuestionnaireDetails";
class ExpandedRule extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      isDelete: false,
      isQuestionnaireDelete: false,
      body: "",
      timezoneList: []
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmDeleteQuestionaire = this.confirmDeleteQuestionaire.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.isDelete = this.isDelete.bind(this);
    this.isQuestionnaireDelete = this.isQuestionnaireDelete.bind(this);
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  confirmDelete() {
    this.setState({ isDelete: false });
    const { _id, ruleType } = this.props.ruleDetails;
    this.props.dispatch({
      type: "DO_DELETE_RULE",
      payload: {
        id: _id,
        ruleType: ruleType
      }
    });
  }
  confirmDeleteQuestionaire(){
    this.setState({ isQuestionnaireDelete: false });
    const { _id, ruleType } = this.props.ruleDetails;
    this.props.dispatch({
      type: "DO_DELETE_QUESTIONNAIRE",
      payload: {
        id: _id        
      }
    });
  }
  isDelete() {
    this.setState({ isDelete: true });
  }
  isQuestionnaireDelete(){
    this.setState({isQuestionnaireDelete: true})
  }
  cancelDelete() {
    this.setState({ isDelete: false });
    this.setState({ isQuestionnaireDelete: false });
  }
  cancelQuestionnaireDelete() {
    
  }
  componentDidMount() {
    // this.props.dispatch({
    //   type: "GENERIC_ERROR",
    //   payload: { isGenericSuccess: false }
    // });
    const timezoneList = moment.tz.names();
    const mapTimezones = map(timezoneList, v => {
      return { label: v, value: v };
    });
    this.setState({ timezoneList: mapTimezones });
    this.setState({ body: this.props.ruleDetails });
  }
  render() {
    return (
      <div>
        {this.props.ruleDetails.ruleType === "Vitals" && (
          <VitalsDetails
            confirmDelete={this.confirmDelete}
            isDelete={this.isDelete}
            delete={this.delete}
            ruleDetails={this.props.ruleDetails}
          />
        )}
        {this.props.ruleDetails.ruleType === "Trending Violation" && (
          <div>
            <Alert color="warning">
              <h4 className="alert-heading">Notice!</h4>
              Trending Violation will be process base on frequency at timezone
              of <b>{this.state.body.timezone}</b>
            </Alert>
            <TrendingViolationDetails
              confirmDelete={this.confirmDelete}
              isDelete={this.isDelete}
              cancelDelete={this.cancelDelete}
              ruleDetails={this.props.ruleDetails}
              timezoneList={this.state.timezoneList}
              timezone={{
                label: this.props.ruleDetails.timezone,
                value: this.props.ruleDetails.timezone
              }}
            />
          </div>
        )}
        {this.props.ruleDetails.ruleType === "Questionnaire" && (
          <div>
            <Alert color="warning">
              <h4 className="alert-heading">Notice!</h4>
              Questionnaire will be process base on frequency at timezone
              of <b>{this.state.body.timezone}</b>
            </Alert>
            <QuestionnaireDetails
              confirmDelete={this.confirmDeleteQuestionaire}
              isQuestionnaireDelete={this.isQuestionnaireDelete}
              cancelDelete={this.cancelDelete}
              ruleDetails={this.props.ruleDetails}
              timezoneList={this.state.timezoneList}
              timezone={{
                label: this.props.ruleDetails.timezone,
                value: this.props.ruleDetails.timezone
              }}
            />
          </div>
        )}
        {this.props.ruleDetails.ruleType === "Time Adherence" && (
          <div>
            <Alert color="warning">
              <h4 className="alert-heading">Notice!</h4>
              Time Adherence will be process everyday at the time you set up
              base on timezone <b>{this.state.body.timezone}</b>
            </Alert>
            <TimeAdherenceDetails
              confirmDelete={this.confirmDelete}
              isDelete={this.isDelete}
              cancelDelete={this.cancelDelete}
              ruleDetails={this.props.ruleDetails}
              timezoneList={this.state.timezoneList}
              timezone={{
                label: this.props.ruleDetails.timezone,
                value: this.props.ruleDetails.timezone
              }}
            />
          </div>
        )}
        {this.props.ruleDetails.ruleType === "Reminder" && (
          <div>
            <Alert color="warning">
              <h4 className="alert-heading">Notice!</h4>
              Reminder will be process everyday at the time you set up base on
              timezone <b>{this.state.body.timezone}</b>
            </Alert>
            <ReminderDetails
              confirmDelete={this.confirmDelete}
              isDelete={this.isDelete}
              cancelDelete={this.cancelDelete}
              ruleDetails={this.props.ruleDetails}
              timezoneList={this.state.timezoneList}
              timezone={{
                label: this.props.ruleDetails.timezone,
                value: this.props.ruleDetails.timezone
              }}
            />
          </div>
        )}
        {this.props.ruleDetails.ruleType === "Adherence" && (
          <div>
            <Alert color="warning">
              {" "}
              <h4 className="alert-heading">Notice!</h4>
              Adherence will be process everyday at 9:00AM{" "}
              <b>{this.state.body.timezone}</b>
            </Alert>
            <AdherenceDetails
              confirmDelete={this.confirmDelete}
              isDelete={this.isDelete}
              cancelDelete={this.cancelDelete}
              ruleDetails={this.props.ruleDetails}
              timezoneList={this.state.timezoneList}
              timezone={{
                label: this.props.ruleDetails.timezone,
                value: this.props.ruleDetails.timezone
              }}
            />
          </div>
        )}
        {this.props.ruleDetails.ruleType === "Weight" && (
          <WeightRuleDetails
            confirmDelete={this.confirmDelete}
            isDelete={this.isDelete}
            cancelDelete={this.cancelDelete}
            ruleDetails={this.props.ruleDetails}
          />
        )}
        <SweetAlert
          warning
          showCancel
          show={this.state.isDelete}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.confirmDelete}
          onCancel={this.cancelDelete}
        >
          You will not be able to recover this rule created!
        </SweetAlert>
        <SweetAlert
          warning
          showCancel
          show={this.state.isQuestionnaireDelete}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.confirmDeleteQuestionaire}
          onCancel={this.cancelDelete}
        >
          You will not be able to recover this questionnaire created!
        </SweetAlert>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR",
              payload: { isGenericSuccess: false }
            });
          }}
          confirmBtnBsStyle="info"
        >
          Submitted Successfully
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    rulesSetByOthers
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    rulesSetByOthers,
    userId,
    isCareProvider
  };
};

export default connect(mapStateToProps)(ExpandedRule);
