import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";

const RequestAccessModal = props => (
  <Modal isOpen={props.modal} toggle={props.toggleModal} className="bastaLang">
    <Form onSubmit={props.onSubmitRequestAccess}>
      <ModalHeader toggle={props.toggleModal}>
        Request Access to Reading
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="exampleEmail">Type Email of a Patient/User</Label>
          <Input
            type="email"
            name="email"
            required
            id="exampleEmail"
            placeholder="Email Address"
            onChange={props.emailAccessChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="success" type="submit">
          Request Reading Data Sharing
        </Button>
        <Button color="secondary" onClick={props.toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Form>
  </Modal>
);

export default RequestAccessModal;
