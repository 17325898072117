import React, { Component } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import _ from "lodash";
import moment from "moment";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import CCMForm from "./CCMForm";
import ReactToPrint from "react-to-print";

class ViewCCMLogs extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modelViewCCMFormLogs}
        toggle={this.props.toggleViewCCMFormLogs}
        size="lg"
        className="display-table"
        style={{ display: "table" }}>
        <ModalHeader className="close_button" toggle={this.props.toggleViewCCMFormLogs}>
          <Label className="align-items-center justify-content-center text-center"> CCM Submission Logs</Label>
          <button
            style={{ float: 'right' }}
            onClick={this.props.toggleViewCCMFormLogs}            
            className="btn btn-info ml-2 close-click-cls" >
            <i class="mdi mdi-close" style={{
              fontSize: 20
            }}></i>
            Close
          </button>
        </ModalHeader>

        <ModalBody style={{ backgroundColor: "white" }}>
          <Row>
            <Col md={12}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                keyField="_id"
                bordered={true}
                hover={true}
                condensed={true}
                noDataIndication={() => (
                  <div>
                    <LottieLoader
                      loadLoop={false}
                      autoplayLoop={true}
                      source={lottieEmptyFile}
                      Lheight={150}
                      Lwidth={150}
                      titleSmall={"Sorry! No submission log found :("}
                      subTitleSmall={"We can't find any submission logs."}
                    />
                  </div>
                )}
                data={this.props.ccmSubmissinLogs ? this.props.ccmSubmissinLogs : []}
                columns={[
                  {
                    dataField: "description",
                    text: "Created By",
                    formatter: (cell, row) => {
                      if (!row.createdBy) {
                        return <div className="text-left"> - </div>;
                      }
                      return (
                        <div className="text-left">{row.createdBy.fname}{" "} {row.createdBy.lname}</div>
                      );
                    },
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                    },
                  },
                  {
                    dataField: "description",
                    text: "Modify By",
                    formatter: (cell, row) => {
                      if (!row.modifyBy) {
                        return <div className="text-left"> Initial Form </div>;
                      }
                      return (
                        <div className="text-left">{row.modifyBy.fname}{" "} {row.modifyBy.lname}</div>
                      );
                    },
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                    },
                  },
                  {
                    dataField: "createdDate",
                    text: "Created Date",
                    formatter: (cell, row) => {
                      if (row.createdDate) {
                        return moment(row.createdDate).tz(moment.tz.guess()).format("MMMM DD, YYYY HH:mm:ss");
                      } else {
                        return "NONE";
                      }
                    },
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                    },
                  },
                  {
                    dataField: "description",
                    text: "Last Modify Date",
                    formatter: (cell, row) => {
                      if (row.modifyDate) {
                        return moment(row.modifyDate).tz(moment.tz.guess()).format("MMMM DD, YYYY HH:mm:ss");
                      } else {
                        return "NONE";
                      }
                    },
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                    },
                  }
                  // ,
                  // {
                  //   text: "Action",
                  //   headerStyle: (colum, colIndex) => {
                  //     return { weight: "150px", padding: "16px", fontSize: "16px", color: "#070707" };
                  //   },
                  //   formatter: (cell, row) => {
                  //     return (
                  //       <div>
                  //         <button
                  //           type="button"
                  //           className="btn btn-info ml-2 add-ccm-form"
                  //           onClick={() => {
                  //             this.toggleAddCCMForm();
                  //           }}>
                  //           <i class="mdi mdi-eye"></i>&nbsp;View
                  //         </button>
                  //       </div>
                  //     );
                  //   }
                  // }
                ]}
                expandRow={{
                  renderer: (row) => rowComponent(row,this.props),
                  onlyOneExpanding: false,
                  showExpandColumn: true,
                  expandByColumnOnly: false
                }}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}
const rowComponent = (row,props) => { 
  //console.log(row);
  const submission = row.submission
  return (
    <div>
      <Row>
        <Col md={12} style={{background: "white"}}>
          <CCMForm            
            isEditable={false}
            latestSubmission={submission}
            submisionModel={row}
            profile={props.profile}
          />
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage
  } = careprovider;
  const userDetails = user.userDetails;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    userDetails
  };
};

export default connect(mapStateToProps)(ViewCCMLogs);
