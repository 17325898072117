import React, { Component } from "react";

class NotFound extends Component {
  componentDidMount() {
    const url = this.props.location.pathname;
    const splitUrl = url.split("/");
    const countPathName = url.split("/").length - 1;
    if (countPathName >= 2) {
      this.props.history.push("/" + splitUrl[1]);
    }
  }
  render() {
    return <div>Test</div>;
  }
}
export default NotFound;
