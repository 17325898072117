import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";

import Link from "components/ExtendLink";
import { connect } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback
} from "reactstrap";
const queryString = require("query-string");

class InviteRegister extends Component {
  constructor(props) {
    super(props);
    this.phoneNumber = this.phoneNumber.bind(this);
    this.state = {
      fname: this.props.fname,
      lname: this.props.lname,
      email: this.props.email,
      username: "",
      password: "",
      bday: "",
      organization: "",
      phone: this.props.phone,
      gender: "Male",
      role: "",
      isCareProvider: true,
      isStaff: true,
      phone: "",
      confirmpassword: "",
      errorPassword: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  phoneNumber(value) {
    this.setState({
      phone: value
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  emailChange(event) {
    this.props.dispatch({
      type: "DO_CHANGE_EMAIL",
      payload: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let stateValue = this.state;
    delete stateValue.errorPassword;
    delete stateValue.confirmpassword;
    this.props.dispatch({
      type: "DO_REGISTER_FINAL",
      payload: stateValue
    });
  }

  componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    this.setState(parsed);
    const { client } = this.props.match.params;
    const domainCheck = window.location.hostname;
    if (domainCheck.includes("testnshare")) {
      this.props.dispatch({
        type: "DO_GET_CLIENT_INFO",
        payload: { slug: "testnshare" }
      });
    }
    if (client) {
      this.props.dispatch({
        type: "DO_GET_CLIENT_INFO",
        payload: { slug: client }
      });
    }
    // if (!this.props.email) {
    //   this.props.history.push("/register");
    // }
    // if (this.props.authenticated) {
    //   this.props.history.push("/");
    // }
  }
  componentDidUpdate() {
    // if (this.props.authenticated) {
    //   this.props.history.push("/");
    // }
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            <div className="mx-auto" style={{ width: "500px" }}>
              <div className="auth-form-light text-left p-5 login-style">
                <h4>Invitation Form for {this.state.organization} Staff</h4>

                {this.props.errorRegister && (
                  <div className="alert alert-danger text-center">
                    {this.props.errorRegister.message}
                  </div>
                )}
                <form className="pt-3" onSubmit={this.handleSubmit}>
                  <div>
                    Name:
                    <span className="font-weight-bold">
                      {this.state.fname} {this.state.lname}
                    </span>
                  </div>
                  <div>
                    Email:{" "}
                    <span className="font-weight-bold">{this.state.email}</span>
                  </div>
                  <div>
                    Role:{" "}
                    <span className="font-weight-bold">
                      {this.capitalizeFirstLetter(this.state.role)}
                    </span>
                  </div>
                  <br />
                  <h6 className="font-weight-light">
                    Please Provide the Following Details
                  </h6>
                  <div className="form-group">
                    <label>Username*</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Username"
                      required
                      name="username"
                      value={this.state.username}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password {this.state.errorPassword}</label>
                    <Input
                      invalid={this.state.errorPassword}
                      type="password"
                      className="form-control form-control-lg"
                      placeholder="Confirm Password"
                      name="confirmpassword"
                      value={this.state.confirmPassword}
                      onChange={this.handleInputChange}
                    />

                    {this.state.errorPassword && (
                      <FormFeedback>Password does not match</FormFeedback>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <PhoneInput
                      country="US"
                      inputClassName="form-control"
                      required
                      placeholder="Enter phone number"
                      value={this.state.value}
                      onChange={this.phoneNumber}
                    />
                  </div>
                  <div className="form-group">
                    <label>Home Number</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Home Number"
                      name="homeNumber"
                      value={this.state.homeNumber}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Work Number</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Work Number"
                      name="workNumber"
                      value={this.state.workNumber}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Date of Birth</label>
                    <input
                      type="date"
                      className="form-control form-control-lg"
                      name="bday"
                      placeholder="dd/mm/yyyy"
                      value={this.state.bday}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      name="gender"
                      value={this.state.gender}
                      class="form-control"
                      onChange={this.handleInputChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                  <FormGroup>
                    <Label for="exampleText">Address</Label>
                    <Input
                      type="text"
                      name="address"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">City</Label>
                    <Input
                      type="text"
                      name="city"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">State</Label>
                    <Input
                      type="text"
                      name="state"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">Zip Code</Label>
                    <Input
                      type="text"
                      name="zip"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <div className="form-group">
                    <label>Organization</label>
                    <input
                      type="text"
                      name="organization"
                      className="form-control form-control-lg"
                      placeholder="Organization"
                      readOnly
                      value={this.state.organization}
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                    >
                      TAKE ME TO THE PORTAL
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, user }) => {
  const { email, fname, lname, errorRegister, firstRegister, phone } = auth;
  return {
    email,
    fname,
    lname,
    errorRegister,
    firstRegister,
    phone,
    user
  };
};

export default withRouter(connect(mapStateToProps)(InviteRegister));
