import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";

import {
  VitalsIcon,
  TimeIcon,
  AdherenceIcon,
  ReminderIcon,
  TrendingIcon
} from "../../Rules/RuleIcon";
import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";

const PickCPM = props => (
  <Modal
    isOpen={props.modal}
    toggle={props.toggleModal}
    className="bastaLang modal-md"
  >
    <ModalHeader toggle={props.toggleModal} className="bg-info text-white">
      Choose a Patient Monitoring Mode
    </ModalHeader>

    <ModalBody style={{ backgroundColor: "white" }}>
      <h5 className="text-center" />
      <div className="row">
        <div className="col-6">
          <NavLink onClick={props.countRPM} className="text-center d-block">
            <img
              src="http://hbcommunications.com/images/uploads/services_gallery/RemoteMonitor.png"
              style={{ height: "180px" }}
            />
            <span className="menu-title font-weight-bold d-block text-dark">
              RPM MODE
            </span>
            <small>Remote Patient Monitoring</small>
          </NavLink>
        </div>
        <div className="col-6">
          <NavLink onClick={props.countCCM} className="text-center d-block">
            <img
              src="http://pcm.um.edu.my/wp-content/uploads/2017/09/chronic-diseases-icon.png"
              style={{ height: "180px" }}
            />
            <span className="menu-title font-weight-bold d-block text-dark">
              CCM MODE
            </span>
            <small>Chronic Care Monitoring</small>
          </NavLink>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default PickCPM;
