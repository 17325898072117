import React from 'react'

import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import ErrorDisplay from '../Reusable/ErrorDisplay';

const FaxInput = (props) => {
  const { register, control, getValues, setValue, handleSubmit, formState: { errors }, reset } = useForm();
  const onSubmit = data => {
    props.onSubmit(data);
  };

  const registerOptions = {
    faxFieldLimit: {
      required: "This field is required!",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid fax number! Must be a number!"
      },
      minLength: {
        value: 6,
        message: "Must be min 6 and max 12 number"
      },
      maxLength: {
        value: 12,
        message: "Must be min 6 and max 12 number"
      }
    },
  };
  return (
    <>
      <div>
        <h4>
          Please enter a fax number!
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='session-div-signature'>
            <div style={{ width: "100%" }}>
              <input
                className="input-box-style-editbale"
                style={{ height: 40 }}
                name={`faxNumber`}
                ref={register(registerOptions.faxFieldLimit)}
              />              
            </div>
            <button
              type="submit"
              style={{ float: 'center', marginRight: 10 }}
              className="btn btn-info ml-2 save-configration" >
              <i class="mdi mdi-check-circle" style={{
                fontSize: 20,
                marginRight: 8
              }}></i>
              Sent
            </button>            
          </div>
          {errors?.faxNumber && errors?.faxNumber.message && (
            <ErrorDisplay message={errors?.faxNumber?.message} />
          )}
        </form>
      </div>
    </>
  )
}

export default FaxInput