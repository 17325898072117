import React, { Component } from "react";
import moment from "moment";
import { map, uniq } from "lodash";
import { formatDuration } from "components/utils/fetchHook";
import ReadingSubTypeSummary from "./ReadingSubTypeSummary";
import male from "../../files/male-placeholder.png";
import female from "../../files/woman-placeholder.png";
import CCMQuestionaire from "./CCMQuestionaire";
import SessionNotes from "./SessionNotes";
import readingSubTypes from "store/reducers/readingSubType.json";
import CCMNotesWithSignature from "./CCMNotesWithSignature";
import RPMNotesWithSignature from "./RPMNotesWithSignature";
import CCMForm from "../CCMForm/CCMForm";

export default class CCMinterventionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1"
    };
  }
  componentDidMount() {}
  render() {
    const {
      start,
      end,
      profile,
      totalSessionCCM,
      totalSessionRPM,
      violations,
      expectation,
      noteIntervention,
      ccmAnswers,
      readings,
      bodytraceReading,
      lastReadingDate,
      onBoardingNotes,
      rpmMode,
      ccmMode,      
      onRPMBoardingNotes,
      ccmFormSubmission,
      isBloodPressureReading
    } = this.props.responseData;
    const getDevice = map(profile.devices, "device");
    const getDeviceType = map(getDevice, "deviceType");
    const getDeviceTypeName = map(getDeviceType, "description");
    const getUniqNameDevice = uniq(getDeviceTypeName);
    const image = this.props.patient.gender === "Male" || this.props.patient.gender === "M" ? male : female;
    document.title = "RPM / CCM Monthly Attestation and Clinical Report";
    const humanInteractionNoteInterventions = noteIntervention.filter(
      (type) => {
        if ((type.subject && type.subject.includes("RPM")) || (type.subject && type.subject.includes("CCM"))) {
          // These notes are ones included in RPM / CCM notes section, remove from here
          if(type.subject.includes("Received RPM Order from Provider")){
            return true;    
          }
          return false;
        }
        return true;
      }
    );
    return (
      <div id="exportToImageIntervention">
        <h4 className="text-center"></h4>
        <h3 class="text-center">
          RPM / CCM Monthly Attestation and Clinical Report
        </h3>
        <h4 class="text-center">
          <span id="dateRange">
            Date Range: {moment(start).format("MM/DD/YYYY")} -{" "}
            {moment(end).format("MM/DD/YYYY")}
          </span>
        </h4>
        <div class="card card-design-session-popup">
          <div class="card-body card-body-design-session-popup" style={{ padding: 5 }}>
            <div class="card-title row">
              <div class="col-5">
                <h2 style={{padding:5,}}>
                  <img
                    src={image}
                    alt="profile-image"
                    className="profile rounded-circle img-fluid mr-2"
                    style={{ width: "50px" }}
                  />

                  <span id="patientImage"></span>
                  <b>
                    <span id="patientName" style={{
                      fontSize: 22,
                      fontWeight: 500
                    }}>
                      {this.props.patient.fname} {this.props.patient.lname}                      
                    </span>
                  </b>
                </h2>
              </div>
              <div class="col-7" style={{ 
                  float: 'right', 
                  paddingTop:10,
                  paddingBottom:10,
                  paddingLeft:20,
                  paddingRight:20 
                }}>
                <div class="row-vartical">
                  <div class="col-12" style={{textAlign: "end"}}>
                    <span class="text-dark" style={{fontSize:15}}>Nickname: </span>
                    <span id="patientBday" class="ccm-lable"> {this.props.patient.nickname ? this.props.patient.nickname : '-'} </span>
                  </div>
                  <div class="col-12" style={{textAlign: "end"}}>
                    <span class="text-dark" style={{fontSize:15}}>DOB: </span>
                    <span id="patientBday" class="ccm-lable"> {moment(this.props.patient.bday).format("MMMM DD, YYYY")} </span>
                  </div>
                  <div class="col-12" style={{textAlign: "end"}}>
                    <span class="text-dark" style={{fontSize:15}}>Last Reading Date: </span>
                    <span id="patientBday" class="ccm-lable"> {lastReadingDate ? moment(lastReadingDate).format("MMMM DD, YYYY") : 'NONE'} </span>
                  </div>
                  <div class="col-12" style={{textAlign: "end"}}> 
                    <p>
                      <span class="text-dark">Devices: </span>
                      <span id="patientDevices" class="ccm-lable">
                          {getUniqNameDevice.join(", ")}
                      </span>
                    </p>
                  </div>
              </div>
              </div>
            </div>
            {ccmFormSubmission && (
              <>
                <hr style={{ borderTop: "3px solid #5c5959" }} />
                <h3 className="text-center font-weight-bold">
                  <div>
                    CCM CARE PLAN FORM
                  </div>
                </h3>
                <hr style={{ borderTop: "1px solid #dedede" }} />
                <div>
                  <div class="card-title row">
                    <div class="col-6">
                      <div class="col-12" style={{ textAlign: "start" }}>
                        <span class="text-dark" style={{ fontSize: 15, fontWeight:500 }}><i className="mdi mdi-clock" /> Created Date  : </span>
                        <span id="patientBday">{ccmFormSubmission ? moment(ccmFormSubmission.createdDate).format("MMMM DD, YYYY HH:mm:ss") : '-'}</span>
                      </div>
                      <div class="col-12" style={{ textAlign: "start" }}>
                        <span class="text-dark" style={{ fontSize: 15, fontWeight:500 }}><i className="mdi mdi-clock" /> Modified Date: </span>
                        <span id="patientBday">{ccmFormSubmission && ccmFormSubmission.modifyDate ? moment(ccmFormSubmission.modifyDate).format("MMMM DD, YYYY HH:mm:ss") : '-'}</span>
                      </div>
                    </div>                    
                  </div>
                </div>
                <CCMForm
                  isEditable={false}
                  isAttestationPrint={true}
                  latestSubmission={ccmFormSubmission.submission}
                  profile={profile}
                  submisionModel={ccmFormSubmission}
                />
                <hr style={{ borderTop: "3px solid #5c5959" }} />
              </>
            )}
            {onRPMBoardingNotes != undefined && onRPMBoardingNotes.length>0 && (
              <>
                <RPMNotesWithSignature
                  onBoardingNotes={onRPMBoardingNotes}
                  profile={profile}
                />
              </>
            )}
            {onBoardingNotes != undefined && onBoardingNotes.length>0 && (
              <>
                <CCMNotesWithSignature
                  onBoardingNotes={onBoardingNotes}
                  profile={profile}
                />
              </>
            )}
            {ccmAnswers.ccmAnswers != undefined && ccmAnswers.ccmAnswers.length>0 && (
              <>
              <CCMQuestionaire 
              ccmAnswers={ccmAnswers.ccmAnswers}
              ccmModel={ccmAnswers.ccmNewAnswers}
              profile={profile}
              />
              </>
            )}            
            {totalSessionCCM != "00:00:00" && (
              <>                
                <SessionNotes
                  notes={ccmMode}
                  pmMode={"CCM MODE"}
                  pmModeTotal={formatDuration(totalSessionCCM)}
                />
              </>
            )}
            {totalSessionRPM != "00:00:00" && (
              <SessionNotes
                notes={rpmMode}
                pmMode={"RPM MODE"}
                pmModeTotal={formatDuration(totalSessionRPM)}
              />
            )}

            <br />

            {humanInteractionNoteInterventions.length > 0 && (
              <HumanInteraction
                noteIntervention={humanInteractionNoteInterventions}
              />
            )}

            <ReadingSubTypeSummary
              profileOwner={this.props.profileOwner}
              profile={profile}
              violations={violations}
              expectation={expectation}
              start={start}
              readings={readings}
              bodytraceReading={bodytraceReading}
              readingSubTypes={readingSubTypes}
              dateRange={`${moment(start).format("MM/DD/YYYY")} - ${moment(
                end
              ).format("MM/DD/YYYY")}`}
              isBloodPressureReading={isBloodPressureReading}
            />
          </div>
        </div>
      </div>
    );
  }
}

function HumanInteraction(props) {
  const filteredNoteInterventions = props.noteIntervention.map((note) => {
    return (
      <tr>
        <td>{moment(note.createdAt).format("MM/DD/YYYY")}</td>
        <td>{note.subject ? note.subject : '-'}</td>
        <td>{note.body}</td>
        <td>{note.device ? note.device.name  :  <div class="text-center"> - </div>}</td>
        <td>{note.createdBy ? note.createdBy.fname + " " + note.createdBy.lname : ''}</td>
      </tr>
    );
  });

  return (
    <div className="w-100 overflow-auto">{/*Shrey Added */}
    <table class="table table-bordered page-break-ka-after">
      <thead>
        <tr>
          <th colSpan="4" className="text-center text-dark font-weight-bold">
            <h3 class="font-weight-bold">Notes</h3>
          </th>
        </tr>

        <tr>
          <th className="text-center">Date</th>
          <th className="text-center">Subject</th>
          <th className="text-center">Note</th>
          <th className="text-center">Device</th>
          <th className="text-center" style={{width: 185}}>Noted By</th>
        </tr>
      </thead>
      <tbody className="text-left">{filteredNoteInterventions}</tbody>
    </table>
    </div>
  );
}
