import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import ReactLoading from "react-loading";
import { useFetch2 } from "components/utils/authenticatedHook";
import { useFetch, formatDuration } from "components/utils/fetchHook";
import { size, map, reverse } from "lodash";
import Select from "react-select";
import RequiredSelect from "../Rules/RequiredSelect";
import { useDispatch } from "react-redux";
const MoveUserClientComponent = ({
  modal,
  moveClientModalToggle,
  userDetails: user
}) => {
  const dispatch = useDispatch();
  const [serial, setserial] = useState("");
  const [device, setdevice] = useState("");
  const [selectedCareprovider, setselectedCareprovider] = useState("");
  const [careProviderList, setcareProviderList] = useState("");

  const [
    { response: deviceListASC = [], error2, isLoading2 },
    doFetchfES,
    sendError2
  ] = useFetch2({
    url: "/client-list",
    method: "GET"
  });
  const responseData = deviceListASC ? deviceListASC.data : deviceListASC;
  const [{ response, error, isLoading }, doFetch, sendError] = useFetch2(null);
  useEffect(() => {
    if (response) {
      if(response.message == "careProviderList") {
        setcareProviderList(response.careProviders);
      } else {
        moveClientModalToggle();
      }
    }

    return () => {};
  }, [response]);
  const submitReset = e => {
    e.preventDefault();
    doFetch({
      url: "/transfer-client",
      method: "POST",
      body: JSON.stringify({ user: user._id, client: device._id, careProvider:  selectedCareprovider._id})
    });
  };
  const deviceList = reverse(responseData);
  const getCareProvidersList = (selectedOption) => {
    doFetch({
      url: "/careprovider-list/" + selectedOption._id,
      method: "GET"
    });
 };

  return (
    <Modal
      isOpen={modal}
      toggle={moveClientModalToggle}
      className="bastaLang modal-md"
      centered
    >
      <ModalHeader toggle={moveClientModalToggle}>
        Transfer User to a Client
      </ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <h2 className="text-center pb-2">
          Transfer {user.fname} {user.lname} from client{" "}
          {user.client
            ? user.client._id + ` (${user.client.organization})`
            : "(No Client)"}
        </h2>
        {size(deviceList) > 0 && (
          <Form onSubmit={submitReset}>
            <FormGroup>
              <Label for="exampleText" className="font-weight-bold">
                Pick Client to Transfer to
              </Label>
              <RequiredSelect
                SelectComponent={Select}
                required={true}
                value={device}
                onChange={selectedOption => {
                  setdevice(selectedOption);
                  getCareProvidersList(selectedOption);
                }}
                getOptionLabel={option =>
                  option["organization"] + " (" + option["_id"] + ")"
                }
                getOptionValue={option => option["_id"]}
                options={deviceList}
              />{" "}
            </FormGroup>
            {careProviderList && (
              <FormGroup>
                <Label for="exampleText" className="font-weight-bold">
                  Pick CareProvider to Transfer to
                </Label>
                <RequiredSelect
                  SelectComponent={Select}
                  required={true}
                  onChange={selectedOption => {
                    setselectedCareprovider(selectedOption);
                  }}
                  getOptionLabel={option =>
                    option["fname"] + " " + option["lname"]
                  }
                  getOptionValue={option => option["_id"]}
                  options={careProviderList}
                />{" "}
              </FormGroup>
            )}

            {isLoading && !error && (
              <ReactLoading
                type="spin"
                className="text-center mx-auto"
                color="blue"
              />
            )}
            {error && <span className="text text-danger">{error.message}</span>}
            <button type="submit" className="btn btn-block btn-info">
              SUBMIT
            </button>
            <button
              type="button"
              onClick={moveClientModalToggle}
              className="btn btn-block btn-danger"
            >
              CANCEL
            </button>
          </Form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default MoveUserClientComponent;
