import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import pdf from "../../files/Privacy Policy.pdf";
import PhoneInput from "react-phone-number-input";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import "react-phone-number-input/style.css";
class ClientRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
      region: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.phoneNumber = this.phoneNumber.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  phoneNumber(value) {
    this.setState({ phone: value });
  }
  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.dispatch({
      type: "DO_REGISTER_CLIENT",
      payload: this.state
    });
  }
  componentDidUpdate() {}
  render() {
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            <div className="mx-auto col-5">
              <div className="auth-form-light text-left p-5 login-style">
                <div className="brand-logo mx-auto text-center">
                  <img
                    alt=""
                    src="https://cdn3.iconfinder.com/data/icons/luchesa-vol-9/128/Html-512.png"
                  />
                </div>
                <h2 className="text-center"> REGISTER TO OUR API PROGRAM</h2>
                <h6 className="font-weight-light" />
                {this.props.errorRegister && (
                  <div className="alert alert-danger text-center">
                    {JSON.stringify(this.props.errorRegister.message)}
                  </div>
                )}
                <form className="pt-3" onSubmit={this.handleSubmit}>
                  <div class="form-row">
                    <div className="form-group col-6">
                      <label for="exampleInputEmail1">Username</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Username"
                        name="username"
                        required
                        value={this.state.username}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label for="exampleInputEmail1">Email Address</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Email"
                        required
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div className="form-group col-6">
                      <label for="exampleInputEmail1">Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg"
                        placeholder="Password"
                        required
                        value={this.state.password}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label for="exampleInputEmail1">Phone</label>
                      <PhoneInput
                        inputClassName="form-control form-control-lg"
                        countrySelectToggleClassName="form-control"
                        required
                        placeholder="Enter phone number"
                        value={this.state.value}
                        onChange={this.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Usage</label>
                    <input
                      type="textarea"
                      name="usage"
                      className="form-control form-control-lg"
                      placeholder="Usage"
                      required
                      value={this.state.usage}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Organization</label>
                    <input
                      type="text"
                      name="organization"
                      className="form-control form-control-lg"
                      placeholder="Company/Organization"
                      required
                      value={this.state.organization}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div>
                    <div class="form-row">
                      <div className="form-group">
                        <label for="exampleInputEmail1">Choose Country</label>
                        <CountryDropdown
                          className="form-control form-control-lg"
                          value={this.state.country}
                          onChange={(val) => this.selectCountry(val)}
                        />
                      </div>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Region</label>
                        <RegionDropdown
                          className="form-control form-control-lg"
                          country={this.state.country}
                          value={this.state.region}
                          onChange={(val) => this.selectRegion(val)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 col-8 mx-auto text-center">
                    <div className="form-check form-check-info ">
                      <label className="form-check-label text-dark">
                        <input
                          type="checkbox"
                          required
                          className="form-check-input"
                        />
                        I agree to all <i className="input-helper" />
                        <a
                          target="_blank"
                          className="text text-info"
                          rel="noopener noreferrer"
                          href={pdf}
                        >
                          Terms &amp; Conditions
                        </a>
                      </label>
                    </div>
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                    >
                      {!this.props.loading && <span>SIGN UP</span>}

                      {this.props.loading && (
                        <span>
                          Please Wait <i class="fa fa-spinner fa-spin" />
                        </span>
                      )}
                    </button>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Already have an access account ?{" "}
                    <Link to="/client/login" className="text-info">
                      Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { email, fname, lname, errorRegister, firstRegister, loading } = auth;

  return { email, fname, lname, errorRegister, firstRegister, loading };
};

export default withRouter(connect(mapStateToProps)(ClientRegister));
