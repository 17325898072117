import React from 'react';

import moment from "moment";
import _ from "lodash";

const AnswerPrintHeader = React.forwardRef((props) => {    
    return (
      <div className="notes-pdf-title">
                <div className='view-ccm-answer-patient'>
                  <div>
                    <h4 class="reading-card-title">{`${props.profile.lname} ${props.profile.fname}`}</h4>
                    <h6 class="reading-card-subtitle print-label-font-size-small">CCM Question and Answers</h6>
                  </div>
                  <h6 class="reading-card-subtitle display-center">
                    <i class="mdi mdi-clock print-margin" style={{
                      fontSize: 20
                    }}></i>{moment(props.createdAt).format("MMM DD, YYYY HH:mm:ss")}</h6>
                </div>
              </div>
    );
  });
  export default AnswerPrintHeader;