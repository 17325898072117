import React from "react";
import moment from "moment";
import SignatureWithName from "../Reusable/SignatureWithName";
const _ = require("lodash");
function SessionNotes(props) {
  const notes = props.notes;
  const list = _.orderBy(notes, 'createdAt', 'desc');
  const notesRows = list.map((note) => {
    if (!note.pmMode.includes(props.pmMode)) {
      return false;
    }
    const signatureImage = note.careProvider ? note.careProvider.signatureImage : undefined;
    const name = note.careProvider ? note.careProvider.lname+" "+note.careProvider.fname : undefined;
    var signatureLink = signatureImage ? signatureImage : undefined;
    if (signatureLink && !signatureLink.indexOf("http") == 0) {
        signatureLink = "/" + signatureLink
    }
    return (
      <tr>
        <td>{note.pmMode}</td>
        <td>{note.comment}</td>
        <td>{note.time}</td>
        <td>{note.hadPhoneConsultation ? "Yes" : "No"}</td>
        <td>{note.careProvider.lname + " " + note.careProvider.fname}</td>
        <td>{moment(note.createdAt).format("MM/DD/YYYY")}</td>
        <td style={{ minWidth: 150 }}>
          <div className="session-div-signature">
            <SignatureWithName
              signature={signatureLink}
              isBorder={false}
              name={name}
            />
          </div>
        </td>
      </tr>
    );
  });
  return (
    <table class="table table-bordered page-break-ka-after mb-4">
      <thead>
        <tr>
          <th colSpan="7" className="text-center text-dark">
            <h3 class="font-weight-bold">
              {props.pmMode} TOTALS  {props.pmModeTotal}
            </h3>
          </th>
        </tr>
        {notesRows && (
          <tr>
            <th className="text-center">Subject</th>
            <th className="text-center">Note</th>
            <th className="text-center">Time</th>
            <th className="text-center">Call Included</th>
            <th className="text-center">Created By</th>
            <th className="text-center">Date</th>
            <th className="text-center">Signature</th>
          </tr>
        )}
      </thead>
      <tbody className="text-left">
        {notes && notesRows.length > 0 ? (
          notesRows
        ) : (
          <tr>
            <th
              colSpan="5"
              className="text-black text-danger text-center mx-auto"
            >
              No Record Found
            </th>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default SessionNotes;
