import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row } from "reactstrap";
import logoImage from "../../files/smarthealth.jpg";
import questionnarieImage from "../../files/questionnarie_icon.png";
import moment from "moment";
class QuestionnariePrintReport extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const props = this.props;
    return (
    <div className="text-center col-md-12 mx-auto m-5 margin-row-notes-print">
    <div className="text-center">
      <img
        src={logoImage}
        alt="new"
        width="240px"
        className="p-2"
      />
    </div>
    <Row className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print" style={{ margin: 10,padding:0 }}>
      <Col className="notes-pdf-title">              
        <img
          src={questionnarieImage}
          width="50px"
          className="img-circle"
          alt
        />
        <br />
        <span className="text-center m-2 report-title">{props.title}</span>
        <br />
      </Col>
      <Col className="notes-pdf-details">
        <table>
          <tbody>
            <tr>
              <td style={{ textAlign: "left" }}>Patient</td>
              <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                {props.patient ?
                  props.patient.fname + " " +
                  props.patient.lname : " - "}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Nickname</td>
              <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                {props.patient ?
                  props.patient.nickname : " - "}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Main Care Provider</td>
              <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                {props.patient && props.patient.mainCareProvider ?
                  props.patient.mainCareProvider.fname + " " +
                  props.patient.mainCareProvider.lname : " - "}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Organization</td>
              <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                {props.patient ?
                  props.patient.organization : " - "}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Questionnaire Answered/Completed</td>
              <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                {props.endDateTime 
                  ? moment(props.endDateTime).format("MMM DD, YYYY hh:mm:ss A") : "NONE"}</td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
    <BootstrapTable
      wrapperClasses="table-responsive"
      keyField="_id"
      noDataIndication="No Data Found"
      data={ props.answers }
      columns={[
        {
          dataField: "questionName",
          text: "Question",
          sort: false
        },
        {
          dataField: "answer",
          text: "Answer",
          sort: false
        },
        {
          dataField: "isCurrectAnswer",
          text: "Answer status",
          sort: false,
          formatter: props.answerStatus
        },
      ]}
    />
  </div>);
  }
}

export default QuestionnariePrintReport;
