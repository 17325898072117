import React, { useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Avatar from "react-avatar";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import moment from "moment";
import {
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Row,
  Col,
  Modal
} from "reactstrap";
import UserName from "../Reusable/UserName";
import DateTime from "react-datetime";
import { some } from "lodash";
import { useDispatch } from "react-redux";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import CCMListClass from "./CCMListClass";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import callingIcon from "../../files/called.png";
import lottieCompleted from '../../files/animation/ic_fax_send_completed.json';
import lottiePending from '../../files/animation/ic_pending.json';
import ic_warning from '../../files/animation/ic_warning.json';
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import FullNameWithAvatar from "../Reusable/FullNameWithAvatar";
import CCMListClassPrint from "../CCM/CCMListClassPrint"


const _ = require("lodash");

function fullname(row, cell) {
  return <UserName user={cell} />;
}
function fullnameChild(cell) {
  return <UserName user={cell} />;
}
function formatPatient(cell, row) {
  // return <UserName row={row}>({cell})</UserName>;
  return (
    <FullNameWithAvatar
      fullName={row.fullname}
      isActive={row.userObject.isActive}
    />
  );
}
function strPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
function formatDuration(duration) {
  if (duration) {
    return [
      ("" + Math.floor(duration.asHours())).slice(-2),
      ("0" + duration.minutes()).slice(-2),
      ("0" + duration.seconds()).slice(-2)
    ].join(":");
  }
  return "0:00:00";
}

function getCurrentCCMTrackings(row) {
  // const dateFrom = moment(row.dateFrom).format("MMMM DD, YYYY");
  // const dateTo = moment(row.dateTo)
  //   .endOf("day")
  //   .add(1, "day")
  //   .format("MMMM DD, YYYY");

  const currentCcmTracking = _.filter(row.ccmLogs, { hadPhoneConsultation: true });
  return currentCcmTracking;
}

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <button className="btn btn-info ml-2 download-click-cls" onClick={handleClick}>
      <i className="mdi mdi-file-excel" /> Export to CSV
    </button>
  );
};

function formatGrand(cell, row, index, extra) {
  const finalGrandRPM = row.totalTimeRPM;
  return (
    <div className="text-center">
      <h2 className="mb-0">{finalGrandRPM}</h2>
      <small>
        <b>HOUR:MINUTE:SECOND</b>
      </small>
    </div>
  );
}

function formatGrandCCM(cell, row, index, extra) {
  const finalGrandCCM = row.totalTimeCCM;
  return (
    <div className="text-center">
      <h2 className="mb-0">{finalGrandCCM}</h2>
      <small>
        <b>HOUR:MINUTE:SECOND</b>
      </small>
    </div>
  );
}

function formatDate(cell) {
  return moment(cell).tz("America/New_York").format("MMMM DD, YYYY hh:mm:ss A") + " (America/New York)";
}
const defaultSorted = [
  {
    dataField: "endTime",
    order: "desc"
  }
];

const NoDataIndicationPM = () => (
  <div>
    <LottieLoader
        loadLoop={false}
        autoplayLoop={true}
        source={lottieEmptyFile}
        Lheight={200}
        Lwidth={200}
        titleSmall={"Sorry! No pm session record found :("}
        subTitleSmall={"We can't find any pm session record."}
      />            
  </div>
);

const rowComponent = (row, userDetails, setEditTime,setValues,setDetails) => (
  <div>
    <h4 className="text-center">{row.fullname}</h4>
    {(row.ccmTrackings == undefined) && (
        <h6 style={{ color: "#6a6a6a" }} className="text-center">No Data Found</h6>
    )}
    {/* if ccmTrackings array is null means this is added default data.  */}
    {(row.ccmTrackings !== undefined) && (
      <div className="child-pm-session-table">
        <BootstrapTable
        wrapperClasses="table-responsive"
        keyField="_id"
        noDataIndication={ () => <NoDataIndicationPM /> }
        data={_.orderBy(row.ccmLogs, "startTime", "asc")}    
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        columns={[          
          {
            dataField: "careProvider.lname",
            text: "Tracked By",
            sort: true,
            formatter: (cell, row) => {
              return fullnameChild(row.careProvider);
            },
            headerStyle: (colum, colIndex) => {
              return { width: '200px', textAlign: 'left' };
            }
          },
          {
            dataField: "pmMode",
            text: "Patient Monitor Mode",
            sort: true
          },
          {
            dataField: "startTime",
            text: "Start Time",
            sort: true,
            formatter: formatDate
          },
          {
            dataField: "endTime",
            text: "End Time",
            sort: true,
            formatter: formatDate
          },
          {
            dataField: "time",
            text: "Total Collected Time",
            sort: true,
            formatter: (cell, row) => {
              if (userDetails && userDetails.role === "admin") {
                return (
                  <>
                    <div>
                    <h5 style={{textAlign:"center"}}>
                    <i class="mdi mdi-timer" style={{
                        fontSize: 20
                      }}></i>
                      {" "}{row.time}</h5>
                    <button
                      className="btn btn-info ml-2 edit-click-cls"
                      style={{ float: 'right' }}
                      onClick={() => {
                        setEditTime({
                          _id: row._id,
                          startTime: row.startTime,
                          endTime: row.endTime,
                          time: row.time
                        });
                        setDetails({
                          _id:row._id,
                          fname:row.patient.fname,
                          lname:row.patient.lname,
                          patient:row.patient,
                          careprovider:row.careProvider,
                        });                      
                        setValues({                        
                          startTime: row.startTime,
                          patient: row.patient.id,
                          careProvider: row.careProvider,
                          endTime: row.endTime,
                          time: row.time,
                          pmMode: row.pmMode,
                          comment: row.comment
                        });                           
                      }}              
                      >
                      <i class="mdi mdi-account-edit" style={{
                        fontSize: 20
                      }}></i>
                      Edit
                    </button>                    
                    </div>
                  </>
                );
              }
              return row.time;
            }
          },
          {
            dataField: "comment",
            text: "Note",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '650px', textAlign: 'left' };
            },
            formatter: (cell, row) => {
              return <p>{cell}</p>;
            }
          },
          {
            dataField: "hadPhoneConsultation",
            text: "Call Included",
            sort: true,
            formatter: (cell, row) => {
              return row.hadPhoneConsultation ? "Yes" : "No";
            }
          }
        ]}
        />
      </div>
    )}
  </div>
);


function billable(row) {
  let cptcode99457;
  let cptcode99458;
  let cptCode99490;
  let cptCode99439;
  let cptCode99453;
  let cptCode99454;
  const codeModel = row && row.codeModel ? row.codeModel : undefined;

  //*******************************************
  // RPM: CPT CODE - 99457 Information.
  //*******************************************
  //* 99457 - This RPM CPT code is populated every month, 
  //* IF the patient has had at least 20 minutes of review, a phone consultation with the patient AND at least 2 vital's reading needed
  //---------------------------------- START CPT CODE 99457 ----------------------------------//
  if (codeModel && _.size(codeModel.cpt99457) > 0) {
    cptcode99457 = [];
    _.map(codeModel.cpt99457, (value, index) => {
      cptcode99457.push(
        <div key={`key99457${row._id}`}>
          <button className="btn btn-sm btn-info session-code-button-radial" id={`popover99457${row._id}-${index}`}> 99457 </button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99457${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody> There has been an attestation of a phone consultation and at least 20 minutes of Remote Patient Monitoring performed on this patient this month. </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99457 ----------------------------------//

  //*******************************************
  // RPM: CPT CODE - 99458 Information.
  //*******************************************
  //* 99458 - This RPM CPT code is populated IF the patient qualified for 99457 AND had an additional 20 minutes of review, 
  //* in excess of the required 20 minutes for CPT 99457. For example if the patient’s total review time was more than 40 minutes, 
  //* we would populate one 99458 for the additional 20 minutes over the required 20 minutes. 
  //* If the total review time was over 60 minutes we would populate (2) 99458 CPT Codes because there were two separate additional 
  //* 20 minutes reviewed in excess to the initial 20 minutes required for CPT 99454. This CPT code can only be populated 3 times each month, 
  //* so if there is more than 80 minutes of review we only populate (3) 99458, no matter how much time. They limit billing for 99458 to only (3) per month.
  //---------------------------------- START CPT CODE 99458 ----------------------------------//  
  if (codeModel && _.size(codeModel.cpt99458) > 0) {
    cptcode99458 = [];
    _.map(codeModel.cpt99458, (value, index) => {
      cptcode99458.push(
        <div key={`key99458${row._id}`}>
          <button className="btn btn-sm btn-warning session-code-button-radial" id={`popover99458${row._id}-${index}`}>99458</button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99458${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              The patient has met the condition of a minimum requirement of 40
              grand total minutes of RPM a month.
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99458 ----------------------------------//
  //---------------------------------- END RPM LOGIC ---------------------------------------//

  //---------------------------------- START CCM LOGIC -------------------------------------//
  // Calculate the CCM TIME 
  //-----------------------------------------------------------------------------------------//  
  //*******************************************
  // CCM: CPT CODE - 99490 Information
  //*******************************************
  //* 99490 - This CCM CPT code is populated every month,
  //* IF the patient has had at least 20 minutes of review AND a phone consultation with the patient.
  //---------------------------------- START CPT CODE 99490 ----------------------------------//

  if (codeModel && _.size(codeModel.cpt99490) > 0) {
    cptCode99490 = [];
    _.map(codeModel.cpt99490, (value, index) => {
      cptCode99490.push(
        <div key={`key99458${row._id}`}>
          <button className="btn btn-sm btn-info session-code-button-radial" id={`popover99490${row._id}-${index}`}> 99490 </button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99490${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              There has been an attestation of a phone consultation and at least
              20 minutes of Chronic Care Management performed on this patient this
              month.
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99490 ----------------------------------//

  //*******************************************
  // CCM: CPT CODE - 99439 Information
  //*******************************************
  //* 99439 - This CCM CPT code is populated IF the patient qualified for 99490 AND had an additional 20 minutes of review, 
  //* in excess of the required 20 minutes for CPT 99490. For example if the patient’s total review time was more than 40 minutes, 
  //* we would populate one 99439 for the additional 20 minutes over the required 20 minutes. 
  //* If the total review time was over 60 minutes we would populate (2) 99439 CPT Codes because there were two separate additional 20 minutes 
  //* reviewed in excess to the initial 20 minutes required for CPT 99454. This CPT code can only be billed 2 times each month, 
  //* so if there is more than 60 minutes of review we only populate (2) 99439, no matter how much time. They limit 99439 to only (2) per month.
  //---------------------------------- START CPT CODE 99439 ----------------------------------//
  if (codeModel && _.size(codeModel.cpt99439) > 0) {
    cptCode99439 = [];
    _.map(codeModel.cpt99439, (value, index) => {
      cptCode99439.push(
        <div>
          <button className="btn btn-sm btn-warning session-code-button-radial" id={`popover99439${row._id}-${index}`}>99439</button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99439${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              There has been an attestation of an additional 20 minutes of
              Chronic Care Management performed on this patient this month.
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99439 ----------------------------------//

  //---------------------------------- CPT CODE 99453 ---------------------------------------//
  // Initial setup && Completed Call
  // This RPM CPT code is only populated once. The first time we bill for 99454, 
  // we bill for 99453. This code is for the initial training and
  //-----------------------------------------------------------------------------------------//
  if (codeModel && _.size(codeModel.cpt99453) > 0) {
    cptCode99453 = [];
    _.map(codeModel.cpt99453, (value, index) => {
      cptCode99453.push(
        <div>
          <button className="btn btn-sm btn-primary text-white session-code-button-radial" id={`popover99453${row._id}-${index}`}> 99453 </button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99453${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              Patient have met the condition Initial Setup & Training Completed!
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- CPT CODE 99454 ---------------------------------------//
  // This RPM CPT code is populated every month, 
  // IF the patient has had at least 16 unique days with any type of a vital upload, within that month.
  //-----------------------------------------------------------------------------------------//
  if (codeModel && _.size(codeModel.cpt99454) > 0) {
    cptCode99454 = [];
    _.map(codeModel.cpt99454, (value, index) => {
      cptCode99454.push(
        <div>
          <button className="btn btn-sm btn-success text-white session-code-button-radial" id={`popover99454${row._id}-${index}`} type="button">99454</button>
          <UncontrolledPopover trigger="legacy" placement="right" target={`popover99454${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              Patient have met the condition at least 16 days on the system and
              should have records of activity every month!
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  if (cptCode99490 || cptCode99439 || cptCode99453 || cptCode99454 || cptcode99457 || cptcode99458) {
    return (
      <div className="mx-auto text-center">
        {cptCode99453} {cptCode99454}
        {cptcode99457} {cptcode99458}
        {cptCode99490} {cptCode99439}
      </div>
    );
  } else {
    return (
      <div className="mx-auto text-center">
        -
      </div>
    );
  }
}

const interventionButton = (cell, row, rowIndex, extra) => {
  const finalGrandRPM = row.totalTimeCCM;
  const finalGrandCCM = row.totalTimeRPM;

  return (
    <div className="text-center">
      {(finalGrandRPM != "00:00:00" || finalGrandCCM != "00:00:00") && (
        <button
        className="btn btn-info ml-2 report-click-cls"
        style={{ float: 'right' }}
        onClick={() => {
          extra.openInterventionReport(row);                         
        }}              
        >
        <i class="mdi mdi-view-list" style={{
          fontSize: 20
        }}></i>
        Report
      </button>  
      )}
    </div>
  );
};
/*
 * Main class list is used for table UI, CSV UI (print is located in CCMListClass.js)
 */
const CCMList = (props, context) => {
  const dispatch = useDispatch();
  const printDiv = (props) => {
    html2canvas(document.querySelector("#printCCM")).then((canvas) => {
      var a = document.createElement("a");
      // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
      a.href = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download =
        "InterventionReport" + props.startDate + "-" + props.endDate + ".jpg";
      a.click();
    });
  };
  const componentRef = useRef();
  const [editTime, setEditTime] = useState(false);
  const [updateConditions, setReloadList] = useState(false);
  const handleClose = () => setEditTime(false);
  let durationOptions = [];
  for (let i = 1; i <= 120; i++) {
    if (i < 10) {
      durationOptions.push(<option value={`00:0${i}:00`}>{i} minutes</option>);
    } else {
      durationOptions.push(<option value={`00:${i}:00`}>{i} minutes</option>);
    }
  }
  const [details, setDetails] = useState({
    _id: "",
    fname: "",
    lname: "",
    patient: "",
    careprovider: null,
  });
  const [form, setValues] = useState({
    startTime: moment().format("MM/DD/YYYY"),
    careProvider: details.careprovider,
    endTime: null,
    time: "00:01:00",
    pmMode: null,
    comment: ""
  });
  
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [pmModeError, setPmModeError] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);
  const [commentError, setCommentError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  function submitPM (e) {
    e.preventDefault();    
    if(!validation()){
      return;
    }
    const finalForm = { ...form, patient: details.patient.id };    
    finalForm.id = details._id;
    const dateNow = new Date();
    finalForm.startTime = moment(dateNow)
      .date(moment(finalForm.startTime).date())
      .month(moment(finalForm.startTime).month())
      .year(moment(finalForm.startTime).year())
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    finalForm.endTime = moment(dateNow)
      .date(moment(finalForm.endTime).date())
      .month(moment(finalForm.endTime).month())
      .year(moment(finalForm.endTime).year())
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    const checkValue = some(finalForm, function (value) {
      return value === null;
    });

    if (!checkValue) {
      dispatch({
        type: "DO_UPDATE_TIMER",
        payload: finalForm
      });
      setEditTime(false);   
      props.updateList();   
      setValues({
        startTime: moment().format("MM/DD/YYYY"),
        patient: details._id,
        careProvider: details.careprovider,
        endTime: null,
        time: "00:00:00",
        pmMode: null,
        comment: "",
        isPublic: true,
        hadPhoneConsultation: false
      });
    }
  };

  /**
   * Form validation checked.
   * @returns validation status
   */
  function validation(){
    // Start Date Validation
    if(!isStartDateValid(moment(form.startTime).format("MM/DD/YYYY"))){
      return false
    } 
    // End Date Validation
    if(!isEndDateValid(moment(form.endDate).format("MM/DD/YYYY"))){
      return false
    }
    // PM Mope Validation
    if(!isPmModeValid(form.pmMode)){
      return false
    }
    // Time Validation
    if(!isTimeValid(form.time)){
      return false
    }
    // Comment Validation
    if(!isCommentValid(form.comment)){
      return false
    }
    return true;
  }
  function formatDateAndAddMinutes(cell, minutes, startTime) {
    return moment(cell)
      .date(moment(startTime).date())
      .month(moment(startTime).month())
      .year(moment(startTime).year())
      .add(minutes, "minutes")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
  }

  /**
   * Input fields on changed event
   * @param {*} e 
   */
  const updateField = (e) => { 
    if(e.target.name==='comment'){
      setValues({
        ...form,
        [e.target.name]: e.target.value
      });
      isCommentValid(e.target.value);      
    }
    if(e.target.name==='time'){
      setValues({
        ...form,
        [e.target.name]: e.target.value
      });
      isTimeValid(e.target.value)
    } 
    if(e.target.name==='pmMode'){
      setValues({
        ...form,
        [e.target.name]: e.target.value
      });      
      isPmModeValid(e.target.value);
    }
    if(e.target.name==='startTime'){
      setValues({
        ...form,
        [e.target.name]: e.target.value
      });      
      isStartDateValid(e.target.value);
    }
    if(e.target.name==='endTime'){
      setValues({
        ...form,
        [e.target.name]: e.target.value
      });  
      isEndDateValid(e.target.value);
    }   
  };

  /**
   * Validation of the Start Date field
   * @param {*} value Entered value
   * @returns validation status
   */
  function isStartDateValid(value) {    
    if(value===undefined || value===null ||  value.trim().length == 0 ){
      setStartDateError("Start date must be filled out!");
      setIsDisabled(true);        
      return false;
    } else {
      const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20|21|22|23|24|25|26|27|28|29|30)\d{2}$/g;
      const result = pattern.test(value);        
      if(result===false){
        setStartDateError("The start date format does not match. Please enter the correct start date!");
        setIsDisabled(true); 
        return false;    
      } else{
        var date = moment(new Date(form.startTime), 'MM/DD/YYYY');
        date.set({hour:0,minute:0,second:0,millisecond:0});
        var dateEntered = new Date(value);
        var today = moment(date, 'MM/DD/YYYY');
        var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
        var dDiff = today.diff(enteredDate);        
        if (dDiff < 0) {
          setStartDateError("The selected date is future date. Please enter the correct start date!");
          setIsDisabled(true);
          return false;
        } else {
          setStartDateError(null);
          setIsDisabled(false);
          return true;
        }
        
      }
    }
  };

  /**
   * Validation of the End Date field
   * @param {*} value Entered value
   * @returns validation status
   */
  function isEndDateValid(value) {    
    if(value===undefined || value===null ||  value.trim().length == 0 ){
      setEndDateError("End date must be filled out!");
      setIsDisabled(true);        
     return false;
    } else {
      const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20|21|22|23|24|25|26|27|28|29|30)\d{2}$/g;
      const result = pattern.test(value);        
      if(result===false){
        setEndDateError("The end date format does not match. Please enter the correct start date!");
        setIsDisabled(true);          
        return false;
      } else{
        var today = moment();
        var enteredDate = moment(value, 'MM/DD/YYYY');
        var dDiff = today.diff(enteredDate);
        if (dDiff < 0) {
          setEndDateError("The selected date is future date. Please enter the correct end date!");
          setIsDisabled(true);
          return false;
        } else {
          var date = moment(new Date(form.startTime), 'MM/DD/YYYY');
          date.set({hour:0,minute:0,second:0,millisecond:0});
          var dateEntered = new Date(value);
          var today = moment(date, 'MM/DD/YYYY');
          var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
          var dDiff = today.diff(enteredDate);
          if (dDiff > 0) {
            setEndDateError("Please select end date is greater than equal to start date.");
            setIsDisabled(true);
            return false;
          } else if (today.isSame(enteredDate)) {
            setEndDateError(null);
            setIsDisabled(false);            
            return true;
          } else {
            setEndDateError(null);
            setIsDisabled(false);            
            return true;
          }
          
        }
      }
    }    
  };

  /**
   * Validation of the PM Mode field
   * @param {*} value Entered value
   * @returns validation status
   */
  function isPmModeValid(value) {
    if(value===null || value===undefined || value == "Please select one..."){
      setPmModeError("PM Mode must be selected any one!");
      setIsDisabled(true);       
      return false;
    } else {
      setPmModeError(null);
      setIsDisabled(false);        
      return true;
    }
  }

  /**
   * Validation of the Time duration field
   * @param {*} value Entered value
   * @returns validation status
   */
  function isTimeValid(value) {
    if(value===undefined || value===null ||  value.trim().length == 0 ){
      setTotalDuration("Total duration must be filled out!");
      setIsDisabled(true);
      return false;
    } else {
      const pattern = /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/g;
      const result = pattern.test(value);
      if(result===false){
        setTotalDuration("The time format does not match. Please enter the correct time!");
        setIsDisabled(true);    
        return false;
      } else{
        setTotalDuration(null);
        setIsDisabled(false);
        return true;
      }      
    }
  }

  /**
   * Validation of the Comment field
   * @param {*} value 
   * @returns validation status
   */
  function isCommentValid(value) {
    if(value===undefined || value===null ||  value.trim().length == 0 ){
      setCommentError("Comment must be filled out!");
      setIsDisabled(true);      
      return false;
    } else {
      setCommentError(null);
      setIsDisabled(false);
      return true;
    }
  }

  const [isPublic, setIsPublic] = useState(true);
  const [hadPhoneConsultation, setHadPhoneConsultation] = useState(false);    
  function priceFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="mb-2">{filterElement}</span>
  
        {column.text}
        {sortElement}
      </div>
    );
  }
  const columns = [
    {
      dataField: "lname",
      text: "Last, First Name",
      formatter: fullname,
      sort: true,      
      csvFormatter: (cell, row) => {
        return row ? row.lname + " " + row.fname : "";
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      headerFormatter: priceFormatter
    },
    {
      dataField: "bday",
      text: "DOB",
      formatter: (cell, row) => {
        return moment(row.bday).format("MMMM DD, YYYY");
      },
      csvFormatter: (cell, row) => {
        return moment(row.bday).format("MMMM DD, YYYY");
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      sort: true
    },
    {
      dataField: "createdAt",
      text: "Enrolled Date",
      sort: true,
      formatter: (cell, row) => {
        return moment(row.createdAt).tz(moment.tz.guess()).format("MMMM DD, YYYY");
      },
      csvFormatter: (cell, row) => {
        return moment(row.createdAt).tz(moment.tz.guess()).format("MMMM DD, YYYY");
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
    },
    {
      dataField: "deviceReadingDate",
      text: "Last Upload Date",
      formatter: (cell, row) => {
        if (row.lastReading && row.lastReading.deviceReadingDate) {
          return moment(row.lastReading.deviceReadingDate).format(
            "MMMM DD, YYYY"
          );
        }
        return "NONE";
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      csvFormatter: (cell, row) => {
        if (row.lastReading && row.lastReading.deviceReadingDate) {
          return moment(row.lastReading.deviceReadingDate).format("MMMM DD, YYYY");
        }
        return "NONE";
      },
      sort: true
    },
    {
      dataField: "conditions",
      text: "Condition(s) Managed",
      formatter: (cell, row) => {
        if (row.conditions && row.conditions.length > 0) {          
          return <div style={{ width: '100px'}}> {row.conditions.toString() }</div>;
        }
        return <div style={{textAlign:"center", width: '100px'}}> - </div>;
      },
      style: {
        padding: '0px !important',        
      },
      csvFormatter: (cell, row) => {
        if (row.conditions && row.conditions.length > 0) {          
          return row.conditions.toString();
        }
        return "-";
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      sort: false
    },
    {
      dataField: "mainCareProviders",
      text: "Main Care Provider",
      sort: true,      
      headerFormatter: priceFormatter,
      formatter: (cell, row) => {
        return row.mainCareProviders ? row.mainCareProviders : "-";
      },
      csvFormatter: (cell, row) => {
        return row.mainCareProviders ? row.mainCareProviders : "-";
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
    },
    {
      dataField: "organization",
      text: "Organization",
      sort: true,
      formatter: (cell, row) => {
        return row.organization ? row.organization : "-";
      },
      csvFormatter: (cell, row) => {
        return row.organization ? row.organization : "-";
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
    },

    {
      dataField: "enrolledPrograms",
      text: "Enrolled Program(s)",
      sort: true,
      formatter: (cell, row) => {      
        return (
          <div className="text-center">
            <span style={{textAlign:"center"}}>{row.enrolledPrograms ? row.enrolledPrograms : "-"}</span>
          </div>
        );
      },
      csvFormatter: (cell, row) => {
        return row.enrolledPrograms ? row.enrolledPrograms : "-";
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
    },
    {
      dataField: "tesasdft",
      text: "Total CCM Time", //"Grand Total for CCM Sessions",
      sort: false,
      formatter: formatGrandCCM,  
      csvFormatter: (cell, row) => {
        return row.totalTimeCCM ? row.totalTimeCCM : "-";
      },    
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      formatExtraData: props
    },
    {
      dataField: "totalTimeRPM",
      text: "Total RPM Time", //"Grand Total for RPM Sessions",
      sort: false,
      formatter: formatGrand,    
      csvFormatter: (cell, row) => {
        return row.totalTimeRPM ? row.totalTimeRPM : "-";
      }, 
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      formatExtraData: props
    },
    {
      dataField: "asdassd",
      text: "Billable CPT Code(s)", // RPM
      sort: false,
      formatter: (cell, row) => {
        return billable(row, props.billableCodes);
      },
      csvFormatter: (cell, row) => {
        return row.codeModel && row.codeModel.allCodes ? row.codeModel.allCodes.toString() : "";
      }, 
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      formatExtraData: props
    },
    {
      dataField: "nonAdhenrenceDay",
      text: "Non Adherence Days",
      formatter: (cell, row) => {
        if (row.nonAdhenrenceDay) {
          return <div style={{textAlign:"center"}}>{row.nonAdhenrenceDay}</div>;
        }
        return <div style={{textAlign:"center"}}> 0 </div>;
      },
      csvFormatter: (cell, row) => {
        return row.nonAdhenrenceDay ? row.nonAdhenrenceDay : "-";
      },
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // }, 
      sort: false,
      formatExtraData: props      
    },
    {
      dataField: "insuranceProvider",
      text: "Primary Insurance Provider",
      formatter: (cell, row) => {
        if (row.insuranceProvider) {
          return <div style={{textAlign:"center"}}>{row.insuranceProvider}</div>;
        }
        return <div style={{textAlign:"center"}}> - </div>;
      },
      csvFormatter: (cell, row) => {
        return row.insuranceProvider ? row.insuranceProvider : "-";
      }, 
      // headerStyle: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      // style: {
      //   minWidth: '195px' // Set your desired width  // Shrey Added
      // },
      sort: true,
      formatExtraData: props      
    },
    {
      dataField: "isCalled",
      text: "Called",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            {cell && (
              <img src={callingIcon} />
            )}
            {!cell && (
              <div style={{textAlign:"center"}}>-</div>
            )}
          </div>
        );
      },
      csvFormatter: (cell, row) => {
        return row.isCalled ? "TRUE" : "FALSE";
      }, 
      formatExtraData: props      
    },
    {
      dataField: "faxStatus.status",
      text: "Fax Status",
      sort: false,
      formatter: (cell, row) => {  
        if(row.faxStatus){
          if(row.faxStatus && row.faxStatus.isError){
            return (
              <div className='text-center'>
                  <LottieLoader
                    loadLoop={false}
                    autoplayLoop={true}
                    source={ic_warning}
                    Lheight={55}
                    Lwidth={55}
                    subTitleExtraSmall={row.faxStatus.errorMessage}
                  />
                </div>
            );
          } else if(row.faxStatus.isSent){
            return (
              <div className='text-center'>
                  <LottieLoader
                    loadLoop={false}
                    autoplayLoop={true}
                    source={lottieCompleted}
                    Lheight={55}
                    Lwidth={55}
                    subTitleExtraSmall={"Fax Successful"}
                  />
                </div>
            );
          } else if(!row.faxStatus.isSent && !row.faxStatus.isError){
            return (
              <div className='text-center'>
                  <LottieLoader
                    loadLoop={true}
                    autoplayLoop={true}
                    source={lottiePending}
                    Lheight={55}
                    Lwidth={55}
                    subTitleExtraSmall={"Fax Sending..."}
                  />
                </div>
            );
          }          
        } else {
          return (
            <div className='text-center'>
                -
            </div>
          );
        }        
      },
      csvFormatter: (cell, row) => {
        if(row.faxStatus){
          if(row.faxStatus && row.faxStatus.isError){
            return row.faxStatus.errorMessage;
          } else if(row.faxStatus.isSent){
            return "Fax Successful";
          } else if(!row.faxStatus.isSent && !row.faxStatus.isError){
            return "Fax Sending...";
          }          
        } else {
          return "-";
        }  
      },  
    },
    {
      dataField: "intervention",
      text: "Attestation Report",
      sort: false,
      formatter: interventionButton,
      formatExtraData: props,
      csvExport: false
    }
  ];
  
  return (
    <div className=""> {/* Shrey Added */}
      <div style={{ display: "none" }}>     
        <CCMListClassPrint        
          ref={componentRef}                               
          startDate={props.startDate}
          endDate={props.endDate}
          pmRecords={props.pmRecords}      
          billableCodes={undefined}                
        />
      </div>

      <div id="printCCM" className="pb-2">
        <ToolkitProvider
          keyField="userId"
          data={_.orderBy(props.pmRecords, "fullname", "asc")}
          columns={columns}
          search
          exportCSV={{ fileName: `pmSessions.csv` }}
        >
          {(toolKitProp) => (
            <div>
              <Row className={`text-center mx-auto float-right`}>
                <Col className="remove-table-padding d-flex g-4">{/* Shrey Added */}
                 <MyExportCSV {...toolKitProp.csvProps}>
                    Export CSV
                  </MyExportCSV>
                {/* </Col> */}
                {/* <Col className="remove-table-padding"> */}
                <ReactToPrint
                  trigger={() => <button className="btn btn-info ml-2 print-click-cls" style={{ float: 'right' }}> 
                  <i class="mdi mdi-cloud-print" style={{
                    fontSize: 20
                  }}></i> Print</button>}
                  content={() => componentRef.current}
                  />                  
                </Col>
              </Row>    
              <div className="w-100 overflow-auto">          
              <BootstrapTable
                className="table"
                wrapperClasses="table-responsive"
                keyField="userId"
                noDataIndication="No Data Found"
                filter={filterFactory()}
                expandRow={{
                  onlyOneExpanding: false,
                  showExpandColumn: true,
                  expandByColumnOnly: false,
                  renderer: (row) =>
                  rowComponent(row, props.userDetails, setEditTime,setValues,setDetails),
                  expanded: props.pleaseExpand
                    ? _.map(props.pmRecords, "userId")
                    : ""
                }}
                {...toolKitProp.baseProps}
              />
              </div>
            </div>
          )}
        </ToolkitProvider>   
        <Modal
          backdrop="static"
          isOpen={editTime}
          toggle={() => {
            setEditTime(!editTime);
            setValues({
              startTime: moment().format("MM/DD/YYYY"),
              patient: details.patient.id,
              careProvider: details.careprovider,
              endTime: moment().format("MM/DD/YYYY"),
              time: "00:01:00",
              pmMode: null,
              comment: ""
            });
          }}
      >
        <ModalHeader
          toggle={() => {
            setEditTime(!editTime);
            setValues({
              startTime: moment().format("MM/DD/YYYY"),
              patient: details.patient.id,
              careProvider: details.careprovider,
              endTime: moment().format("MM/DD/YYYY"),
              time: "00:01:00",
              pmMode: null,
              comment: ""
            });
          }}
        >
          Edit PM Session
        </ModalHeader>

        <ModalBody style={{ backgroundColor: "white" }}>
          <Form onSubmit={submitPM} autocomplete="off">
            <FormGroup>
              <Label for="exampleSelect">Patient</Label>
              <Input readOnly value={details.fname + " " + details.lname} />
            </FormGroup>
            <FormGroup inline>
              <Label for="exampleSelect">Start Date*</Label>
              <DateTime
                onChange={(value) =>
                  updateField({ target: { name: "startTime", value: moment(value).format("MM/DD/YYYY") } })
                }
                inputProps={{
                  name: "startTime",
                  required: "required",
                  autocomplete: "off"
                }}
                timeFormat={false}
                defaultValue={moment(form.startTime).format("MM/DD/YYYY")}
              />
              <Label 
                style={{color: "red"}}>{startDateError}</Label>

              <Label for="exampleSelect">End Date*</Label>
              <DateTime
                onChange={(value) =>
                  updateField({ target: { name: "endTime", value: moment(value).format("MM/DD/YYYY") } })
                }
                inputProps={{
                  name: "endTime",
                  required: "required",
                  autocomplete: "off"
                }}
                timeFormat={false}
                defaultValue={moment(form.endTime).format("MM/DD/YYYY")}
              /> 
              <Label 
                style={{color: "red"}}>{endDateError}</Label>
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">Select which Program*  </Label>
              <select
                style={{"margin-left": "15px"}}              
                value = {form.pmMode}
                name="pmMode"
                onChange={updateField}
                required
              >
                <option>Please select one...</option>
                <option value="RPM MODE">RPM</option>
                <option value="CCM MODE">CCM</option>
              </select>                            
              <Label 
                style={{color: "red"}}>{pmModeError}</Label>
              
            </FormGroup>            
            {/* <FormGroup>
              <Label for="exampleSelect">Total Duration</Label>
              <Input type="select" name="time" onChange={updateField}>
                {durationOptions}
              </Input>
            </FormGroup> */}
            {
              <FormGroup>
              <Label for="exampleSelect">Total Duration</Label>
              <Input 
                name="time" 
                value={form.time} 
                required
                onChange={updateField}
              />
              <Label 
                style={{color: "red"}}>{totalDuration}</Label>              
            </FormGroup> }
            <FormGroup>
              <Label for="exampleSelect">Comment/Reason*</Label>
              <Input
                required
                type="text"
                name="comment"
                onChange={updateField}
                value={form.comment}
              />              
              <Label 
                style={{color: "red"}}>{commentError}</Label>              
            </FormGroup>
            {/* <FormGroup check inline>
              <input
                type="checkbox"
                name="isPublic"
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
              <Label check>Public</Label>
            </FormGroup>
            <FormGroup check className="mb-3" controlId="formBasicCheckbox">
              <input
                type="checkbox"
                name="hadPhoneConsultation"
                checked={hadPhoneConsultation}
                onChange={(e) => {
                  setHadPhoneConsultation(e.target.checked);
                }}
              />
              <Label check className="font-weight-bold">
                PM Session included a phone consultation
              </Label>
            </FormGroup> */}
            <button type="submit" disabled={isDisabled} class="btn btn-block btn-info">
              Submit
            </button>
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>     
        {/* <BootstrapTable
          wrapperClasses="table-responsive"
          keyField="userId"
          noDataIndication="No Data Found"
          data={props.pmRecords}
          expandRow={{
            onlyOneExpanding: false,
            showExpandColumn: true,
            expandByColumnOnly: false,
            renderer: (row) => rowComponent(row),
            expanded: props.pleaseExpand ? _.map(props.pmRecords, "userId") : ""
          }}
          filter={filterFactory()}
          columns={[
            {
              dataField: "userId",
              text: "Patient ID",
              sort: true,
              formatter: formatPatient,
              csvFormatter: formatPatient
            },

            {
              dataField: "bday",
              text: "Date of Birth",
              formatter: formatDate,
              csvFormatter: formatDate,
              sort: true
            },
            {
              dataField: "mainCareProvider",
              text: "Main Care Provider",
              sort: true,
              filter: textFilter(),
              formatter: (cell, row) => {
                return row.mainCareProvider
                  ? row.mainCareProvider.lname +
                      ", " +
                      row.mainCareProvider.fname
                  : "";
              },
              csvFormatter: (cell, row) => {
                return row.mainCareProvider
                  ? row.mainCareProvider.lname +
                      ", " +
                      row.mainCareProvider.fname
                  : "";
              }
            },

            {
              dataField: "test",
              text: "Grand Total for RPM Sessions",
              sort: true,
              formatter: formatGrand,
              csvFormatter: formatGrand,
              formatExtraData: props
            },
            {
              dataField: "tesasdft",
              text: "Grand Total for CCM Sessions",
              sort: true,
              formatter: formatGrandCCM,
              csvFormatter: formatGrandCCM,
              formatExtraData: props
            },
            {
              dataField: "asdassd",
              text: "RPM Billable CPT Code",
              sort: true,
              formatter: billable,
              csvFormatter: billable,
              formatExtraData: props
            },
            {
              dataField: "intervention",
              text: "Intervention Report",
              sort: true,
              formatter: interventionButton,
              formatExtraData: props,
              csvExport: false
            }
          ]}
        />*/}
      </div>
    </div>
  );
};

export default CCMList;
