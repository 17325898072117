import React from "react";

const Heading = props => (
  <div className="col-12">
    <div className="page-header">
      <h3 className="page-title">
        <span className="page-title-icon bg-gradient-info   text-white mr-2">
          <i className="mdi mdi-account-convert" />
        </span>
        HEALTHCARE TEAM
      </h3>
      <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <span />
            Sharing Reading Data
            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle" />
          </li>
        </ul>
      </nav>
    </div>
    <span className="d-flex align-items-center purchase-popup mb-0">
      {props.userDetails.isCareProvider && (
        <a
          target="_blank"
          onClick={props.toggleModal}
          className="text-white btn btn-success btn-rounded btn-fw"
        >
          Request Access to Patient/User Readings
        </a>
      )}
      {!props.userDetails.isCareProvider && (
        <a
          target="_blank"
          onClick={props.toggleModal2}
          className="text-white ml-auto btn btn-info btn-rounded btn-fw"
        >
          Offer Access to My Readings
        </a>
      )}
    </span>
  </div>
);

export default Heading;
