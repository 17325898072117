import React from "react";
import Circle from "files/circle.svg";
const FilterExceptionCard = (props) => (
  <div className="row px-4">
    <div className="col-md-4 stretch-card grid-margin">
      <div className="card bg-gradient-white card-img-holder ">
        <div className="card-body">
          <img src={Circle} className="card-img-absolute" alt="circle-image" />
          <h4 className="font-weight-normal mb-3">
            {props.currentState.ruleType === "Adherence" && (
              <span>
                {" "}
                Total Expected Adherence ({props.currentState.readingSubType2})
              </span>
            )}
            {props.currentState.ruleType === "Time Adherence" && (
              <span>
                {" "}
                Total Expected Time Adherence (
                {props.currentState.readingSubType2})
              </span>
            )}
            {props.currentState.ruleType !== "Time Adherence" &&
              props.currentState.ruleType !== "Adherence" && (
                <span>
                  {" "}
                  Total Readings Found ({props.currentState.readingSubType2})
                </span>
              )}

            <i className="mdi mdi-chart-line mdi-24px float-right" />
          </h4>
          <h1 className="mt-5 mb-4 text-center">{props.totalReadings}</h1>
          <h6 className="card-text"> </h6>
        </div>
      </div>
    </div>
    <div className="col-md-4 stretch-card grid-margin">
      <div className="card bg-danger card-img-holder text-white">
        <div className="card-body">
          {/* <img src={Circle} className="card-img-absolute" alt="circle-image" /> */}
          <h4 className="font-weight-normal mb-3">
            Total Exceptions Found base on Expectation Picked
            <i className="mdi mdi-bookmark-outline mdi-24px float-right" />
          </h4>
          <h1 className="mt-5 mb-4 text-center">{props.countExceptions}</h1>
          <h6 className="card-text" />
        </div>
      </div>
    </div>
    <div className="col-md-4 stretch-card grid-margin">
      <div className="card bg-gradient-white card-img-holder ">
        <div className="card-body">
          <img src={Circle} className="card-img-absolute" alt="circle-image" />
          <h4 className="font-weight-normal mb-3">
            Percent
            <i className="mdi mdi-bookmark-outline mdi-24px float-right" />
          </h4>
          <h1 className="mt-5 mb-4 text-center">{props.average || 0} %</h1>
        </div>
      </div>
    </div>
  </div>
);

export default FilterExceptionCard;
