import {
  FINALREGISTER_USER_SUCCESS,
  REGISTER_CLIENT_SUCCESS,
  LOGOUT_USER,
  JUST_REGISTERED,
  GET_PROFILE,
  SAVE_PROFILE_IMAGE,
  GET_DEVICES,
  GET_CLIENT_SUCCESS,
  GET_PROFILE_CLIENT,
  GET_PROFILE2,
  GET_GLOBAL_THRESHOLD_CARE_PROVIDER,
} from "../actions/types";

const INITIAL_STATE = {
  authenticated: false,
  user: {},
  userDetails: "",
  clientRegister: false,
  redirectClient: false,
  profile: null,
  profileOwner: null,
  devices: "",
  clientInfo: null,
};

const RESET_STATE = {
  authenticated: false,
  user: {},
  userDetails: "",
  clientRegister: false,
  redirectClient: false,
  isRegister: false,
  profile: null,
  profileOwner: null,
  devices: "",
  clientInfo: null,
};
const RESET_STATE_CLIENT = {
  authenticated: false,
  user: {},
  userDetails: "",
  clientRegister: false,
  redirectClient: true,
  isRegister: false,
  clientInfo: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FINALREGISTER_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        userDetails: action.payload,
      };
    case JUST_REGISTERED:
      return {
        ...state,
        ...action.payload,
      };

    case GET_PROFILE2:
      return {
        ...state,
        profileOwner: action.payload.data,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload.data,
      };
    case SAVE_PROFILE_IMAGE:
      return {
        ...state,
        profile: action.payload.data,
      };
    case GET_GLOBAL_THRESHOLD_CARE_PROVIDER:
      return {
        ...state,
        userGlobalThreshold: action.payload.data,
      };
    case GET_DEVICES:
      return {
        ...state,
        devices: action.payload.data,
      };
    case REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        authenticated: true,
        clientRegister: true,
        userDetails: action.payload,
      };

    case GET_PROFILE_CLIENT:
      return {
        ...state,
        clientInfo: action.payload.data,
        userDetails: action.payload.data,
      };
    case GET_CLIENT_SUCCESS:
      return { ...state, clientInfo: action.payload };
    case LOGOUT_USER:
      const isClientRegister = state.clientRegister;
      if (isClientRegister) {
        return {
          ...state,
          ...RESET_STATE_CLIENT,
        };
      }

      return {
        ...state,
        ...RESET_STATE,
      };
    default:
      return state;
  }
};
