import React, { useState } from "react";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { find } from "lodash";
const Tutorials = ({ location, user }) => {
  const [modal, setmodal] = useState(false);
  const { isCareProvider = null } = user;
  const { pathname = "/" } = location;
  const videoTutorials = [
    { link: "conditions", embed: "https://www.youtube.com/embed/4uc6KBNMwww" },
    { link: "rules", embed: "https://www.youtube.com/embed/5X7VIEiq1fE" },
    { link: "patients", embed: "https://www.youtube.com/embed/vtBnNyMYaF4" },
    { link: "dashboard", embed: "https://www.youtube.com/embed/vtBnNyMYaF4" },
    { link: "inbox", embed: "https://www.youtube.com/embed/xTIIt3EsTls" },
    { link: "ccm-records", embed: "https://www.youtube.com/embed/3KGykG2dZ6k" },
    {
      link: "population-data",
      embed: "https://www.youtube.com/embed/Rid7AaOpWFc"
    },
    {
      link: "exceptions",
      embed: "https://www.youtube.com/embed/Pe7V1hXClKw"
    },
    {
      link: "settings",
      embed: "https://www.youtube.com/embed/R7nWnBuJdmI"
    }
  ];

  const findVideo = find(videoTutorials, e => {
    const { link } = e;
    if (pathname === "/") {
      return link === "rules";
    }
    if (pathname.includes(link)) {
      return e;
    }
  });

  const icon = modal ? "mdi-close" : "mdi-youtube-tv";
  const color = modal ? "#e74c3c" : "orange";
  const { embed = null } = findVideo || [];
  return (
    <div>
      {isCareProvider && (
        <div>
          <Fab
            icon={
              <div className="h-100 w-100" onClick={() => setmodal(!modal)}>
                <i className={`mdi ${icon} icon-md`} />
              </div>
            }
            mainButtonStyles={{
              backgroundColor: color,
              display: !embed ? "none" : "block"
            }}
            alwaysShowTitle={true}
            position={{ bottom: 10, right: 10 }}
          ></Fab>
          <Modal
            size="lg"
            centered
            isOpen={modal}
            toggle={() => setmodal(!modal)}
          >
            {/* <ModalHeader toggle={() => setmodal(!modal)}>Video</ModalHeader> */}
            <ModalBody className="p-0">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive-item"
                  src={embed}
                  allowfullscreen
                ></iframe>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Tutorials;
