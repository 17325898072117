import React from "react";
import moment from "moment";
import { Label } from "reactstrap";
import isNoSignature from "../../files/ic_no_signature.svg";
import SignatureWithName from "../Reusable/SignatureWithName";
function CCMNotesWithSignature(props) {
  const onBoardingNotes = props.onBoardingNotes;
  const signatureImage = props.profile.signatureImage;
  var signatureLink = signatureImage ? signatureImage : undefined;
  if (signatureLink && !signatureLink.indexOf("http") == 0) {
    signatureLink = "/" + signatureLink
  }
  const ccmOnboardingRows = onBoardingNotes.map((answer) => {
    const signatureImage = answer.createdBy ? answer.createdBy.signatureImage : undefined;
    const name = answer.createdBy ? answer.createdBy.lname+" "+answer.createdBy.fname : undefined;
    var signatureLink = signatureImage ? signatureImage : undefined;
    if (signatureLink && !signatureLink.indexOf("http") == 0) {
        signatureLink = "/" + signatureLink
    }
    return (
      <tr>
        <td>Patient Verbal Consent for CCM Services</td>
        <td style={{ maxWidth: 650 }}>{answer.body}</td>
        <td>{answer.category ? answer.category.title : ""}</td>
        <td>{answer.createdBy.fname + " " + answer.createdBy.lname}</td>
        <td>{moment(answer.createdAt).format("MMMM DD, YYYY HH:mm:ss")}</td>
        <td style={{ minWidth: 150 }}>
          <SignatureWithName
            signature={signatureLink}
            isBorder={false}
            name={name}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <table class="table table-bordered page-break-ka-after mb-4">
        <thead>
          <tr>
            <th colSpan="6" className="text-center text-dark">
              <h3 class="font-weight-bold">CCM ON-BOARDING</h3>
            </th>
          </tr>
          {ccmOnboardingRows && (
            <tr>
              <th className="text-center">Title</th>
              <th className="text-center">Message</th>
              <th className="text-center">Shipment Category</th>
              <th className="text-center">Created By</th>
              <th className="text-center">Date</th>
              <th className="text-center">Signature</th>
            </tr>
          )}
        </thead>
        <tbody className="text-left">
          {onBoardingNotes ? (
            ccmOnboardingRows
          ) : (
            <tr>
              <th
                colSpan="3"
                className="text-black text-danger text-center mx-auto"
              >
                No Record Found
              </th>
            </tr>
          )}
        </tbody>
      </table>
      <hr style={{ borderTop: "3px solid #5c5959" }} />
    </div>
  );
}

export default CCMNotesWithSignature;
