import React, { useRef } from "react";
import { useFetch } from "components/utils/fetchHook";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Label,
  Row
} from "reactstrap";
import ReactLoading from "react-loading";
import ReactToPrint from "react-to-print";
import CCMinterventionContainer from "./CCMinterventionContainer";
import html2canvas from "html2canvas";

function CCMintervention(props) {  
  var { patient } = props.details;
  if(patient == undefined){
    patient = props.details;
  }
  var path = props.region ? "/client"  : "/careprovider" 
  const { response, error, isLoading } = useFetch(
    path + `/intervention?user=${patient ? patient._id : props.details._id}&start=${encodeURIComponent(
      props.startDate
    )}&end=${encodeURIComponent(props.endDate)}`
  );
  const responseData = response ? response.data : response;

  const printDiv = (startDate) => {
    html2canvas(document.querySelector("#exportToImageIntervention")).then(
      (canvas) => {
        var a = document.createElement("a");
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
        a.download = "InterventionReport" + startDate + ".jpg";
        a.click();
      }
    );
  };
  const componentRef = useRef();
  if (isLoading && !error) {
    return (
      <Modal
        isOpen={props.modal}
        toggle={() => props.openCCMinterventionReport(undefined)}
        className="modal-lg"
        contentClassName="bg-white"
      >
        <ModalHeader
          toggle={() => props.openCCMinterventionReport(undefined)}
          cssModule={{ "modal-title": "w-100 text-center" }}
        ></ModalHeader>
        <ModalBody>
          <ReactLoading
            type="spin"
            className="text-center mx-auto"
            color="blue"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => props.openCCMinterventionReport(!props.modal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
  if (!response) {
    return (
      <Modal
        contentClassName="bg-white"
        isOpen={props.modal}
        toggle={() => props.openCCMinterventionReport(undefined)}
        className="modal-lg"
      >
        <ModalHeader
          toggle={() => props.openCCMinterventionReport(undefined)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center">
            Service is unavailable at this moment, Please try again later
          </div>{" "}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => props.openCCMinterventionReport(!props.modal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
  if (error) {
    return (
      <Modal
        contentClassName="bg-white"
        isOpen={props.modal}
        toggle={() => props.openCCMinterventionReport(undefined)}
        className="modal-lg"
      >
        <ModalHeader
          toggle={() => props.openCCMinterventionReport(undefined)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center">
            Something went wrong, Please try again by closing and opening the
            popup
          </div>{" "}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => props.openCCMinterventionReport(!props.modal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
  if (responseData) {
    return (
      <div>
        <Modal
          contentClassName="bg-white"
          isOpen={props.modal}
          toggle={() => props.openCCMinterventionReport(undefined)}
          className="modal-lg"
        >
          <ModalHeader className="close_button" style={{ float: 'right' }} toggle={() => props.openCCMinterventionReport(undefined)}>
            <Label className="align-items-left justify-content-center text-left">RPM / CCM Report <br></br>
            <small style={{ color: '#f15a5a' }}>
              {" "}
              <i class="mdi mdi-warning" style={{
                  fontSize: 20
              }}></i>
              Note : Prints sometimes render a blank value, please close the modal
              or popup and open again if you encounter this issue
            </small>
            </Label>

            <button
              style={{ float: 'right' }}
              onClick={() => {
                props.openCCMinterventionReport(undefined)
              }}
              className="btn btn-info ml-2 close-click-cls">
              <i class="mdi mdi-close" style={{
                fontSize: 20
              }}></i>
              Close
            </button>

            <ReactToPrint
              trigger={() => <button className="btn btn-info ml-2 print-click-cls" style={{ float: 'right' }}> 
              <i class="mdi mdi-cloud-print" style={{
                fontSize: 20
              }}></i> Print</button>}
              content={() => componentRef.current}
            />
            
            <button
              className="btn btn-info ml-2 question-display"
              style={{ float: 'right' }}
              onClick={() => {
                props.handleSendFax(patient._id);
                props.openCCMinterventionReport(!props.modal);
              }}              
              >
              <i class="mdi mdi-fax" style={{
                fontSize: 20
              }}></i>
              Fax
            </button>             
          </ModalHeader>
          <ModalBody>
            <div className="text-center">                            
              {/* <button
                onClick={() => printDiv(props.startDate)}
                className="btn btn-success text-white ml-2"
              >
                Export to Image
              </button> */}
            </div>

            <CCMinterventionContainer
              patient={patient ? patient : props.details}
              profile={props.profile}
              profileOwner={props.profileOwner}
              responseData={responseData}
              ref={componentRef}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
  return (
    <Modal
      contentClassName="bg-white"
      isOpen={props.modal}
      toggle={() => props.openCCMinterventionReport(undefined)}
      className="modal-lg"
    >
      <ModalHeader
        toggle={() => props.openCCMinterventionReport(undefined)}
      ></ModalHeader>
      <ModalBody>
        <div className="text-center">
          Something went wrong, Please try again by closing and opening the
          popup
        </div>{" "}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => props.openCCMinterventionReport(!props.modal)}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CCMintervention;