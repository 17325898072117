import Link from "components/ExtendLink";
import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import callingIcon from "../../files/called.png";
import userImage from "../../files/user.png";
import LottieLoader from "../LottieLoader";
import lottieEmptyFile from "../../files/animation/empty_new.json";
import lottieLoaderFile from "../../files/animation/loading.json";

const _ = require("lodash");
const { ExportCSVButton } = CSVExport;

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Row>
      <Col>
        <button className="btn btn-success float-left" onClick={handleClick}>
          <i className="mdi mdi-file-excel" /> Export to CSV
        </button>
      </Col>
    </Row>
  );
};

function fullname(cell, row) {
  const profile = row.profileImage || userImage;
  // return <UserName user={row} />;
  return (
    <div className={row.isActive ? "" : " text-danger"}>
      <span>
        <img src={profile} className="mr-2" alt="image" />
        {capitalize(row.lname)}
        {", "}
        {capitalize(row.fname)}

        {row?.ccmTrackings?.length > 0 && <img src={callingIcon} />}
      </span>
    </div>
  );
}
function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}
function isApproved(cell, row) {
  return (
    <span>
      {row.isApproved ? (
        <label className="badge badge-gradient-success">APPROVED</label>
      ) : (
        <label className="badge badge-gradient-warning">PENDING</label>
      )}
    </span>
  );
}
function formatDate(cell, row) {
  return moment(cell).format("MMMM DD, YYYY");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  const authenticatedUser = formatExtraData.userId;
  if (row) {
    const isSameUser = authenticatedUser !== row._id;
    const approvedButton =
      isSameUser && !row.isApproved ? (
        <button
          type="button"
          onClick={() => formatExtraData.approve(row._id)}
          className="btn btn-success btn-fw"
        >
          Accept Request
        </button>
      ) : (
        ""
      );
    return (
      <span>
        {row.isApproved ? (
          <button
            type="button"
            onClick={() => formatExtraData.delete(row._id)}
            className="btn btn-danger btn-fw"
          >
            Delete
          </button>
        ) : (
          approvedButton
        )}
      </span>
    );
  }
}
const rowComponent = (row, readingSubTypes, profile) => {
  let readingsHeader = "No readings in the Last 30 Days";
  if (_.size(row.readingsSevenDays) > 0 || _.size(row.bodyTraceReadings) > 0) {
    readingsHeader = "Last 30 Days Summary";
  }
  const readingSubType = readingSubTypes.filter(
    (item) => item.description !== "Weight Difference"
  );
  return (
    <div>
      <Card className="remove-card-shadow">
        <CardHeader className="text-center summary-title summary-header">
          <div>
            {(_.size(row.readingsSevenDays) == 0 && _.size(row.bodyTraceReadings) == 0) && (
              <>
                <div className="summary-div-center">
                  <LottieLoader
                    loadLoop={false}
                    autoplayLoop={true}
                    source={lottieEmptyFile}
                    Lheight={200}
                    Lwidth={200}
                    titleSmall={"Sorry! No last 30 days summary found :("}
                    subTitleSmall={"We can't find any reading's for last 30 days."}
                  />
                  <Link
                    to={`/dashboard/${row.fname
                      .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                      .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row._id
                      }`}
                    className="btn pull-center more-btn"
                    style={{ marginTop: 10 }}
                  >
                    <i className="mdi mdi-account-card-details"></i> More Details
                  </Link>
                </div>
              </>
            )}
            {(_.size(row.readingsSevenDays) > 0 || _.size(row.bodyTraceReadings) > 0) && (
              <>
                {row.fname} {row.lname} - {readingsHeader}
                <Link
                  to={`/dashboard/${row.fname
                    .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                    .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row._id
                    }`}
                  className="btn pull-right more-btn "
                >
                  <i className="mdi mdi-account-card-details"></i> More Details
                </Link>
              </>
            )}
          </div>
        </CardHeader>
      </Card>
      <Row style={{ margin: "15px 0px" }}>
        {readingSubType.map((value, i) => {
          var result = _.includes(value.abbrev, "BT_");
          var readings = undefined;
          var readingValue = undefined;
          var checkThis = undefined;
          if (result) {
            readings = _.filter(row.bodyTraceReadings, (reading) => {
              if (_.includes(value.abbrev, "WS") && reading.values.unit == 0) {
                return false;
              } else if (value.name.toLowerCase() in reading.values) {
                return true;
              }
              return false;
            });
            readingValue = _.map(readings, "values");
            checkThis = _.map(readingValue, (readingIndex, i) => {
              return _.get(readingValue[i], value.name.toLowerCase());
            });
          } else {
            readings = _.filter(row.readingsSevenDays, {
              readingType: value.reading,
            });
            readingValue = _.map(readings, "value");
            checkThis = _.map(readingValue, (readingIndex) => {
              return readingIndex[value.index];
            });
          }

          if (value.name === "Mean Arterial") {
            checkThis = _.map(readingValue, (readingIndex) => {
              return _.round(
                (1 / 3) * readingIndex[0] + (2 / 3) * readingIndex[1],
                2
              );
            });
          }
          var checkNan = isNaN(_.min(checkThis));

          if (readings.length === 0 || checkNan) return;

          var latestReading = _.first(
            _.orderBy(readings, ["deviceReadingDate"], ["desc"])
          );
          var getTheValueLatest;
          if (_.has(latestReading, "value")) {
            getTheValueLatest = _.pick(latestReading, "value");
          } else {
            getTheValueLatest = _.pick(latestReading, "values");
          }

          var latestResult;
          if (_.has(latestReading, "value")) {
            latestResult = getTheValueLatest.value[value.index];
            if (value.name === "Mean Arterial") {
              latestResult = Math.round(
                _.round(
                  (1 / 3) * getTheValueLatest.value[0] +
                    (2 / 3) * getTheValueLatest.value[1],
                  2
                )
              );
            }
          } else {
            latestResult = _.get(
              getTheValueLatest.values,
              value.name.toLowerCase()
            );
            if (value.name === "Mean Arterial") {
              latestResult = Math.round(
                _.round(
                  (1 / 3) * _.get(getTheValueLatest.values, "systolic") +
                    (2 / 3) * _.get(getTheValueLatest.values, "diastolic"),
                  2
                )
              );
            }
          }
          var lastSevenDays = _.size(readings);
          var lastTestTaken =
            readings.length > 0 &&
            moment(latestReading.deviceReadingDate).format("MM/DD/YY"); // hh:mm A

          var matchingExpectationsBreached = _.filter(
            row.expectations,
            function (o) {
              return (
                o &&
                o &&
                o.readingSubType.toLowerCase() ==
                  _.replace(
                    _.replace(value.name, "Pulse", "Heart Rate").toLowerCase(),
                    "bpm",
                    "Heart Rate"
                  ).toLowerCase() &&
                !o.name.toLowerCase().includes("Event".toLowerCase())
              );
            }
          );

          // Checked all of the readings to see which had a low threshold.
          var lowExpectation = _.flatMap(
            _(matchingExpectationsBreached)
              .filter(
                (o) => (o && o.condition == "<=") || o.condition == "between"
              )
              .flatMap((_value, i) => _.pick(_value, "valueProperty"))
              .value(),
            "valueProperty"
          );

          var highExpectation = _.flatMap(
            _(matchingExpectationsBreached)
              .filter(
                (o) => (o && o.condition == ">=") || o.condition == "between"
              )
              .flatMap((_value, i) => _.pick(_value, "valueProperty"))
              .value(),
            "valueProperty"
          );

          var lowMinThreshold = _.maxBy(
            _.map(lowExpectation, (i) => i.minValue || i.value),
            function (o) {
              return o && parseInt(o);
            }
          );
          var highMaxThreshold = _.minBy(
            _.map(highExpectation, (i) => i.maxValue || i.value),
            function (o) {
              return o && parseInt(o);
            }
          );

          var lowThreshold = _.filter(checkThis, (reading, i) => {
            if (lowMinThreshold != undefined) {
              return lowMinThreshold != undefined
                ? reading <= lowMinThreshold
                : reading <= lowMinThreshold;
            } else {
              return false;
            }
          });
          // Checked all of the readings to see which had a high threshold.
          var highThreshold = _.filter(checkThis, (reading, i) => {
            if (highMaxThreshold != undefined) {
              return highMaxThreshold != undefined
                ? reading >= highMaxThreshold
                : reading >= highMaxThreshold;
            } else {
              return false;
            }
          });

          var countLowThreshold = Math.round(
            (_.size(lowThreshold) / lastSevenDays) * 100
          );
          var countHighThreshold = Math.round(
            (_.size(highThreshold) / lastSevenDays) * 100
          );
          var goodThreshold = Math.round(
            100 -
              (parseFloat(countLowThreshold) + parseFloat(countHighThreshold))
          );
          var sevenDaysLowest =
            value.name != "Body Temperature"
              ? Math.round(_.min(checkThis))
              : _.min(checkThis).toFixed(2);
          var sevenDaysHighest =
            value.name != "Body Temperature"
              ? Math.round(_.max(checkThis))
              : _.max(checkThis).toFixed(2);
          var sevenDaysAverage =
            value.name != "Body Temperature"
              ? Math.round(_.mean(checkThis))
              : _.mean(checkThis).toFixed(2);
          if (value.name == "Body Temperature") {
            latestResult = ((latestResult * 9) / 5 + 32).toFixed(2);
            sevenDaysLowest = ((sevenDaysLowest * 9) / 5 + 32).toFixed(2);
            sevenDaysHighest = ((sevenDaysHighest * 9) / 5 + 32).toFixed(2);
            sevenDaysAverage = ((sevenDaysAverage * 9) / 5 + 32).toFixed(2);
          }
          if (value.name == "Weight") {
            if (value.abbrev == "BT_WS") {
              latestResult = Math.round(latestResult);
              sevenDaysLowest = Math.round(sevenDaysLowest);
              sevenDaysHighest = Math.round(sevenDaysHighest);
              sevenDaysAverage = Math.round(sevenDaysAverage);
            } else {
              latestResult = Math.round(latestResult * 2.2046);
              sevenDaysLowest = Math.round(sevenDaysLowest * 2.2046);
              sevenDaysHighest = Math.round(sevenDaysHighest * 2.2046);
              sevenDaysAverage = Math.round(sevenDaysAverage * 2.2046);
            }
          } else {
            latestResult = Math.round(latestResult);
          }
          var readingName = _.replace(value.name, "Pulse", "Heart Rate");
          return (
            <Col sm="4" className="mt-2 remove-summary-paddding">
              <Card style={{ border: "0px 10px 10px 0px" }}>
                <CardHeader
                  className="text-white"
                  style={{ padding: "3px 0px" }}
                >
                  <div class="col-12">
                    <div>
                      <div className="text-left reading-card-for-all-reading">
                        <img
                          src={require(`../../files/Reading/${value.abbrev}.svg`)}
                          alt="profile-image"
                          className="profile rounded-circle img-fluid mr-2 reading-icon-summary" />
                        <div>
                          <h4
                            class="reading-card-title"
                            style={{
                              color: "black",
                              fontSize: 14,
                              fontFamily: "ubuntu-regular",
                              fontWeight: "700",
                            }}
                          >
                            {readingName}
                          </h4>
                          <h6
                            class="reading-card-subtitle"
                            style={{
                              color: "#787878",
                              fontSize: 14,
                              fontFamily: "ubuntu-regular",
                              fontWeight: "500",
                            }}
                          >{`${value.description}`}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="padding-card-body">
                  <Row className="mb-2">
                    <Col sm="8">No. of Test taken in last 30 days:</Col>
                    <Col sm="4" className="font-weight-bold">
                      {lastSevenDays || 0}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="8">Last Test Taken:</Col>
                    <Col sm="4" className="font-weight-bold">
                      {lastTestTaken}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="8">Last Test Result:</Col>
                    <Col sm="4" className="font-weight-bold">
                      {latestResult}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="8">30 days lowest:</Col>
                    <Col sm="4" className="font-weight-bold">
                      {sevenDaysLowest || 0}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="8">30 days highest:</Col>
                    <Col sm="4" className="font-weight-bold">
                      {sevenDaysHighest || 0}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="8">30 days average:</Col>
                    <Col sm="4" className="font-weight-bold">
                      {sevenDaysAverage || 0}
                    </Col>
                  </Row>
                  <>
                    {matchingExpectationsBreached == undefined ||
                      (matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% High:</Col>
                          <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                          >
                            No care plan
                          </Col>
                        </Row>
                      ))}
                    {matchingExpectationsBreached !== undefined &&
                      matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% High:</Col>
                          <Col sm="4" className="font-weight-bold">
                            {countHighThreshold || 0}%
                          </Col>
                        </Row>
                      )}
                    {matchingExpectationsBreached == undefined ||
                      (matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Low:</Col>
                          <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                          >
                            No care plan
                          </Col>
                        </Row>
                      ))}
                    {matchingExpectationsBreached !== undefined &&
                      matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Low</Col>
                          <Col sm="4" className="font-weight-bold">
                            {countLowThreshold || 0}%
                          </Col>
                        </Row>
                      )}
                    {matchingExpectationsBreached == undefined ||
                      (matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Good(InRange):</Col>
                          <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                          >
                            No care plan
                          </Col>
                        </Row>
                      ))}
                    {matchingExpectationsBreached !== undefined &&
                      matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Good(InRange)</Col>
                          <Col sm="4" className="font-weight-bold">
                            {goodThreshold || 0}%
                          </Col>
                        </Row>
                      )}
                  </>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
const status = (cell, row) => {
  const newUser = moment(row.createdAt).isSame(new Date(), "week");
  if (!row.isActive) {
    return <label className="badge badge-gradient-danger">INACTIVE</label>;
  }
  if (newUser) {
    return <label className="badge badge-gradient-info">NEW</label>;
  } else {
    return <label className="badge badge-gradient-success">ACTIVE</label>;
  }
};
const { SearchBar, ClearSearchButton } = Search;
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <div>
      <div className="form-group">
        <input
          type="text"
          className="form-control input-date-filter remove-bottom-margin"          
          id="exampleInputName1Test"
          style={{ fontSize: "20px" }}
          placeholder="Search First, Last Name, Nickname, Email, etc..."
          ref={(n) => (input = n)}
          onChange={handleClick}
        />
      </div>
    </div>
  );
};
const PatientList = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    //hideSizePerPage: props.usersGivenAccessMyReading.length > 0 ? false : true, // Hide the sizePerPage dropdown always
    //hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "All",
        value: props.usersGivenAccessMyReading.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <Row className=" main-cls-modal"> {/*Shrey Added */}
      <Col sm="12" className="mt-12">
        <ToolkitProvider
          keyField="_id"
          data={props.usersGivenAccessMyReading && props.usersGivenAccessMyReading.length > 0 ? props.usersGivenAccessMyReading : []}
          columns={[
            {
              dataField: "lname",
              text: "Last, First Name",
              formatter: fullname,
              csvFormatter: (cell, row) => {
                return row.lname + ", " + row.fname;
              },
              sortField: "lname",
              sort: true,
              filter: textFilter(),
              filterValue: (cell, row) => {
                return row.lname + ", " + row.fname;
              },
              headerFormatter: priceFormatter,
              headerStyle: {
                minWidth: '195px' // Set your desired width  // Shrey Added
              },
              style: {
                minWidth: '195px' // Set your desired width  // Shrey Added
              },
            },
            {
              dataField: "nickname",
              text: "Nickname",
              sort: true,
              formatter: (cell, row) => {
                return row.nickname ? row.nickname : "-";
              },
              csvFormatter: (cell, row) => {
                return row.nickname ? row.nickname : "-";
              },
              filter: textFilter(),
              filterValue: (cell, row) => {
                return row.nickname ? row.nickname : "-";
              },
              headerFormatter: priceFormatter,
              headerStyle: {
                minWidth: '195px' // Set your desired width  // Shrey Added
              },
              style: {
                minWidth: '195px' // Set your desired width  // Shrey Added
              },
            },
            {
              dataField: "bday",
              text: "DOB",
              formatter: (cell, row) => {
                return moment(row.bday).format("MMMM DD, YYYY");
              },
              csvFormatter: (cell, row) => {
                return moment(row.bday).format("MMMM DD, YYYY");
              },
              // headerStyle: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              // style: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              sort: true
            },
            {
              dataField: "createdAt",
              text: "Enrolled Date",
              sort: true,
              formatter: (cell, row) => {
                return moment(row.createdAt).format("MMMM DD, YYYY");
              },
              headerFormatter: priceFormatter,
              csvFormatter: (cell, row, rowIndex) => {
                return moment(row.createdAt).format("MMMM DD, YYYY");
              },
              // headerStyle: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              // style: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
            },
            {
              dataField: "lastReading.deviceReadingDate",
              text:
                props.displayActiveList === true ||
                  props.displayActiveList === null
                  ? "Last Upload Date"
                  : "Deactivated Date",
              sort: true,
              formatter: (cell, row) => {
                if (
                  props.displayActiveList === true ||
                  props.displayActiveList === null
                ) {
                  if (row.lastReading && row.lastReading.deviceReadingDate) {
                    return moment(row.lastReading.deviceReadingDate).format(
                      "MMMM DD, YYYY"
                    );
                  }
                  return "NONE";
                } else if (props.displayActiveList === false) {
                  return row.inActiveAt
                    ? moment(row.inActiveAt)
                      .tz(moment.tz.guess())
                      .format("MMMM DD, YYYY")
                    : moment(row.updatedAt)
                      .tz(moment.tz.guess())
                      .format("MMMM DD, YYYY");
                }
              },
            },
            {
              dataField: "organization",
              text: "Organization",
              sort: true,
              filter: textFilter(),
              headerFormatter: priceFormatter,
              // headerStyle: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              // style: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
            },
            {
              dataField: "mainCareProvider",
              text: "Main Care Provider",
              sort: true,
              formatter: (cell, row) => {
                return row.mainCareProvider
                  ? row.mainCareProvider.lname + ", " + row.mainCareProvider.fname
                  : "";
              },
            },
            {
              dataField: "enrolledPrograms",
              text: "Enrolled Program(s)",
              sort: true,
              // headerStyle: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              // style: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
            },
            {
              dataField: "ccmTotalTimes",
              text: "Total CCM Time",
              formatter: (cell, row) => {
                return row.ccmTotalTimes ? row.ccmTotalTimes : "00:00:00";
              },
              csvFormatter: (cell, row) => {
                return row.ccmTotalTimes ? row.ccmTotalTimes : "00:00:00";
              },
              sort: true,
              // headerStyle: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              // style: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
            },
            {
              dataField: "rpmTotalTimes",
              text: "Total RPM Time",
              formatter: (cell, row) => {
                return row.rpmTotalTimes ? row.rpmTotalTimes : "00:00:00";
              },
              csvFormatter: (cell, row) => {
                return row.rpmTotalTimes ? row.rpmTotalTimes : "00:00:00";
              },
              sort: true,
              // headerStyle: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              // style: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
            },
            {
              dataField: "nonAdhenrenceDay",
              text: "Non Adherence Days",
              formatter: (cell, row) => {
                return row.nonAdhenrenceDay ? row.nonAdhenrenceDay : 0;
              },
              sort: false,
              headerFormatter: priceFormatter,
              // headerStyle: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
              // style: {
              //   minWidth: '195px' // Set your desired width  // Shrey Added
              // },
            },
            {
              dataField: "weekAssigned",
              text: "Designated Week",
              formatter: (cell, row) => {
                return row.weekAssigned ? row.weekAssigned : "";
              },
              csvFormatter: (cell, row) => {
                return row.weekAssigned ? row.weekAssigned : "";
              },
              sort: true,
            },
          ]}
          search
          exportCSV={{ fileName: `patients.csv` }}
        >
          {(toolKitProp) => (
            <div>
              <div>
                <div>
                  <Row className={`text-center col-md-12 mx-auto float-right`}>
                    <Col md={10} style={{ marginTop: 5 }}>
                      <MySearch {...toolKitProp.searchProps} />
                    </Col>
                    <Col md={2} style={{ marginTop: 1 }}>
                      <MyExportCSV {...toolKitProp.csvProps}>
                        Export CSV
                      </MyExportCSV>
                    </Col>
                  </Row>
                </div>
                <div className="scrollbar-table-cls w-100 ">
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    keyField="_id"
                    noDataIndication="No Data Found"
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                    expandRow={{
                      renderer: (row) =>
                        rowComponent(row, props.readingSubTypes, props.profileOwner),

                      showExpandColumn: true
                    }}
                    {...toolKitProp.baseProps}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </Col>
    </Row>
  );
};

export default PatientList;
