import { delay, call, put, select } from "redux-saga/effects";
import axios from "axios";
import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  PHONE_CHANGED,
  LOGIN_USER_FAIL,
  FIRSTNAME_CHANGED,
  LASTNAME_CHANGED,
  NICKNAME_CHANGED,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  REGISTER_CLIENT_FAIL,
  REGISTER_CLIENT_SUCCESS,
  CODE_CHANGED,
  VERIFY_USER_SUCCESS,
  LOGOUT_USER,
  LOADING_START,
  LOADING_STOP,
  FORGOT_USER_SUCCESS,
  FINALREGISTER_USER_SUCCESS,
  JUST_REGISTERED,
  GET_CLIENT_SUCCESS,
  RESET_USER_SUCCESS,
} from "../actions/types";
import { toast } from "react-toastify";
if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3000/api";
} else {
  axios.defaults.baseURL = "https://dev.portal.myvitalview.com/api";
}
export const getClient = (state) => state.user.clientInfo;
export function* emailChanged(action) {
  yield put({ type: EMAIL_CHANGED, payload: action.payload });
  yield put({
    type: REGISTER_USER_FAIL,
    payload: "",
  });
  yield put({ type: LOGIN_USER_FAIL, payload: "" });
}
export function* nicknameChanged(action) {
  yield put({ type: NICKNAME_CHANGED, payload: action.payload });
  yield put({
    type: REGISTER_USER_FAIL,
    payload: "",
  });
}
export function* fnameChanged(action) {
  yield put({ type: FIRSTNAME_CHANGED, payload: action.payload });
  yield put({
    type: REGISTER_USER_FAIL,
    payload: "",
  });
}
export function* phoneChanged(action) {
  yield put({ type: PHONE_CHANGED, payload: action.payload });
  yield put({
    type: REGISTER_USER_FAIL,
    payload: "",
  });
}
export function* lnameChanged(action) {
  yield put({ type: LASTNAME_CHANGED, payload: action.payload });
  yield put({
    type: REGISTER_USER_FAIL,
    payload: "",
  });
}
export function* changeCode(action) {
  yield put({ type: CODE_CHANGED, payload: action.payload });
}
export function* passwordChanged(action) {
  yield put({ type: PASSWORD_CHANGED, payload: action.payload });
  yield put({ type: LOGIN_USER_FAIL, payload: "" });
}
export function* logoutSaga(action) {
  try {
    yield put({ type: LOGOUT_USER, payload: "" });
    if (action.payload.userId) {
      const authData = {
        userId: action.payload.userId,
        type: "Web",
      };
      let url;
      let client = yield select(getClient);
      if (client) {
        url = "/client/loggedOut";
      } else {
        url = "/loggedOut";
      }
      yield axios.post(url, authData);
    } else {
      yield put({ type: LOGOUT_USER, payload: "" });
    }
  } catch (error) {
    yield put({ type: LOGOUT_USER, payload: "" });
  }
}
export function* verifyEmailSaga(action) {
  yield put({ type: LOADING_START });

  try {
    const authData = {
      email: action.payload.email,
      code: action.payload.code,
    };

    let url = "/verify";
    const response = yield axios.post(url, authData);
    const responseData = response.data;

    yield put({
      type: REGISTER_USER_FAIL,
      payload: "",
    });
    yield put({
      type: VERIFY_USER_SUCCESS,
    });
    yield put({ type: LOADING_STOP });
  } catch (error) {
    yield put({
      type: REGISTER_USER_FAIL,
      payload: error.response.data,
    });
    yield put({ type: LOADING_STOP });
  }
}
export function* aggrementApproved(action) {
  yield put({ type: LOADING_START });
  try {
    const authData = {
      userId: action.payload.userId,
      Signature: action.payload.Signature,
    };
    let url;
    let client = yield select(getClient);
    if (client) {
      url = "/aggrement-Approved-Client";
    } else {
      url = "/aggrement-Approved";
    }
    const response = yield axios.post(url, authData);    
    
    const responseData = response.data.data;    
    if (client) {
      yield put({
        type: REGISTER_CLIENT_SUCCESS,
        payload: responseData,
      });
    } else {
      yield put({
        type: FINALREGISTER_USER_SUCCESS,
        payload: responseData,
      });
    }
    yield put({ type: LOADING_STOP });
  } catch (error) {
    yield put({ type: LOADING_STOP });
  }
}
export function* clientInfo(action) {
  try {
    yield put({
      type: GET_CLIENT_SUCCESS,
      payload: null,
    });
    if (action.payload && action.payload.isClear) {
      return;
    }
    const authData = action.payload;

    let url = "/client-info";
    const response = yield axios.post(url, authData);
    const responseData = response.data;

    yield put({
      type: GET_CLIENT_SUCCESS,
      payload: responseData.data,
    });
    yield put({ type: LOADING_STOP });
  } catch (error) {
    yield put({
      type: GET_CLIENT_SUCCESS,
      payload: error.response.data,
    });
    yield put({ type: LOADING_STOP });
  }
}
export function* loginClient(action) {
  yield put({ type: LOADING_START });

  try {
    const authData = action.payload;

    let url = "/client/login";
    const response = yield axios.post(url, authData);
    const responseData = response.data;

    yield put({
      type: REGISTER_CLIENT_SUCCESS,
      payload: responseData.data,
    });
    yield put({ type: LOADING_STOP });
  } catch (error) {
    yield put({ type: LOGIN_USER_FAIL, payload: error.response.data });
    yield put({ type: LOADING_STOP });
  }
}
export function* loginSaga(action) {
  yield put({ type: LOADING_START });

  try {
    const authData = {
      email: action.payload.email,
      password: action.payload.password,
      type: "Web",
      platform: "Browser",
    };

    let url = "/login";
    const response = yield axios.post(url, authData);
    const responseData = response.data;
    if (responseData.ApprovalPending || responseData.ApprovalRejected) {
      yield put({
        type: LOGIN_USER_FAIL,
        payload: { message: responseData.message },
      });
    } else {
      yield put({
        type: FINALREGISTER_USER_SUCCESS,
        payload: responseData,
      });
    }
    yield put({ type: LOADING_STOP });
  } catch (error) {
    yield put({ type: LOGIN_USER_FAIL, payload: error.response.data });
    yield put({ type: LOADING_STOP });
  }
}

export function* registerClient(action) {
  yield put({ type: LOADING_START });

  try {
    const authData = action.payload;

    let url = "/client/register";
    const response = yield axios.post(url, authData);
    const responseData = response.data;
    yield put({ type: LOADING_STOP });
    yield put({
      type: REGISTER_CLIENT_SUCCESS,
      payload: responseData.data,
    });
  } catch (error) {
    yield put({ type: LOADING_STOP });
    yield put({
      type: REGISTER_CLIENT_FAIL,
      payload: error.response.data,
    });
  }
}
export function* registerSaga(action) {
  yield put({ type: LOADING_START });

  try {
    const authData = {
      email: action.payload.email,
      fname: action.payload.fname,
      lname: action.payload.lname,
      nickname: action.payload.nickname,
    };

    let url = "/register";
    let client = yield select(getClient);
    const response = yield axios.post(url, authData, {
      headers: {
        "CLIENT-ID": client ? client._id : null,
      },
    });
    const responseData = response.data;

    yield put({ type: LOADING_STOP });
    yield put({
      type: REGISTER_USER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: LOADING_STOP });
    yield put({
      type: REGISTER_USER_FAIL,
      payload: error.response.data,
    });
  }
}
export function* resetSaga(action) {
  yield put({ type: LOADING_START });
  try {
    const authData = {
      password: action.payload.password,
      token: action.payload.token,
    };

    let url = "/reset-password/" + action.payload.token;
    const response = yield axios.post(url, authData);
    const responseData = response.data;
    yield put({ type: RESET_USER_SUCCESS });

    toast.success("Reset Successfully");

    yield put({ type: LOADING_STOP });
  } catch (error) {
    toast.error("Reset password is not successful");

    yield put({
      type: REGISTER_USER_FAIL,
      payload: error.response.data,
    });
    yield put({ type: LOADING_STOP });
  }
}
export function* forgotSaga(action) {
  yield put({ type: LOADING_START });
  try {
    const authData = {
      email: action.payload.email,
    };

    let url = "/forgot-password";
    const response = yield axios.post(url, authData);
    const responseData = response.data;
    if (responseData.ApprovalPending || responseData.ApprovalRejected) {
      yield put({
        type: REGISTER_USER_FAIL,
        payload: { message: responseData.message },
      });
    } else {
      yield put({
        type: FORGOT_USER_SUCCESS,
      });
    }
    yield put({ type: LOADING_STOP });
  } catch (error) {
    yield put({
      type: REGISTER_USER_FAIL,
      payload: error.response.data,
    });
    yield put({ type: LOADING_STOP });
  }
}
export function* registerFinalSaga(action) {
  yield put({ type: LOADING_START });
  try {
    const authData = action.payload;
    let client = yield select(getClient);
    let url = "/final-registration";
    const response = yield axios.post(url, authData, {
      headers: {
        "CLIENT-ID": client ? client._id : null,
      },
    });
    const responseData = response.data;
    if (!responseData.isCareProvider && !responseData.isStaff) {
      yield put({
        type: REGISTER_USER_FAIL,
        payload: {
          isFirstRegistration: true,
        },
      });
    } else {
      yield put({
        type: FINALREGISTER_USER_SUCCESS,
        payload: responseData,
      });
      yield put({
        type: JUST_REGISTERED,
        payload: {
          isRegister: true,
        },
      });
    }
    yield put({ type: LOADING_STOP });
  } catch (error) {
    yield put({
      type: REGISTER_USER_FAIL,
      payload: error.response.data,
    });
    yield put({ type: LOADING_STOP });
  }
}
