import React, { Component } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { connect } from 'react-redux';
import ReactToPrint from "react-to-print";
import QuestionForm from "./QuestionForm";

class AddCCMQuestionnaire extends Component {

  constructor(props) {
    super(props);
    this.printRef = React.createRef();
    this.saveRef = React.createRef();
    this.saveCCMDetails = this.saveCCMDetails.bind(this);
  }

  saveCCMDetails(data) {
    //console.log("SAVED : " + data);
    if (data) {
      const patient = this.props.profile._id;
      const createdBy = this.props.profileOwner._id;
      this.props.dispatch({
        type: "DO_SAVE_PATIENT_CCMANSWERS",
        payload: { answers: data, patient: patient, createdBy: createdBy }
      });

      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PATIENT_CCM_ANSWERS",
          payload: { userid: patient }
        });
      }, 1000);
      this.props.toggleCcmQuestionsModal();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.ccmQuestionsModal}
        toggle={this.props.toggleCcmQuestionsModal}
        size="lg"
        className="display-table"
        style={{ display: "table" }}
      >
        <ModalHeader className="close_button" toggle={this.props.toggleCcmQuestionsModal}>
          <Label className="align-items-center justify-content-center text-center"> CCM Questions</Label>
          <button
            style={{ float: 'right' }}
            onClick={this.props.toggleCcmQuestionsModal}
            className="btn btn-info ml-2 close-click-cls" >
            <i class="mdi mdi-close" style={{
              fontSize: 20
            }}></i>
            Close
          </button>

          <button
            name="submit"
            style={{ float: 'right' }}
            onClick={() => {
              this.saveRef.current.click();
            }}
            className="btn btn-info ml-2 save-click-cls" >
            <i class="mdi mdi-content-save" style={{
              fontSize: 20
            }}></i>
            Save
          </button>
        </ModalHeader>

        <ModalBody style={{ backgroundColor: "white" }}>
          <Row>
            <Col md={12}>
              <QuestionForm
                ref={{
                  saveRef: this.saveRef
                }}
                isEditable={true}
                saveCCMDetails={this.saveCCMDetails}
                mQuestionsList={this.props.mQuestionsList}
                profile={this.props.profileOwner}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage
  } = careprovider;
  const profile = user.profile;
  const profileOwner = user.profileOwner ? user.profileOwner : user.userDetails;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    profile,
    profileOwner
  };
};

export default connect(mapStateToProps)(AddCCMQuestionnaire);