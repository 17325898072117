import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ReactToPrint from "react-to-print";
import Link from "components/ExtendLink";
import {
  Row,
  Col
} from "reactstrap";
import moment from "moment";
import LottieLoader from '../../LottieLoader';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import UserName from "../../Reusable/UserName";
const _ = require("lodash");

function fullname(row, cell) {
  return <UserName user={cell} />;
}
function countReadings(cell, row, props) {
  let count = 0;
  row.readings.forEach((item) => {
    // exceptions count
    if(item && item.exceptions && item.exceptions.length > 0){
      item.exceptions.forEach(item => {
        const filterSubType = props.prevFilter && props.prevFilter.subTypeExceptionName ? props.prevFilter.subTypeExceptionName.toLowerCase() : undefined;
        let filterException =  item.readingSubType ? item.readingSubType.toLowerCase() : undefined;
        if(filterException == 'ws' || filterException == 'WS'){
          filterException = 'weight';
         }
        if (!item.name.toLowerCase().includes("Event".toLowerCase())) {
          if(props && props.prevFilter.isWeightSelectExcpection && props.prevFilter.expectationId == item.rule ){
            count += 1;
          }else if(!props.prevFilter.isWeightSelectExcpection && filterSubType == filterException){
            count += 1;
          }
        }
      });
    }
  })
  return <div>{count}</div>;
}
function totalReadings(cell, row) {
  return <div>{ _.size(row.readings)}</div>;
}
function mainCareProviderName(cell, row) {
  let mainCareProviderName = "-";
  if(row.mainCareProvider && Object.keys(row.mainCareProvider).length > 0 && row.mainCareProvider.fname && row.mainCareProvider.lname){
    let {mainCareProvider} = row;
    mainCareProviderName = `${mainCareProvider.fname} ${mainCareProvider.lname}`;
  }
  return <div>{mainCareProviderName}</div>
}
function organizationName(cell, row) {
  return <div>{row.organization ? row.organization : "-"}</div>
}
function capitalize(value) {
  if (value && (value == 'pulseRate' || value == 'pulse' || value == 'BPM')) {
    return 'Heart Rate';
  }if (value && value == 'spo2') {
    return 'Oxygen Saturation';
  }else{
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
// range parameter calculation
function rangeParameter(cell,props){
  if(cell && cell[0].isWeight) {
    const weightGain = cell[0].notificationExp && cell[0].notificationExp.weightGain;
    if(props.prevFilter && props.prevFilter.isEventExceptions){
      return ( <div> - </div>)
    }else if(weightGain){
      return ( <div> Less than {weightGain}lbs gain or loss within 24 hours </div>)
    }else{
      return ( <div style={{color : '#dc3545'}}> No Care Plan </div>)
    }
  }else{
    if(props.prevFilter && props.prevFilter.isEventExceptions){
     return (<div>-</div>)
    }else{
      const desiredRange = cell[0].desiredRange;
      const low = desiredRange && desiredRange.lowestValue ? desiredRange.lowestValue  : 'No Care Plan';
      const high = desiredRange  && desiredRange.highestValue  ? desiredRange.highestValue : 'No Care Plan';
      if(low == 'No Care Plan' && high == 'No Care Plan'){
        return (<div style={{color : '#dc3545'}}> No Care Plan </div>)
        }else{
        return ( <div> {low == 'No Care Plan' ? <span style={{color : '#dc3545'}}> No Care Plan </span> : low} &nbsp;-&nbsp; {high == 'No Care Plan' ? <span style={{color : '#dc3545'}}> No Care Plan </span> : high} </div> )
      }
    }
}
}
function addReadingUnit(name,row, isBloodPressureReading = false){
  let readingName = name;
  if(name == 'pulseRate' && row?.values){
    readingName = 'pulse'
  }if(name == 'pulseRate' && row?.readingValue && row.readingType._id == '63343c21db4e75cc4566c511'){
    readingName = 'BPM' 
  }
  let readingValue =  row?.values ? row.values[readingName] : row.readingValue[readingName];
  if (row && row.readingType) {
    switch (row.readingType.abbrev) {
        case "GL":
        const valueGL = readingValue ?  readingValue + 'mg/dL' : '-'
        readingValue = valueGL;
        break;
        case "BP":
         if(name == 'systolic' || name == 'diastolic'){
          const valueBP = readingValue ?  readingValue + 'mmHg' : '-'
          readingValue = valueBP;
         }else if(name == 'Mean Arterial'){
          const BP_Readings = row.readingValue || row.values;
          if(BP_Readings && Object.keys(BP_Readings).length > 0){
          const systolic = BP_Readings.systolic || 0;
          const diastolic = BP_Readings.diastolic || 0;
          readingValue = Math.round((1 / 3) * systolic + (2 / 3) * diastolic);
          }else{
          readingValue = '-'
          }
         }else{
          const valueBPM = readingValue ?  readingValue + 'bpm' : '-'
          readingValue = valueBPM;
         }
        break;
        case "BT_BP":
          if(name == 'systolic' || name == 'diastolic'){
           const valueBP = readingValue ?  readingValue + 'mmHg' : '-'
           readingValue = valueBP;
          }else if(name == 'Mean Arterial'){
            const BP_Readings = row.readingValue || row.values;
            if(BP_Readings && Object.keys(BP_Readings).length > 0){
            const systolic = BP_Readings.systolic || 0;
            const diastolic = BP_Readings.diastolic || 0;
            readingValue = Math.round((1 / 3) * systolic + (2 / 3) * diastolic);
            }else{
            readingValue = '-'
            }
          }else{
           const valueBPM = readingValue ?  readingValue + 'bpm' : '-'
           readingValue = valueBPM;
          }
         break;
         case "WS":
          const valueWS = row?.values ? (readingValue + 'lb') : readingValue  ? (Math.round(readingValue * 2.2046) + 'lb') : '-'
          readingValue = valueWS;
         break;
         case "BT_WS":
          const valueBT_WS = row?.values ? (readingValue + ' lb') : readingValue ? (Math.round(readingValue * 2.2046) + 'lb') : '-'
          readingValue = valueBT_WS;
         break;
         case "PO":
          if(name == 'spo2'){
            const valueSPO2 = readingValue ?  readingValue + '%' : '-'
            readingValue = valueSPO2;
           }else if(name == 'pulseRate' && isBloodPressureReading){
            readingValue = '-'
           }else{
            const valueRate = readingValue ?  readingValue + 'bmi' : '-'
            readingValue = valueRate;
           }
         break;
         case "TM":
         const valueTM = (readingValue ? Math.round((readingValue * 1.8 + 32) * 10 / 10).toFixed(2) : '0') + 'F'
         readingValue = valueTM;
         break;
         case "AT":
         const valueAT = (readingValue ? readingValue  : '-')
         readingValue = valueAT;
         break;
         case "CO2":
         const valueCO2 = (readingValue ? readingValue + "g/mol" : "-")
         readingValue = valueCO2;
         break;
         case "ZOE":
         const valueZOE = (readingValue ? readingValue + "fluid" : "-")
         readingValue = valueZOE;
         break;
         case "HR":
         const valueHR = (readingValue ? readingValue + "bpm" : '-')
         readingValue = valueHR;
         break;
    }
  }
  return readingValue;
}
const rowComponent = (row, props) => {
  const isBloodPressureReading = row.isBloodPressureReading ? row.isBloodPressureReading : false;
  let mergedArray = row?.readings;
  const readingValuesArray = [];
  // Sort the merged array by deviceReadingDate
  const sortedArray = mergedArray.sort((a, b) => {
    readingValuesArray.push(_.keys(a?.readingValue || a?.values))
    return new Date(b.deviceReadingDate) - new Date(a.deviceReadingDate);
  });

  mergedArray.map((item) => {
    readingValuesArray.push(_.keys(item?.readingValue || item?.values))
  })
  const flattenedArray = readingValuesArray.flat();
  //  Remove duplicates using Set
  const uniqueValuesArray = Array.from(new Set(flattenedArray));
  //  Filter out the 'pulse' and 'BPM' value
  const readingValues = uniqueValuesArray.filter(value => value !== 'pulse' && value !== 'BPM');
  if(props.readingType && props.readingType == '5bb306382598931ffbd1b624'){
    if(!readingValues.includes('pulseRate')){
      readingValues.push('pulseRate')
    }
   readingValues.push('Mean Arterial');
  }
  row.mergedArray = sortedArray;
  
  // const readingValues = _.keys(mergedArray[0]?.readingValue || mergedArray[0]?.values);
  const expandRowColumns = [
    {
      dataField: "deviceReadingDate",
      text: "Date of Reading",
      formatter: (cell, row) => {
        return moment(row.deviceReadingDate).format("MMMM DD, YYYY hh:mm A");
      },
    },
    {
      dataField: "readingType.description",
      text: "Reading Type",
    }
  ];
  _.each(readingValues, function (value) {
    if(value !== "event" && value!=="eventDescription" && value != "bmiDescription" && value != 'bmi' && value !== 'unit' && value !== 'tare' && value !== 'irregular'){
      expandRowColumns.push({
        dataField: `readingValue.${value}`,
        text: value == 'temperature' ? 'Body Temperature' : capitalize(value),
        formatter: (cell, row) => {
          let isExacptions = false;
          if(row.exceptions && row.exceptions.length > 0){
            row.exceptions.forEach((item) => {
              let readingSubtype = item.readingSubType.replace(/\s+/g, '').toLowerCase();
              let subTypevalue = value.replace(/\s+/g, '').toLowerCase();
              if(item.readingSubType == 'Body Temperature'){
                readingSubtype = 'temperature'
              }else if(item.readingSubType == 'Heart Rate'){
                if( (value == 'pulseRate' && row?.values) || value == 'pulseRate'){
                  subTypevalue = 'heartrate'
                 }
              }else if(value == 'spo2'){
                subTypevalue = 'oxygensaturation'
              }else if(item.readingSubType == 'WS'){
                readingSubtype = 'weight'
              }
              if(props && props.isWeightSelectExcpection && props.expectationId == item.rule ){
                 isExacptions = true;
              }else if(!props?.isWeightSelectExcpection && readingSubtype == subTypevalue && !item.name.toLowerCase().includes("Event".toLowerCase())){
                isExacptions = true;
              }
            })
          }
          const addUnitReadinValue = addReadingUnit(value, row, isBloodPressureReading && isExacptions ? false : isBloodPressureReading)
          return <div style={{backgroundColor : `${(isExacptions ? '#D5271B' : '#67BD03' )}`, padding: '0.5rem', textAlign: 'center', fontSize: '15px',color: 'white', fontWeight: '800', width: '165px', borderRadius: '7px'}}>{addUnitReadinValue}</div>;
        },
        headerStyle: (colum, colIndex) => {
          return { width: '210px' };
        }
      })
    }
  });
  return (
    <Row>
      <Col md={12}>
        <BootstrapTable
          keyField="_id"
          search 
          exportCSV={{ fileName: `patient_hypertension.csv`}}
          wrapperClasses="table-responsive"
          data={row.mergedArray || []}
          columns={expandRowColumns}
        />
      </Col>
   </Row>
  );
};
function viewProfile(cell, row, s, dateRange) {
  return (
    <div>
      <Link
        to={`/dashboard/${row.fname
          .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
          .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${
          row._id
        }`}
        target="_blank"
        className="btn pull-center more-btn"
      >
      <i class="mdi mdi-account-card-details"></i> More Details
      </Link>
    </div>
  );
}

export default class TableSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideProfile : false,
    }
  }
  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        {"   "}Showing {from} to {to} of {size} Results
      </span>
    );
    if (this.props.populationFilePath) {
      if (this.state.link === undefined) {
        this.setState({
          link: this.props.populationFilePath,
        }, () => {
          this.props.dispatch({
            type: "DO_REMOVE_PATIENT_LINK_PATH",
            payload: undefined
          });
          window.open(this.state.link, "_self");
        }
        );
      }
    } else {
      this.state.link = undefined;
    }

    const NoDataIndication = () => (
      <div>
        {this.props.loading && (
          <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieLoaderFile}
            Lheight={150}
            Lwidth={150}
            subTitle={"Please Wait ..."}
          />
        )}
        {!this.props.loading && (
          <LottieLoader
            loadLoop={false}
            autoplayLoop={true}
            source={lottieEmptyFile}
            Lheight={300}
            Lwidth={300}
            title={`Sorry! No patient record with the selected ${this.props.exceptionType ? 'expectation'  : 'condition'} found :(`}
            subTitle={"We can't find any patient record."}
          />
        )}
      </div>
    );
    let columns;
      columns = [
        {
          dataField: "lname",
          text: "Last, First Name",
         formatter: fullname,
        },
        {
          dataField: "bday",
          text: "DOB",
          formatter: (cell, row) => {
            return moment(row.bday).format("MMMM DD, YYYY");
          },
          csvFormatter: (cell, row) => {
            return moment(row.bday).format("MMMM DD, YYYY");
          }
        },
        {
          dataField: "_id",
          text: "Main Care Provider",
          formatter: mainCareProviderName,
        },
        {
          dataField: "_id",
          text: "Organization",
          formatter: organizationName,
        },
        {
          dataField: "thresoldDetailes",
          text: "Range Parameter",
          formatter:  (cell, row) => {
            return rangeParameter(cell, this.props);
          }
        },
        {
          dataField: "_id",
          text: "Total Triggered Exceptions",
          formatter: (cell, row) => {
            return countReadings(cell, row, this.props)
          },
          csvFormatter: (cell, row) => {
            return _.size(row.readings);
          },
          headerStyle: (colum, colIndex) => {
            return { width: '130px', textAlign: 'left' };
          }
        },
        {
          dataField: "_id",
          text: "Total Readings Found",
          formatter: totalReadings,
          csvFormatter: (cell, row) => {
            return _.size(row.readings);
          },
          headerStyle: (colum, colIndex) => {
            return { width: '130px', textAlign: 'left' };
          }
        },
        !this.state.hideProfile && {
          dataField: "asdf",
          text: "Profile",
          formatter: viewProfile,
          formatExtraData: {
            startDate: this.props.startDate,
            endDate: this.props.endDate
          },
          headerStyle: (colum, colIndex) => {
            return { width: '130px', textAlign: 'left' };
          }
        }
      ].filter(Boolean);
 
    const options = {
      page: parseInt(this.props.page || 1),
      sizePerPage: parseInt(this.props.limit || 25),
      totalSize: parseInt(this.props.total || 0),
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      hideSizePerPage: parseInt(this.props.total) == 0 ? true : false,// Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      showTotal:  parseInt(this.props.total) == 0 ? false : true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "25",
          value: 25
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "100",
          value: 100
        },
        {
          text: "All",
          value: parseInt(this.props.total) == 0
        }
      ] // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
      <ToolkitProvider
      keyField="_id"
      data={this.props.users}
      columns={columns} 
      search 
      exportCSV={{ fileName: `${this.props.exceptionType ? 'Expectation ' : 'Condition '} patients.csv`}}
    >
 {(toolKitProp) => (
  <div >
     <div>
    <BootstrapTable
    remote={{
      sort: false,
      pagination: true,
    }
    }
    wrapperClasses="table-responsive"
    {...toolKitProp.baseProps}
         data={this.props.users}
         columns={columns}
         noDataIndication={() => <NoDataIndication />}
         expandRow={{
          renderer: (row) =>
            rowComponent(row, this.props?.prevFilter || null),
          onlyOneExpanding: false,
          showExpandColumn: true,
          expandByColumnOnly: false
        }}
        pagination={options.page > 0 ? paginationFactory(options) : undefined}
        onTableChange={this.props.pagination}
       />
    </div>
  </div>
)}
    </ToolkitProvider>
    );
  }
}
