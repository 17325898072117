import React, { Component } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";

class EmailQuestionnaire extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      toEmail: "",
      subject: "",
      message: "",
      formErrors: { toEmail: "", subject: "" ,message:""},
      toEmailValid: false,
      subjectValid: false,
      messageValid: false,
      formValid: false,
    };
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let toEmailValid = this.state.toEmailValid;
    let subjectValid = this.state.subjectValid;
    let messageValid = this.state.messageValid;

    switch (fieldName) {
      case "toEmail":
        toEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.toEmail = toEmailValid ? "" : "Receiver email is invalid";
        break;
      case "subject":        
        subjectValid = value != undefined && value.length > 0 ? value : undefined;
        fieldValidationErrors.subject = subjectValid ? "" : "Subject is invalid";
        break;
      case "message":        
        messageValid = value != undefined && value.length > 0 ? value : undefined;
        fieldValidationErrors.message = messageValid ? "" : "Message is invalid";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        toEmailValid: toEmailValid,
        subjectValid: subjectValid,
        messageValid: messageValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.toEmailValid && this.state.subjectValid && this.state.messageValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  
  handleSubmit = (e) => {        
    e.preventDefault();
    const htmlContent = this.props.htmlContent;
    if(this.state.toEmail && this.state.subject && this.state.message)    
    this.props.dispatch({
      type: "DO_SEND_QUESTIONNAIRE_EMAIL",
      payload: {
        toEmail:this.state.toEmail,
        subject:this.state.subject,
        message:this.state.message,
        name: this.props.name,
        image: this.props.image,
      }
    });
    this.props.dismissModel();
  }

  render() {
    return (
      <form className="emailForm" onSubmit={this.handleSubmit}>        
        <div
          className={`form-group ${this.errorClass(
            this.state.formErrors.toEmail
          )}`}
        >
          <label htmlFor="toEmail">Receiver Email</label>
          <input
            type="toEmail"
            required
            className="form-control"
            name="toEmail"
            placeholder=""
            value={this.state.toEmail}
            onChange={this.handleUserInput}
          />
          <div className="panel panel-default">            
            <p style={{color:"red"}}>{this.state.formErrors["toEmail"]}</p>
          </div>
        </div>
        <div
          className={`form-group ${this.errorClass(
            this.state.formErrors.subject
          )}`}
        >
          <label htmlFor="subject">Subject</label>
          <input
            type="subject"
            className="form-control"
            name="subject"
            placeholder=""
            value={this.state.subject}
            onChange={this.handleUserInput}
          />
          <div className="panel panel-default">            
            <p style={{color:"red"}}>{this.state.formErrors["subject"]}</p>
          </div>
        </div>
        <div
          className={`form-group ${this.errorClass(
            this.state.formErrors.message
          )}`}
        >
          <label htmlFor="message">Message</label>
          <textarea
            type="message"
            className="form-control"
            name="message"            
            placeholder="Write a message"
            value={this.state.message}            
            style={{height:180}}
            onChange={this.handleUserInput}
          />
          <div className="panel panel-default">            
            <p style={{color:"red"}}>{this.state.formErrors["message"]}</p>
          </div>
        </div>        
        <button
          type="submit"
          className="btn btn-block btn-info"
          disabled={!this.state.formValid}>
          Send
        </button>
      </form>
    );
  }
}

const mapStateToProps = ({ emailQuestionnaire, user, auth }) => {
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    userId,
    profileOwner
  };
};
export default connect(mapStateToProps)(EmailQuestionnaire);
