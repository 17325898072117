import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from "prop-types";
import Link from "components/ExtendLink";
import React from 'react';
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap";
import userImage from "../../files/user.png";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import lottiePending from '../../files/animation/ic_pending.json';
import lottieCompleted from '../../files/animation/ic_fax_send_completed.json';
import ic_warning from '../../files/animation/ic_warning.json';
const _ = require("lodash");

const DevicesListDataTable = (props) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.totalSize) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "200",
        value: 200
      },
      {
        text: "All",
        value: props.totalSize
      }
    ]
  };
  const columns = [
    {
      dataField: "fullName",
      text: "Last, First Name",
      formatter: fullname,
      sort: true
    },{
      dataField: "nickname",
      text: "Nickname",
      formatter: (cell, row) => {
        return row.nickname ? row.nickname : <div className='text-center'>-</div>;
      },
      sort: true
    }, {
      dataField: "mainCareProvider",
      text: "Main Care Provider",
      formatter: (cell, row) => {
        return row.mainCareProvider ? row.mainCareProvider : <div className='text-center'>-</div>;
      },
      sort: false
    },
    {
      dataField: "serialSize",
      text: "Devices",
      formatter: (cell, row) => {
        return (
          <div className='text-center'>
            <span>{row.serialSize ? row.serialSize : <div className='text-center'>-</div>}</span>
          </div>
        )
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { textAlign: 'center' };
      },
    },
    {
      dataField: "readingCount",
      text: "Reading Counts",
      formatter: (cell, row) => {
        return (
          <div className='text-center'>
            <span>{row.readingCount ? row.readingCount : <div className='text-center'>-</div>}</span>
          </div>
        )
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { textAlign: 'center' };
      },
    },{
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className='div-center'>
            <Link
              to={`/dashboard/${row.fullName.replace(/\s+/g, "-").toLowerCase()}/${row.user._id
                }`}
              className="btn pull-right more-btn ">
              <i class="mdi mdi-account-card-details"></i> More Details
            </Link>
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: '150px', textAlign: 'center' };
      },
      sort: false
    },
  ];
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No device's found :("}
          subTitle={"We can't find any device's found."}
        />
      )}
    </div>
  );
  return (
    <Row className="col-sm-12 main-cls-modal remove-table-padding">
      <Col sm="12" className="mt-12">
        <BootstrapTable
          remote={{
            sort: false,
            pagination: true,
            filter: true,
            search: true
          }
          }
          keyField="_id"
          loading={props.loading}
          data={props.data}
          columns={columns}
          pagination={paginationFactory(options)}
          onTableChange={props.onTableChange}
          noDataIndication={() => <NoDataIndication />}
          bordered
          wrapperClasses="table-responsive"
          expandRow={{
            renderer: (row) => rowComponent(row,props),
            onlyOneExpanding: false,
            showExpandColumn: true,
            expandByColumnOnly: false
          }}
        />
      </Col>
    </Row>
  );
};

function fullname(cell, row) {
  const profile = row.profileImage || userImage;
  // return <UserName user={row} />;
  return (
    <div className={row.isActive ? "" : " text-danger"}>
      <span>
        <img src={profile} className="mr-2" alt="image" />
        {capitalize(row.fullName)}
      </span>
    </div>
  );
}

function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

const rowComponent = (rowData,props) => {  
  return (
    <>
    <div>
      {rowData.serials && rowData.serials.length > 0 && (
        <>        
        <Row style={{ margin: "15px 0px" }}>          
          <Col sm="12" className="mt-2 remove-summary-paddding">
            <Card style={{ border: "0px 10px 10px 0px" }}>
              <CardBody className='padding-card-body'>
                <h4 className='title-for-device-list'>Reading Details :</h4>
                <div className='divider-view'></div>
                <BootstrapTable
                  keyField='_id'
                  data={rowData.serials}
                  columns={[{
                    dataField: 'serial',
                    sort: true,
                    hover: false,
                    bordered: false,
                    text: 'Mac Address',
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    formatter: (cell, row) => {
                      return (
                        <div className='text-left'>
                          <span>{cell.toUpperCase() ? cell.toUpperCase() : "-"}</span>
                        </div>
                      )
                    },
                  }, {
                    dataField: 'device.description', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Device',
                    formatter: (cell, row) => {
                      return (
                        <div className='text-center'>
                          <span>{cell ? cell : "-"}</span>
                        </div>
                      )
                    },
                  },
                  {
                    dataField: 'device.brand', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Device Brand',
                    formatter: (cell, row) => {
                      return (
                        <div className='text-center'>
                          <span>{cell ? cell : "-"}</span>
                        </div>
                      )
                    },
                  },
                  {
                    dataField: 'device.brandModel', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Device Model',
                    formatter: (cell, row) => {
                      return (
                        <div className='text-center'>
                          <span>{cell ? cell : "-"}</span>
                        </div>
                      )
                    },
                  },
                  {
                    dataField: 'device.readingType.description', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Reading Type',
                    formatter: (cell, row) => {
                      var description = row && row.device && row.device.readingType && row.device.readingType ? row.device.readingType.description : "-";
                      var subTypes = row && row.device && row.device.readingType && row.device.readingType ? row.device.readingType.subTypes : "-";
                      var image = row && row.device && row.device.readingType && row.device.readingType ? row.device.readingType.abbrev : undefined;
                      if(image){
                        return (
                          <>
                            <div className="text-left reading-card-for-all-reading">                            
                              <img
                                src={require(`../../files/Reading/${image}.svg`)}
                                alt="profile-image"
                                className="profile rounded-circle img-fluid mr-2 reading-icon-summary"
                              />
                              <div>
                                <h4 class="reading-card-title" style={{ color: "black", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "700" }}>{description}</h4>
                                <h6 class="reading-card-subtitle" style={{ color: "#787878", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "500" }}>{`${subTypes}`}</h6>
                              </div>
                            </div>
                          </>
                        )
                      } else {
                        return (
                          <>
                            <div className="text-left reading-card-for-all-reading">                                                  
                              <div>
                                <h4 class="reading-card-title" style={{ color: "black", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "700" }}>{description}</h4>
                                <h6 class="reading-card-subtitle" style={{ color: "#787878", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "500" }}>{`${subTypes}`}</h6>
                              </div>
                            </div>
                          </>
                        )
                      }
                      
                    },
                  },
                  {
                    dataField: 'row.device._id',
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: "Source",
                    formatter: (cell, row) => {
                      if(row.device._id == "5bc3cb14cba82b066cae7bba" || row.device._id == "658e9fdb0ed4d03cbd8b4c20" || row.device._id == "658ea3400ed4d03cbd8b4c22"
                       || row.device._id == "658ea59f0ed4d03cbd8b4c24" || row.device._id == "658ea6700ed4d03cbd8b4c25" || row.device._id == "658ea6db0ed4d03cbd8b4c26"
                       || row.device._id == "658ea7a20ed4d03cbd8b4c27" || row.device._id == "658ea7c80ed4d03cbd8b4c28" || row.device._id == "658ea7ff0ed4d03cbd8b4c29"){
                        return (
                          <div className='text-center'>
                            <span className='reading-uploaded-type'>Manual Entry</span>
                          </div>
                        )
                      } else {
                        return (
                          <div className='text-center'>
                            <span className='reading-uploaded-type-by-device'>MVV Mobile App</span>
                          </div>
                        )
                      }                      
                    },                    
                  },
                  {
                    dataField: 'count',
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: "Total Reading (By Device)",
                    formatter: (cell, row) => {
                      return (
                        <div className='text-center'>
                          <span style={{fontWeight:500}}>{cell}</span>
                        </div>
                      )
                    },                    
                  }]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        </>
      )}
    </div>
    <div>
      {rowData.device && rowData.device.length > 0 && (
        <>        
        <Row style={{ margin: "15px 0px" }}>          
          <Col sm="12" className="mt-2 remove-summary-paddding">
            <Card style={{ border: "0px 10px 10px 0px" }}>
              <CardBody className='padding-card-body'>
                <h4 className='title-for-device-list'>Assign Devices :</h4>
                <div className='divider-view'></div>
                <BootstrapTable
                  keyField='_id'
                  data={rowData.device}
                  columns={[{
                    dataField: 'lastCategory.title', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Title',
                    formatter: (cell, row) => {
                      return (
                        <div className='text-left'>
                          <span>{row && row.lastCategory ? row.lastCategory.title : "-"}</span>
                        </div>
                      )
                    }
                  }, {
                    dataField: 'device.name', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Device',
                    formatter: (cell, row) => {
                      return (
                        <div className='text-center'>
                          <span>{row.device.name ? row.device.name : "-"}</span>
                        </div>
                      )
                    },
                  },
                  {
                    dataField: 'device.readingType.description', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Reading Type',
                    formatter: (cell, row) => {
                      var description = row && row.device && row.device.readingType && row.device.readingType ? row.device.readingType.description : "-";
                      var subTypes = row && row.device && row.device.readingType && row.device.readingType ? row.device.readingType.subTypes : "-";
                      var image = row && row.device && row.device.readingType && row.device.readingType ? row.device.readingType.abbrev : undefined;
                      if(image){
                        return (
                          <>
                            <div className="text-left reading-card-for-all-reading">                            
                              <img
                                src={require(`../../files/Reading/${image}.svg`)}
                                alt="profile-image"
                                className="profile rounded-circle img-fluid mr-2 reading-icon-summary"
                              />
                              <div>
                                <h4 class="reading-card-title" style={{ color: "black", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "700" }}>{description}</h4>
                                <h6 class="reading-card-subtitle" style={{ color: "#787878", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "500" }}>{`${subTypes}`}</h6>
                              </div>
                            </div>
                          </>
                        )
                      } else {
                        return (
                          <>
                            <div className="text-left reading-card-for-all-reading">                                                  
                              <div>
                                <h4 class="reading-card-title" style={{ color: "black", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "700" }}>{description}</h4>
                                <h6 class="reading-card-subtitle" style={{ color: "#787878", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "500" }}>{`${subTypes}`}</h6>
                              </div>
                            </div>
                          </>
                        )
                      }
                      
                    },
                  },
                  {
                    dataField: 'createdAt', 
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    text: 'Date',
                    formatter: (cell, row) => {
                      return (
                        <div>
                          {row.createdAt && (
                            <div className='text-left'>
                              <span>{moment(row.createdAt).format("MMMM DD, YYYY hh:mm:ss A")}</span>
                            </div>
                          )}
                          {!row.createdAt && (
                            <div className='text-center'>
                              <span> - </span>
                            </div>
                          )}
                        </div>
                      )
                    },
                  },
                  {
                    dataField: 'row.lastCategory.title',
                    text: 'Shipped Status',
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center' };
                    },
                    formatter: (cell, row) => {   
                      var id = row.lastCategory ? row.lastCategory._id : undefined; 
                      return (
                        <div className='text-center'>
                          <>
                          {id == "646208d6b1cebb9c4d5b70ed" && (
                            <div className='text-center callCompleted-device-report'>
                              <span>Call Completed</span>
                            </div>
                          )}
                          {id == "64620ebdb1cebb9c4d5b70f7" && (
                            <div className='text-center device-status-pending'>
                              <span>Device Return Pending</span>
                            </div>
                          )}
                          {id == "64c781b245daedcb3562a35a" && (
                            <div className='text-center device-status-pending'>
                              <span>Device Returned</span>
                            </div>
                          )}
                          
                        </>
                        </div>
                      )
                    },
                  }]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        </>
      )}
    </div>
    </>
  );
};
function getFileExtensionFromUrl(url) {
  const match = url.match(/\.([a-zA-Z0-9]+)$/);
  if (match) {
    return match[1];
  }
  return null;
}
function documentRead(cell,row,index, formatExtraData){
  let url = row.path;
  let extension;
  if(url){
    extension = url.substring(url.lastIndexOf('.') + 1, url.length);
  }
  return (
    <div className="text-center">
      {(row.path && extension != 'jpg' && extension != 'jpeg' && extension != 'png' && extension != 'gif') && (
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            var filename2 = row.path.split(/[\\\/]/).pop();            
            formatExtraData.downloadDocument(row.path,filename2);
          }}
        >
          {extension == 'pdf' ? <i className="mdi mdi-file-pdf pdf-icon-size pdf-color" /> : null}
        </a>
      )}
      {!row.path && (
        <div className="text-center"> - </div>
      )}
    </div>
  );
}
DevicesListDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
export default DevicesListDataTable;