import React, { Component } from 'react';

import { connect } from 'react-redux';
import moment from "moment";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import _ from "lodash";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import AnswerDetailsPrintOld from './AnswerDetailsPrintOld';

class CCMPatientAnswerListOld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldCCMAnswerModal: false,
      selectedModel: undefined,
      expanded: []
    };
    this.toggleOldCcmAnswerModal = this.toggleOldCcmAnswerModal.bind(this);
    this.printAnswers = React.createRef();
  }

  toggleOldCcmAnswerModal() {
    this.setState({
      oldCCMAnswerModal: !this.state.oldCCMAnswerModal
    });
  }

  componentDidMount() {

  }

  fetchData() {
    this.props.dispatch({
      type: "DO_GET_PATIENT_CCM_ANSWERS",
      payload: { userid: this.props.currentUserId }
    });
  }

  formatDate(cell) {
    return moment(cell).format("MMM DD, YYYY hh:mm:ss A");
  }
  toCapitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  NoDataIndication = () => (
    <div>
      {this.props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!this.props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No CCM answer found :("}
          subTitle={"We can't find any ccm answer."}
        />
      )}
    </div>
  );
  handleOnExpand = (row, rowIndex) => {
    const isExpanded = typeof this.state.expanded.find(x => x === row.id) !== 'undefined';
    if (!isExpanded) {
      this.setState(() => ({
        expanded: [...this.state.expanded, row.question._id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== row.question._.id)
      }));
    }
  }
  render() {
    //console.log(this.props.ccmAnswers);
    return (
      <div>
        <Modal
          isOpen={this.props.oldCCMAnswerModal}
          toggle={this.props.toggleOldCcmAnswerModal}
          size="lg"
          className="display-table"
          style={{ display: "table" }}
        >
          <ModalHeader className="close_button" toggle={this.props.toggleOldCcmAnswerModal}>
            <Label className="align-items-center justify-content-center text-center"> CCM Monthly Reports</Label>
            {!this.state.isEditbale && (
              <button
                style={{ float: 'right' }}
                onClick={() => {
                  this.setState({
                    isEditbale: false,
                    expanded: []
                  });
                  this.props.toggleOldCcmAnswerModal();
                }}
                className="btn btn-info ml-2 close-click-cls" >
                <i class="mdi mdi-close" style={{
                  fontSize: 20
                }}></i>
                Close
              </button>
            )}
          </ModalHeader>

          <ModalBody style={{ backgroundColor: "white" }}>
            <Row>
              <Col md={12}>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  keyField="question._id"
                  noDataIndication={() => <this.NoDataIndication />}
                  data={this.props.ccmAnswers ? this.props.ccmAnswers : []}
                  filter={filterFactory()}
                  filterPosition="top"
                  expandRow={{
                    parentClassName: 'expand-selected-style',
                    expanded: this.state.expanded,
                    renderer: (row) => (
                      <>
                        <div>
                          <AnswerDetailsPrintOld
                            ref={el => (this.printAnswers = el)}
                            row={row}
                            profile={this.props.profile}
                            printAnswers={this.printAnswers}
                          />
                        </div>
                      </>
                    ),
                    onlyOneExpanding: false,
                    showExpandColumn: true,
                    expandByColumnOnly: false
                  }}
                  columns={[
                    {
                      dataField: "createdAt",
                      text: "Date",
                      sort: true,
                      formatter: (cell, row) => {
                        return moment(row.createdAt).tz(moment.tz.guess()).format("MMMM DD, YYYY HH:mm:ss");
                      },

                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                      }
                    },
                    {
                      dataField: "createdBy.lname",
                      text: "Created By",
                      sort: true,
                      formatter: (cell, row) => {
                        if (!row.createdBy) {
                          return <div className="text-left">-</div>;
                        }
                        return (
                          <div className="text-left">
                            {this.toCapitalize(row.createdBy.lname)}  {this.toCapitalize(row.createdBy.fname)}
                          </div>
                        );
                      },

                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                      },
                      filter: textFilter({
                        className: 'input-date-filter full-width',
                        placeholder: "Search by last name"
                      }),
                    },
                    {
                      dataField: "",
                      text: "Action",
                      sort: false,
                      headerStyle: (colum, colIndex) => {
                        return { width: '140px', textAlign: 'center', textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                      },
                      formatter: (cell, row, rowIndex, extra) => {
                        return (
                          <>
                            <button
                              className="btn btn-success answer-display"
                              onClick={() => {
                                this.handleOnExpand(row, rowIndex)
                              }}
                            >
                              <i class="mdi mdi-eye answer-display-icon" style={{
                                fontSize: 20,
                              }}></i> <text style={{ color: "#fffff" }}>View</text>
                            </button>
                          </>
                        );
                      },
                    }
                  ]}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    ccmAnswers,
    latestCCMAnswers } = careprovider;
  const userId = user.userDetails._id;
  const profile = user.profile;
  const profileOwner = user.profileOwner ? user.profileOwner : user.userDetails;
  const userDetails = user;
  return {
    isGenericSuccess,
    isGenericError,
    userId,
    profileOwner,
    profile,
    userDetails,
    ccmAnswers,
    latestCCMAnswers
  };
};

export default connect(mapStateToProps)(CCMPatientAnswerListOld);
