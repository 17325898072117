import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
class ClientLogin extends Component {
  constructor(props) {
    super(props);
    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  emailChange(event) {
    this.props.dispatch({
      type: "DO_CHANGE_EMAIL",
      payload: event.target.value
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.dispatch({
      type: "DO_LOGIN_CLIENT",
      payload: { username: this.props.email, password: this.props.password }
    });
  }
  passwordChange(event) {
    this.props.dispatch({
      type: "DO_CHANGE_PASSWORD",
      payload: event.target.value
    });
  }
  componentDidMount() {
    const { persistor } = configureStore();
    persistor.purge();
  }
  render() {
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            <div className="mx-auto" style={{ width: "500px" }}>
              <div className="auth-form-light text-left p-5 login-style">
                <div className="brand-logo mx-auto text-center">
                  <img
                    alt=""
                    src="https://cdn3.iconfinder.com/data/icons/luchesa-vol-9/128/Html-512.png"
                  />
                </div>
                <h4 className="text-center">Login to your Client Access</h4>
                <h6 className="font-weight-light text-center">
                  Sign in to continue.
                </h6>
                <form className="pt-3" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label for="exampleInputUsername1">
                      Email Address or Username
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Email Address or Username"
                      required
                      onChange={this.emailChange}
                      value={this.props.email}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputUsername1">Password</label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      placeholder="Password"
                      required
                      onChange={this.passwordChange}
                      value={this.props.password}
                    />
                  </div>
                  {this.props.errorLogin && (
                    <div className="alert alert-danger text-center">
                      {this.props.errorLogin.message}
                    </div>
                  )}

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                      style={{ color: "white" }}
                    >
                      SIGN IN
                    </button>
                  </div>

                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account?{" "}
                    <Link to="/client/signup" className="text-info">
                      Create
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, password, errorLogin } = auth;

  return { email, password, errorLogin };
};

export default connect(mapStateToProps)(ClientLogin);
