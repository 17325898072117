import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import ReactLoading from "react-loading";
import { useFetch2 } from "components/utils/authenticatedHook";
import { size, map } from "lodash";
const ResetPasswordComponent = ({ modal, resetToggle, userDetails }) => {
  const [password, setpassword] = useState("");
  const [error2, seterror] = useState("");
  const [{ response, error, isLoading }, doFetch, sendError] = useFetch2(null);
  useEffect(() => {
    if (response) {
      resetToggle();
    }

    return () => {};
  }, [response]);
  const submitReset = e => {
    e.preventDefault();
    doFetch({
      url: "/reset-password",
      method: "POST",
      body: JSON.stringify({ password: password, user: userDetails._id })
    });
  };
  return (
    <Modal
      isOpen={modal}
      toggle={resetToggle}
      backdrop="static"
      className="bastaLang modal-md"
    >
      <ModalHeader toggle={resetToggle}>
        CHANGE PASSWORD OF {userDetails.fname} {userDetails.lname}
      </ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <Form onSubmit={submitReset}>
          <FormGroup>
            <Label for="exampleText">New Password</Label>
            <Input
              className="input-date-filter"
              type="password"
              name="password"
              pattern=".{6,}"
              placeholder="New Password"
              required
              title="6 characters minimum"
              onChange={({ target }) => {
                setpassword(target.value);
                seterror(false);
              }}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Confirm New Password</Label>
            <Input
              invalid={error2}
              type="password"
              className="form-control form-control-lg input-date-filter"
              placeholder="Confirm Password"
              name="confirmpassword"
              pattern=".{6,}"
              required
              title="6 characters minimum"
              onChange={({ target }) => {
                if (target.value !== password) {
                  seterror(true);
                } else {
                  seterror(false);
                }
              }}
              rows={10}
            />

            {error2 && <FormFeedback>Password does not match</FormFeedback>}
          </FormGroup>
          {isLoading && (
            <ReactLoading
              type="spin"
              className="text-center mx-auto"
              color="blue"
            />
          )}
          {error && <span className="text text-danger">{error.message}</span>}
          <button type="submit" className="btn btn-block btn-info">
            CHANGE PASSWORD
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ResetPasswordComponent;
