import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import CareProviderReducer from "./CareProviderReducer";
import UserReducer from "./UserReducer";
import ClientReducer from "./ClientReducer";
import { persistReducer } from "redux-persist";
import localforage from "localforage";
import SupportReducer from "./SupportReducer";

const blockPersistConfig = {
  key: "careprovider",
  storage: localforage,
  blacklist: ["timerStarts"]
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  client: ClientReducer,
  careprovider: persistReducer(blockPersistConfig, CareProviderReducer),
  support: SupportReducer
});

export default rootReducer;
