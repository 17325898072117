import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import {
  UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ClientManagement from "./ClientManagement";
import { useFetch2 } from "components/utils/authenticatedHook";
import Swal from "sweetalert2";
const _ = require("lodash");

function fullname(cell, row) {
  const profile =
    row.profileImage || "https://cdn-icons-png.flaticon.com/512/194/194915.png";
  return (
    <div>
      <img src={profile} className="mr-2" alt="image" />
      {row.fname} {row.lname}
    </div>
  );
}

function formatDate(cell, row) {
  return moment(cell).format("MMMM DD, YYYY");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  const authenticatedUser = formatExtraData.userId;
  if (row) {
    const isSameUser = authenticatedUser !== row.receivedBy._id;
    const approvedButton =
      isSameUser && !row.isApproved ? (
        <button
          type="button"
          onClick={() => formatExtraData.approve(row._id)}
          className="btn btn-success btn-fw"
        >
          Accept Request
        </button>
      ) : (
        ""
      );
    return (
      <span>
        {row.isApproved ? (
          <button
            type="button"
            onClick={() => formatExtraData.delete(row._id)}
            className="btn btn-danger btn-fw"
          >
            Delete
          </button>
        ) : (
          approvedButton
        )}
      </span>
    );
  }
}

const defaultSorted = [
  {
    dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
    order: "desc", // desc or asc
  },
];
const status = (cell, row) => {
  const newUser = moment(row.createdAt).isSame(new Date(), "week");
  if (!row.isActive) {
    return <label class="badge badge-gradient-danger">INACTIVE</label>;
  }
  if (newUser) {
    return <label class="badge badge-gradient-info">NEW</label>;
  } else {
    return <label class="badge badge-gradient-success">ACTIVE</label>;
  }
};
const quickActions = (cell, row, rowIndex, formatExtraData) => {
  let sendTo;
  let to = row.phone;
  if (to) {
    if (
      to.includes("+1") ||
      to.includes("+") ||
      to.includes("09") ||
      to.includes("+63")
    ) {
      sendTo = to;
    } else {
      sendTo = "+1" + to;
    }
    sendTo = "tel:" + sendTo.replace(/-/g, "");
  } else {
    sendTo = "javascript:;";
  }

  const fullname = row.fname + " " + row.lname;
  return (
    <div className="btn-group">
      <UncontrolledTooltip
        placement="bottom"
        target={"resetPassword" + row._id}
      >
        Reset Password of {fullname}
      </UncontrolledTooltip>

      <button
        type="button"
        className="btn btn-info reset-password-click"
        onClick={() => formatExtraData(fullname, row._id)}
        id={"resetPassword" + row._id}
      >
        Reset Password
      </button>
    </div>
  );
};
function formatPhone(cell, row) {
  return <p>{cell}</p>;
}
const { SearchBar, ClearSearchButton } = Search;
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value.replace(/[^@.\w\s]/gi, ""));
  };
  return (
    <div>
      <div class="form-group">
        <input
          type="text"
          class="form-control input-date-filter"
          id="exampleInputName1Test"
          style={{ fontSize: "20px" }}
          placeholder="Search Name, Email, etc..."
          ref={(n) => (input = n)}
          onChange={handleClick}
        />
      </div>
    </div>
  );
};
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Row>
      <Col>
        <button className="btn btn-info ml-2 download-click-cls float-left" onClick={handleClick}>
        <i class="mdi mdi-download" style={{
                fontSize: 20
        }}></i> Export to CSV
        </button>
      </Col>
    </Row>
  );
};
const ClientList = (props, context) => {
  const title = props.title ? props.title : "Client";
  const [{ response, error, isLoading }, doFetch, sendError] = useFetch2(null);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  function diactivate(cell, row) {
    if (row.isActive) {
      return (
        <button
          type="button"
          className="btn btn-info ml-2 inactive-note-click"
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You want to deactivate this client; if so, this client portal's all patients, staff, and care providers are deactivated!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, DeActivate it!",
            }).then((result) => {
              //console.log(row);
              if (result.isConfirmed) {   
                props.reloadApi();            
                doFetch({
                  url: "/client-action/" + row._id + "?enable=false",
                  method: "PUT",
                });
              }
            });
          }}
        >
          <i class="mdi mdi-account-remove" style={{color:"white"}}></i> DeActivate
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-info ml-2 active-note-click"
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You want to activate this client; if so, this client portal's all patients, staff, and care providers are activated!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Activate it!",
            }).then((result) => {
              //console.log(row);
              if (result.isConfirmed) {
                props.reloadApi();
                doFetch({
                  url: "/client-action/" + row._id + "?enable=true",
                  method: "PUT",
                });
              }
            });
          }}
        >
          <i class="mdi mdi-account-check"></i> Activate
        </button>
      );
    }
  }
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: props.usersGivenAccessMyReading.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const expandRow = {
    renderer: (row) => (
      <div className="text-center">
        This portion is under maintenance, you can use the api for now and
        follow Temporary instructions Temporary Instructions:
        <p className="text-center">
          Refer to documentation for CLIENT API{" "}
          <a href="https://dev.portal.myvitalview.com/api-docs/" target="_blank">
            here
          </a>
        </p>
        <div>
          <b>CLIENT-ID:</b> {row._id}
        </div>
        <div>
          <b>CLIENT-TOKEN:</b> {row.accessToken}
        </div>
        <div>
          {" "}
          As how you use your own api, its the same as for your subclients
        </div>
        <ClientManagement
          organization={row.organization}
          clientId={row._id}
          title={props.title}
        ></ClientManagement>
      </div>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
  };
  return (
    <ToolkitProvider
      keyField="_id"
      data={props.usersGivenAccessMyReading}
      columns={[
        {
          dataField: "organization",
          text: title + " Organization",
          sort: true,
          // headerStyle: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // },
          // style: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // },
          formatter: (cell, row) => {
            return (
              <div>
                {row.websiteLogo && (
                  <img
                    src={row.websiteLogo}
                    className="img-thumbnail"
                    alt="image"
                  ></img>
                )}

                <span className="text-center"> {cell}</span>
                <span className="text-center">
                  {" "}
                  {row.isSubClient ? " (Subclient)" : ""}
                </span>
              </div>
            );
          },
        },
        {
          dataField: "email",
          text: "Email Address",
          sort: true,
          // headerStyle: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // },
          // style: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // }
        },
        {
          dataField: "username",
          text: "Username",
          sort: true,
        },

        {
          dataField: "phone",
          text: "Phone",
          sort: true
        },
        {
          dataField: "country",
          text: "Country",
          sort: true,
          // headerStyle: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // },
          // style: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // }
        },
        {
          dataField: "region",
          text: "Region",
          sort: true,
          // headerStyle: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // },
          // style: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // }
        },
        {
          dataField: "usage",
          text: "Usage",
          sort: true,
          // headerStyle: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // },
          // style: {
          //   minWidth: '150px' // Set your desired width  // Shrey Added
          // }
        },
        {
          dataField: "",
          text: "Action",
          formatter: diactivate,          
          sort: false,
          csvExport: false
        }
      ]}
      search 
      exportCSV={{ fileName: "Clients.csv" }}
    >
      {toolKitProp => (
        <div className="row">{/*Shrey Added */}
          <div className="col-xl-12 col-lg-10 col-sm-8 col-12">{/*Shrey Added */}
            <Row className={`text-center mx-auto mt-3`}>{/*Shrey Added */}
              <Col md={10}>
                <MySearch {...toolKitProp.searchProps} />
              </Col>
              <Col md={2}>
                <MyExportCSV {...toolKitProp.csvProps}>
                  Export CSV
                </MyExportCSV>
              </Col>
            </Row>
          </div>
          <div className="scrollbar-table-cls w-100">{/*Shrey Added */}
          <BootstrapTable
            wrapperClasses="table-responsive"
            {...toolKitProp.baseProps}
            noDataIndication="No Data Found"
            defaultSorted={defaultSorted}
            //expandRow={expandRow}
            pagination={paginationFactory(options)}
          />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default ClientList;
