import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";

import { QuestionIcon } from "./ruleIcon";
import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";

const ToggleModel = (props) => (
  <Modal
    isOpen={props.modal}
    toggle={props.toggleModal}
    className="bastaLang modal-md"
  >
    <Form onSubmit={props.onSubmitOffer}>
      <ModalHeader toggle={props.toggleModal}>Select Type</ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <h5 className="text-center" />
        <div className="row">
          <div className="col-4 button-icon-center">
            <NavLink
              tag={Link}
              to="/create/question"
              className="text-center d-block"
            >
              <img src={QuestionIcon} style={{ height: "70px" }} />
              <span className="menu-title font-weight-bold d-block text-dark" style={{padding:5}}>
                Questionnaire
              </span>
            </NavLink>
          </div>
        </div>
      </ModalBody>
    </Form>
  </Modal>
);

export default ToggleModel;
