import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
class ExtendLink extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    let url;
    let clientInfo;
    if (this.props.clientInfo && !this.props.clientInfo.error) {
      clientInfo = this.props.clientInfo;
    }
    if (clientInfo && !this.props.clientRegister) {
      const currentPath = this.props.match.path;
      const countSplit = currentPath.split("/");
      url =
        countSplit.length > 2 ? this.props.to : clientInfo.slug + this.props.to;
      return (
        <Link
          className={this.props.className}
          target={this.props.target}
          to={{
            pathname: "/" + clientInfo.slug + this.props.to
          }}
        >
          {this.props.children}
        </Link>
      );
    } else {
      return <Link {...this.props} />;
    }
  }
}
const mapStateToProps = ({ auth, user }) => {
  const { clientInfo, clientRegister } = user;
  return { clientInfo, clientRegister };
};

export default withRouter(connect(mapStateToProps)(ExtendLink));
