import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import Link from "components/ExtendLink";
import Avatar from "react-avatar";
import staffImage from "../../../files/nurse.png";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
const _ = require("lodash");
function fullname(cell, row) {
  return (
    <div className={row.user && row.user.isActive ? "" : " text-danger"}>
      <span>
        <img src={staffImage} className="mr-2" alt="image" />
        {capitalize(row.lname)} {capitalize(row.fname)}
      </span>
    </div>
  );
}
function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}
function isApproved(cell, row) {
  return (
    <span>
      {cell ? (
        <label className="badge badge-gradient-success">ACCOUNT CREATED</label>
      ) : (
        <label className="badge badge-warning">PENDING INVITATION</label>
      )}
    </span>
  );
}
function formatDate(cell, row) {
  return moment(row.receivedBy.bday).format("MMMM DD, YYYY");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      {!row.isCreated && (
        <button
          type="button"
          onClick={() => formatExtraData.delete(row._id)}
          className="btn btn-danger btn-fw"
        >
          Delete Invitation
        </button>
      )}
    </div>
  );
}
const rowComponent = (
  row,
  users,
  expectations,
  userInputChange,
  expectationInputChange
) => (
  <Row>
    <Col>
      <Card body>
        <CardTitle className="text-center">Pick Patient to Assign</CardTitle>
        <Form>
          <FormGroup>
            <Row>
              {users.map((object, i) => {
                const isUserFound = _.includes(row.assignedUsers, object._id);
                return (
                  <Col xs="4">
                    <CustomInput
                      type="checkbox"
                      defaultChecked={isUserFound}
                      id={object._id}
                      name={object._id}
                      onChange={(e) => userInputChange(e, row._id)}
                      label={object.fname + " " + object.lname}
                    />
                  </Col>
                );
              })}
            </Row>
          </FormGroup>
        </Form>
      </Card>
    </Col>
    <Col>
      <Card body>
        <CardTitle className="text-center">
          Pick Expectation to Assign
        </CardTitle>
        <Form>
          <FormGroup>
            <div>
              {expectations && expectations.map((object, i) => {
                const expectationFound = _.includes(
                  row.assignedExpectationsReference,
                  object._id
                );
                return (
                  <CustomInput
                    type="checkbox"
                    defaultChecked={expectationFound}
                    id={object._id}
                    value={object._id}
                    name={object._id}
                    onChange={(e) => expectationInputChange(e, row._id, object)}
                    label={object.name}
                  />
                );
              })}
            </div>
          </FormGroup>
        </Form>
      </Card>
    </Col>
  </Row>
);
const StaffList = (props, context) => (
  <div className="w-100 overflow-auto">{/*Shrey Added */}
  <BootstrapTable
    wrapperClasses="table-responsive"
    keyField="_id"
    noDataIndication="No Data Found"
    data={props.staff}
    expandRow={{
      renderer: (row) =>
        rowComponent(
          row,
          props.usersOfferedAccess,
          props.expectations,
          props.userInputChange,
          props.expectationInputChange
        ),

      showExpandColumn: true,
    }}
    filter={filterFactory()}
    columns={[
      {
        dataField: "fname",
        text: "Name",
        formatter: fullname,
        sort: true,
        filter: textFilter(),
        filterValue: (cell, row) => {
          return row.fname + row.lname + row.organization;
        },
        headerFormatter: priceFormatter,
      },
      {
        dataField: "user.nickname",
        text: "Nickname",
        sort: true,
        formatter: (cell, row) => {
          return (row.user ? ( row.user.nickname ? row.user.nickname : "-" ) :"-");
        },
        filter: textFilter(),
        filterValue: (cell, row) => {
          return (row.user ? ( row.user.nickname ? row.user.nickname : "-" ) :"-");
        },
        headerFormatter: priceFormatter,   
        headerStyle: {
          minWidth: '150px' // Set your desired width  // Shrey Added
        },
        style: {
          minWidth: '150px' // Set your desired width  // Shrey Added
        }       
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        filter: textFilter(),
        headerFormatter: priceFormatter,
        headerStyle: {
          minWidth: '150px' // Set your desired width  // Shrey Added
        },
        style: {
          minWidth: '150px' // Set your desired width  // Shrey Added
        }
      },
      {
        dataField: "isCreated",
        text: "Status",
        formatter: isApproved,
        headerFormatter: priceFormatter,
      },
      {
        dataField: "action",
        text: "Action",
        formatter: showButtons,
        headerFormatter: priceFormatter,
        formatExtraData: {
          delete: props.deleteInvitation,
        },
        headerStyle: {
          minWidth: '150px' // Set your desired width  // Shrey Added
        },
        style: {
          minWidth: '150px' // Set your desired width  // Shrey Added
        }
      },
    ]}
  />
  </div>
);

export default StaffList;
