const moment = require("moment-timezone");
export const QuestionBody = {
  name: "",
  message: "",
  messageCareprovider: "",
  readingType: "",
  readingSubType: "",
  readingSubTypeId: "",
  condition: "",
  notifySelf: [],
  notifyReadingOwner: [],
  notifyStaff: [],
  sharedUsers: [],
  conditionGroup: [],
  valueProperty: [],
  timeDuration: "",
  startDate: "",
  endDate:"",
  timezone: moment.tz.guess(),
};
