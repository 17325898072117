import React, { Component } from "react";
import Link from "components/ExtendLink";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
import logoImage from "../../files/smarthealth.jpg";
import backgroundImg from "../../files/ic_optimizer_background.jpg";
import ReactLoading from "react-loading";
import LottieLoader from '../LottieLoader';
import lottieError from '../../files/animation/ic_error.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import LottieLoaderForRound from "../LottieLoaderForRound";
import CCMForm from "./CCMForm";
class SubmissionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: undefined
    }
  }

  componentWillUnmount() {

  }

  componentDidMount() {    
    const { userId } = this.props.match.params;
    //console.log("USER IF :" + userId);
    this.setState({
      userId: userId
    });
    if (userId) {
      this.setState({
        userId: userId
      }, () => {
        this.fetchData();
      });
    }
  }

  /**
   * fetched the form submission details and display the submisison viewed for the mobile application!
   */
  fetchData() {
    this.props.dispatch({
      type: "DO_PATIENT_CCM_SUBMISSION",
      payload: {
        userId: this.state.userId
      }
    });
  }
  render() {
    //console.log("SUBMISSION DETAILS : " + this.props.ccmSubmission);
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div
          className="content-wrapper d-flex align-items-center auth"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundImage: `url(${backgroundImg})`
          }}>
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            {this.props.ongoingSubmissionRequest && !this.props.ccmSubmission && !this.props.submissionError && (
              <div className="mx-auto submission-loading-div">
                <LottieLoaderForRound
                  loadLoop={true}
                  autoplayLoop={true}
                  source={lottieLoaderFile}
                  Lheight={60}
                  Lwidth={60}
                  titleSmall={"Please wait..."}
                  extraSubTitleExtraSmall={"Fetching details from the server."}
                />
              </div>
            )}
            {this.props.ccmSubmission && (
              <div className="mx-auto mobile-view" >
                <CCMForm
                  isEditable={false}
                  latestSubmission={this.props.ccmSubmission.submission}
                  profile={this.props.profileOwner}
                  submisionModel={this.props.ccmSubmission}
                />
              </div>
            )}
            {this.props.submissionError && !this.props.ccmSubmission && (
              <>
              <div className="mx-auto submission-loading-div">
                <LottieLoaderForRound
                  loadLoop={false}
                  autoplayLoop={false}
                  source={lottieError}
                  Lheight={60}
                  Lwidth={60}
                  titleSmall={this.props.submissionErrorTitle}
                  extraSubTitleExtraSmall={this.props.submissionError}
                />
              </div>              
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ careprovider }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    ccmSubmission,
    ongoingSubmissionRequest,
    submissionError,
    submissionErrorTitle
  } = careprovider;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    ccmSubmission,
    ongoingSubmissionRequest,
    submissionError,
    submissionErrorTitle
  };
};

export default connect(mapStateToProps)(SubmissionView);
