import React, { Component } from "react";
import {
    Col,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import { connect } from 'react-redux';
import ReactToPrint from "react-to-print";
import QuestionForm from "./QuestionForm";
import AnswerDetailsPrint from "./AnswerDetailsPrint";
const _ = require("lodash");

class ViewCCMAnswerDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditbale: false,
        };
        this.printRef = React.createRef();
        this.saveRef = React.createRef();
        this.saveCCMDetails = this.saveCCMDetails.bind(this);
    }

    saveCCMDetails(data) {
        try {
            const selectedModel = this.props.selectedModel;
            if (data && selectedModel) {
                this.setState({
                    isEditbale: false
                })
                const patient = this.props.profile._id;
                const createdBy = this.props.profileOwner._id;
                this.props.dispatch({
                    type: "DO_SAVE_PATIENT_CCMANSWERS",
                    payload: { answers: data, patient: patient, createdBy: createdBy, session: selectedModel.session }
                });
                setTimeout(() => {
                    this.props.dispatch({
                        type: "DO_GET_PATIENT_CCM_ANSWERS",
                        payload: { userid: patient }
                    });
                }, 1000);
                this.props.toggleCcmAnswersModal();
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        document.title = "CCM Question And Answers Report";  
        const selectedModel = this.props.selectedModel;
        let mAnswersList = [];
        if (selectedModel) {
            _.map(selectedModel.submissions, model => {
                mAnswersList[model.question] = model.answer;
                mAnswersList["note-" + model.question] = model.note;
            });
        }
        return (
            <Modal
                isOpen={this.props.ccmAnswersModal}
                toggle={this.props.toggleCcmAnswersModal}
                size="lg"
                className="display-table"
                style={{ display: "table" }}
            >
                <ModalHeader className="close_button" toggle={this.props.toggleCcmAnswersModal}>
                    <Label className="align-items-center justify-content-center text-center"> View CCM Question And Answers</Label>
                    {!this.state.isEditbale && (
                        <button
                            style={{ float: 'right' }}
                            onClick={() => {
                                this.setState({
                                    isEditbale: false
                                });
                                this.props.toggleCcmAnswersModal();
                            }}
                            className="btn btn-info ml-2 close-click-cls" >
                            <i class="mdi mdi-close" style={{
                                fontSize: 20
                            }}></i>
                            Close
                        </button>
                    )}
                    {this.state.isEditbale && (
                        <button
                            style={{ float: 'right' }}
                            onClick={() => {
                                this.setState({
                                    isEditbale: false
                                });
                            }}
                            className="btn btn-info ml-2 close-click-cls" >
                            <i class="mdi mdi-close" style={{
                                fontSize: 20
                            }}></i>
                            Edit View Close
                        </button>
                    )}
                    {this.state.isEditbale && (
                        <button
                            name="submit"
                            style={{ float: 'right' }}
                            onClick={() => {
                                this.saveRef.current.click();
                            }}
                            className="btn btn-info ml-2 print-click-cls" >
                            <i class="mdi mdi-content-save" style={{
                                fontSize: 20
                            }}></i>
                            Save
                        </button>
                    )}
                    {!this.state.isEditbale && (
                        <button
                            name="submit"
                            style={{ float: 'right' }}
                            onClick={() => {
                                this.setState({
                                    isEditbale: true
                                })
                            }}
                            className="btn btn-info ml-2 edit-ccm-form-click-cls" >
                            <i class="mdi mdi-account-edit" style={{
                                fontSize: 20
                            }}></i>
                            Edit
                        </button>
                    )}
                    {!this.state.isEditbale && (
                        <ReactToPrint
                            trigger={() => <button className="btn btn-info ml-2 print-click-cls" style={{ float: 'right' }}>
                                <i class="mdi mdi-cloud-print" style={{
                                    fontSize: 20
                                }}></i> Print</button>}
                            content={() => this.printRef.current}
                        />
                    )}

                </ModalHeader>

                <ModalBody style={{ backgroundColor: "white" }}>
                    <Row>
                        <Col md={12}>
                            <QuestionForm
                                ref={{
                                    saveRef: this.saveRef
                                }}
                                isEditable={this.state.isEditbale}
                                saveCCMDetails={this.saveCCMDetails}
                                mQuestionsList={this.props.mQuestionsList}
                                selectedModel={this.props.selectedModel}
                                mAnswersList={mAnswersList}
                                profile={this.props.profileOwner}
                            />
                            <div style={{ display: "none" }}>
                                <AnswerDetailsPrint
                                    ref={{
                                        printRef: this.printRef
                                    }}
                                    selectedModel={this.props.selectedModel}
                                    profile={this.props.profile}
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
    const {
        isGenericSuccess,
        isGenericError,
        genericErrorMessage
    } = careprovider;
    const profile = user.profile;
    const profileOwner = user.profileOwner ? user.profileOwner : user.userDetails;
    return {
        isGenericSuccess,
        isGenericError,
        genericErrorMessage,
        profile,
        profileOwner
    };
};

export default connect(mapStateToProps)(ViewCCMAnswerDetails);