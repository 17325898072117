import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PropTypes from "prop-types";
import Link from "components/ExtendLink";
import React from "react";
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import userImage from "../../files/user.png";
import LottieLoader from "../LottieLoader";
import lottieEmptyFile from "../../files/animation/empty_new.json";
import lottieLoaderFile from "../../files/animation/loading.json";
import lottiePending from "../../files/animation/ic_pending.json";
import lottieCompleted from "../../files/animation/ic_fax_send_completed.json";
import ic_warning from "../../files/animation/ic_warning.json";
const _ = require("lodash");

const FaxReportListDataTable = (props) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.totalSize) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: props.totalSize,
      },
    ],
  };
  const columns = [
    {
      dataField: "recepientFullNamefl",
      text: "Last, First Name",
      formatter: fullname,
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      sort: true
    },{
      dataField: "recepientNickname",
      text: "Nickname",
      formatter: (cell, row) => {
        return row.recepientNickname ? (
          row.recepientNickname
        ) : (
          <div className="text-center">-</div>
        );
      },
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      sort: true
    },{
      dataField: "recepientPhone",
      text: "Phone",
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      formatter: (cell, row) => {
        return row.recepientPhone ? (
          row.recepientPhone
        ) : (
          <div className="text-center">-</div>
        );
      },
      sort: true,
    },
    {
      dataField: "recepientMainCareProvider",
      text: "Main Care Provider",
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      formatter: (cell, row) => {
        return row.recepientMainCareProvider ? (
          row.recepientMainCareProvider
        ) : (
          <div className="text-center">-</div>
        );
      },
      sort: true,
    },
    {
      dataField: "recepientOrganization",
      text: "Organization",
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      formatter: (cell, row) => {
        return row.recepientOrganization ? (
          row.recepientOrganization
        ) : (
          <div className="text-center">-</div>
        );
      },
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="div-center">
            <Link
              to={`/dashboard/${row.recepient.fname
                .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase()}-${row.recepient.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row.recepient._id
                }`}
              className="btn pull-right more-btn ">
              <i class="mdi mdi-account-card-details"></i> More Details
            </Link>
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
      sort: false,
    },
  ];
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No fax found found :("}
          subTitle={"We can't find any fax found."}
        />
      )}
    </div>
  );
  return (
    <Row className="col-sm-12 main-cls-modal remove-table-padding">
      <div className="mt-12 w-100">{/*Shrey Added */}
        <div className="scrollbar-table-cls">
        <BootstrapTable
          remote={{
            sort: false,
            pagination: true,
            filter: true,
            search: true
          }
          }
          keyField="_id"
          loading={props.loading}
          data={props.data}
          columns={columns}
          pagination={paginationFactory(options)}
          onTableChange={props.onTableChange}
          noDataIndication={() => <NoDataIndication />}
          bordered
          wrapperClasses="table-responsive"
          expandRow={{
            renderer: (row) => rowComponent(row,props),
            onlyOneExpanding: false,
            showExpandColumn: true,
            expandByColumnOnly: false
          }}
        />
        </div>
      </div>
    </Row>
  );
};

function fullname(cell, row) {
  const profile = row.profileImage || userImage;
  // return <UserName user={row} />;
  return (
    <div className={row.isActive ? "" : " text-danger"}>
      <span>
        <img src={profile} className="mr-2" alt="image" />
        {capitalize(row.recepientFullNamefl)}
      </span>
    </div>
  );
}
function fullnameByGenerated(cell, row) {
  if (row.generatedBy) {
    const profile = row.generatedBy.profileImage || userImage;
    return (
      <div className={row.generatedBy.isActive ? "" : " text-danger"}>
        <span>
          <img src={profile} className="mr-2" alt="image" />
          {capitalize(row.generatedBy.lname)}{" "}
          {capitalize(row.generatedBy.fname)}
        </span>
      </div>
    );
  } else {
    return (
      <div className="text-center">
        {" "}
        <span>-</span>
      </div>
    );
  }
}

function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

const rowComponent = (rowData, props) => {
  const orderCategory = _.orderBy(rowData.faxReports, ["createdAt"], ["desc"]);
  return (
    <div>
      {orderCategory && orderCategory.length > 0 && (
        <Row style={{ margin: "15px 0px" }}>
          <Col sm="12" className="mt-2 remove-summary-paddding">
            <Card style={{ border: "0px 10px 10px 0px" }}>
              <CardBody className="padding-card-body">
                <BootstrapTable
                  keyField="_id"
                  data={orderCategory}
                  columns={[
                    {
                      dataField: 'action',
                      text: 'Type',
                      formatter: (cell, row) => {
                        const type = cell == "PMSession" ? "Attestation Report" : "CCM Care Plan Report";
                        return (
                          <div>
                            {row && (
                              <div className='text-center'>
                                <span>{type}</span>
                              </div>
                            )}
                          </div>
                        )
                      },
                      sort: true
                    },{
                      dataField: 'generatedBy.lname',
                      text: 'Fax Initiated By',
                      formatter: fullnameByGenerated,
                      sort: true,
                    },
                    {
                      dataField: "createdAt",
                      sort: true,
                      hover: false,
                      bordered: false,
                      text: "Fax Report Date",
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      formatter: (cell, row) => {
                        return (
                          <div>
                            {row && (
                              <div className="text-center">
                                <span>
                                  {moment(row.createdAt).format(
                                    "MMMM DD, YYYY hh:mm:ss A"
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "termNumber",
                      sort: true,
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      text: "TO (Fax Number)",
                      formatter: (cell, row) => {
                        if (_.isEmpty(cell)) {
                          return (
                            <div className="text-center">
                              <span>-</span>
                            </div>
                          );
                        } else {
                          return (
                            <div className="text-center">
                              <span>{cell}</span>
                            </div>
                          );
                        }
                      },
                    },
                    {
                      dataField: "origNumber",
                      sort: true,
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      text: "FROM (Fax Number)",
                      formatter: (cell, row) => {
                        return (
                          <div className="text-center">
                            <span>{cell}</span>
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "reportEndDate",
                      text: "Reported Month",
                      sort: true,
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      formatter: (cell, row) => {
                        return (
                          <div>
                            {row && (
                              <div className="text-center">
                                <span>
                                  {moment(row.reportEndDate).format("MMMM")}
                                </span>
                              </div>
                            )}
                            {!row.createdAt && (
                              <div className="text-center">
                                <span> - </span>
                              </div>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "reportEndDate",
                      text: "Reported Year",
                      sort: true,
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      formatter: (cell, row) => {
                        return (
                          <div>
                            {row && (
                              <div className="text-center">
                                <span>
                                  {moment(row.reportEndDate).format("YYYY")}
                                </span>
                              </div>
                            )}
                            {!row.createdAt && (
                              <div className="text-center">
                                <span> - </span>
                              </div>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "pageCount",
                      sort: true,
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      text: "Fax Report Page",
                      formatter: (cell, row) => {
                        return (
                          <div className="text-center">
                            <span>{cell}</span>
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "path",
                      text: "Fax Report File",
                      formatter: documentRead,
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      formatExtraData: {
                        downloadDocument: props.downloadDocument,
                      },
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      headerStyle: (colum, colIndex) => {
                        return { textAlign: "center" };
                      },
                      formatter: (cell, row) => {
                        console.log(row);
                        return (
                          <>
                            {row.isError && (
                              <div className="text-center">
                                <LottieLoader
                                  loadLoop={false}
                                  autoplayLoop={true}
                                  source={ic_warning}
                                  Lheight={55}
                                  Lwidth={55}
                                  subTitleExtraSmall={row.errorMessage}
                                />
                              </div>
                            )}
                            {cell && cell === "Completed" && row.isSent && (
                              <div className="text-center">
                                <LottieLoader
                                  loadLoop={false}
                                  autoplayLoop={true}
                                  source={lottieCompleted}
                                  Lheight={55}
                                  Lwidth={55}
                                  subTitleExtraSmall={"Fax Successful"}
                                />
                              </div>
                            )}
                            {cell &&
                              cell === "Submitted" &&
                              !row.isSent &&
                              !row.isError && (
                                <div className="text-center">
                                  <LottieLoader
                                    loadLoop={false}
                                    autoplayLoop={true}
                                    source={lottiePending}
                                    Lheight={55}
                                    Lwidth={55}
                                    subTitleExtraSmall={"Fax Sending..."}
                                  />
                                </div>
                              )}
                          </>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
function getFileExtensionFromUrl(url) {
  const match = url.match(/\.([a-zA-Z0-9]+)$/);
  if (match) {
    return match[1];
  }
  return null;
}
function documentRead(cell, row, index, formatExtraData) {
  let url = row.path;
  let extension;
  if (url) {
    extension = url.substring(url.lastIndexOf(".") + 1, url.length);
  }
  return (
    <div className="text-center">
      {row.path &&
        extension != "jpg" &&
        extension != "jpeg" &&
        extension != "png" &&
        extension != "gif" && (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              var filename2 = row.path.split(/[\\\/]/).pop();
              formatExtraData.downloadDocument(row.path, filename2);
            }}
          >
            {extension == "pdf" ? (
              <i className="mdi mdi-file-pdf pdf-icon-size pdf-color" />
            ) : null}
          </a>
        )}
      {!row.path && <div className="text-center"> - </div>}
    </div>
  );
}
FaxReportListDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default FaxReportListDataTable;
