import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { map, orderBy, groupBy, size, keyBy } from "lodash";
import moment from "moment";
import LineGraph from "./LineGraph";
export const DexcomGlucoseData = ({ dexcomData, startDate, endDate }) => {
  var egvs = null;
  try {
    egvs = JSON.parse(dexcomData).egvs;
  } catch (exception) {
    egvs = null;
  }

  if (!dexcomData || size(dexcomData) === 0 || !egvs) {
    return (
      <Col sm="6" className="mt-4">
        <Card style={{ backgroundColor: "#fffff" }}>
          <CardBody>
            <h4 className="text-center">DEXCOM GLUCOSE</h4>
            <div className="text-center mb-2"></div>
            {/* <div className="text-center">
              <a
                target="_blank"
                className="text-white ml-auto btn btn-sm btn-success"
              >
                Show More Details
              </a>
            </div> */}
            <div className="text-center">NO RECORDS</div>
            <div />
          </CardBody>
        </Card>
      </Col>
    );
  }

  const unitValue = JSON.parse(dexcomData).unit;
  const egvOrder = orderBy(egvs, ["systemTime"]);
  const allDates = map(egvOrder, "systemTime");
  let groupDataSet = [];
  let color = "green";

    const systemTime = moment(egvOrder["systemTime"]).format();

    let mapData = map(egvOrder, v => {
      const {
        value,
        systemTime
      } = v;
      return {
        y: value || 0,
        eventDescription: `GLUCOSE`
      };
    });
    groupDataSet.push({
      dataSet: [
        {
          label: `${unitValue}`,
          fill: false,
          pointRadius: 4,
          pointHoverRadius: 4,
          backgroundColor: color,
          pointRadius: 5,
          pointHoverRadius: 5,
          borderColor: color,
          pointBackgroundColor: color,
          data: mapData
        }
      ],
      allDates: allDates
    });
  return (
    <Col sm="6" className="mt-4">
      <Card style={{ backgroundColor: "#fffff" }}>
        <CardBody>
          <h4 className="text-center">DEXCOM GLUCOSE</h4>
          <div className="text-center mb-2"></div>
          {/*<div className="text-center">
            <a
              target="_blank"
              className="text-white ml-auto btn btn-sm btn-success"
            >
              Show More Details
            </a>
          </div>*/}
          {map(groupDataSet, v => {
            // const arrayTextLabel = v.eventType === "health" ? healthArray : [];
            // const showTextLabel = v.eventType === "health" ? true : false;
            return (
              <LineGraph
                readingDate={v.allDates}
                readingDataSet={v.dataSet}
                hidePrint="d-none"
                // yAxisTextLabel={showTextLabel}
                // textArray={arrayTextLabel}
                dontRender="true"
                title={`(${moment(startDate)
                  .clone()
                  .format("MMMM DD, YYYY")} - ${moment(endDate)
                  .subtract(1, "d")
                  .format("MMMM DD, YYYY")})`}
              />
            );
          })}

          <div />
        </CardBody>
      </Card>
    </Col>
  );
};
