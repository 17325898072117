import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import PickCPM from "./PickCPM";
import { matchPath, withRouter } from "react-router-dom";
import moment from "moment";
import startSound from "../../../files/startsound.mp3";
import stopSound from "../../../files/stopsound.mp3";
import _ from "lodash";
import PopupApp from "./PopupApp";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import pdf from "../../../files/Privacy Policy.pdf";
class Layout extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.startPmMode = this.startPmMode.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.stopPmMode = this.stopPmMode.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.requestVideo = this.requestVideo.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      time: 0,
      start: 0,
      startDate: null,
      endDate: null,
      isOn: false,
      mode: "",
      userDashboard: null,
      requestVideo: false,
      modalAgreement: false,
      openSidebar: false,
      windowSize: null,
    };

    this.toggle = this.toggle.bind(this);
    this.toggleAgreement = this.toggleAgreement.bind(this);
    this.countRPM = this.countRPM.bind(this);
    this.countCCM = this.countCCM.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
  }
  toggleAgreement() {
    this.setState((prevState) => ({
      modalAgreement: !prevState.modalAgreement,
    }));
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  logout() {
    this.props.dispatch({
      type: "DO_LOGOUT",
    });
  }
  startPmMode() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  closeModal() {
    this.props.dispatch({
      type: "JUST_REGISTERED",
      payload: {
        isRegister: false,
      },
    });
  }
  openSidebar() {
    this.setState({ openSidebar: !this.state.openSidebar });
  }
  requestFullScreen() {
    var element = document.body;
    // Supports most browsers and their versions.
    var requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
    }
  }

  requestVideo() {
    let rootURL;
    if (process.env.NODE_ENV === "development") {
      rootURL = "http://localhost:3000";
    } else {
      rootURL = "https://dev.portal.myvitalview.com";
    }
    let match;
    if (this.props.location.pathname.indexOf("refer") > -1) {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/refer-population/dashboard/:name/:id/:start/:end",
        exact: true,
        strict: false,
      });
    } else {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/dashboard/:name/:id",
        exact: true,
        strict: false,
      });
    }
    const patient = btoa(match.params.id);
    const videoToken = btoa(this.props.userDetails._id);
    window.open(
      rootURL + "/video?patient=" + patient + "&video=" + videoToken,
      "VideoCall",
      "height=850,width=650"
    );
  }
  stopPmMode() {
    const { receivedFrom } = this.state.userDashboard;
    const careProvider = this.props.userDetails._id;
    const mode = this.state.mode;
    const dateNow = Date.now();
    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
    this.props.dispatch({
      type: "DO_SAVE_TIMER",
      payload: {
        careProvider: careProvider,
        patient: receivedFrom._id,
        time: this.state.time,
        startTime: this.state.start,
        pmMode: this.state.mode,
        endTime: dateNow,
      },
    });
    // this.audioStop = new Audio(stopSound);
    // this.audioStop.play();
    this.setState({ isOn: false, time: 0, start: 0 });
    clearInterval(this.timer);
  }
  countCCM() {
    this.props.dispatch({
      type: "START_TIMER",
      payload: true,
    });
    // this.audio = new Audio(startSound);
    // this.audio.play();

    let match;
    if (this.props.location.pathname.indexOf("refer") > -1) {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/refer-population/dashboard/:name/:id/:start/:end",
        exact: true,
        strict: false,
      });
    } else {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/dashboard/:name/:id",
        exact: true,
        strict: false,
      });
    }
    const userAccess = this.props.usersOfferedAccess;
    const userStat = _.find(userAccess, {
      receivedFrom: { _id: match.params.id },
    });
    this.setState({
      modal: !this.state.modal,
      userDashboard: userStat,
    });
    const dateNow = moment.utc().format();
    this.setState({
      time: this.state.time,
      start: Date.now(),
      isOn: true,
      mode: "CCM MODE",
    });
    this.timer = setInterval(
      () =>
        this.setState({
          time: moment.utc(Date.now() - this.state.start).format("HH:mm:ss"),
        }),
      1
    );
  }
  countRPM() {
    this.props.dispatch({
      type: "START_TIMER",
      payload: true,
    });
    // this.audio = new Audio(startSound);
    // this.audio.play();
    let match;
    if (this.props.location.pathname.indexOf("refer") > -1) {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/refer-population/dashboard/:name/:id/:start/:end",
        exact: true,
        strict: false,
      });
    } else {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/dashboard/:name/:id",
        exact: true,
        strict: false,
      });
    }
    const userAccess = this.props.usersOfferedAccess;
    const userStat = _.find(userAccess, {
      receivedFrom: { _id: match.params.id },
    });
    this.setState({
      modal: !this.state.modal,
      userDashboard: userStat,
    });

    const dateNow = moment.utc().format();
    this.setState({
      time: this.state.time,
      start: Date.now(),
      isOn: true,
      mode: "RPM MODE",
    });
    this.timer = setInterval(
      () =>
        this.setState({
          time: moment.utc(Date.now() - this.state.start).format("HH:mm:ss"),
        }),
      1
    );
  }
  updateWindowDimensions() {
    const innerWidth = window.innerWidth;
    this.setState({ windowSize: innerWidth });
  }

  componentDidMount() {
    const innerWidth = window.innerWidth;
    this.updateWindowDimensions();
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT",
    });
    if (innerWidth < "1199") {
      this.setState({ openSidebar: true });
    }
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    if (this.props.userDetails.firstLogin) {
      this.setState({ modalAgreement: true });
    }
  }

  render() {
    if (this.state.isOn && !this.props.timerStarts) {
      this.stopPmMode();
    }
    const isOpenSidebar = this.state.openSidebar ? "main-hidden" : "";
    return (
      <div className="container-scroller">
        <Navbar
          startPmMode={this.startPmMode}
          stopPmMode={this.stopPmMode}
          timerStarts={this.state.isOn}
          requestVideo={this.requestVideo}
          user={this.props.userDetails}
          clientInfo={this.props.clientInfo}
          logout={this.logout}
          openSidebar={this.openSidebar}
          requestFullScreen={this.requestFullScreen}
          unreadCount={this.props.unReadCount}
        />
        <div
          className={`container-fluid page-body-wrapper menu-sub-hidden ${isOpenSidebar}`}
        >
          <Sidebar
            user={this.props.userDetails}
            clientInfo={this.props.clientInfo}
            isOpenSidebar={this.state.openSidebar}
            windowSize={this.state.windowSize}
            openSidebar={this.openSidebar}
          />

          <div className="main-panel">
            {this.props.authenticated ? (
              <div
                className="content-wrapper"
                style={{ background: "#f2edf3" }}
              >
                {this.props.children}
              </div>
            ) : (
              <div className="content-wrapper">{this.props.children}</div>
            )}
          </div>
        </div>
        <Footer
          time={this.state.time}
          user={this.state.userDashboard}
          mode={this.state.mode}
        />
        <PickCPM
          countCCM={this.countCCM}
          countRPM={this.countRPM}
          modal={this.state.modal}
          toggleModal={this.toggleModal}
        />
        {!this.props.userDetails.isCareProvider && (
          <PopupApp
            isRegister={this.props.isRegister}
            closeModal={this.closeModal}
          />
        )}
        {/* <Modal
          isOpen={this.state.modalAgreement}
          size="lg"
          toggle={this.logout}
          backdrop="static"
          className={this.props.className}
        >
          <ModalHeader toggle={this.logout} charCode="">
            Terms and Conditions
          </ModalHeader>
          <ModalBody>
            <embed src={pdf} frameBorder={0} width="100%" height="500px" />
          </ModalBody>
          <ModalFooter className="mx-auto">
            <Button color="info" onClick={this.toggleAgreement}>
              Agree
            </Button>{" "}
            <Button color="black" onClick={this.logout}>
              Logout
            </Button>
          </ModalFooter>
        </Modal> */}
      </div>
    );
  }
}

const mapStateToProps = ({ user, careprovider, auth }) => {
  const { usersOfferedAccess, timerStarts, unReadCount } = careprovider;
  const { authenticated, userDetails, isRegister, clientInfo } = user;
  return {
    authenticated,
    userDetails,
    isRegister,
    usersOfferedAccess,
    timerStarts,
    clientInfo,
    unReadCount,
  };
};
export default withRouter(connect(mapStateToProps)(Layout));
