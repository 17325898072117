import React, { useEffect, useMemo, useState } from "react";
import "./../../css/css/pdf.css";
import { useForm } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";
import isNoSignature from "../../files/ic_no_signature.svg";
import ErrorDisplay from "../Reusable/ErrorDisplay";
import SignatureWithName from "../Reusable/SignatureWithName";
const _ = require("lodash");

const QuestionForm = React.forwardRef((props, ref) => {    
    const { isEditable } = props;
    const { register, getValues, handleSubmit, errors, reset } = useForm({
        defaultValues: useMemo(() => {
            return props.mAnswersList;
        }, [props])
    });

    const registerOptions = {
        dateValidationRequired: {
            required: "This field is required!",
        },
        dateValidationOptional: {
            required: false,
        },
        requiredField: {
            required: "This field is required!",
            pattern: {
                value: /^\s*(\S(.*\S)?)\s*$/,
                message: "Must be value without any white space!"
            },
            minLength: {
                value: 2,
                message: "Must include at least 2 characters!"
            }
        },
        optional: {
            required: false,
            minLength: {
                value: 2,
                message: "Must include at least 2 characters!"
            },
            pattern: {
                value: /^\s*(\S(.*\S)?)\s*$/,
                message: "Must be value without any white space!"
            },
        }
    };
    useEffect(() => {
        reset(props.mAnswersList);
    }, [props.mAnswersList]);
    /**
     * Submit form details
     * @param {*} data 
     */
    const onSubmit = (data) => {
        props.saveCCMDetails(data)
    };

    function getStype() {
        let names = [];
        if (isEditable) names.push('input-box-style-editbale-muliti-line', 'remove-padding');
        if (!isEditable) names.push('input-box-style-editbale-muliti-line', 'remove-padding','input-box-style-editbale-muliti-line-disable-radio');
        return names.join(' ');
    }
    function getStypeForRadio() {
        let names = [];
        if (isEditable) names.push('radio-label', 'label-center');
        if (!isEditable) names.push('radio-label', 'label-center', 'input-box-style-editbale-disable-radio');
        return names.join(' ');
    }
    function getLogsCss() {
        let names = [];
        if (!props.saveCCMDetails) names.push('logs-view');
        names.push('App')
        return names.join(' ');
    }
    var signatureLink = undefined;
    var name = "";
    if (props.selectedModel) {
        const signatureImage = props.selectedModel.lastModifyBy ? props.selectedModel.lastModifyBy.signatureImage : props.selectedModel.createdBy ? props.selectedModel.createdBy.signatureImage : undefined;
        name = props.selectedModel.lastModifyBy ? props.selectedModel.lastModifyBy.lname + " " + props.selectedModel.lastModifyBy.fname : props.selectedModel.createdBy ? props.selectedModel.createdBy.lname + " " + props.selectedModel.createdBy.fname : undefined;
        signatureLink = signatureImage ? signatureImage : undefined;
        if (signatureLink && !signatureLink.indexOf("http") == 0) {
            signatureLink = "/" + signatureLink
        }
    } else {
        const signatureImage = props.profile.signatureImage ? props.profile.signatureImage : undefined;
        name = props.profile.lname + " " + props.profile.fname;
        signatureLink = signatureImage ? signatureImage : undefined;
        if (signatureLink && !signatureLink.indexOf("http") == 0) {
            signatureLink = "/" + signatureLink
        }
    }    
    return (
        <>
            <div className={getLogsCss()}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <button ref={ref ? ref.saveRef : undefined} type="submit" style={{ display: "none" }}>Save</button>
                    <div className="row">
                        {Object.keys(props.mQuestionsList).map((key, index) => (
                            <div className="col-md-4 div-remove-padding">
                                <div className="div-border">
                                    <h5>
                                        {props.mQuestionsList[key].question}
                                    </h5>
                                    <div className="mb-2">
                                        <div style={{ display: "flex", alignItems: "center", gap: "45px" }}>
                                        <label className={getStypeForRadio()}>
                                                <input
                                                    type="radio"
                                                    className={getStypeForRadio()}
                                                    label="Met"
                                                    value="Met"
                                                    style={{ width: "40px", height: "25px" }}
                                                    name={`${props.mQuestionsList[key]._id}`}
                                                    ref={register(registerOptions.requiredField)}></input>
                                                Met</label>
                                        <label className={getStypeForRadio()}>
                                            <input
                                                type="radio"
                                                className={getStypeForRadio()}
                                                label="Not Met"
                                                style={{ width: "40px", height: "25px" }}
                                                value="Not Met"
                                                name={`${props.mQuestionsList[key]._id}`}
                                                ref={register(registerOptions.requiredField)}></input>
                                                Not Met</label>
                                        <label className={getStypeForRadio()}>
                                            <input
                                                type="radio"
                                                className={getStypeForRadio()}
                                                label="N/A"
                                                style={{ width: "40px", height: "25px" }}
                                                value="N/A"
                                                name={`${props.mQuestionsList[key]._id}`}
                                                ref={register(registerOptions.requiredField)}></input>
                                                N/A</label>
                                        </div>
                                        {errors[`${props.mQuestionsList[key]._id}`]?.message && (
                                            <ErrorDisplay message={errors[`${props.mQuestionsList[key]._id}`]?.message} />
                                        )}
                                    </div>
                                    <div style={{ marginTop: 5 }}>
                                        <label className="note-text" for="exampleText">Additional Notes:</label>
                                        <textarea
                                            type="textarea"
                                            rows={2}
                                            className={getStype()}                                            
                                            name={`note-${props.mQuestionsList[key]._id}`}
                                            ref={register(registerOptions.optional)}
                                        />                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                        <SignatureWithName
                            signature={signatureLink}
                            isBorder={true}
                            name={name}
                            isCCMQuestion={true}
                        />
                    </div>
                </form>
            </div>
        </>
    );
});

export default QuestionForm;