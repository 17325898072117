import { ErrorMessage } from "formik";
import React from "react";
import { useFieldArray } from "react-hook-form";
import ErrorDisplay from "../Reusable/ErrorDisplay";
import { Col, Row } from "reactstrap";
let renderCount = 0;

export default function EmailInput({ control, register, errors, isEditable, props }) {
  const { fields: fieldEmails, append: emailAppend, remove: emailRemove } = useFieldArray({
    control,
    name: "emailIds"
  });

  const { fields: fieldFaxs, append: faxAppend, remove: faxRemove } = useFieldArray({
    control,
    name: "faxNumbers"
  });

  renderCount++;

  function getStype() {
    let names = [];
    if (isEditable) names.push('input-box-style-editbale');
    if (!isEditable) names.push('disabled', 'input-box-style-editbale-disable');
    return names.join(' ');
  }

  const registerOptions = {
    emailRequired: {
      required: "This field is required!",
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: "Invalid email!"
      },
      minLength: {
        value: 2,
        message: "Must include at least 2 characters!"
      }
    },
    faxFieldLimit: {
      required: "This field is required!",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid fax number! Must be a number!"
      },
      minLength: {
        value: 6,
        message: "Must be min 6 and max 12 number"
      },
      maxLength: {
        value: 12,
        message: "Must be min 6 and max 12 number"
      }
    },
  };

  return (
    <>
      <Row className="col-12 remove-table-padding">
        <Col md="6" className="remove-table-padding">
          <div className="div-width-full">
            <div className="header-device-list delete-condition-view">
              <h6 className="text-center title-device display-align-justify-center" style={{ margin: 0 }}>
                <i class="mdi mdi-email" style={{ fontSize: 20 }}></i> Email Information
              </h6>
              <div>
                {isEditable && (
                  <button
                    style={{ float: 'right' }}
                    onClick={() => emailAppend({ name: "emailAppend" })}
                    className="btn btn-info ml-2 add-email-input float-right" >
                    <i class="mdi mdi-plus" style={{
                      fontSize: 20
                    }}></i>
                    Add Email
                  </button>
                )}
              </div>
            </div>
            {fieldEmails && fieldEmails.length > 0 && fieldEmails.map((item, index) => {
              return (
                <>
                  <div style={{ overflowX: "auto" }} key={item.id}>
                    <div>
                      <table class="table table-bordered table-responsive remove-margin">
                        <tbody>
                          <tr>
                            <td scope="row" style={{ width: "85%", padding: "5px" }}>
                              <div>
                                <input
                                  className={getStype()}
                                  style={{ height: 40 }}
                                  defaultValue={props.notifyReadingMonthlyReport && props.notifyReadingMonthlyReport.emailIds && props.notifyReadingMonthlyReport.emailIds[index] && props.notifyReadingMonthlyReport.emailIds[index].email ? props.notifyReadingMonthlyReport.emailIds[index].email : ""}
                                  name={`emailIds.${index}.email`}
                                  ref={register(registerOptions.emailRequired)}
                                />
                                {errors?.emailIds?.[index] && errors?.emailIds?.[index].email && errors?.emailIds?.[index].email.message && (
                                  <ErrorDisplay message={errors?.emailIds?.[index].email?.message} />
                                )}
                              </div>
                            </td>
                            {isEditable && (
                              <td scope="row" style={{ width: "15%", fontWeight: "700" }}>
                                <button
                                  style={{ float: 'right' }}
                                  onClick={() => emailRemove(index)}
                                  className="btn btn-info ml-2 delete-condtion-click-cls float-right" >
                                  <i class="mdi mdi-close" style={{
                                    fontSize: 20
                                  }}></i>
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              );
            })}
            {!fieldEmails && (
              <p>NO Email Details Found</p>
            )}
          </div>
        </Col>
        <Col md="6" className="remove-table-padding justify-content-end ">
          <div className="div-width-full">
            <div className="header-device-list delete-condition-view">
              <h6 className="text-center title-device display-align-justify-center" style={{ margin: 0 }}>
                <i class="mdi mdi-fax" style={{ fontSize: 20 }}></i> Fax Information
              </h6>
              <div>
                {isEditable && (
                  <button
                    style={{ float: 'right', }}
                    onClick={() => faxAppend({ name: "faxAppend" })}
                    className="btn btn-info ml-2 add-email-input float-right" >
                    <i class="mdi mdi-plus" style={{
                      fontSize: 20
                    }}></i>
                    Add Fax
                  </button>
                )}
              </div>
            </div>
            {fieldFaxs.map((item, index) => {
              return (
                <>
                  <div style={{ overflowX: "auto" }} key={item.id}>
                    <div>
                      <table class="table table-bordered table-responsive remove-margin">
                        <tbody>
                          <tr>
                            <td scope="row" style={{ width: "85%", padding: "5px" }}>
                              <div>
                                <input
                                  className={getStype()}
                                  style={{ height: 40 }}
                                  defaultValue={props.notifyReadingMonthlyReport && props.notifyReadingMonthlyReport.faxNumbers && props.notifyReadingMonthlyReport.faxNumbers[index] && props.notifyReadingMonthlyReport.faxNumbers[index].faxNumber ? props.notifyReadingMonthlyReport.faxNumbers[index].faxNumber : ""}
                                  name={`faxNumbers.${index}.faxNumber`}
                                  ref={register(registerOptions.faxFieldLimit)}
                                />
                                {errors?.faxNumbers?.[index] && errors?.faxNumbers?.[index].faxNumber && errors?.faxNumbers?.[index].faxNumber.message && (
                                  <ErrorDisplay message={errors?.faxNumbers?.[index].faxNumber?.message} />
                                )}
                              </div>
                            </td>
                            {isEditable && (
                              <td scope="row" style={{ width: "15%", fontWeight: "700" }}>
                                <button
                                  style={{ float: 'right' }}
                                  onClick={() => faxRemove(index)}
                                  className="btn btn-info ml-2 delete-condtion-click-cls float-right" >
                                  <i class="mdi mdi-close" style={{
                                    fontSize: 20
                                  }}></i>
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
}
