import React, { Component } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { connect } from 'react-redux';
import CCMForm from "./CCMForm";
import ReactToPrint from "react-to-print";
import FaxInput from "./FaxInput";
import Swal from 'sweetalert2';
import moment from "moment";
class ViewCCMDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isEditbale: false,
      isOpenFaxModel: false
    };
    this.printRef = React.createRef();
    this.scrollRef = React.createRef()
    this.saveCCMDetails = this.saveCCMDetails.bind(this);
    this.openFaxModel = this.openFaxModel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (data) => {
    if(data && data.faxNumber){      
      const patient = this.props.profile._id;
      const updatedBy = this.props.profileOwner._id;
      const submisionId = this.props.submisionModel._id;
      this.props.dispatch({
        type: "DO_SENT_CCM_CARE_PLAN_FAX_REPORT",
        payload: {
          faxNumber: data.faxNumber,
          patient: patient,
          updatedBy: updatedBy,
          submisionId: submisionId,
          tz: moment.tz.guess()
        }
      });
      Swal.fire({
        title: "Fax Report!",
        text: "Fax report was successfully sent to the entered fax number!",
        icon: "success"
      });
    }
    this.openFaxModel();
  };

  openFaxModel() {
    this.setState({
      isOpenFaxModel: !this.state.isOpenFaxModel,
    });
  }
  saveCCMDetails(data) {
    if (data) {
      setTimeout(() => {
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 500)

      const patient = this.props.profile._id;
      const createdBy = this.props.profileOwner._id;
      this.props.dispatch({
        type: "DO_SAVE_CCM_FOMR_IINFORMATION",
        payload: {
          patient: patient,
          createdBy: createdBy,
          submission: data,
          tz: moment.tz.guess()
        }
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PATIENT_CCM_SUBMISSION",
          payload: { userid: patient }
        });
      }, 1000);
      this.setState({
        isEditbale: false
      });
    }
  }

  render() {
    document.title = "CCM Care Plan Form";
    return (
      <>
        <Modal
          isOpen={this.props.modelViewCCMFormDetails}
          toggle={this.props.toggleViewCCMFormDetails}
          size="lg"
          className="display-table"
          style={{ display: "table" }}
        >
          <ModalHeader className="close_button" toggle={this.props.toggleViewCCMFormDetails}>
            <Label className="align-items-center justify-content-center text-center"> View CCM Care Plan</Label>
            {!this.state.isEditbale && (
              <button
                style={{ float: 'right' }}
                onClick={() => {
                  this.setState({
                    isEditbale: false
                  });
                  this.props.toggleViewCCMFormDetails();
                }}
                className="btn btn-info ml-2 close-click-cls" >
                <i class="mdi mdi-close" style={{
                  fontSize: 20
                }}></i>
                Close
              </button>
            )}
            {this.state.isEditbale && (
              <button
                style={{ float: 'right' }}
                onClick={() => {
                  this.setState({
                    isEditbale: false
                  });
                  this.props.toggleViewCCMFormDetails();
                }}
                className="btn btn-info ml-2 close-click-cls" >
                <i class="mdi mdi-close" style={{
                  fontSize: 20
                }}></i>
                Close
              </button>
            )}
            {!this.state.isEditbale && (
              <button
                name="submit"
                style={{ float: 'right' }}
                onClick={() => {
                  this.setState({
                    isEditbale: true
                  })
                }}
                className="btn btn-info ml-2 edit-ccm-form-click-cls" >
                <i class="mdi mdi-account-edit" style={{
                  fontSize: 20
                }}></i>
                Edit
              </button>
            )}
            {!this.state.isEditbale && (
              <ReactToPrint
                trigger={() => <button className="btn btn-info ml-2 print-click-cls" style={{ float: 'right' }}>
                  <i class="mdi mdi-cloud-print" style={{
                    fontSize: 20
                  }}></i> Print</button>}
                content={() => this.printRef.current}
              />
            )}
            {!this.state.isEditbale && (
              <button
                name="submit"
                style={{ float: 'right' }}
                onClick={() => {
                  this.openFaxModel();
                }}
                className="btn btn-info ml-2 fax-ccm-form-click-cls" >
                <i class="mdi mdi-fax" style={{
                  fontSize: 20
                }}></i>
                Fax Report
              </button>
            )}
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <Row>
              <Col md={12}>
                <CCMForm
                  ref={{
                    print: this.printRef,
                    scrollRef: this.scrollRef
                  }}
                  isEditable={this.state.isEditbale}
                  saveCCMDetails={this.saveCCMDetails}
                  latestSubmission={this.props.latestSubmission}
                  submisionModel={this.props.submisionModel}
                  profile={this.props.profileOwner}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.isOpenFaxModel}
          toggle={this.openFaxModel}
          className={this.props.className}
        >
          <ModalHeader className="close_button" toggle={this.openFaxModel}>
            <Label className="align-items-center justify-content-center text-center">CCM Care Plan Fax Report</Label>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                this.openFaxModel();
              }}
              className="btn btn-info ml-2 close-click-cls" >
              <i class="mdi mdi-close" style={{
                fontSize: 20
              }}></i>
              Close
            </button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <FaxInput
            onSubmit={this.onSubmit}
            />
          </ModalBody>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage
  } = careprovider;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage
  };
};

export default connect(mapStateToProps)(ViewCCMDetails);
