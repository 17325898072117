import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import PickCPM from "./PickCPM";
import { matchPath, withRouter } from "react-router-dom";
import moment from "moment";
import startSound from "../../files/startsound.mp3";
import stopSound from "../../files/stopsound.mp3";
import _ from "lodash";
import PopupApp from "./PopupApp";
import SignatureCanvas from "react-signature-canvas";

import qs from "qs";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Label,
} from "reactstrap";
import pdf from "../../files/Privacy Policy And Hipaa Business Associate Agreement.pdf";
import Tutorials from "./Tutorials";
import PmModeCommentModal from "./PmModeCommentModal";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch({
      type: "DO_GET_USERS_LIST",
    });

    this.logout = this.logout.bind(this);
    this.startPmMode = this.startPmMode.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.stopPmMode = this.stopPmMode.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closePmModeCommentModal = this.closePmModeCommentModal.bind(this);
    this.requestVideo = this.requestVideo.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      time: 0,
      start: 0,
      startDate: null,
      endDate: null,
      isOn: false,
      mode: "",
      userDashboard: null,
      requestVideo: false,
      modalAgreement: false,
      openSidebar: false,
      windowSize: null,
      currentPatient: [],
      urlParam: window.location.search,
      openPmModeCommentModal: false,
      unreadCount: "",
    };
    this.sigCanvas = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.toggleAgreement = this.toggleAgreement.bind(this);
    this.countRPM = this.countRPM.bind(this);
    this.countCCM = this.countCCM.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
  }
  toggleAgreement() {
    if (!this.sigCanvas.current.isEmpty()) {
      this.setState((prevState) => ({
        modalAgreement: !prevState.modalAgreement,
      }));
      this.props.dispatch({
        type: "DO_AGGREMENT_APPROVED",
        payload: {
          userId: this.props.userDetails._id,
          Signature: this.sigCanvas.current.getSignaturePad().toDataURL("image/png")
        }
      });
    } else {
      alert("Please read the agreement and sign your digital signature.");
    }
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  logout() {
    this.props.dispatch({
      type: "DO_LOGOUT",
    });
  }
  startPmMode() {
    localStorage.setItem("stopIdleTimerNow", "yes");
    this.setState({
      modal: !this.state.modal,
    });
    this.props.pauseChannel.postMessage("yes");
  }
  closeModal() {
    this.props.dispatch({
      type: "JUST_REGISTERED",
      payload: {
        isRegister: false,
      },
    });
  }
  closePmModeCommentModal() {
    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
    // this.audioStop = new Audio(stopSound);
    // this.audioStop.play();
    this.setState({ isOn: false, time: 0, start: 0 });
    clearInterval(this.timer);
    localStorage.setItem("stopIdleTimerNow", "no");
    this.props.pauseChannel.postMessage("no");
    this.setState({ openPmModeCommentModal: false });
  }
  openSidebar() {
    this.setState({ openSidebar: !this.state.openSidebar });
  }
  requestFullScreen() {
    var element = document.body;
    // Supports most browsers and their versions.
    var requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
    }
  }

  requestVideo() {
    let rootURL;
    if (process.env.NODE_ENV === "development") {
      rootURL = "http://localhost:3000";
    } else {
      rootURL = "https://dev.portal.myvitalview.com";
    }
    let match;
    if (this.props.location.pathname.indexOf("refer") > -1) {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/refer-population/dashboard/:name/:id/:start/:end",
        exact: true,
        strict: false,
      });
    } else {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/dashboard/:name/:id",
        exact: true,
        strict: false,
      });
    }
    const patient = btoa(match.params.id);
    const videoToken = btoa(this.props.userDetails._id);
    window.open(
      rootURL + "/video?patient=" + patient + "&video=" + videoToken,
      "VideoCall",
      "height=850,width=650"
    );
  }
  stopPmMode() {
    // const { receivedFrom } = this.state.userDashboard;
    const dateNow = Date.now();
    this.setState({
      openPmModeCommentModal: true,
      endDate: undefined,
      time: this.state.time,
      start: this.state.start,
    });
    //  open={this.state.openPmModeCommentModal}
    //         time={this.state.time}
    //         startTime={this.state.start}
    //         pmMode={this.state.mode}
    //         endTime={this.state.dateAtTimerStop}
    //         careprovider={this.state.userDetails?._id}
    //         patient={this.state.userDashboard?._id}
    //         dispatch={this.props.dispatch}
  }

  countCCM() {
    this.props.dispatch({
      type: "START_TIMER",
      payload: true,
    });
    // this.audio = new Audio(startSound);
    // this.audio.play();

    let match;
    if (this.props.location.pathname.indexOf("refer") > -1) {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/refer-population/dashboard/:name/:id/:start/:end",
        exact: true,
        strict: false,
      });
    } else {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/dashboard/:name/:id",
        exact: true,
        strict: false,
      });
    }
    const userAccess = this.props.assignedUsersList; //this.props.usersOfferedAccess;
    const userStat = _.find(userAccess, {
      // receivedFrom: { _id: match.params.id }
      _id: match.params.id,
    });
    this.setState({
      modal: !this.state.modal,
      userDashboard: userStat,
    });

    const dateNow = moment.utc().format();
    this.setState({
      time: this.state.time,
      start: Date.now(),
      isOn: true,
      mode: "CCM MODE",
    });
    this.timer = setInterval(
      () =>
        this.setState({
          time: moment.utc(Date.now() - this.state.start).format("HH:mm:ss"),
        }),
      1
    );
  }
  countRPM() {
    this.props.dispatch({
      type: "START_TIMER",
      payload: true,
    });
    // this.audio = new Audio(startSound);
    // this.audio.play();
    let match;
    if (this.props.location.pathname.indexOf("refer") > -1) {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/refer-population/dashboard/:name/:id/:start/:end",
        exact: true,
        strict: false,
      });
    } else {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/dashboard/:name/:id",
        exact: true,
        strict: false,
      });
    }
    const userAccess = this.props.assignedUsersList; //this.props.usersOfferedAccess;
    const userStat = _.find(userAccess, {
      // receivedFrom: { _id: match.params.id }
      _id: match.params.id,
    });
    this.setState({
      modal: !this.state.modal,
      userDashboard: userStat,
    });

    const dateNow = moment.utc().format();
    this.setState({
      time: this.state.time,
      start: Date.now(),
      isOn: true,
      mode: "RPM MODE",
    });
    this.timer = setInterval(
      () =>
        this.setState({
          time: moment.utc(Date.now() - this.state.start).format("HH:mm:ss"),
        }),
      1
    );
  }
  updateWindowDimensions() {
    const innerWidth = window.innerWidth;
    this.setState({ windowSize: innerWidth });
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT",
    });
    const urlParams = this.state.urlParam.replace("?", "");
    const convertObject = qs.parse(urlParams);
    const { redirect } = convertObject || [];
    if (redirect) {
      setTimeout(() => {
        this.props.history.push(redirect);
      }, 1000);
    }
    this.updateWindowDimensions();
    const innerWidth = window.innerWidth;

    if (innerWidth < "1199") {
      this.setState({ openSidebar: true });
    }
    // window.addEventListener("resize", this.updateWindowDimensions.bind(this)); Causes performance issues so this is disabled
    if (
      !this.props.userDetails.isAgreedAggrement ||
      !this.props.userDetails.isAgreedPrivacyPolicy
    ) {
      this.setState({ modalAgreement: true });
      localStorage.setItem("done", true);
    }
  }

  render() {
    const title = this.props.clientInfo
      ? this.props.clientInfo.websiteName || "MYVITALVIEW"
      : "MYVITALVIEW";
    document.title = title;
    if (this.state.isOn && !this.props.timerStarts) {
      this.stopPmMode();
    }
    const isOpenSidebar = this.state.openSidebar ? "main-hidden" : "";

    let match;
    if (this.props.location.pathname.indexOf("refer") > -1) {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/refer-population/dashboard/:name/:id/:start/:end",
        exact: true,
        strict: false,
      });
    } else {
      match = matchPath(this.props.location.pathname, {
        path: "/:client?/dashboard/:name/:id",
        exact: true,
        strict: false,
      });
    }
    let currentPatient = [];
    if (match && match.params) {
      const { client, id, name } = match.params;
      const userAccess = this.props.assignedUsersList; //this.props.usersOfferedAccess;
      const userStat = _.find(userAccess, {
        _id: id,
      });
      // const { receivedFrom = [] } = userStat;
      currentPatient = [userStat];
    }
    // if(this.props.userDetails){
    //   currentPatient = [this.props.userDetails];
    // }
    return (
      <div className="container-scroller">
        <Navbar
          time={this.state.time}
          mode={this.state.mode}
          startPmMode={this.startPmMode}
          stopPmMode={this.stopPmMode}
          timerStarts={this.state.isOn}
          requestVideo={this.requestVideo}
          user={this.props.userDetails}
          clientInfo={this.props.clientInfo}
          logout={this.logout}
          openSidebar={this.openSidebar}
          requestFullScreen={this.requestFullScreen}
          unreadCount={this.props.unReadCount}
        />
        <div
          className={`container-fluid page-body-wrapper menu-sub-hidden ${isOpenSidebar}`}
        >
          <Sidebar
            user={this.props.userDetails}
            clientInfo={this.props.clientInfo}
            isOpenSidebar={this.state.openSidebar}
            windowSize={this.state.windowSize}
            openSidebar={this.openSidebar}
          />

          <div className="main-panel">
            {this.props.authenticated ? (
              <div
                className="content-wrapper"
                style={{ background: "#f2edf3" }}
              >
                {this.props.children}
              </div>
            ) : (
              <div className="content-wrapper">{this.props.children}</div>
            )}
          </div>
        </div>
        {currentPatient.length > 0 && (
          <PickCPM
            countCCM={this.countCCM}
            countRPM={this.countRPM}
            modal={this.state.modal}
            patient={currentPatient}
            careprovider={this.props.userDetails._id}
            toggleModal={this.toggleModal}
          />
        )}
        {!this.props.userDetails.isCareProvider && (
          <PopupApp
            isRegister={this.props.isRegister}
            closeModal={this.closeModal}
          />
        )}
        <Modal
          isOpen={this.state.modalAgreement}
          size="lg"
          toggle={this.logout}
          backdrop="static"
          className={this.props.className}
        >
          <ModalHeader toggle={this.logout} charCode="">
            <Label className="align-items-center justify-content-center text-center model-title-size">
              Terms and Conditions & Hipaa Business Associate Agreement
            </Label>
          </ModalHeader>
          <ModalBody className="modal-body-remove-padding">
            <embed src={pdf} frameBorder={0} width="100%" height="500px" />
            <div>
              <Row>
                <Col md="8">
                  <div style={{ paddingLeft: 19, paddingTop: "2%" }}>
                    <p>
                      <span>
                        I have carefully reviewed and understand the terms and
                        conditions outlined in the aforementioned documents. I
                        hereby give my consent for the collection, use, and
                        processing of my personal information in accordance with
                        the terms and conditions of the Privacy Policy. I am
                        aware that my continued use of{" "}
                        <strong>MyVitalvView's</strong> services is subject to
                        my acceptance of these terms.
                      </span>
                    </p>
                    <p>
                      <span>
                        If you accept, please locate a digital signature pad and
                        sign on the right side, confirming{" "}
                        <strong>MyVitalview</strong> agreement.
                      </span>
                    </p>
                    <p
                      className="remove-padding-margin"
                      style={{ fontWeight: 600 }}
                    >
                      <span>
                        I appreciate your attention to this agreement. If you
                        need any further information or documents regarding this
                        agreement, please contact us at{" "}
                        <strong>info@myvitalview.com</strong>.
                      </span>
                    </p>
                    <p>
                      <span>
                        Thank you for your precious time for reading this.
                      </span>
                    </p>
                    <p className="remove-padding-margin">
                      <span>Sincerely,</span>
                      <br />
                      <span>
                        <strong>MyVitalView Team</strong>
                      </span>
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="signature-div-style">
                    <SignatureCanvas
                      ref={this.sigCanvas}
                      penColor="black"
                      canvasProps={{ width: 350, height: 150 }}
                    />
                    <code>
                      <hr className="header-line-style" />
                      <button
                        className="clear-signature"
                        onClick={() => this.sigCanvas.current.clear()}
                      >
                        Clear
                      </button>
                    </code>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className="mx-auto">
            <Button
              className="aggre-click-cls"
              color="info"
              onClick={this.toggleAgreement}
            >
              <i
                class="mdi mdi-checkbox-marked"
                style={{
                  fontSize: 20,
                  olor: "white",
                }}
              ></i>{" "}
              Agree
            </Button>{" "}
            <Button
              color="black"
              className="logout-click-cls"
              onClick={this.logout}
            >
              <i
                class="mdi mdi-exit-to-app"
                style={{
                  fontSize: 20,
                  color: "white",
                }}
              ></i>{" "}
              Logout
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.openPmModeCommentModal && (
          <PmModeCommentModal
            open={this.state.openPmModeCommentModal}
            time={this.state.time}
            startTime={this.state.start}
            pmMode={this.state.mode}
            endTime={this.state.endDate}
            careProvider={this.props.userDetails?._id}
            patient={this.state.userDashboard?._id}
            dispatch={this.props.dispatch}
            closePmModeCommentModal={this.closePmModeCommentModal}
          />
        )}
        <Tutorials
          location={this.props.location}
          user={this.props.userDetails}
        ></Tutorials>
      </div>
    );
  }
}

const mapStateToProps = ({ user, careprovider, auth }) => {
  const { usersOfferedAccess, timerStarts, assignedUsersList, unReadCount } =
    careprovider;
  const { authenticated, userDetails, isRegister, clientInfo } = user;
  return {
    authenticated,
    userDetails,
    isRegister,
    usersOfferedAccess,
    assignedUsersList,
    timerStarts,
    clientInfo,
    unReadCount,
  };
};
export default withRouter(connect(mapStateToProps)(Layout));
