import {
  SUBMIT_OFFER_FAIL,
  SUBMIT_OFFER_SUCCESS,
  REQUEST_ACCESS_SUCCESS,
  REQUEST_ACCESS_FAIL,
  GET_CAREPROVIDERS,
  LOGOUT_USER,
  ONGOING_REQUEST,
  GET_CAREPROVIDER_PATIENTS,
  GET_CAREPROVIDER_STAFF,
  GET_STAFF_CLIENT,
  GET_NO_READINGS,
  GET_NEW_PATIENT,
  GET_CLIENTS,
  GET_AUTENTICATE_FAX_PLUS,
  ONGOING_REQUEST_DEVICE
} from "../actions/types";
import subType from "./readingSubType.json";

import readingType from "./readingType.json";
const INITIAL_STATE = {
  submitOfferFail: false,
  requestAccessFail: false,

  genericErrorMessage: "",
  isGenericError: false,
  isGenericSuccess: false,

  ongoingRequest: false,
  careproviders: [],
  careproviderPatients: [],
  careproviderStaff: [],
  clientStaff: [],
  noReadings: null,
  clientList: null
};

const RESET_STATE = {
  submitOfferFail: false,
  requestAccessFail: false,

  genericErrorMessage: "",
  isGenericError: false,
  isGenericSuccess: false,

  ongoingRequest: false,
  careproviders: [],
  careproviderPatients: [],
  careproviderStaff: [],
  clientStaff: [],
  noReadings: null,
  clientList: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...state,
        ...RESET_STATE
      };
    case SUBMIT_OFFER_SUCCESS:
      return {
        ...state,
        isGenericError: false,
        isGenericSuccess: action.payload.isGenericSuccess,
        genericErrorMessage: ""
      };
    case ONGOING_REQUEST:
      return {
        ...state,
        ongoingRequest: action.payload
      };
    case ONGOING_REQUEST_DEVICE:
      return {
        ...state,
        ongoingRequestDevice: action.payload
      };
    case SUBMIT_OFFER_FAIL:
      return {
        ...state,
        isGenericError: true,
        isGenericSuccess: false,
        genericErrorMessage: action.payload
      };
    case REQUEST_ACCESS_SUCCESS:
      return {
        ...state,
        isGenericError: false,
        isGenericSuccess: true,
        genericErrorMessage: ""
      };
    case REQUEST_ACCESS_FAIL:
      return {
        ...state,
        isGenericError: true,
        isGenericSuccess: false,
        genericErrorMessage: action.payload
      };
    case GET_CAREPROVIDERS:
      
      return {
        ...state,
        careproviders: action.payload.data
      };
    case GET_CAREPROVIDER_PATIENTS:
      
      return {
        ...state,
        careproviderPatients: action.payload.data
      };
    case GET_CAREPROVIDER_STAFF:
      
      return {
        ...state,
        careproviderStaff: action.payload.data
      };
    case GET_STAFF_CLIENT:
      
      return {
        ...state,
        clientStaff: action.payload.data
      };
    case GET_NO_READINGS:
      
      return {
        ...state,
        noReadings: action.payload.data
      };
    case GET_NEW_PATIENT:
      
      return {
        ...state,
        newPatients: action.payload.data.newPatients
      };      
    case GET_CLIENTS:
      
      return {
        ...state,
        clientList: action.payload.data
      };
    case GET_AUTENTICATE_FAX_PLUS:
      return {
        ...state,
        authenticationToken: action.payload.data
      };
    default:
      return state;
  }
};
