import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Link from "components/ExtendLink";
import { Redirect } from "react-router";

class Logout extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_LOGOUT",
      payload: {
        userId: this.props.userDetails.CLIENT_ID ? this.props.userDetails.CLIENT_ID  : this.props.userDetails._id       
      }
    });
  }
  render() {
    if (this.props.userDetails.CLIENT_ID) {
      return <Redirect to="/client/signin" />;
    }
    if (this.props.clientInfo && !this.props.clientInfo.error) {
      return <Redirect to={`/${this.props.clientInfo.slug}/`} />;
    }

    return <Redirect to="/" />;
  }
}
const mapStateToProps = ({ auth, user }) => {
  const { email, password } = auth;
  const { authenticated, userDetails, isRegister, clientInfo } = user;
  return {
    email,
    password,
    authenticated,
    userDetails,
    isRegister,
    clientInfo
  };
};

export default connect(mapStateToProps)(Logout);
