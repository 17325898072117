import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PropTypes from "prop-types";
import Link from "components/ExtendLink";
import React from "react";
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import userImage from "../../files/user.png";
import LottieLoader from "../LottieLoader";
import lottieEmptyFile from "../../files/animation/empty_new.json";
import lottieLoaderFile from "../../files/animation/loading.json";
import lottiePending from "../../files/animation/ic_pending.json";
import lottieCompleted from "../../files/animation/ic_completed.json";
const _ = require("lodash");

const OnBoardingDataTable = (props) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage:
      props.isAdmin && props.isAdmin == true
        ? true
        : parseInt(props.totalSize) == 0
        ? true
        : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer:
      props.isAdmin && props.isAdmin == true ? undefined : customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: props.totalSize,
      },
    ],
  };
  const columns = [
    {
      dataField: "recepientLname",
      text: "Last, First Name",
      formatter: fullname,
      sort: true,
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "recepientNickname",
      text: "Nickname",
      formatter: (cell, row) => {
        return row.recepientNickname ? (
          row.recepientNickname
        ) : (
          <div className="text-center">-</div>
        );
      },
      sort: true,
      headerStyle: {
        minWidth: '120px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '120px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "recepientPhone",
      text: "Phone",
      formatter: (cell, row) => {
        return row.recepientPhone ? (
          row.recepientPhone
        ) : (
          <div className="text-center">-</div>
        );
      },
      sort: true,
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "lastNoteCreatedDate",
      text: "Date of Last Note",
      formatter: (cell, row) => {
        const lastOnBoarding = row.lastOnBoarding;
        const model = _.find(props.allCategorys, (model) => {
          if (lastOnBoarding && lastOnBoarding.category) {
            return model._id == lastOnBoarding.category;
          } else if (lastOnBoarding && lastOnBoarding.lastCategory) {
            return model._id == lastOnBoarding.lastCategory;
          }
        });
        if (model && model.category) {
          return lastOnBoarding && lastOnBoarding.createdAt ? (
            <div>
              <span>
                {moment(lastOnBoarding.updatedAt).format(
                  "MMMM DD, YYYY hh:mm:ss A"
                )}
              </span>
              <span style={{ fontWeight: 800 }}>
                <br></br>
                <br></br>
                {model ? model.category : <div className="text-center">-</div>}
              </span>
            </div>
          ) : (
            <div className="text-center">-</div>
          );
        } else {
          return lastOnBoarding && lastOnBoarding.createdAt ? (
            <div>
              <span>{moment(lastOnBoarding.updatedAt).format("MMMM DD, YYYY hh:mm:ss A")}</span>
              <span style={{fontWeight:800}}><br></br><br></br>{lastOnBoarding ? (lastOnBoarding.subject ? lastOnBoarding.subject : '-') : <div className='text-center'>-</div>}</span>
            </div>
          ) : (
            <div className="text-center">-</div>
          );
        }
      },
      sort: props.isAdmin && props.isAdmin == true ? true : false,
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "lastRequestedRPM",
      text: "Requested RPM Order from Provider",
      sort: false,
      formatter: (cell, row) => {
        return cell && cell.createdAt ? (
          moment(cell.createdAt).format("MMMM DD, YYYY hh:mm:ss A")
        ) : (
          <div className="text-center">-</div>
        );
      },
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "lastPreShippmentRPM",
      text: "Pre-Shipment Phone Call",
      sort: false,
      formatter: (cell, row) => {
        return cell && cell.createdAt ? (
          moment(cell.createdAt).format("MMMM DD, YYYY hh:mm:ss A")
        ) : (
          <div className="text-center">-</div>
        );
      },
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "lastNeedToShipDevice",
      text: "Need To Ship Devices",
      sort: false,
      formatter: (cell, row) => {
        return cell && cell.createdAt ? (
          moment(cell.createdAt).format("MMMM DD, YYYY hh:mm:ss A")
        ) : (
          <div className="text-center">-</div>
        );
      },
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "lastWelcomeCallNeeded",
      text: "Welcome Phone Call Needed",
      sort: false,
      formatter: (cell, row) => {
        return cell && cell.createdAt ? (
          moment(cell.createdAt).format("MMMM DD, YYYY hh:mm:ss A")
        ) : (
          <div className="text-center">-</div>
        );
      },
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "recepientMainCareProvider",
      text: "Main Care Provider",
      formatter: (cell, row) => {
        return row.recepientMainCareProvider ? (
          row.recepientMainCareProvider
        ) : (
          <div className="text-center">-</div>
        );
      },
      sort: true,
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    }, {
      dataField: "recepientOrganization",
      text: "Organization",
      formatter: (cell, row) => {
        return row.recepientOrganization ? (
          row.recepientOrganization
        ) : (
          <div className="text-center">-</div>
        );
      },
      sort: true,
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },{
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="div-center">
            <Link
              to={`/dashboard/${row.recepient.fname
                .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase()}-${row.recepient.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row.recepient._id
                }`}
              className="btn pull-right more-btn ">
              <i class="mdi mdi-account-card-details"></i> More Details
            </Link>
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
      sort: false,
      headerStyle: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      },
      style: {
        minWidth: '150px' // Set your desired width  // Shrey Added
      }
    },
  ];
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No rpm on boarding shipment found :("}
          subTitle={"We can't find any shipment."}
        />
      )}
    </div>
  );
  return (
    <Row className="col-sm-12 main-cls-modal remove-table-padding">
      <div className="mt-12 w-100"> {/* Shrey Added */}
        <div className="scrollbar-table-cls">
        <BootstrapTable
          remote={{
            sort: false,
            pagination: true,
            filter: true,
            search: true
          }
          }
          keyField="_id"
          loading={props.loading}
          data={props.data}
          columns={columns}
          pagination={paginationFactory(options)}
          onTableChange={props.onTableChange}
          noDataIndication={() => <NoDataIndication />}
          hover={props.totalSize > 0 ? true : false}
          condensed
          bordered
          wrapperClasses="table-responsive"
          expandRow={{
            renderer: (row) => rowComponent(row,props),
            onlyOneExpanding: false,
            showExpandColumn: true,
            expandByColumnOnly: false
          }}
        />
        </div>
      </div>
    </Row>
  );
};

function fullname(cell, row) {
  const profile = row.profileImage || userImage;
  // return <UserName user={row} />;
  return (
    <div className={row.isActive ? "" : " text-danger"}>
      <span>
        <img src={profile} className="mr-2" alt="image" />
        {capitalize(row.recepientLname)} {capitalize(row.recepientFname)}
      </span>
    </div>
  );
}

function device(cell, row) {
  const deviceImage = row.deviceAbbrev
    ? require(`../../files/Reading/${row.deviceAbbrev}.svg`)
    : undefined;
  return (
    <div className="text-left reading-card-for-all-reading">
      {deviceImage && (
        <img
          src={deviceImage}
          alt="profile-image"
          className="device-icon rounded-circle img-fluid"
        />
      )}
      <div>
        <span style={{ paddingLeft: 5 }}>{row.deviceDescription}</span>
      </div>
    </div>
  );
}

function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

const rowComponent = (rowData, props) => {
  const category = _.filter(props.allCategorys, (model) => {
    return (
      model._id != "64620873b1cebb9c4d5b70ea" &&
      model._id != "646208d0b1cebb9c4d5b70eb" &&
      model._id != "64f02ecb6d9efff481a93e67"
    );
  });
  const orderCategory = _.orderBy(
    _.filter(rowData.onBoardings, (model) => {
      return (
        model.category == "64620873b1cebb9c4d5b70ea" ||
        (model.category == "646208d0b1cebb9c4d5b70eb") |
          (model.category == "64f02ecb6d9efff481a93e67")
      );
    }),
    ["createdAt"],
    ["acs"]
  );
  const mListOnBoarding = _.filter(rowData.onBoardings, (model) => {
    return (
      model.category !== "64620873b1cebb9c4d5b70ea" &&
      model.category !== "646208d0b1cebb9c4d5b70eb" &&
      model.category !== "64f02ecb6d9efff481a93e67"
    );
  });
  return (
    <div>
      {orderCategory && orderCategory.length > 0 && (
        <Row style={{ margin: "15px 0px" }}>
          <Col sm="12" className="mt-2 remove-summary-paddding">
            <Card style={{ border: "0px 10px 10px 0px" }}>
              <CardBody className="padding-card-body">
                <BootstrapTable
                  keyField="id"
                  data={orderCategory}
                  columns={[
                    {
                      dataField: "category",
                      text: "Shipment Category",
                      formatter: (cell, row) => {
                        const modelSession = _.find(
                          props.allCategorys,
                          (modelV) => {
                            return row.category == modelV._id;
                          }
                        );
                        return (
                          <div>
                            {modelSession && (
                              <div className="text-left">
                                <span>{modelSession.category}</span>
                              </div>
                            )}
                            {!modelSession && (
                              <div className="text-center">
                                <span> - </span>
                              </div>
                            )}
                          </div>
                        );
                      },
                      classes: "row-padding",
                      headerStyle: (colum, colIndex) => {
                        return { width: "300px", textAlign: "left" };
                      },
                    },
                    {
                      dataField: "title",
                      text: "Title",
                      headerStyle: (colum, colIndex) => {
                        return { width: "300px", textAlign: "left" };
                      },
                      formatter: (cell, row) => {
                        return (
                          <div>
                            {row.subject && (
                              <div className="text-left">
                                <span>{row.subject}</span>
                              </div>
                            )}
                            {!row.subject && (
                              <div className="text-center">
                                <span> - </span>
                              </div>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "body",
                      text: "Message",
                      formatter: (cell, row) => {
                        return (
                          <div>
                            {row.body && (
                              <div className="text-left">
                                <span>{row.body}</span>
                              </div>
                            )}
                            {!row.body && (
                              <div className="text-center">
                                <span> - </span>
                              </div>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "createdAt",
                      text: "Date",
                      headerStyle: (colum, colIndex) => {
                        return { width: "190px", textAlign: "left" };
                      },
                      formatter: (cell, row) => {
                        return (
                          <div>
                            {row.createdAt && (
                              <div className="text-left">
                                <span>
                                  {moment(row.createdAt).format(
                                    "MMMM DD, YYYY hh:mm:ss A"
                                  )}
                                </span>
                              </div>
                            )}
                            {!row.createdAt && (
                              <div className="text-center">
                                <span> - </span>
                              </div>
                            )}
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {mListOnBoarding && mListOnBoarding.length > 0 && (
        <Row style={{ margin: "15px 0px" }}>
          {_.map(mListOnBoarding, (model) => {
            return (
              <Col sm="12" className="mt-2 remove-summary-paddding">
                <Card style={{ border: "0px 10px 10px 0px" }}>
                  <CardHeader
                    className="text-white"
                    style={{ padding: "3px 0px" }}
                  >
                    {model && model.device && (
                      <div class="col-12">
                        <div>
                          <div className="text-left reading-card-for-all-reading">
                            <img
                              src={require(`../../files/Reading/${model.device.deviceType.abbrev}.svg`)}
                              alt="profile-image"
                              className="profile rounded-circle img-fluid mr-2 reading-icon-summary"
                            />{" "}
                            <div>
                              <h4
                                class="reading-card-title"
                                style={{
                                  color: "black",
                                  fontSize: 14,
                                  fontFamily: "ubuntu-regular",
                                  fontWeight: "700",
                                }}
                              >
                                {model.device.name}
                              </h4>
                              <h6
                                class="reading-card-subtitle"
                                style={{
                                  color: "#787878",
                                  fontSize: 14,
                                  fontFamily: "ubuntu-regular",
                                  fontWeight: "500",
                                }}
                              >{`${model.device.deviceType.description}`}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {model && !model.device && (
                      <div class="col-12">
                        <div>
                          <div className="text-left reading-card-for-all-reading">
                            <div className="text-center animated-image-border">
                              <LottieLoader
                                loadLoop={false}
                                autoplayLoop={true}
                                source={lottiePending}
                                Lheight={40}
                                Lwidth={40}
                              />
                            </div>
                            <div>
                              <h4
                                class="reading-card-title"
                                style={{
                                  color: "#e80000",
                                  fontSize: 14,
                                  fontFamily: "ubuntu-regular",
                                  fontWeight: "700",
                                }}
                              >
                                On Boarding Pending (Device not shipped)
                              </h4>
                              <h6
                                class="reading-card-subtitle"
                                style={{
                                  color: "#787878",
                                  fontSize: 14,
                                  fontFamily: "ubuntu-regular",
                                  fontWeight: "500",
                                }}
                              >{`The patient does not specify which device is needed.`}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardHeader>
                  <CardBody className="padding-card-body">
                    {model && model.device && (
                      <BootstrapTable
                        keyField="id"
                        data={category}
                        columns={[
                          {
                            dataField: "category",
                            text: "Shipment Category",
                            headerStyle: (colum, colIndex) => {
                              return { width: "300px", textAlign: "left" };
                            },
                          },
                          {
                            dataField: "title",
                            text: "Title",
                            headerStyle: (colum, colIndex) => {
                              return { width: "300px", textAlign: "left" };
                            },
                            formatter: (cell, row) => {
                              const modelSession = _.find(
                                model.sessions,
                                (modelV) => {
                                  return modelV.category == row._id;
                                }
                              );
                              return (
                                <div>
                                  {modelSession && (
                                    <div className="text-left">
                                      <span>{modelSession.subject ? modelSession.subject : '-'}</span>
                                    </div>
                                  )}
                                  {!modelSession && (
                                    <div className="text-center">
                                      <span> - </span>
                                    </div>
                                  )}
                                </div>
                              );
                            },
                          },
                          {
                            dataField: "message",
                            text: "Message",
                            formatter: (cell, row) => {
                              const modelSession = _.find(
                                model.sessions,
                                (modelV) => {
                                  return modelV.category == row._id;
                                }
                              );
                              return (
                                <div>
                                  {modelSession && (
                                    <div className="text-left">
                                      <span>{modelSession.body}</span>
                                    </div>
                                  )}
                                  {!modelSession && (
                                    <div className="text-center">
                                      <span> - </span>
                                    </div>
                                  )}
                                </div>
                              );
                            },
                          },
                          {
                            dataField: "createdAt",
                            text: "Date",
                            headerStyle: (colum, colIndex) => {
                              return { width: "190px", textAlign: "left" };
                            },
                            formatter: (cell, row) => {
                              const modelSession = _.find(
                                model.sessions,
                                (modelV) => {
                                  return modelV.category == row._id;
                                }
                              );
                              return (
                                <div>
                                  {modelSession && (
                                    <div className="text-left">
                                      <span>
                                        {moment(modelSession.createdAt).format(
                                          "MMMM DD, YYYY hh:mm:ss A"
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  {!modelSession && (
                                    <div className="text-center">
                                      <span> - </span>
                                    </div>
                                  )}
                                </div>
                              );
                            },
                          },
                        {
                          dataField: '',
                          text: 'Shipped Status',
                          formatter: (cell, row) => {
                            const modelSession = _.find(model.sessions, (modelV) => {
                              return modelV.category == row._id
                            })
                            return (
                              <>
                                {modelSession && !modelSession.isReturnDevice && modelSession.category == "646208d3b1cebb9c4d5b70ec" && (
                                  <div className='text-center shipped-device'>
                                    <span>Device Shipped</span>
                                  </div>
                                )}
                                {modelSession && !modelSession.isReturnDevice && modelSession.category == "64c781a345daedcb3562a359" && (
                                  <div className='text-center send-Invoice-onBoaring'>
                                    <span>Invoice Sent</span>
                                  </div>
                                )}
                                {modelSession && modelSession.isReturnDevice && modelSession.category == "64620ebdb1cebb9c4d5b70f7" && (
                                  <div className='text-center return-shipped-device'>
                                    <span>Device Return Pending</span>
                                  </div>
                                )}
                                {modelSession && modelSession.isReturnDevice && modelSession.category == "667aa0e00bc7bcfe6b65aa0e" && (
                                  <div className='text-center device-return-to-provider'>
                                    <span>Device Return To Provider</span>
                                  </div>
                                )}
                                {modelSession && modelSession.isReturnDevice && modelSession.category == "64c781b245daedcb3562a35a" && (
                                  <div className='text-center return-shipped-device'>
                                    <span>Device Returned</span>
                                  </div>
                                )}
                                {modelSession && modelSession.category == "646208d6b1cebb9c4d5b70ed" && (
                                  <div className='text-center callCompleted'>
                                    <span>Call Completed</span>
                                  </div>
                                )}
                              </>
                            )
                          }
                        }, {
                          dataField: '',
                          text: 'Status',
                          formatter: (cell, row) => {
                            const modelSession = _.find(model.sessions, (modelV) => {
                              return modelV.category == row._id
                            })
                            return (
                              <div>
                                {modelSession && (
                                  <div className='text-center'>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottieCompleted}
                                      Lheight={35}
                                      Lwidth={35}
                                      subTitleExtraSmall={"Completed"}
                                    />
                                  </div>
                                )}
                                {!modelSession && (
                                  <div className='text-center'>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottiePending}
                                      Lheight={50}
                                      Lwidth={50}
                                      subTitleExtraSmall={"Pending"}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          }
                        }]}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

OnBoardingDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default OnBoardingDataTable;
