import React, { Component } from "react";
import Link from "components/ExtendLink";
import { connect } from "react-redux";
import logoImage from "../../files/smarthealth.jpg";
import ReactLoading from "react-loading";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.emailChange = this.emailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  emailChange(event) {
    this.props.dispatch({
      type: "DO_CHANGE_EMAIL",
      payload: event.target.value
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.dispatch({
      type: "DO_FORGOT",
      payload: { email: this.props.email, password: this.props.password }
    });
  }
  componentWillUnmount() {
    clearTimeout(this.timerid);
  }
  componentDidUpdate() {
    if (this.props.forgotPasswordSuccess) {
      this.timerid = setTimeout(() => {
        this.props.history.push("/");
      }, 3000);
    }
  }
  render() {
    const { client } = this.props.match.params;
    const domainCheck = window.location.hostname;
    const isTNS =
      domainCheck.includes("testnshare") || domainCheck.includes("sugarmds");
    let logo, websiteName, backgroundImage;
    if (this.props.loading) {
      return <ReactLoading />;
    }
    if (client && this.props.clientInfo && this.props.clientInfo.error) {
      return <div>Not found</div>;
    }
    if (client && this.props.clientInfo && !this.props.clientInfo.error) {
      logo = this.props.clientInfo.websiteLogo;
      websiteName = this.props.clientInfo.websiteName;
      backgroundImage =
        this.props.clientInfo.backgroundImage ||
        "http://myvitalview.com/background.jpg";
    }
    if (isTNS && this.props.clientInfo && this.props.clientInfo.error) {
      return <div>Not found</div>;
    }
    if (isTNS && this.props.clientInfo && !this.props.clientInfo.error) {
      logo = this.props.clientInfo.websiteLogo;
      websiteName = this.props.clientInfo.websiteName;
      backgroundImage =
        this.props.clientInfo.backgroundImage ||
        "http://myvitalview.com/background.jpg";
    }
    if (!client && !isTNS) {
      logo = logoImage;
      websiteName = "MYVITALVIEW";
      backgroundImage = "http://myvitalview.com/background.jpg";
    }
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div
          className="content-wrapper d-flex align-items-center auth"
          style={{
            background: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover"
          }}
        >
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            <div className="mx-auto" style={{ width: "500px" }}>
              <div className="auth-form-light text-left p-5 login-style">
                <div className="brand-logo mx-auto text-center">
                  <img style={{ width: "200px" }} alt="" src={logo} />
                </div>
                <h4 className="text-center">Forgot Password</h4>
                <h6 className="font-weight-light text-center">
                  Type your email to reset your password.
                </h6>
                <form className="pt-3" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Email Address"
                      required
                      onChange={this.emailChange}
                      value={this.props.email}
                    />
                  </div>
                  {this.props.forgotPasswordSuccess && (
                    <div className="alert alert-success text-center">
                      Reset password link has been sent to your Email
                    </div>
                  )}
                  {this.props.errorRegister && (
                    <div className="alert alert-danger text-left">
                      {this.props.errorRegister.message}
                    </div>
                  )}

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                      style={{ color: "white" }}
                    >
                      {!this.props.loading && <span>SUBMIT</span>}

                      {this.props.loading && (
                        <span>
                          Please Wait <i class="fa fa-spinner fa-spin" />
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, errorRegister, forgotPasswordSuccess, loading } = auth;

  return { email, errorRegister, forgotPasswordSuccess, loading };
};

export default connect(mapStateToProps)(ForgotPassword);
