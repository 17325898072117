import React, { useRef } from 'react';

import moment from "moment";
import { Col, Label, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import _ from "lodash";
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import AnswerPrintHeader from './AnswerPrintHeader';
import isNoSignature from "../../files/ic_no_signature.svg";
import SignatureWithName from '../Reusable/SignatureWithName';

const AnswerDetailsPrintOld = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const mAnswersList = props.row.answers;
  const signatureImage = props.row.createdBy ? props.row.createdBy.signatureImage : undefined;
  const name = props.row.createdBy ? props.row.createdBy.lname+" "+props.row.createdBy.fname : undefined;
  var signatureLink = signatureImage ? signatureImage : undefined;
  if (signatureLink && !signatureLink.indexOf("http") == 0) {
      signatureLink = "/" + signatureLink
  }
  return (
    <div className='answer-print-border'>
      <Row>
        <Col md={12} style={{ background: "white" }}>
          <ReactToPrint
            trigger={() => (
              <div className="pull-right">
                <button
                  className="btn btn-info ml-2 print-click-cls"
                  onClick={handlePrint}
                  style={{ float: 'right' }}>
                  <i class="mdi mdi-cloud-print" style={{
                    fontSize: 20
                  }}></i>
                  Print
                </button>
              </div>
            )}
            content={() => componentRef}
          />
        </Col>
        <Col md={12} style={{ background: "white" }} >
          <div style={{ margin: 15 }} ref={componentRef}>
            <AnswerPrintHeader
              profile={props.profile}
              createdAt={props.row.createdAt}
            />
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication="No Data Found"
              data={mAnswersList ? mAnswersList : []}
              columns={[
                {
                  dataField: "question.question",
                  text: "Question",
                  sort: false
                },
                {
                  dataField: "answer",
                  text: "Answer",
                  sort: false
                },
                {
                  dataField: "note",
                  text: "Note",
                  sort: false,
                  headerStyle: (colum, colIndex) => {
                    return { width: '150px', textAlign: 'center' };
                  },
                  formatter: (cell, row) => {
                    if (_.isEmpty(cell)) {
                      return <div className="text-center">-</div>;
                    }
                    return (
                      <div className="text-left">
                        {cell}
                      </div>
                    );
                  }
                }
              ]} />

            <SignatureWithName
              signature={signatureLink}
              isBorder={true}
              name={name}              
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AnswerDetailsPrintOld;