import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Row,
  Collapse,
  Col,
} from "reactstrap";

import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";
import axios from "axios";

import { useFetch } from "components/utils/fetchHook";
import Select from "react-select";
const _ = require("lodash");
const AddConditionsModal = (props) => {
  const {
    response = { data: [] },
    error: errorGet,
    isLoading: isLoadingGet,
  } = useFetch(`/careprovider/conditions`);
  const conditions = response ? response.data : response;
  // Define custom sorting function
  const sortedArrayView = _.sortBy(conditions, (obj) => {
    var name = obj.description;
    if (name.toLowerCase().includes("CUSTOM".toLowerCase())) return 1;    
    return 0; // For items without V, T, or W
  });
  const sortedArrayViewNew = _.sortBy(sortedArrayView, (obj) => {
    var name = obj.condition;
    if (name.toLowerCase().includes("CUSTOM".toLowerCase())) return 1;    
    return 0; // For items without V, T, or W
  });
  return (
    <Modal
      isOpen={props.modalAddConditions}
      toggle={props.toggleAddConditions}
      backdrop="static"
      size="lg"
      className="bastaLang modal-md"
    >
      <ModalHeader toggle={props.toggleAddConditions}>
        Add Conditions
      </ModalHeader>

      <ModalBody style={{ backgroundColor: "white", height:450 }}>
        <Form onSubmit={props.updateConditions }>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="exampleSelect">
                  Select the Condition to add (Can be Multiple)*
                </Label>
                <Select
                  SelectComponent={Select}
                  onChange={props.handleInputChangeUpdateCondition}
                  options={sortedArrayViewNew}
                  isMulti={true}
                  required={true}
                  getOptionLabel={(option) =>
                    `${option["condition"]}  (${option["description"]})`
                  }
                  getOptionValue={(option) => option["_id"]}
                />
              </FormGroup>
            </Col>
          </Row>
          <button type="submit" className="btn btn-block btn-info">
            SAVE
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddConditionsModal;
