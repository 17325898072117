import React, { Component } from 'react';

import { connect } from 'react-redux';
import ImprovedPatientList from './ImprovedPatientList';
import moment from "moment";
import {
  Col,
  Label,
  Row,
  FormGroup,
  Input
} from "reactstrap";
import _ from "lodash";

class ActivePatientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: props.displayNeedToFollowUpList ? 10000 : 10,
      search: "",
      enrolledPrograms: "",
      displayActiveList: true,
      displayDemoList: null,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      fileLoading: false,
      startDate: "",
      startDateError: null,
      endDate: "",
      endDateError: null,
      link: undefined,
      sortOrder : null,
      sortField : null
    };
    this.timeout =  null;
    this.onNavigatePage = this.onNavigatePage.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.filterEnrolledPrograms = this.filterEnrolledPrograms.bind(this);
    this.fetchDataFromServer = this.fetchDataFromServer.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.validatedate = this.validatedate.bind(this);
    this.isDateValid = this.isDateValid.bind(this);
  }

  onNavigatePage(page, limit) {
    // Page
  }
  handleTableChange(type, { page, sizePerPage, filters, sortField, sortOrder }) {
    var sortNewOrder;
    const payload = {
      page: page, limit: sizePerPage
    }
    if(!_.isEmpty(sortField)) {
      payload.sortField = sortField
    }
    if(type == 'sort'){
      if(this.state.sortOrder == "desc"){
        if(!_.isEmpty(sortField)) {
          sortNewOrder = "asc";
        }else{
          sortNewOrder = "desc";
        }
        sortNewOrder = "asc";
        payload.sortOrder = sortNewOrder;
        this.setState(
          payload
        , () => {
          this.fetchData();
        });    
      }else{
        if(!_.isEmpty(sortField)) {
          sortNewOrder = "desc"
        }else{
          sortNewOrder = "asc";
        }
        sortNewOrder = "desc"
        payload.sortOrder = sortNewOrder;
        this.setState(
          payload
        , () => {
          this.fetchData();
        });    
    }
    }else{
      this.setState(
        payload
      , () => {
        this.fetchData();
      });  
    }

  }
  filterEnrolledPrograms(event) {
    if (event.target.value) {
      this.setState({
        page: 1,
        sortField: "",
        sortOrder: "",
        enrolledPrograms: event.target.value
      }, () => {
        this.fetchData();
      });
    } else {
      this.setState({
        enrolledPrograms: undefined
      }, () => {
        this.fetchData();
      });
    }
  }
  componentDidMount() {
    this.props.fetchDataFromServer(this.fetchDataFromServer);
  }
  fetchDataFromServer() {
    this.setState({
      page: 1,
      limit: this.props.displayNeedToFollowUpList ? 10000 : 10,
      search: "",
      enrolledPrograms: "",
      endDateError: null,
      startDate:"",
      endDate:"",
      startDateError: null,
      sortField: "",  
      sortOrder: "",
    }, () => {
      this.fetchData();
    });
  }
  fetchData() {
    if(this.state.startDate){
      const startDateCheck = this.validatedate(this.state.startDate,'startDate');
      if(!startDateCheck){
        return
      }
    }
    if(this.state.endDate){
      const endDateCheck = this.validatedate(this.state.endDate,'endDate');
      if(!endDateCheck){
        return
      }
    }
        // date validation
        if(this.state.endDateError || this.state.startDateError){
          return;
        }
        this.props.dispatch({
          type: "DO_GET_ALL_PATIENT",
          payload: {
            page: this.state.page,
            limit: this.state.limit,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            sortField: this.state.sortField ? this.state.sortField : "",
            sortOrder: this.state.sortOrder ? this.state.sortOrder : "",
            search: this.state.search ? this.state.search : "",
            enrolledPrograms: this.state.enrolledPrograms ? this.state.enrolledPrograms : "",
            selectedApi: this.props.selectedApi,
            timezone: moment.tz.guess()
          }
        });
  }
  MyExportCSV = (props) => {
    const handleClick = () => {
      this.props.dispatch({
        type: "DO_REMOVE_PATIENT_LINK_PATH",
        payload: undefined
      });
      var tz = moment.tz.guess();
      this.props.dispatch({
        type: "DO_EXPORT_PATIENT_LIST",
        payload: {
          selectedApi: this.props.selectedApi,
          tz: tz,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          sortField: this.state.sortField ? this.state.sortField : "",
          sortOrder: this.state.sortOrder ? this.state.sortOrder : "",
          search: this.state.search ? this.state.search : "",
          enrolledPrograms: this.state.enrolledPrograms ? this.state.enrolledPrograms : "",
        }
      });
    };
    return (
      <Row>
        <Col>
          <button 
          style={{ marginTop: 5,width:164, height:43 }} 
          className="btn btn-info ml-2 download-click-cls"
          disabled={!(this.props?.allPatientOfferedAccess &&  this.props?.allPatientOfferedAccess.length > 0 && !this.props.ongoingPatientListRequestExport)}
          onClick={handleClick}>
          {(this.props.ongoingPatientListRequestExport) ? (
            <i className="mdi mdi-loading mdi-spin mr-2" style={{ fontSize: 20 }}></i> // Loader icon
          ) : (
            <>
              <i className="mdi mdi-download" style={{ fontSize: 20 }}></i>
            </>
          )}
          Export to CSV
          </button>
        </Col>
      </Row>
    );
  };
  MySearch = () => {
    let input;
    const handleChange = (e) => {
      e.preventDefault();
      const val = e.target.value;
      this.setState({
        page: 1,
          search: val,
          sortField: "",
          sortOrder: "",
      });
    };
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        this.fetchData();        
      }, 200)
      // if this effect run again, because `value` changed, we remove the previous timeout
      return () => clearTimeout(timeout)
    }, [this.state.search])
    return (
      <div>
        <div class="form-group">
          <input
            type="text"
            class="form-control input-date-filter remove-bottom-margin"
            id="exampleInputName1Test"
            style={{ fontSize: "20px" }}
            placeholder="Search Last Name, First Name, Nickname, Organization, Enrolled Programs"
            ref={(n) => (input = n)}
            value={this.state.search}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };
  /**
   * Questionnaire date inputs
   * @param {*} event 
   */
  handleInputDateChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      { 
        [name]: value }, () => {
            const check = this.validatedate(value,name);
            if(check){
              this.setState({
                endDateError: null,
                startDateError : null
              }, () => this.fetchData());
            }
      }
    );
  }
  validatedate(date,name){
    const dateParts = date.split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
   
    if(name == 'startDate'){
      this.setState({
        startDateError: null,
      });
    }else{
      this.setState({
        endDateError: null
      });
    }
    
    // if(name == 'endDate' && _.isEmpty(this.state.startDate)){
    //   this.setState({ startDateError: "Please select first start date." });
    //   return false;
    // }
    const todayDate = moment().format('YYYY-MM-DD');
    // JavaScript Date object handles invalid dates by returning "Invalid Date"
    const isValid = !isNaN(year) && !isNaN(month) && !isNaN(day);
    if(!isValid){
      if(name == 'startDate'){
        this.setState({startDateError : null}, () => this.fetchData())
      }if(name == 'endDate'){
        this.setState({endDateError : null}, () => this.fetchData())
      }
    } else if (year.toString().length != 4) {
      if(name == 'startDate'){
        this.setState({ startDateError : "Please select proper formmated start date." });
      }else{
        this.setState({ endDateError: "Please select proper formmated end date." });
      }
    }else if(this.props.selectedApi == "FollowUpReminder"){
      let currenDate = moment().format('YYYY-MM-DD');
       if(name == 'endDate' && this.state.startDate && date < this.state.startDate){
        this.setState({ endDateError: "Please select reminder end date greater or equal to start date." });
        return false;
      }if(name == 'startDate' && this.state.endDate && this.state.endDate < date){
        this.setState({ endDateError: "Please select reminder end date greater or equal to start date." });
        return false;
      } 
      if(name == 'startDate' && date < currenDate){
        this.setState({ startDateError : "Please select reminder start date greater than today." });
        return false;
      } if(name == 'endDate' && date < currenDate){
        this.setState({ endDateError: "Please select reminder end date greater or equal to today Date." });
        return false;
      }
        this.setState({ endDateError: null, startDateError : null});
        return true;
    }else if(this.state.startDate && this.state.endDate && this.state.startDate > this.state.endDate){
      this.setState({ endDateError: "Please select end date greater than start day." });
      if(date > todayDate){
        if(name == 'startDate'){
          this.setState({ startDateError : "Please select start date less than today." });
        }
      }
    }else{
      // Check date is greter than today or not
      if(date > todayDate){
        if(name == 'startDate'){
          this.setState({ startDateError : "Please select start date less than today." });
        }
        if(name == 'endDate'){
          this.setState({ endDateError: "Please select end date less than today." });
        }
      }else{
        const isValid = !isNaN(year) && !isNaN(month) && !isNaN(day);
        const checkDate = this.isDateValid(date,name);
        if(isValid && year.toString().length == 4 && checkDate){
          return true;
        }
      }
     
    }
    return false;
  }

  isDateValid(value,name) {
    if(value === undefined || value === null || value.trim().length == 0 ){
      return true;
    } else {
      let date;
      const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
      const todayDate = moment().format('YYYY-MM-DD');
      if(name == 'endDate' && startDate < todayDate && !_.isEmpty(this.state.startDate)){
        date = moment(new Date(this.state.startDate), 'MM/DD/YYYY');
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        var dateEntered = new Date(value);
        var today = moment(date, 'MM/DD/YYYY');
        var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
        var dDiff = today.isSameOrAfter(enteredDate);
        if (dDiff) {
          this.setState({
            endDateError: "Please select end date is greater than equal to start date.",
          });
          return false;
        } else if (today.isSame(enteredDate)) {
          this.setState({
            endDateError: null,
          });
          return true;
        } else {
          this.setState({
            endDateError: null,
          });
          return true;
        }
      }
    }
    return true;
  };
  render() {
    if (this.props.patientFilePath) {
      if (this.state.link === undefined) {
        this.setState({
          link: this.props.patientFilePath,
        }, () => {
          this.props.dispatch({
            type: "DO_REMOVE_PATIENT_LINK_PATH",
            payload: undefined
          });
          window.open(this.state.link, "_self");
        }
        );
      }

    } else {
      this.state.link = undefined;
    }
    return (
      <Row className="col-sm-12 main-cls-modal remove-table-padding">
        <div sm="12" className="mt-12 w-100">{/*Shrey Added */}
          <div>
            <Row className={`col-md-12 mx-auto float-right remove-pading-table`} style={{ marginTop: 35 }}>
              <Col md={4} xl={3} className="col-lg-4">{/*Shrey Added */}
                <FormGroup className="remove-bottom-margin form-group-patient">
                  {this.props.selectedApi != "HospitalAdmission" && this.props.selectedApi != "FollowUpReminder" && this.props.selectedApi != "ReminderSent" && (
                    <Label for="exampleEmail">Start Date (Enrolled Date):</Label>
                  )}
                  {this.props.selectedApi == "HospitalAdmission" && (
                    <Label for="exampleEmail">Start Date (Hospital Admission Date):</Label>
                  )}
                  {(this.props.selectedApi == "FollowUpReminder" || this.props.selectedApi == "ReminderSent") && (
                    <Label for="exampleEmail">Start Date (Reminder Date):</Label>
                  )}
                  {(this.props.selectedApi != "FollowUpReminder") &&  (
                   <Input
                    className="input-date-filter remove-bottom-margin"
                    type="date"
                    name="startDate"
                    max={this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                    onChange={this.handleInputDateChange}
                    value={this.state.startDate}
                  />
                  )
                  }
                  {(this.props.selectedApi == "FollowUpReminder") && (
                    <Input
                     className="input-date-filter remove-bottom-margin"
                     type="date"
                     name="startDate"
                     min={moment().format("YYYY-MM-DD")}
                     onChange={this.handleInputDateChange}
                     value={this.state.startDate}
                   />
                   )
                   }
                  <Label
                  style={{
                    color: "red",
                    textAlign: "left",
                    padding: 5
                  }}>{this.state.startDateError}</Label>
                </FormGroup>
              </Col>
              <Col md={4} xl={3} className="col-lg-4">{/*Shrey Added */}
                <FormGroup className="remove-bottom-margin form-group-patient">
                  {this.props.selectedApi != "HospitalAdmission" && this.props.selectedApi != "FollowUpReminder" && this.props.selectedApi != "ReminderSent" && (
                    <Label for="examplePassword">End Date (Enrolled Date):</Label>
                  )}
                  {this.props.selectedApi == "HospitalAdmission" && (
                     <Label for="examplePassword">End Date (Hospital Admission Date):</Label>
                  )}
                  {(this.props.selectedApi == "FollowUpReminder" || this.props.selectedApi == "ReminderSent") && (
                    <Label for="exampleEmail">End Date (Reminder Date):</Label>
                  )}
                  {(this.props.selectedApi != "FollowUpReminder") &&  (
                    <Input
                    className="input-date-filter remove-bottom-margin"
                    type="date"
                    name="endDate"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={this.handleInputDateChange}
                    value={this.state.endDate}
                  />
                   )
                   }
                   {(this.props.selectedApi == "FollowUpReminder") && (
                     <Input
                      className="input-date-filter remove-bottom-margin"
                      type="date"
                      name="endDate"
                      min={this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                      onChange={this.handleInputDateChange}
                      value={this.state.endDate}
                    />
                    )
                    }
                  <Label
                    style={{
                      color: "red",
                      textAlign: "left",
                      padding: 5
                    }}>{this.state.endDateError}</Label>
                </FormGroup>
              </Col>

              <Col md={4} xl={3} className="col-lg-4">{/*Shrey Added */}
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">Enrolled Programs:&nbsp;&nbsp;</Label>
                  <select className="filter-Questionnaire" onChange={this.filterEnrolledPrograms} value={this.state.enrolledPrograms}>
                    <option value="">Select Enrolled Program</option>
                    <option value="CCM">CCM</option>
                    <option value="RPM">RPM</option>
                    <option value="CCM&RPM">CCM&RPM</option>
                  </select>
                </FormGroup>                
              </Col>
              <Col md={3}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">&nbsp;&nbsp;</Label>
                  {(this.state.startDate || this.state.endDate || this.state.search || this.state.enrolledPrograms) && (
                    <button
                      style={{ float: 'left', marginTop: 20, width:150, height:37 }}
                      onClick={() => {
                        this.fetchDataFromServer()
                      }}
                      className="btn btn-info ml-2 close-click-cls"
                    >
                      <i class="mdi mdi-close" style={{
                        fontSize: 20
                      }}></i>
                      Clear Filters
                    </button>
                  )}
                </FormGroup>
              </Col>                            
            </Row>
            <Row className={`text-center col-md-12 mx-auto float-right remove-pading-table`}>
              <Col md={9} style={{ marginTop: 15 }}>{/*Shrey Added */}
                <this.MySearch />
              </Col>

              <Col md={2} style={{ marginTop: 10 }}>
                <this.MyExportCSV>
                  Export CSV
                </this.MyExportCSV>
              </Col>

            </Row>
          </div>
          {this.props.ongoingPatientListRequest && (
            <ImprovedPatientList
              data={[]}
              page={this.state.page}
              loading={this.props.ongoingPatientListRequest}
              sizePerPage={this.state.limit}
              profileOwner={this.props.profileOwner}
              readingSubTypes={this.props.readingSubTypes}
              userId={this.props.userId}
              totalSize={0}
              onNavigatePage={this.onNavigatePage}
              onTableChange={this.handleTableChange}
              displayActiveList={this.props.displayActiveList}
              displayCellularConnectivityList={
                this.props.displayCellularConnectivityList
              }
              displayNeedToFollowUpList={this.props.displayNeedToFollowUpList}
              displayHospitalAddmissionList={this.props.displayHospitalAddmissionList}
              displayItIssuesList={this.props.displayItIssuesList}
              displayFollowUpReminderList={this.props.displayFollowUpReminderList}
              displayReminderSentList={this.props.displayReminderSentList}
            />
          )}
          {!this.props.ongoingPatientListRequest && this.props.allPatientOfferedAccess && (
            <ImprovedPatientList
              data={this.props.allPatientOfferedAccess}
              page={this.state.page}
              loading={this.props.ongoingPatientListRequest}
              sizePerPage={this.state.limit}
              profileOwner={this.props.profileOwner}
              readingSubTypes={this.props.readingSubTypes}
              userId={this.props.userId}
              totalSize={this.props.totalRecode}
              onNavigatePage={this.onNavigatePage}
              onTableChange={this.handleTableChange}
              displayActiveList={this.props.displayActiveList}
              displayCellularConnectivityList={
                this.props.displayCellularConnectivityList
              }
              displayNeedToFollowUpList={this.props.displayNeedToFollowUpList}
              displayHospitalAddmissionList={this.props.displayHospitalAddmissionList}
              displayItIssuesList={this.props.displayItIssuesList}
              displayFollowUpReminderList={this.props.displayFollowUpReminderList}
              displayReminderSentList={this.props.displayReminderSentList}
            />
          )}
        </div>
      </Row>
    );
  }
}

// function mapStateToProps(state, ownProps) {
//   return {
//     products: state.products
//   };
// }

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    allPatientOfferedAccess,
    totalRecode,
    ongoingRequest,
    patientFilePath,
    ongoingPatientListRequest,
    ongoingPatientListRequestExport
  } = careprovider;
  const { readingSubTypes } = auth;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    totalRecode,
    allPatientOfferedAccess,
    readingSubTypes,
    userId,
    ongoingRequest,
    profileOwner,
    patientFilePath,
    ongoingPatientListRequest,
    ongoingPatientListRequestExport
  };
};

export default connect(mapStateToProps)(ActivePatientList);
