import React, { useRef } from 'react';

import moment from "moment";
import { Col, Label, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import _ from "lodash";
import AnswerPrintHeader from './AnswerPrintHeader';
import isNoSignature from "../../files/ic_no_signature.svg";
import SignatureWithName from '../Reusable/SignatureWithName';

const AnswerDetailsPrint = React.forwardRef((props, ref) => {  
    const signatureImage = props.selectedModel.lastModifyBy ? props.selectedModel.lastModifyBy.signatureImage : props.selectedModel.createdBy ? props.selectedModel.createdBy.signatureImage : undefined;
    const name = props.selectedModel.lastModifyBy ? props.selectedModel.lastModifyBy.lname+" "+props.selectedModel.lastModifyBy.fname : props.selectedModel.createdBy ? props.selectedModel.createdBy.lname+" "+props.selectedModel.createdBy.fname : undefined;
    var signatureLink = signatureImage ? signatureImage : undefined;
    if (signatureLink && !signatureLink.indexOf("http") == 0) {
        signatureLink = "/" + signatureLink
    }  
    return (
      <div className='answer-print-border'>
        <div ref={ref ? ref.printRef : undefined}> 
        <Row >          
          <Col md={12} style={{ background: "white" }}>
            <div style={{ margin: 15 }}>
              <AnswerPrintHeader
                profile={props.profile}
                createdAt={props.selectedModel.createdDate ? props.selectedModel.createdDate : props.selectedModel.createdAt}
              />
              <BootstrapTable
                wrapperClasses="table-responsive"
                keyField="_id"
                noDataIndication="No Data Found"
                data={props.selectedModel.submissions ? props.selectedModel.submissions : []}
                columns={[
                  {
                    dataField: "questionName",
                    text: "Question",
                    sort: false
                  },
                  {
                    dataField: "answer",
                    text: "Answer",
                    sort: false
                  },
                  {
                    dataField: "note",
                    text: "Note",
                    sort: false,
                    formatter: (cell, row) => {
                      if (_.isEmpty(cell)) {
                        return <div className="text-center">-</div>;
                      }
                      return (
                        <div className="text-left">
                          {cell}
                        </div>
                      );
                    }
                  }
                ]} />
            </div>
              <SignatureWithName
                signature={signatureLink}
                isBorder={true}
                name={name}
                isCCMQuestion={true}
              />
          </Col>
        </Row>
        </div>
      </div>
    );
  });
  export default AnswerDetailsPrint;