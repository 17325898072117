import React, { Component } from "react";
import { connect } from "react-redux";

import ReactToPrint from "react-to-print";
import Select from "react-select";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import "react-day-picker/lib/style.css";

import Link from "components/ExtendLink";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Form,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  CardImg,
  CardBody,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  CardSubtitle
} from "reactstrap";
import UserCard from "./UserCard";
import ReactLoading from "react-loading";
import classnames from "classnames";
import FilterExceptionCard from "./FilterExceptionCard";
import FilterConditionCard from "./FilterConditionCard";
import TableSummary from "./TableSummary";
import FilterGlobalThreshold from "./FilterGlobalThreshold";
import TableSummaryThreshold from "./TableSummaryThreshold";
const _ = require("lodash");

class ViewGlobal extends Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleFromChange2 = this.handleFromChange2.bind(this);
    this.handleToChange2 = this.handleToChange2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      from2: undefined,
      to2: undefined,
      readingType: undefined,
      readingSubType: undefined,
      readingTypeValue: undefined,
      readingSubTypeValue: undefined,
      condition: ">=",
      value: 0,
      countCondition: undefined,
      count: undefined,
      minCount: undefined,
      maxCount: undefined,

      violation: undefined,
      ruleType: undefined,
      readingType2: undefined,
      readingType2Value: undefined,
      readingSubType2: undefined,
      pickSelect: "none",
      pickSelect2: "none",
      loading: false,
      countRequest: 0,
      prevFilter: undefined,
      prevFilter2: undefined,
      filterType: "condition",
      activeTab: "1",
      hideProfile: false,
      careprovider: null
    };
    this.showFromMonth = this.showFromMonth.bind(this);
    this.showFromMonth2 = this.showFromMonth2.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.submitFilter = this.submitFilter.bind(this);
    this.submitFilterExpectation = this.submitFilterExpectation.bind(this);

    this.handleCareprovider = this.handleCareprovider.bind(this);
  }

  handleCareprovider = selectedOption => {
    //
    const patientIds = selectedOption._id;
    this.setState({
      careprovider: patientIds,
      expectationsList: selectedOption.expectation,
      globalThreshold: selectedOption.globalThreshold
    });
    
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      if (tab === "1") {
        this.setState({
          filterType: "condition"
        });
      } else {
        this.setState({
          filterType: "expectation"
        });
      }
    }
  }
  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  showFromMonth2() {
    const { from2, to2 } = this.state;
    if (!from2) {
      return;
    }
    if (moment(to2).diff(moment(from2), "months") < 2) {
      this.to2.getDayPicker().showMonth(from2);
    }
  }
  handleSelect = (selectedOption, event) => {
   
    if (selectedOption.reading) {
      this.setState({
        readingSubType: selectedOption.index + "i",
        readingSubTypeValue: selectedOption,
        pickSelect: "none"
      });
    }
    if (selectedOption.description) {
      this.setState({
        readingType: selectedOption._id,
        readingTypeValue: selectedOption,
        readingSubType: null,
        readingSubTypeValue: [],
        pickSelect: "none"
      });
    }
    if (selectedOption.sharedUsers) {
      this.setState({
        ruleType: selectedOption.ruleType,
        readingType2Value: selectedOption.name,
        violation: selectedOption._id,
        readingType2: selectedOption.readingType,
        readingSubType2: selectedOption.readingSubType
      });
    }
  };
  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }
  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleFromChange2(from2) {
    // Change the from date and focus the "to" input field
    this.setState({ from2 });
  }
  handleToChange2(to2) {
    this.setState({ to2 }, this.showFromMonth2);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "countCondition" && value === ">=") {
      this.setState({
        [name]: value,
        minCount: undefined,
        maxCount: undefined
      });
    }
    if (name === "countCondition" && value === "<=") {
      this.setState({
        [name]: value,
        minCount: undefined,
        maxCount: undefined
      });
    }
    if (name === "countCondition" && value === "between") {
      this.setState({
        [name]: value,
        value: undefined
      });
    } else {
      this.setState({
        [name]: value
      });
    }
    if (name === "countCondition" && value === "notBetween") {
      this.setState({
        [name]: value,
        value: undefined
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }
  submitFilter(e) {
    e.preventDefault();
    this.setState({
      countCondition2: this.state.countCondition,
      count2: this.state.count,
      minCount2: this.state.minCount,
      maxCount2: this.state.maxCount,
      value: 0
    });
    if (!this.state.readingSubType) {
      this.setState({
        pickSelect: "block"
      });
      return;
    }
    const {
      from,
      to,
      readingType,
      readingSubType,
      readingTypeValue,
      readingSubTypeValue,
      condition,
      value,
      minCount,
      maxCount,
      violation
    } = this.state;

    let cleanUser;

    cleanUser = _.omit(this.state, [
      "readingTypeValue",
      "readingSubTypeValue",
      "minCount",
      "maxCount",
      "pickSelect",
      "activeTab",
      "loading",
      "prevFilter",
      "readingType2Value",
      "expectationsList"
    ]);

    this.props.dispatch({
      type: "DO_FILTER_USER_POPULATION",
      payload: cleanUser
    });

    this.setState({ prevFilter: this.state, countRequest: 1, value: 0 });
  }
  submitFilterExpectation(e) {
    e.preventDefault();
    if (!this.state.violation) {
      this.setState({
        pickSelect2: "block"
      });
      return;
    }
    const {
      from,
      to,
      readingType,
      readingSubType,
      readingTypeValue,
      readingSubTypeValue,
      condition,
      value,
      minCount,
      maxCount,
      violation
    } = this.state;

    let cleanUser;
    if (condition == "between") {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "readingSubTypeValue",
        "value",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "readingType2Value"
      ]);
    } else {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "readingSubTypeValue",
        "minCount",
        "maxCount",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "readingType2Value"
      ]);

      this.props.dispatch({
        type: "DO_FILTER_USER_POPULATION",
        payload: cleanUser
      });
      this.setState({
        countCondition2: this.state.countCondition,
        count2: this.state.count,
        minCount2: this.state.minCount,
        maxCount2: this.state.maxCount
      });
      this.setState({ prevFilter2: this.state, value: 0 });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_REMOVE_POPULATION",
      payload: ""
    });
    this.props.dispatch({
      type: "DO_GET_MY_RULES"
    });
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: "DO_REMOVE_POPULATION",
      payload: ""
    });
  }
  render() {
    const {
      from,
      to,
      from2,
      to2,
      readingType,
      readingSubType,
      readingTypeValue,
      readingSubTypeValue,
      condition,
      value,
      minCount,
      maxCount,
      violation
    } = this.state;
    const modifiers = { start: from, end: to };
    const modifiers2 = { start: from2, end: to2 };
    let filterReadingSubType;
    if (this.props.readingType) {
      filterReadingSubType = _.filter(this.props.readingSubTypes, {
        reading: this.state.readingType
      });
    }
    const countPopulation = _.size(this.props.population);
    let population;
    if (this.props.population && this.props.population.users_with_readings) {
      population = _.orderBy(
        this.props.population.users_with_readings,
        function(user) {
          return user.readings.length;
        },
        ["desc"]
      );
    }
    
    const getReadingOnlyUsers = _.map(this.props.population, "readings");
    const flattenAndCountReadings = _.size(_.flatten(getReadingOnlyUsers));
    const removeFirst = _.dropRightWhile(this.props.myRules, {
      ruleType: "Trending Violation"
    });
    const removeTrending = _.dropRightWhile(removeFirst, {
      ruleType: "Reminder"
    });
    let countExceptions;
    let percentage;
    let countExceptionsReadings;
    let getViolatedBy;
    let flattenAndCountReadingsExpectation;
    let userReadingWithCountException;
    if (
      this.props.populationException &&
      this.props.populationException.expectations
    ) {
      const getReadingOnlyUsers = _.map(
        this.props.populationException.users_with_readings,
        "readings"
      );
      flattenAndCountReadingsExpectation = _.size(
        _.flatten(getReadingOnlyUsers)
      );
      countExceptions = _.size(
        this.props.populationException.expectations.exceptions
      );
      percentage = Math.round(
        (100 * countExceptions) / flattenAndCountReadingsExpectation
      );

      userReadingWithCountException = _.map(
        this.props.populationException.users_with_readings,
        element => {
          const findUserException = _.filter(
            this.props.populationException.expectations.exceptions,
            { violatedBy: { _id: element._id } }
          );
          const countThis = _.size(findUserException);
          return _.extend({}, element, { exceptionCount: countThis });
        }
      );
    }

    if (!this.props.profile) {
      return <div>Please Wait</div>;
    }
    const globalThreshold = this.state.globalThreshold
      ? this.state.globalThreshold
      : undefined;
    return (
      <div>
        <h2 className="text-center py-4">Analyze by Global Threshold</h2>
        <Row>
          <Col sm="12">
            <Form onSubmit={this.submitFilter}>
              <Row form>
                <Col lg={12}>
                  {" "}
                  <FormGroup>
                    <Label for="exampleSelect">Careprovider</Label>
                    <Select
                      required={true}
                      value={this.state.patientsPlaceholder}
                      onChange={this.handleCareprovider}
                      options={this.props.careproviders}
                      isMulti={false}
                      getOptionLabel={option =>
                        option["fname"] + " " + option["lname"]
                      }
                      getOptionValue={option => option}
                    />
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <Label for="exampleCity">Start</Label>
                    <DayPickerInput
                      value={from}
                      placeholder="From"
                      inputProps={{
                        className: "form-control",
                        required: true
                      }}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus()
                      }}
                      onDayChange={this.handleFromChange}
                    />
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <Label for="exampleState">End</Label>
                    <DayPickerInput
                      ref={el => (this.to = el)}
                      value={to}
                      placeholder="To"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      inputProps={{
                        className: "form-control",
                        required: true
                      }}
                      dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: from,
                        fromMonth: from,
                        numberOfMonths: 2
                      }}
                      onDayChange={this.handleToChange}
                    />
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <Label for="exampleZip">Reading Type</Label>

                    <Select
                      name="readingType"
                      onChange={this.handleSelect}
                      required={true}
                      value={this.state.readingTypeValue}
                      options={this.props.readingType}
                      getOptionLabel={option => option["description"]}
                      getOptionValue={option => option["_id"]}
                    />
                    <FormFeedback style={{ display: this.state.pickSelect }}>
                      Please pick a reading type
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <Label for="exampleZip">Reading Subtype</Label>
                    <Select
                      name="readingSubType"
                      onChange={this.handleSelect}
                      required={true}
                      value={this.state.readingSubTypeValue}
                      style={{ width: "200px" }}
                      options={filterReadingSubType}
                      getOptionLabel={option => option["name"]}
                      getOptionValue={option => option["_id"]}
                    />
                    <FormFeedback style={{ display: this.state.pickSelect }}>
                      {" "}
                      Please pick a reading sub type
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <Label for="exampleZip">Condition (Optional)</Label>
                    <Input
                      type="select"
                      name="countCondition"
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select Condition</option>
                      <option value=">=">Greater Than</option>
                      <option value="<=">Less Than</option>
                      <option value="between">Between</option>
                      <option value="notBetween">Not Between</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <Label for="exampleZip">Reading Found (Optional)</Label>
                    {this.state.countCondition === ">=" && (
                      <Input
                        type="text"
                        name="count"
                        placeholder="Count"
                        onChange={this.handleInputChange}
                      />
                    )}
                    {this.state.countCondition === "<=" && (
                      <Input
                        type="text"
                        required
                        name="count"
                        placeholder="Count"
                        onChange={this.handleInputChange}
                      />
                    )}

                    {this.state.countCondition === "between" && (
                      <div>
                        <Input
                          type="text"
                          name="minCount"
                          required
                          placeholder="Min Count"
                          onChange={this.handleInputChange}
                        />
                        <Input
                          type="text"
                          name="maxCount"
                          required
                          placeholder="Max Count"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    )}
                    {this.state.countCondition == "notBetween" && (
                      <div>
                        <Input
                          type="text"
                          name="minCount"
                          required
                          placeholder="Min Count"
                          onChange={this.handleInputChange}
                        />
                        <Input
                          type="text"
                          name="maxCount"
                          required
                          placeholder="Max Count"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <button type="submit" className="btn btn-info btn-block">
                  SUBMIT
                </button>
              </Row>
            </Form>
          </Col>
        </Row>

        {this.props.ongoingRequest && (
          <ReactLoading className="mx-auto" color={"#007bff"} />
        )}
        {this.props.population &&
          this.props.population.totalFilteredCondition === 0 &&
          this.state.countRequest > 0 &&
          !this.props.ongoingRequest &&
          this.state.activeTab === "1" && (
            <h1 className="text-center">No Result Found</h1>
          )}
        {population &&
          population.length > 0 &&
          !this.props.ongoingRequest &&
          this.state.countRequest > 0 &&
          this.state.activeTab === "1" && (
            <Row className="mx-auto">
              {/* <Col md={12} className="no-gutters">
                <FilterGlobalThreshold
                  countExceptions={this.props.population.totalFilteredCondition}
                  totalReadings={this.props.population.totalReadingPerType}
                  average={this.props.population.percent}
                  currentState={this.state.prevFilter}
                />
              </Col> */}

              <Col md={12} className="no-gutters mx-auto">
                <ReactToPrint
                  trigger={() => (
                    <div className="pull-right">
                      <a
                        className="btn btn-info text-white"
                        onClick={() => this.setState({ hideProfile: true })}
                      >
                        <i className="mdi mdi-printer" />
                      </a>
                    </div>
                  )}
                  content={() => this.componentRef}
                  onBeforePrint={() => this.setState({ hideProfile: true })}
                  closeAfterPrint={() => this.setState({ hideProfile: false })}
                  onAfterPrint={() => this.setState({ hideProfile: false })}
                />
                {globalThreshold && (
                  <TableSummaryThreshold
                    ref={el => (this.componentRef = el)}
                    users={population}
                    countCondition={this.state.countCondition2}
                    count={this.state.count2}
                    minCount={this.state.minCount2}
                    maxCount={this.state.maxCount2}
                    threshold={globalThreshold}
                    prevFilter={this.state.prevFilter}
                    hideProfile={this.state.hideProfile}
                    startDate={this.state.prevFilter.from}
                    endDate={this.state.prevFilter.to}
                  />
                )}
              </Col>
              {/* {population.map((user, i) => {
                return (
                  <UserCard
                    formState={this.state}
                    user={user}
                    readings={user.readings}
                    profile={user}
                  />
                );
              })} */}
            </Row>
          )}
      </div>
    );
  }
}
function mapStateToProps({ auth, careprovider, user, client }) {
  const { readingSubTypes, readingType } = auth;
  const { careproviders } = client;
  const { profile } = user;
  const {
    population,
    populationException,
    isGenericSuccess,
    isGenericError,
    ongoingRequest,
    myRules
  } = careprovider;
  return {
    population,
    populationException,
    readingSubTypes,
    readingType,
    isGenericSuccess,
    isGenericError,
    ongoingRequest,
    myRules,
    profile,
    careproviders
  };
}
export default connect(mapStateToProps)(ViewGlobal);
