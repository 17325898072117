import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
// import Video from "../Video/";
import { initializeFirebase } from "../../push-notification";
import { initializePush } from "../../permission";
import UserDashboard from "../UserDashboard/index";
class Dashboard extends Component {
  constructor(props) {
    super(props);

    initializeFirebase();

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    initializePush(this.props.userId);
  }
  render() {
    return (
      <div>
        <UserDashboard
          owner={this.props.userId}
          hideBackButton
          hideProfile
          hideException
          hideNotesButton
          hidePmSessions
        />
        {/* <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <h1>Welcome</h1>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    usersOfferedAccessOrganization
  } = careprovider;
  const userId = user.userDetails._id;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    usersOfferedAccessOrganization,
    userId
  };
};

export default connect(mapStateToProps)(Dashboard);
