import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import moment from "moment";
import "react-day-picker/lib/style.css";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
  Row,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from "reactstrap";
import classnames from "classnames";
import TableSummary from "./TableSummary";
import { dataConditions } from "../../../constants/general.constant";
const _ = require("lodash");

class index extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      from: '',
      to: '',
      readingType: undefined,
      readingSubType: undefined,
      readingTypeValue: undefined,
      readingSubTypeValue: undefined,
      condition: undefined,
      value: undefined,
      minValue: undefined,
      maxValue: undefined,
      violation: undefined,
      ruleType: undefined,
      pickSelect: "none",
      loading: false,
      countRequest: 0,
      prevFilter: undefined,
      filterType: "expectation",
      activeTab: "1",
      careprovider: null,
      disableReadings: false,
      link: undefined,
      startDateError : undefined,
      endDateError : undefined,
      page : 1,
      limit : 25,
      search : '',
      timeoutId: null,
      isWeightSelectExcpection : false,
      expectationId : undefined,
      maxValueValidation : 'none',
      isEventExceptions : false,
      subTypeName : null,
      valueError : null, 
      lossPound : undefined,
      subTypeExceptionName:null,
      timezone: moment.tz.guess()
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCareprovider = this.handleCareprovider.bind(this);
    this.submitFilter = this.submitFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this)
    this.paginationPatients = this.paginationPatients.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.exportCSV = this.exportCSV.bind(this)
  }
  handleCareprovider = selectedOption => {
    this.props.dispatch({
      type: "DO_GET_CAREPROVIDER_EXPECTATIONS_REPORT",
      payload: {careproviderId : selectedOption?._id}
    });
    const patientIds = selectedOption?._id;
    this.setState({
      careprovider: patientIds,
      readingType: undefined,
      readingSubType: undefined,
      readingTypeValue: undefined,
      readingSubTypeValue: undefined,
      condition: undefined,
      value: undefined,
      minValue: undefined,
      maxValue: undefined,
      violation: undefined,
      ruleType: undefined,
      pickSelect: "none",
      loading: false,
      countRequest: 0,
      prevFilter: undefined,
      disableReadings: false,
      link: undefined,
      startDateError : undefined,
      endDateError : undefined,
      page : 1,
      limit : 25,
      search : '',
      timeoutId: null,
      isWeightSelectExcpection : false,
      expectationId : undefined,
      maxValueValidation : 'none',
      isEventExceptions : false,
      subTypeName : null,
      valueError : null, 
      lossPound : undefined,
      subTypeExceptionName:null,
      rule : ''
    });
    
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      if (tab === "1") {
        this.setState({
          filterType: "expectation"
        });
      } else {
        this.setState({
          filterType: "expectation"
        });
      }
    }
  }

  handleSelect = (selectedOption, event) => {
    if(event.action === "clear"){
      this.setState({
        rule: '',
        value : '',
        readingType: undefined,
        readingTypeValue: undefined,
        readingSubType: undefined,
        subTypeName : null,
        subTypeExceptionName : null,
        readingSubTypeValue: undefined,
        condition: undefined,
        expectationId : undefined,
        isWeightSelectExcpection : false, 
        minValue : '',
        maxValue : ''
      });
    }
    if(selectedOption?.readingType){
      let filterReadingSubType;
      if (selectedOption.readingType) {
        this.props.readingTypeForExpectation.map((readingType) => {
          if(readingType._id === selectedOption.readingType){
           filterReadingSubType = readingType?.subTypes
          }
         })
      }
      var subTypeIndex = _.filter(filterReadingSubType, function(subTypeItem) {
        return subTypeItem._id === selectedOption.readingSubTypeId;
      });
      const nameSubType = subTypeIndex.length && subTypeIndex[0].name;
      this.setState({
        readingType: selectedOption.readingType,
        readingTypeValue: selectedOption.readingType,
        readingSubType: selectedOption?.readingSubType == 'Mean Arterial' ? 1  + "i" : subTypeIndex.length && subTypeIndex[0]['index']+"i" || 0,
        subTypeName : (nameSubType || selectedOption?.readingSubType) ? (nameSubType == 'Heart Rate') ? 'pulseRate' : nameSubType == 'Oxygen Saturation' ? 'spo2' : nameSubType == 'Body Temperature' ? 'temperature' : selectedOption?.readingSubType == 'Mean Arterial' ? 'meanArterial' : nameSubType.toLowerCase() || '' : '',
        subTypeExceptionName : nameSubType || selectedOption?.readingSubType,
        readingSubTypeValue: selectedOption?.readingSubType == 'Mean Arterial' ? '5be56c383863cd0647d79893' : selectedOption.readingSubTypeId,
        condition: selectedOption.condition,
        pickSelect: "none",
        disableReadings: true,
        filterType: 'expectation',
        rule: selectedOption._id
      });
      if(selectedOption.ruleType == "Weight"){
        this.setState({lossPound: selectedOption.gainLossPerDay, 
        value: undefined,
        maxValueValidation : 'none', 
        valueError : null,
        minValue: undefined,
        maxValue: undefined,
        condition: undefined,
      });
      }else{
        if(selectedOption.condition == "between"){
          this.setState({minValue: selectedOption.valueProperty[0].minValue});
          this.setState({maxValue: selectedOption.valueProperty[0].maxValue, lossPound : undefined});
        }else{
          this.setState({value: selectedOption.valueProperty[0].value, lossPound : undefined});
        }
      }
    }else{
      this.setState({
        disableReadings: false,
        rule: ''
      });
    }
    if (selectedOption?.reading) {
      this.setState({
        subTypeName : selectedOption ?  (selectedOption.name == 'Heart Rate') ? 'pulseRate' : selectedOption.name == 'Oxygen Saturation' ? 'spo2' : selectedOption.name == 'Body Temperature' ? 'temperature' : selectedOption.readingSubType == 'Mean Arterial' ? 'meanArterial' : selectedOption.name.toLowerCase() : '',
        subTypeExceptionName : selectedOption?.name || selectedOption?.readingSubType,
        readingSubType: selectedOption?.readingSubType == 'Mean Arterial' ? 1  + "i" : selectedOption.index + "i",
        readingSubTypeValue: selectedOption.readingSubType == 'Mean Arterial' ? '5be56c383863cd0647d79893' :  selectedOption._id,
        pickSelect: "none", 
        expectationId : undefined,
        rule : ''
      });
    }
    if (selectedOption?.description && !selectedOption?.reading) {
      this.setState({
        readingType: selectedOption._id,
        readingTypeValue: selectedOption,
        readingSubType: null,
        readingSubTypeValue: undefined,
        pickSelect: "none",
        expectationId : undefined,
        rule : ''
      });
    }
    if (selectedOption?.sharedUsers) {
      this.setState({
        ruleType: selectedOption.ruleType,
        violation: selectedOption._id,
        readingSubType2: selectedOption.readingSubType
      });
    }
    if(event && event.name && event.name == 'expectation'){
      if(selectedOption && selectedOption.name &&  selectedOption.name.toLowerCase().includes("Event".toLowerCase())){
        this.setState({isEventExceptions : true})
      }else{
        this.setState({isEventExceptions : false})
      }
      if (selectedOption && (selectedOption.ruleType == "Weight")) {
        this.setState({ isWeightSelectExcpection: true});
      } else {
        this.setState({ isWeightSelectExcpection: false });
      }
      this.setState({expectationId : selectedOption && selectedOption._id})
    }
    this.setState({page : 1,limit : 25})
  };
 
  validDateValiadation(start, to ){
    const todayDate = moment().format('YYYY-MM-DD');
    let startDate;
    let endDate;
    if(start){
      startDate = moment(start).format('YYYY-MM-DD');
    }
    if(to){
      endDate = moment(to).format('YYYY-MM-DD');
    }
   let errorFlag = false;
   if(startDate && startDate > todayDate){
    errorFlag = true;
      this.setState({ startDateError : "Please select start date less than today." });
  }
  if(endDate && endDate > todayDate){
    errorFlag = true;
      this.setState({ endDateError: "Please select end date less than today." });
  }
  if(endDate && startDate){
    if(startDate > endDate){
      errorFlag = true;
      this.setState({ startDateError: "Please select start date less than end date." });
    }
    if(endDate < startDate){
      errorFlag = true;
      this.setState({ endDateError: "Please select end date greater than start date." });
    }
  }
  if(!errorFlag){
    this.setState({
      endDateError: undefined,
      startDateError: undefined
    });
  }
  }

  handleDateChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState(
      { [name]: value,page : 1,limit : 25 }, () => {
        this.validDateValiadation(this.state.from, this.state.to);
      }
    );
  }
  handleInputChange(event) {
    const target = event && event.target;
    const value = target && target.type === "checkbox" ? target.checked : target && target.value;
    const name = target && target.name;
    if (event && event.value === ">=") {
      this.setState({
        condition: event.value ,
        minValue: undefined,
        maxValue: undefined,
        maxValueValidation : 'none', 
        valueError : null,
        value : undefined
      });
    }
    if (event && event.value === "<=") {
      this.setState({
        condition: event.value ,
        minValue: undefined,
        maxValue: undefined,
        maxValueValidation : 'none', 
        valueError : null,
        value : undefined
      });
    }
    if (event && event.value === "between") {
      this.setState({
        condition: event.value ,
        value: undefined,
        maxValueValidation : 'none', 
        valueError : null,
        minValue: undefined,
        maxValue: undefined,
      });
    } else {
      this.setState({
        [name]: value
      });
    }
    if (event && event.value === "notBetween") {
      this.setState({
        condition: event.value ,
        value: undefined,
        maxValueValidation : 'none', 
        valueError : null,
        minValue: undefined,
        maxValue: undefined,
      });
    } else {
      this.setState({
        [name]: value
      });
    }
    if((name == 'minValue' || name == 'maxValue' || name == 'value') && value){
      if (isNaN(value)) {
        this.setState({maxValueValidation : 'block', valueError : 'Value must be a number and greater than zero'})
      }else if(parseFloat(value) <= 0){
        this.setState({maxValueValidation : 'block', valueError : 'Value must be a number and greater than zero'})
        return;
      }else{
        this.setState({maxValueValidation : 'none', valueError : null})
      }
    }
    this.setState({page : 1,limit : 25})
  }
  getPatientData(){
    const {
      condition,
    } = this.state;
    let cleanUser;
    
    if (condition == "between" || condition == "notBetween") {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "value",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "startDateError",
        "endDateError",
        'timeoutId'
      ]);
    } else {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "minValue",
        "maxValue",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "startDateError",
        "endDateError",
        "timeoutId"
      ]);
    }
    this.props.dispatch({
      type: "DO_FILTER_USER_POPULATION",
      payload: cleanUser
    });
    this.setState({ prevFilter: this.state, countRequest: 1, pickSelect : 'none' });
  }
  submitFilter(e) {
    e.preventDefault(); 
    if (!this.state.careprovider || ((!this.state.readingSubType || !this.state.readingTypeValue) && (!this.state.rule))) {
      this.setState({
        pickSelect: "block"
      });
      return;
    }
    if(!this.state.readingSubTypeValue || !this.state.condition){
      this.setState({
        pickSelect: "block"
      });
      return;
    }
    let { maxValue, minValue, value } = this.state;
    if (value && isNaN(value)) {
      this.setState({maxValueValidation : 'block', valueError : 'Value must be a number and greater than zero'})
      return;
    }else if (minValue && isNaN(minValue)) {
      this.setState({maxValueValidation : 'block', valueError : 'Value must be a number and greater than zero'})
      return;
    }else if (maxValue && isNaN(maxValue)) {
      this.setState({maxValueValidation : 'block', valueError : 'Value must be a number and greater than zero'})
      return;
    }else{
      this.setState({maxValueValidation : 'none', valueError : null})
    }
    
    if(maxValue && minValue && parseFloat(minValue) > parseFloat(maxValue)){
      this.setState({maxValueValidation : 'block', valueError : 'Maximum value should be greater than or equal to the minimum value'})
      return;
    }else if(parseFloat(minValue) <= 0 || parseFloat(maxValue) <= 0 || parseFloat(value) <= 0){
      this.setState({maxValueValidation : 'block', valueError : 'Value must be a number and greater than zero'})
      return;
    }else{
      this.setState({maxValueValidation : 'none', valueError : null})
    }
    if(this.state.startDateError || this.state.endDateError){
      return;
    }
    this.getPatientData();
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_REMOVE_POPULATION",
      payload: ""
    });
    this.props.dispatch({
      type: "DO_GET_MY_RULES"
    });
    this.props.dispatch({
      type: "DO_GET_CAREPROVIDER",
      payload: ""
    });
    this.props.dispatch({
      type: "ONGOING_POPULATION_REQUEST",
        payload: false
    })
    this.props.dispatch({
      type: "ONGOING_POPULATION_DATA_REQUEST",
        payload: false
    })
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: "DO_REMOVE_POPULATION",
      payload: ""
    });
  }
  clearFilter(){
    this.setState({
      from: '',
      to: '',
      readingType: undefined,
      readingSubType: undefined,
      readingTypeValue: undefined,
      readingSubTypeValue: undefined,
      condition: undefined,
      violation: undefined,
      ruleType: undefined,
      pickSelect: "none",
      loading: false,
      countRequest: 0,
      prevFilter: undefined,
      filterType: "expectation",
      careprovider: null,
      rule: undefined,
      disableReadings: false,
      startDateError : undefined,
      endDateError : undefined,
      value: undefined,
      minValue: undefined,
      maxValue: undefined,
      search : '',
      expectationId : undefined,
      isWeightSelectExcpection : false,
      page : 1,
      limit : 25,
      maxValueValidation : 'none',
      valueError : null,
      subTypeExceptionName : null,
      subTypeName : null,
      lossPound : undefined,
    })
  }
  paginationPatients(type,{ page, sizePerPage}){
    let data = {
      page: page, limit: sizePerPage
    }
   this.setState(data, () => {this.getPatientData()})
  }
    handleChange = (e) => {
      e.preventDefault();
      let value = e.target.value
      this.setState({
        search : value , page : 1, limit : 25
      });
      if (this.state.timeoutId) {
        clearTimeout(this.state.timeoutId);
      }
  
      // Set a new timeout to make the API call after 200ms of user inactivity
      const newTimeoutId = setTimeout(() => {
        this.getPatientData();
      }, 500);
  
      // Update the timeoutId state with the new timeout ID
      this.setState({ timeoutId: newTimeoutId });

    }; 
     exportCSV(){
      this.props.dispatch({
        type: "DO_EXPORT_PATIENT_POPULATE_LIST",
        payload: this.state.prevFilter,
      });
     }
  render() {
    const {
      from,
      to,
    } = this.state;
    const customStyles = {
      control: base => ({
        ...base,
        height: 40,
        minHeight: 40
      })
    };
    let filterReadingSubType;
    
    if (this.props.readingTypeForExpectation) {
     this.props.readingTypeForExpectation.map((readingType) => {
       if(readingType._id === this.state.readingType){
        filterReadingSubType = readingType?.subTypes;
       }
      })
    }
    const getReadingOnlyUsers = _.map(this.props.population, "readings");
    const flattenAndCountReadings = _.size(_.flatten(getReadingOnlyUsers));
    const removeFirst = _.dropRightWhile(this.props?.careProviderExpectations || [], {
      ruleType: "Trending Violation"
    });
    const removeTrending = _.dropRightWhile(removeFirst, {
      ruleType: "Reminder"
    });
    let weightExceptions = [];
    if(this.props && this.props.careProviderExpectations){
      const exceptions = this.props?.careProviderExpectations || [];
      exceptions.map((item) => {
        if(item && item.ruleType == 'Weight' && item.createdBy == this.state?.careprovider){
          weightExceptions.push(item);
        }
      })
    }
    let countExceptions;
    let percentage;
    let countExceptionsReadings;
    let getViolatedBy;
    let flattenAndCountReadingsExpectation;
    let userReadingWithCountException;
    if (
      this.props.populationException &&
      this.props.populationException.expectations
    ) {
      const getReadingOnlyUsers = _.map(
        this.props.populationException.users,
        "readings"
      );
      flattenAndCountReadingsExpectation = _.size(
        _.flatten(getReadingOnlyUsers)
      );
      countExceptions = _.size(
        this.props.populationException.expectations.exceptions
      );
      percentage = Math.round(
        (100 * countExceptions) / flattenAndCountReadingsExpectation
      );

      userReadingWithCountException = _.map(
        this.props.populationException.users,
        element => {
          const findUserException = _.filter(
            this.props.populationException.expectations.exceptions,
            { violatedBy: { _id: element._id } }
          );
          const countThis = _.size(findUserException);
          return _.extend({}, element, { exceptionCount: countThis });
        }
      );

      // const userReadingWithCountException = _.map(
      //   this.props.populationException.users,
      //   function(element) {
      //     const findUserException = _.filter(
      //       this.props.populationException.expectations.exceptions,
      //       { violatedBy: { _id: element._id } }
      //     );
      //     const countThis = _.size(findUserException);
      //     return _.extend({}, element, { exceptionCount: countThis });
      //   }
      // );
    }

    return (
      <div>
        <div className=" grid-margin stretch-card">{/*Shrey Added */}
          <div className="card overflow-hidden">{/*Shrey Added */}
              <Nav tabs className="justify-content-center">{/*Shrey Added */}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                  Population Data
                  </NavLink>
                </NavItem>
              </Nav>
            <div className="card-body pt-2 pb-4">
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12" md={12} lg={12}>
                      <Form onSubmit={this.submitFilter}>
                        <Row form>
                          <Col lg={6}>
                            <FormGroup>
                              <Label for="exampleSelect">Careprovider</Label>
                              <Select
                                required={true}
                                value={this.state.careprovider ? this.props.careProviderAll.find((item) => item._id === this.state.careprovider) : ''}
                                onChange={this.handleCareprovider}
                                options={this.props.careProviderAll}
                                isMulti={false}
                                getOptionLabel={option =>
                                  option["fname"] + " " + option["lname"]
                                }
                                getOptionValue={option => option}
                                styles={customStyles}
                                name='careProvider'
                              />
                              {(!this.state.careprovider) && <FormFeedback
                                style={{ display: this.state.pickSelect }}
                              >
                                Please pick a Careprovider
                              </FormFeedback>}
                            </FormGroup>
                          </Col>
                          <Col lg={6}>
                          <FormGroup>
                          <Label for="exampleSelect">
                            Pick an Expectation
                          </Label>
                          <Select
                            required={true}
                            isClearable={true}
                            value={this.state.rule ? removeTrending.find((item) => item._id === this.state.rule) : ''}
                            onChange={this.handleSelect}
                            getOptionLabel={option => option["name"]}
                            getOptionValue={option => option["_id"]}
                            options={removeTrending}
                            styles={customStyles}
                            name="expectation"
                            noOptionsMessage={() => 'No Expectation Found'}
                            isLoading={this.props.ongoingPopulationDataRequest}
                            isDisabled={this.props.ongoingPopulationDataRequest}
                          />
                          {!this.state.rule && !this.state.readingTypeValue &&  <FormFeedback
                            style={{ display: this.state.pickSelect }}
                          >
                            Please pick an Expectation
                          </FormFeedback>}
                        </FormGroup>
                          </Col>
                          {!(this.state.expectationId && this.state.readingTypeValue == '5bb306382598931ffbd1b624' && this.state.readingSubTypeValue == '5be56c493863cd0647d79894') && <Col lg={3}>
                            <FormGroup className="reading_select">
                              <Label for="exampleZip">Reading Type</Label>

                              <Select
                                isClearable={true}
                                name="readingType"
                                onChange={this.handleSelect}
                                value={this.state.readingTypeValue ? this.props.readingTypeForExpectation.find((item) => item._id === this.state.readingTypeValue) : ''}
                                options={this.props.readingTypeForExpectation}
                                getOptionLabel={option => option["description"]}
                                getOptionValue={option => option["_id"]}
                                isDisabled={this.state.disableReadings}
                                styles={customStyles}
                              />
                              {(!this.state.readingTypeValue) && <FormFeedback
                                style={{ display: this.state.pickSelect }}
                              >
                                Please pick a Reading type
                              </FormFeedback>}
                            </FormGroup>
                          </Col>}
                          {(this.state.subTypeName !== 'meanArterial') && <Col lg={3}>
                            <FormGroup className="reading_select">
                              <Label for="exampleZip">Reading Subtype</Label>
                              <Select
                                name="readingSubType"
                                onChange={this.handleSelect}
                                required={true}
                                value={this.state.readingSubTypeValue ? filterReadingSubType.find((item) => item._id === this.state.readingSubTypeValue) : ''}
                                style={{ width: "200px" }}
                                options={filterReadingSubType}
                                getOptionLabel={option => option["name"]}
                                getOptionValue={option => option["_id"]}
                                isDisabled={this.state.disableReadings}
                                styles={customStyles}
                              />
                              {(!this.state.readingSubTypeValue) && <FormFeedback
                                style={{ display: this.state.pickSelect }}
                              >
                                {" "}
                                Please pick a Reading sub type
                              </FormFeedback>}
                            </FormGroup>
                          </Col>}
                          <Col lg={3}>
                            <FormGroup className="reading_select">
                              <Label for="exampleZip">Condition</Label>
                              <Select
                              name="condition"
                              onChange={this.handleInputChange}
                              required={true}
                              options={dataConditions}
                              getOptionLabel={option => option["label"]}
                              getOptionValue={option => option["value"]}
                              value={this.state.condition ? dataConditions.find((item) => item.value === this.state.condition) : ''}
                              isDisabled={this.state.isWeightSelectExcpection ? false : this.state.disableReadings}
                              styles={customStyles}
                            />
                            {(!this.state.condition) && <FormFeedback
                              style={{ display: this.state.pickSelect }}
                            >
                              {" "}
                              Please select Condition
                            </FormFeedback>}
                
                            </FormGroup>
                          </Col>
                          <Col lg={3}>
                            <FormGroup>
                              <Label for="exampleZip">Value</Label>
                              {(!this.state.condition || this.state.condition === ">=" || this.state.condition === "<=") && (
                                <Input
                                  type="text"
                                  required
                                  name="value"
                                  placeholder="Value"
                                  onChange={this.handleInputChange}
                                  value={this.state.value || ''}
                                  disabled={this.state.isWeightSelectExcpection ? false : (!this.state.condition || this.state.disableReadings)}
                                />
                              )}
                              
                              {(this.state.condition === "between" || this.state.condition == "notBetween") && (
                                <div className="d-flex">
                                  <Input
                                    type="text"
                                    name="minValue"
                                    required
                                    placeholder="Min Value"
                                    onChange={this.handleInputChange}
                                    value={this.state.minValue || ''}
                                    disabled={this.state.isWeightSelectExcpection ? false : this.state.disableReadings}
                                  />
                                  <Input
                                    type="text"
                                    name="maxValue"
                                    required
                                    placeholder="Max Value"
                                    onChange={this.handleInputChange}
                                    className="ml-1"
                                    value={this.state.maxValue || ''}
                                    disabled={this.state.isWeightSelectExcpection ? false : this.state.disableReadings}
                                  />
                                  
                                </div>
                              )}
                              <FormFeedback
                              style={{ display: this.state.maxValueValidation}}
                            >
                              {" "}
                              {this.state.valueError}
                            </FormFeedback>
                            </FormGroup>
                          </Col>
                          {(this.state.isWeightSelectExcpection) && <Col lg={3}>
                          <FormGroup>
                            <Label for="exampleZip">Pound loss/gain per Day</Label>
                              <Input
                                type="text"
                                name="lossPound"
                                placeholder="Pound loss/gain per Day"
                                value={this.state.lossPound || ''}
                                disabled={true}
                              />
                          </FormGroup>
                        </Col>}
                          <Col lg={3}>
                            <FormGroup>
                              <Label for="exampleCity">Start Date</Label>
                              <Input
                                className="input-date-filter remove-bottom-margin"
                                type="date"
                                name="from"
                                max={to ? moment(to).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                onChange={this.handleDateChange}
                                value={from}
                                required
                              />
                              <Label
                              style={{
                                color: "red",
                                textAlign: "left",
                                padding: 5
                              }}>{this.state.startDateError}</Label>
                            </FormGroup>
                          </Col>
                          <Col lg={3}>
                            <FormGroup>
                              <Label for="exampleState">End Date</Label>
                              <Input
                              className="input-date-filter remove-bottom-margin"
                              type="date"
                              name="to"
                              max={moment().format("YYYY-MM-DD")}
                              onChange={this.handleDateChange}
                              value={to}
                              required
                            />
                              <Label
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  padding: 5
                                }}>{this.state.endDateError}</Label>
                            </FormGroup>
                          </Col>
                          <Col>
                          <div className="d-flex justify-content-between">
                          <button
                          type="submit"
                          className="btn btn-info btn-block mt-4 p-0 d-flex align-items-center justify-content-center" 
                          style={{ float: 'right', marginTop: 20,  width:150, height:37, 'borderRadius': '8px' }}
                        >  <i className="mdi mdi-content-save" style={{
                          fontSize: 20
                        }}></i>&nbsp;
                          SUBMIT
                        </button>
                       {(this.state.careprovider || this.state.exceptionType || this.state.readingSubType || this.state.readingType || this.state.value || this.state.from || this.state.to || this.state.condition) && (
                        <button
                        type="button"
                        className="btn btn-info close-click-cls ml-3 mt-4 p-0 d-flex align-items-center justify-content-center"
                        style={{ float: 'right', marginTop: 20, width:150, height:37 }}
                        onClick={this.clearFilter}
                      >
                      <i className="mdi mdi-close" style={{
                        fontSize: 20
                      }}></i>
                        CLEAR FILTERS
                      </button>
                       )}
                      </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                  {this.state.countRequest > 0 &&
                    this.state.activeTab === "1" && (
                      <div>
                      <Row className={`text-center col-md-12 mx-auto float-right remove-pading-table`}>
                      <Col md={10}>
                      <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-date-filter remove-bottom-margin"
                        id="exampleInputName1Test"
                        style={{ fontSize: "20px" }}
                        placeholder="Search Last Name, First Name, Organization, DOB"
                        onChange={this.handleChange}
                        value={this.state.search}         
                         />
                    </div>
                      </Col>
                
                      <Col md={2}>
                          <button className="btn btn-info ml-2 download-click-cls" onClick={this.exportCSV} disabled={this.props.ongoingPopulationDataRequest || false}>
                            <i className="mdi mdi-download" style={{
                              fontSize: 20
                            }}></i> Export to CSV
                          </button>
                      </Col>
                    </Row>
                    </div>
                    )}
                  {!this.props.ongoingPopulationRequest &&
                    this.state.countRequest > 0 &&
                    this.state.activeTab === "1" && (
                      <div className="mx-auto">
                         {/* <FilterConditionCard
                            countExceptions={this.props.population.totalFilteredCondition}
                            totalReadings={this.props.population.totalReadings}
                            average={this.props.population.percent}
                            currentState={this.state.prevFilter}
                            selectedtTab={tabSelection}
                    />*/}
                        <TableSummary
                        ref={el => (this.componentRef = el)}
                        users={this.props?.population?.users}
                        prevFilter={this.state.prevFilter}
                        hideProfile={true}
                        startDate={this.trueom}
                        endDate={this.state.prevFilter.to}
                        average={this.props?.population?.percent}
                        dispatch={this.props.dispatch}
                        populationFilePath={this.props?.populationFilePath}
                        loading={this.props.ongoingPopulationRequest}
                        exceptionType={this.state.disableReadings}
                        pagination={this.paginationPatients}
                        page={this.props.population?.page || 1}
                        limit={this.props.population?.limit || 25}
                        total={this.props.population?.totalReadings || 0}
                        careProviderExceptions={this.props?.careProviderExpectations || []}
                      />
                      </div>
                    )}
                    {
                      this.props.ongoingPopulationRequest &&
                      this.state.activeTab === "1" && (
                        <div className="mx-auto">
                          <TableSummary
                          ref={el => (this.componentRef = el)}
                          users={[]}
                          loading={this.props.ongoingPopulationRequest}
                          exceptionType={this.state.disableReadings}
                          hideProfile={true}
                        />
                        </div>
                      )}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps({ auth, careprovider, client }) {
  const { readingTypeForExpectation } = auth;
  const {
    population,
    populationException,
    isGenericSuccess,
    isGenericError,
    myRules,
    populationFilePath,
    ongoingPopulationRequest,
    careProviderAll,
    careProviderExpectations,
    ongoingPopulationDataRequest
  } = careprovider;
  return {
    population,
    populationException,
    readingTypeForExpectation,
    isGenericSuccess,
    isGenericError,
    myRules,
    careProviderAll,
    populationFilePath,
    ongoingPopulationRequest,
    careProviderExpectations,
    ongoingPopulationDataRequest
  };
}
export default connect(mapStateToProps)(index);
