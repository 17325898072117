import React from "react";
import Link from "components/ExtendLink";
const Heading = props => (
  <div className="col-12">
    <div className="page-header">
      <h3 className="page-title">
        {props.children}

        {props.title}
      </h3>
      <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <span />

            <i className="mdi mdi-alert-circle-outline icon-sm text-danger align-middle" />
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

export default Heading;
