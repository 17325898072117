import React from "react";
import { withRouter } from "react-router-dom";
import Link from "components/ExtendLink";
import { Nav, NavItem, NavLink } from "reactstrap";
import logoImage from "../../files/smarthealth.jpg";
import staffImage from "../../files/staff.png";
import doctorImage from "../../files/doctor.png";

const Navbar = (props) => {
  const logo = props.clientInfo ? props.clientInfo.websiteLogo : logoImage;
  const headerColor = props.clientInfo ? props.clientInfo.headerColor : "white";
  const heightNavbar = props.clientInfo ? "" : "";
  const paddingLogo = props.clientInfo ? "" : "";
  return (
    <nav
      className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
      style={{
        background: headerColor,
        color: "white",
      }}
    >
      <a className="navbar-brands">
        <img src={logo} alt="" className="img-fluidheight" />
      </a>
      <div
        className="navbar-menu-wrapper d-flex align-items-stretch"
        style={{ height: heightNavbar }}
      >
        <ul className="navbar-nav navbar-nav-left d-flex align-items-center navbar-left mr-auto">
          <NavLink
            onClick={props.openSidebar}
            to="/inbox"
            className="nav-link count-indicator dropdown-toggle ml-0"
          >
            <button
              className="navbar-toggler navbar-toggler-right  align-self-center"
              type="button"
              data-toggle="offcanvas"
              onClick={props.openSidebar}
            >
              <span className="mdi mdi-menu" style={{ fontSize: "24px" }} />
            </button>
          </NavLink>

          {props.location.pathname.includes("dashboard") && (
            <>
              {props.timerStarts && (
                <>
                  <li
                    className="nav-item d-none d-lg-block full-screen-link"
                    style={{ zIndex: 999999 }}
                  >
                    <a className="nav-link mx-1">
                      <button
                        type="button"
                        className="btn btn-sm btn-gradient-dark btn-icon-text stop-PM-button"
                        onClick={props.stopPmMode}
                      >
                        <i className="mdi mdi-monitor-dashboard btn-icon-prepend mb-3 remove-padding added-pading-icon" />
                        <p className="remove-padding added-pading-icon">{props.time}</p>
                        <p className="remove-padding added-pading-icon">STOP {props.mode}</p>
                      </button>
                    </a>
                  </li>
                  <li
                    className="nav-item d-none d-lg-block full-screen-link"
                    style={{ zIndex: 999999 }}
                  >
                    <a className="nav-link mx-1">
                      <button
                        type="button"
                        className="btn btn-sm btn-gradient-danger btn-icon-text button-radius video-call-button-color"
                        onClick={props.requestVideo}
                      >
                        <i className="mdi mdi-video btn-icon-prepend" />
                        Request Virtual Visit TeleHealth Call
                      </button>
                    </a>
                  </li>
                  {/* <li className="nav-item d-none d-lg-block full-screen-link">
                <a className="nav-link mx-1">
                  <button
                    type="button"
                    className="btn btn-gradient-danger btn-icon-text"
                  >
                    <i className="mdi mdi-phone btn-icon-prepend" />
                    REQUEST CALL
                  </button>
                </a>
              </li> */}
                </>
              )}
              {!props.timerStarts  && (props.user.isOtherRole == true || (props.user.isOtherRole == false && props.user.staffRole != 'Shared/Family Account')) && (
                <>
                  {
                    <li
                      className="nav-item d-none d-lg-block full-screen-link"
                      style={{ zIndex: 999999 }}
                    >
                      <a className="nav-link mx-1">
                        <button
                          type="button"
                          className="btn btn-sm btn-gradient-light btn-icon-text start-PM-button"
                          onClick={props.startPmMode}
                        >
                          <i className="mdi mdi-monitor-dashboard btn-icon-prepend" />
                          START PM MODE
                        </button>
                      </a>
                    </li>
                  }
                  <li
                    className="nav-item d-none d-lg-block full-screen-link"
                    style={{ zIndex: 999999 }}
                  >
                    <a className="nav-link mx-1">
                      <button
                        type="button"
                        className="btn btn-sm btn-gradient-danger btn-icon-text button-radius video-call-button-color"
                        onClick={props.requestVideo}
                      >
                        <i className="mdi mdi-video btn-icon-prepend" />
                        Request Virtual Visit TeleHealth Call
                      </button>
                    </a>
                  </li>
                </>
              )}
            </>
          )}
        </ul>

        {/* <Link to="/" className="navbar-brand" href="/">
          <img className={paddingLogo} alt="" src={logo} />
        </Link> */}
        <ul className="navbar-nav navbar-nav-right navbar-right">
          <li className="nav-item dropdown">
            <NavLink
              tag={Link}
              to="/inbox"
              className="nav-link count-indicator dropdown-toggle ml-0"
            >
              <i className="mdi text-dark mdi-bell bell-icon" />
              { props.unreadCount != 0 &&
                <span className="unread-cound" > { props.unreadCount } </span>
              }
            </NavLink>
          </li>
          <li className="nav-item dropdown" style={{ zIndex: 88888 }}>
            <NavLink tag={Link} to="/logout" className="nav-link ml-0">
              <i className="mdi text-dark mdi-power logout-icon" />
            </NavLink>
          </li>
          <li className="nav-item nav-profile dropdown ">
            <a
              className="nav-link dropdown-toggle ml-0 pl-0 mr-0"
              id="profileDropdown"
              href="#"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="nav-profile-text pr-1">
                <p className="mb-1 text-dark font-weight-bold">
                  {props.user.fname} {props.user.lname}
                </p>
              </div>
              <div className="nav-profile-img">
                <img
                  src={props.user.isStaff ? staffImage : doctorImage}
                  alt="image"
                />
                <span className="availability-status online" />
              </div>
            </a>
            <div
              className="dropdown-menu navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <a className="dropdown-item" href="#">
                <i className="mdi text-white mdi-cached mr-2 text-success" />
                Activity Log
              </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="#">
                <i className="mdi text-white mdi-logout mr-2 text-primary" />
                Signout
              </a>
            </div>
          </li>
        </ul>
        {/* <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
          onClick={props.openSidebar}
        >
          <span className="mdi text-white mdi-menu" />
        </button> */}
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
