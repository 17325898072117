import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import Link from "components/ExtendLink";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Form,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  CardImg,
  CardBody,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  CardSubtitle
} from "reactstrap";
import moment from "moment";
const _ = require("lodash");
function fullname(cell, row) {
  return (
    <div>
      {row.fname} {row.lname}
    </div>
  );
}
function countReadings(cell, row) {
  return <div>{_.size(row.readings)}</div>;
}

function viewProfile(cell, row, s, dateRange) {
  return (
    <div>
      <Link
        to={`/refer-population/dashboard/${row.fname
          .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
          .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${
          row._id
        }/${moment(dateRange.startDate).format()}/${moment(
          dateRange.endDate
        ).format()}`}
        target="_blank"
        className="btn btn-rounded btn-success btn-fw btn-sm"
      >
        Visit Profile
      </Link>
    </div>
  );
}
function countReadingsHigh(cell, row, rowIndex, extra) {
  const readings = row.readings;
  const valueIndex = extra.valueIndex.replace("i", "");
  const filterReadings = _.filter(readings, function(o) {
    return o.value[valueIndex] >= extra.highValue;
  });

  if (!extra.highValue) {
    return <div>-</div>;
  }
  return (
    <div>
      <span className="font-weight-bold">
        {Math.round((_.size(filterReadings) / _.size(readings)) * 100) + "%"}
      </span>{" "}
      ({_.size(filterReadings)})
    </div>
  );
}
function countReadingsLow(cell, row, rowIndex, extra) {
  const readings = row.readings;
  const valueIndex = extra.valueIndex.replace("i", "");
  const filterReadings = _.filter(readings, function(o) {
    return o.value[valueIndex] <= extra.lowValue;
  });
  if (!extra.lowValue) {
    return <div>-</div>;
  }
  return (
    <div>
      <span className="font-weight-bold">
        {Math.round((_.size(filterReadings) / _.size(readings)) * 100) + "%"}
      </span>{" "}
      ({_.size(filterReadings)})
    </div>
  );
}
function countReadingsGood(cell, row, rowIndex, extra) {
  const readings = row.readings;
  const valueIndex = extra.valueIndex.replace("i", "");
  const filterReadings = _.filter(readings, function(o) {
    if (extra.highValueProperty == "bloodOxygenHigh") {
      return o.value[valueIndex] > extra.lowValue;
    }
    return (
      o.value[valueIndex] > extra.lowValue &&
      o.value[valueIndex] < extra.highValue
    );
  });

  return (
    <div>
      <span className="font-weight-bold">
        {Math.round((_.size(filterReadings) / _.size(readings)) * 100) + "%"}
      </span>{" "}
      ({_.size(filterReadings)})
    </div>
  );
}

function averageValue(cell, row, rowIndex, extra) {
  const readings = row.readings;
  const valueIndex = extra.valueIndex.replace("i", "");
  const average = _.meanBy(readings, function(o) {
    return o.value[valueIndex];
  });
  return Math.round(average);
}
function latestReading(cell, row, rowIndex, extra) {
  const readings = row.readings;
  const valueIndex = extra.valueIndex.replace("i", "");
  const latest = _.maxBy(readings, function(o) {
    return o.deviceReadingDate;
  });
  return moment(latest.deviceReadingDate).format("YYYY-MM-DD HH:mm:ss");
}
export default class TableSummaryThreshold extends Component {
  render() {
    let subType = this.props.prevFilter.readingSubTypeValue.name;
    if (subType == "Oxygen Saturation") {
      subType = "Blood Oxygen";
    }
    const highValueProperty = _.camelCase(subType + "High");
    const lowValueProperty = _.camelCase(subType + "Low");
    const threshold = this.props.threshold;
    const highValue = threshold[highValueProperty];
    const lowValue = threshold[lowValueProperty];
    const valueIndex = this.props.prevFilter.readingSubType;
    let columns;
    if (this.props.hideProfile) {
      columns = [
        {
          dataField: "name",
          text: "Patient Name",
          formatter: fullname,
          sort: true
        },
        {
          dataField: "email",
          text: "Patient Email",
          sort: true
        },
        {
          dataField: "_id",
          text: "Readings Found",
          formatter: countReadings,
          sort: true
        },

        {
          dataField: "a",
          text: "High %",
          formatter: countReadingsHigh,
          sort: true,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          }
        },

        {
          dataField: "b",
          text: "Low %",
          formatter: countReadingsLow,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          },
          sort: true
        },
        {
          dataField: "c",
          text: "Good %",
          formatter: countReadingsGood,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          },
          sort: true
        },
        {
          dataField: "d",
          text: `Average ${subType} Value`,
          formatter: averageValue,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          },
          sort: true
        },
        {
          dataField: "e",
          text: `Latest Reading Date (Based on Range)`,
          formatter: latestReading,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          },
          sort: true
        }
      ];
    } else {
      columns = [
        {
          dataField: "name",
          text: "Patient Name",
          formatter: fullname,
          sort: true
        },
        {
          dataField: "email",
          text: "Patient Email",
          sort: true
        },
        {
          dataField: "_id",
          text: "Readings Found",
          formatter: countReadings,
          sort: true
        },

        {
          dataField: "a",
          text: "High %",
          formatter: countReadingsHigh,
          sort: true,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          }
        },

        {
          dataField: "b",
          text: "Low %",
          formatter: countReadingsLow,
          sort: true,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          }
        },
        {
          dataField: "c",
          text: "Good %",
          formatter: countReadingsGood,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          },
          sort: true
        },

        {
          dataField: "d",
          text: `Average ${subType} Value`,
          formatter: averageValue,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          },
          sort: true
        },
        {
          dataField: "e",
          text: `Latest Reading Date (Based on Range)`,
          formatter: latestReading,
          formatExtraData: {
            valueIndex: valueIndex,
            highValue: highValue,
            highValueProperty: highValueProperty,
            lowValue: lowValue
          },
          sort: true
        },
        {
          dataField: "asdf",
          text: "Profile",
          formatter: viewProfile,
          formatExtraData: {
            startDate: this.props.startDate,
            endDate: this.props.endDate
          }
        }
      ];
    }

    let readingsUser = this.props.users;
    const countCondition = this.props.countCondition;
    const count = this.props.count;
    const minCount = this.props.minCount;
    const maxCount = this.props.maxCount;
    let removeZeroReadings = [];
    let removeNoReadings = _.filter(readingsUser, function(o) {
      return _.size(o.readings) > 0;
    });
    if (
      countCondition &&
      this.props.countCondition == ">=" &&
      this.props.count
    ) {
      removeZeroReadings = _.filter(removeNoReadings, function(o) {
        return _.size(o.readings) > count;
      });
    }
    if (
      countCondition &&
      this.props.countCondition == "<=" &&
      this.props.count
    ) {
      removeZeroReadings = _.filter(removeNoReadings, function(o) {
        return _.size(o.readings) < count;
      });
    }
    if (this.props.countCondition == "between" && minCount && maxCount) {
      removeZeroReadings = _.filter(removeNoReadings, function(o) {
        const countReading = _.size(o.readings);
        if (countReading >= minCount && countReading <= maxCount) {
          return o;
        }
      });
    }

    if (this.props.countCondition == "notBetween") {
      removeZeroReadings = _.filter(removeNoReadings, function(o) {
        const countReading = _.size(o.readings);
        if (countReading >= minCount && countReading <= maxCount) {
        } else {
          return o;
        }
      });
    }
    if (!this.props.countCondition) {
      removeZeroReadings = _.filter(removeNoReadings, function(o) {
        return _.size(o.readings) > 0;
      });
    }

    return (
      <Card>
        <CardBody>
          <CardTitle className="text-center">
            Analyze by Global Threshold
          </CardTitle>
          <CardSubtitle className="text-center">
            Date Range :{" "}
            {this.props.exceptionType ? (
              <span className="font-weight-bold">
                {" "}
                {moment(this.props.prevFilter.from2).format(
                  "MMM DD, YYYY"
                )} - {moment(this.props.prevFilter.to2).format("MMM DD, YYYY")}
              </span>
            ) : (
              <span className="font-weight-bold">
                {" "}
                {moment(this.props.prevFilter.from).format(
                  "MMM DD, YYYY"
                )} - {moment(this.props.prevFilter.to).format("MMM DD, YYYY")}
              </span>
            )}
          </CardSubtitle>
          {this.props.exceptionType && (
            <CardSubtitle className="text-center">
              Expectation {this.props.prevFilter.readingType2Value}
            </CardSubtitle>
          )}
          {!this.props.exceptionType && (
            <CardSubtitle className="text-center">
              Reading Type ({this.props.prevFilter.readingTypeValue.description}
              ) SubType(
              {this.props.prevFilter.readingSubTypeValue.name})
            </CardSubtitle>
          )}
          <CardSubtitle className="text-center">
            <h2 className="card-title">
              {this.props.prevFilter.readingSubTypeValue.name !=
                "Oxygen Saturation" && (
                <span>
                  High Threshold: {">="} {highValue}
                </span>
              )}
              Low Threshold: {"<="} {lowValue}
            </h2>
          </CardSubtitle>
          <div className="table-responsive">
            <BootstrapTable   wrapperClasses="table-responsive"
              keyField="_id"
              data={removeZeroReadings}
              columns={columns}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}
