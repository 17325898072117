import classnames from "classnames";
import React, { Component } from "react";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import onBoarding from "../../files/ic_on_boarding.png";
import SelectationTab from "./SelectationTab";
import OnBoardingList from "./OnBoardingList";
const _ = require("lodash");
class OnBoarding extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      activeTab: "1",
      onDisplayAll: true,
      onDisplayRequestedRPM: false,
      onDisplayPreShipment: false,
      onNeedToShipDevice: false,
      onDisplayWelcomePhoneNeeded: false,
      onDisplayWelcomePhoneCompleted: false,      
      loading: true,
      catagoryID: undefined
    };
  }
  
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_ALL_DEVICE"
    });
    this.onFetchReadingCount();    
  }
  onFetchReadingCount(){
    this.props.dispatch({
      type: "DO_GET_ON_BOARDING_COUNT"
    });
  }

  onDisplayAll() {
    this.onFetchReadingCount();        
    this.setState({
      onDisplayAll: true,
      onDisplayRequestedRPM: false,
      onDisplayPreShipment: false,
      onNeedToShipDevice: false,
      onDisplayWelcomePhoneNeeded: false,
      onDisplayWelcomePhoneCompleted: false,
      catagoryID: undefined
    }, () => {
      this.fetchData();
    });
  }

  onDisplayRequestedRPM() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onDisplayRequestedRPM: true,
      onDisplayPreShipment: false,
      onDisplayWelcomePhoneNeeded: false,
      onDisplayWelcomePhoneCompleted: false,      
      catagoryID: "64620873b1cebb9c4d5b70ea"
    }, () => {
      this.fetchData();
    });
  }

  onDisplayPreShipment() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onDisplayRequestedRPM: false,
      onDisplayPreShipment: true,
      onNeedToShipDevice: false,
      onDisplayWelcomePhoneNeeded: false,
      onDisplayWelcomePhoneCompleted: false,     
      catagoryID: "646208d0b1cebb9c4d5b70eb"
    }, () => {
      this.fetchData();
    });
  }
  onNeedToShipDevice() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onDisplayRequestedRPM: false,
      onDisplayPreShipment: false,
      onNeedToShipDevice: true,
      onDisplayWelcomePhoneNeeded: false,
      onDisplayWelcomePhoneCompleted: false,      
      catagoryID: "64f02ecb6d9efff481a93e67"
    }, () => {
      this.fetchData();
    });
  }
  onDisplayWelcomePhoneNeeded() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onDisplayRequestedRPM: false,
      onDisplayPreShipment: false,
      onNeedToShipDevice: false,
      onDisplayWelcomePhoneNeeded: true,
      onDisplayWelcomePhoneCompleted: false,      
      catagoryID: "646208d3b1cebb9c4d5b70ec"
    }, () => {
      this.fetchData();
    });
  }
  onDisplayWelcomePhoneCompleted() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onDisplayRequestedRPM: false,
      onDisplayPreShipment: false,
      onNeedToShipDevice: false,
      onDisplayWelcomePhoneNeeded: false,
      onDisplayWelcomePhoneCompleted: true,      
      catagoryID: "646208d6b1cebb9c4d5b70ed"
    }, () => {
      this.fetchData();
    });
  }

  render() {   
    var allOnBoarding = { backgroundColor: "#198ae3", color: "white" };     
    var mRequestedRPM = { backgroundColor: "white", color: "black" };
    var mPreShipment = { backgroundColor: "white", color: "black" };
    var mNeedToShipDevice = { backgroundColor: "white", color: "black" };
    var WelcomePhoneNeeded = { backgroundColor: "white", color: "black" };
    var WelcomePhoneCompleted = { backgroundColor: "white", color: "black" };

    var allOnBoardingSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.allOnBoarding : 0;
    var requestedRPMOrderFromProviderSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.requestedRPMOrderFromProviderSize : 0;
    var preShipmentPhoneCallSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.preShipmentPhoneCallSize : 0;
    var needToShipDeviceSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.needToShipDeviceSize : 0;
    var welcomePhoneCallNeededSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.welcomePhoneCallNeededSize : 0;
    var welcomePhoneCompleted = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.welcomeCallCompletedSize : 0;
    
    if (this.state.onDisplayRequestedRPM) {
      // Display Requested RPM
      allOnBoarding = { backgroundColor: "white", color: "black" };
      mRequestedRPM = { backgroundColor: "#198ae3", color: "white" };
      mPreShipment = { backgroundColor: "white", color: "black" };
      mNeedToShipDevice = { backgroundColor: "white", color: "black" };
      WelcomePhoneNeeded = { backgroundColor: "white", color: "black" };
      WelcomePhoneCompleted = { backgroundColor: "white", color: "black" };      
    } else if (this.state.onDisplayPreShipment) {
      // Display Pre Shipment
      allOnBoarding = { backgroundColor: "white", color: "black" };
      mRequestedRPM = { backgroundColor: "white", color: "black" };
      mPreShipment = { backgroundColor: "#198ae3", color: "white" };
      mNeedToShipDevice = { backgroundColor: "white", color: "black" };
      WelcomePhoneNeeded = { backgroundColor: "white", color: "black" };
      WelcomePhoneCompleted = { backgroundColor: "white", color: "black" };      
    } else if (this.state.onNeedToShipDevice) {
      // Display Requested RPM
      allOnBoarding = { backgroundColor: "white", color: "black" };
      allOnBoarding = { backgroundColor: "white", color: "black" };
      mRequestedRPM =  { backgroundColor: "white", color: "black" };
      mPreShipment = { backgroundColor: "white", color: "black" };
      mNeedToShipDevice = { backgroundColor: "#198ae3", color: "white" };
      WelcomePhoneNeeded = { backgroundColor: "white", color: "black" };
      WelcomePhoneCompleted = { backgroundColor: "white", color: "black" };      
    } else if (this.state.onDisplayWelcomePhoneNeeded) {
      // Display Requested RPM
      allOnBoarding = { backgroundColor: "white", color: "black" };
      mRequestedRPM =  { backgroundColor: "white", color: "black" };
      mPreShipment = { backgroundColor: "white", color: "black" };
      mNeedToShipDevice = { backgroundColor: "white", color: "black" };
      WelcomePhoneNeeded = { backgroundColor: "#198ae3", color: "white" };
      WelcomePhoneCompleted = { backgroundColor: "white", color: "black" };      
    } else if (this.state.onDisplayWelcomePhoneCompleted) {
      // Display WelcomePhone Completed
      allOnBoarding = { backgroundColor: "white", color: "black" };
      mRequestedRPM =  { backgroundColor: "white", color: "black" };
      mPreShipment = { backgroundColor: "white", color: "black" };
      mNeedToShipDevice = { backgroundColor: "white", color: "black" };
      WelcomePhoneNeeded = { backgroundColor: "white", color: "black" };
      WelcomePhoneCompleted =  { backgroundColor: "#198ae3", color: "white" };      
    }
    return (
      <div>
        <div className="row">
          <div className="col-12 grid-margin">          
            <div className="card overflow-hidden">
                <Nav tabs className="justify-content-center"> {/* Shrey Added */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <img className="img-tabsfluid" src={onBoarding} style={{marginRight:5}}/>
                      RPM On-Boarding
                    </NavLink>
                  </NavItem>
                </Nav>
              <div className="card-body project-tab pt-2">
                <Row>
                  <Col className="grid-margin col-sm-12 mx-auto">
                    {!this.state.loading && (
                      <ReactLoading className="mx-auto" color={"#007bff"} />
                    )}
                    {this.state.loading && (
                      <Row className="mx-auto d-flex flex-wrap justify-content-around">   
                        <Col className="mt-4 col-sm-2-half col-12 col-lg-4 col-md-6 col-xl-2 p-1">  {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayAll()
                            }
                          >
                            <SelectationTab
                              title={"All Patients"}
                              displayCount={allOnBoardingSize}
                              style={allOnBoarding}
                              />           
                          </div>
                        </Col>                     
                        <Col className="mt-4 col-sm-2-half col-12 col-lg-4 col-md-6 col-xl-2 p-1">  {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayRequestedRPM()
                            }
                          >
                            <SelectationTab
                              title={"Requested Order from Provider"}
                              displayCount={requestedRPMOrderFromProviderSize}
                              style={mRequestedRPM}
                              />           
                          </div>
                        </Col>
                        
                        <Col className="mt-4 col-sm-2-half col-12 col-lg-4 col-md-6 col-xl-2 p-1">  {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayPreShipment()
                            }
                          >
                            <SelectationTab
                              title={"Pre-Shipment Phone Call Needed"}
                              displayCount={preShipmentPhoneCallSize}
                              style={mPreShipment}
                              />           
                          </div>
                        </Col>
                        <Col className="mt-4 col-sm-2-half col-12 col-lg-4 col-md-6 col-xl-2 p-1">  {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onNeedToShipDevice()
                            }
                          >
                            <SelectationTab
                              title={"Need to Ship Device(s)"}
                              displayCount={needToShipDeviceSize}
                              style={mNeedToShipDevice}
                              />           
                          </div>
                        </Col>
                        <Col className="mt-4 col-sm-2-half col-12 col-lg-4 col-md-6 col-xl-2 p-1">  {/* Shrey Added */}
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayWelcomePhoneNeeded()
                            }
                          >
                            <SelectationTab
                              title={"Welcome Phone Call Needed"}
                              displayCount={welcomePhoneCallNeededSize}
                              style={WelcomePhoneNeeded}
                              />           
                          </div>
                        </Col>                        
                        {/* <Col sm="2" className="mt-4 ">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayWelcomePhoneCompleted()
                            }
                          >
                            <SelectationTab
                              title={"Welcome Call Completed"}
                              displayCount={welcomeCallCompletedSize}
                              style={WelcomePhoneCompleted}
                              />           
                          </div>
                        </Col> */}
                        {/* <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayReturnDevice()
                            }
                          >
                            <SelectationTab
                              title={"Return Merchandise Authorization"}
                              displayCount={returnMerchandiseAuthorizationSize}
                              style={ReturnDevice}
                              />           
                          </div>
                        </Col> */}
                        {/* <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayReceivedReturned()
                            }
                          >
                            <SelectationTab
                              title={"Received Returned Device From Patient"}
                              displayCount={receivedReturnedDeviceSize}
                              style={ReceivedReturnedDeviceFromPatient}
                              />           
                          </div>
                        </Col> */}
                        {/* <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplaySentInvoice()
                            }
                          >
                            <SelectationTab
                              title={"Sent Invoice To Patient"}
                              displayCount={sentInvoiceToPatientSize}
                              style={SentInvoiceToPatient}
                              />           
                          </div>
                        </Col> */}
                        {/* <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayReceivedPayment()
                            }
                          >
                            <SelectationTab
                              title={"Received Payment For Invoice"}
                              displayCount={receivedPaymentForInvoiceSize}
                              style={ReceivedPaymentForInvoice}
                              />           
                          </div>
                        </Col>                      */}
                      </Row>
                    )}
                  </Col>
                  <OnBoardingList
                      fetchDataFromServer={click => this.fetchData = click}
                      catagoryID={this.state.catagoryID}
                      profileOwner={this.props.profileOwner}
                      userId={this.props.userId}
                      mDeviceList={this.props.mDeviceList}
                    />
                </Row>                
              </div>
            </div>
          </div>
        </div>       
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    mDeviceList,
    onBoardingDataCount
  } = careprovider;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    mDeviceList,
    onBoardingDataCount,
    userId,
    profileOwner
  };
};

export default connect(mapStateToProps)(OnBoarding);
