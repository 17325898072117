import React, { Component } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row} from "reactstrap";
import { connect } from 'react-redux';
import CCMForm from "./CCMForm";
import ReactToPrint from "react-to-print";
import moment from "moment";

class AddCCMModal extends Component {

  constructor(props) {
    super(props);
    this.printRef = React.createRef();
    this.saveCCMDetails = this.saveCCMDetails.bind(this);    
  }

  saveCCMDetails(data){
    //console.log("SAVED : " + data);    
    if(data){
      const patient = this.props.profile._id;
      const createdBy = this.props.profileOwner._id;
      this.props.dispatch({
        type: "DO_SAVE_CCM_FOMR_IINFORMATION",
        payload: {
          patient: patient,
          createdBy: createdBy,
          submission: data,
          tz: moment.tz.guess()
        }
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PATIENT_CCM_SUBMISSION",
          payload: { userid: patient }
        });
      },1000);
      this.props.toggleAddCCMForm();
    }    
  }
  
  render() {
    return (
      <Modal
        isOpen={this.props.modelAddCCMForm}
        toggle={this.props.toggleAddCCMForm}
        size="lg"
        className="display-table"
        style={{ display: "table" }}
      >
        <ModalHeader className="close_button" toggle={this.props.toggleAddCCMForm}>
          <Label className="align-items-center justify-content-center text-center"> CCM Care Plan</Label>
          <button
            style={{ float: 'right' }}
            onClick={this.props.toggleAddCCMForm}
            className="btn btn-info ml-2 close-click-cls" >
            <i class="mdi mdi-close" style={{
              fontSize: 20
            }}></i>
            Close
          </button>          
        </ModalHeader>

        <ModalBody style={{ backgroundColor: "white" }}>
          <Row>
            <Col md={12}>
              <CCMForm
              isEditable={true} 
              saveCCMDetails={this.saveCCMDetails}
              profile={this.props.profileOwner}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage
  } = careprovider;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner ? user.profileOwner : user.userDetails;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    profileOwner
  };
};

export default connect(mapStateToProps)(AddCCMModal);
