import VitalsIcon from "../../files/vitals.jpg";
import TimeIcon from "../../files/timeadherence.jpg";
import AdherenceIcon from "../../files/adherence.svg";
import ReminderIcon from "../../files/reminder.jpg";
import TrendingIcon from "../../files/trending.svg";
import WeightIcon from "../../files/weight.svg";

export {
  VitalsIcon,
  TimeIcon,
  AdherenceIcon,
  ReminderIcon,
  TrendingIcon,
  WeightIcon
};
