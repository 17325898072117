import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { Link } from "react-router-dom";

import Avatar from "react-avatar";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col
} from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import lottieEmptyFile from '../../files/animation/empty_new.json';
import LottieLoader from "../LottieLoader";
import userImage from "../../files/user.png";
import callingIcon from "../../files/called.png";
const _ = require("lodash");
function fullname(cell, row) {
  if(row){
    const profile = row && row.profileImage ? row.profileImage : userImage;
    // return <UserName user={row} />;
    return (
      <div>
        <span>
          <img src={profile} className="mr-2" alt="image"   
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = userImage;
          }}/>
          {capitalize(row.lname)}
          {", "}
          {capitalize(row.fname)}
        </span>
      </div>
    );
  } else {
    return (
      <div>
        
      </div>
    );
  }
}

function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

function formatDate(cell, row) {
  return moment(cell).format("MMMM DD, YYYY");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  const authenticatedUser = formatExtraData.userId;

  if (row) {
    const isSameUser = authenticatedUser !== row.receivedBy._id;
    const approvedButton =
      isSameUser && !row.isApproved ? (
        <button
          type="button"
          onClick={() => formatExtraData.approve(row._id)}
          className="btn btn-success btn-fw"
        >
          Accept Request
        </button>
      ) : (
        ""
      );
    return (
      <span>
        {row.isApproved ? (
          <button
            type="button"
            onClick={() => formatExtraData.delete(row._id)}
            className="btn btn-danger btn-fw"
          >
            Delete
          </button>
        ) : (
          approvedButton
        )}
      </span>
    );
  }
}
const rowComponent = (row, readingSubTypes) => (
  <div>
    <Card>
      <CardHeader className="text-center">
        {row.fname} {row.lname} Last 7 Day Summary
        <Link
          to={`/dashboard/${row.fname
            .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
            .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${
            row._id
          }`}
          className="text-white ml-auto btn btn-sm btn-success pull-right btn-rounded btn-fw"
        >
          More Details
        </Link>
      </CardHeader>
    </Card>
    <Row>
      {readingSubTypes.map((value, i) => {
        const readings = _.filter(row.readingsSevenDays, {
          readingType: value.reading
        });
        const readingValue = _.map(readings, "value");

        let checkThis = _.map(readingValue, readingIndex => {
          return readingIndex[value.index];
        });
        const latestReading = _.maxBy(readings, "deviceReadingDate");
        const getTheValueLatest = _.pick(latestReading, "value");
        // let latestValue = _.map(getTheValueLatest, readingIndex => {
        //   return readingIndex[value.index];
        // });

        // const getTheValueLatest = _.map(latestReading, "value");
        // let latestValue = _.map(getTheValueLatest, readingIndex => {
        //   return readingIndex[value.index];
        // });

        if (value.name === "Mean Arterial") {
          checkThis = _.map(readingValue, readingIndex => {
            return _.round(
              (1 / 3) * readingIndex[0] + (2 / 3) * readingIndex[1],
              2
            );
          });
        }
       

        const checkNan = isNaN(_.min(checkThis));
        if (value.name == "Beats Per Minute(BPM)") {
        }
        if (readings.length === 0 || checkNan) return;

        let latestResult = getTheValueLatest.value[value.index];
        if (value.name === "Mean Arterial") {
          latestResult = Math.round(
            _.round(
              (1 / 3) * getTheValueLatest.value[0] +
                (2 / 3) * getTheValueLatest.value[1],
              2
            )
          );
        }
        let lastSevenDays = _.size(readings);
        let lastTestTaken =
          readings.length > 0 &&
          moment(
            _.maxBy(readings, "deviceReadingDate").deviceReadingDate
          ).format("MMMM DD, YYYY hh:mm A");
        let sevenDaysLowest =
          value.name != "Body Temperature"
            ? Math.round(_.min(checkThis))
            : _.min(checkThis).toFixed(2);
        let sevenDaysHighest =
          value.name != "Body Temperature"
            ? Math.round(_.max(checkThis))
            : _.max(checkThis).toFixed(2);
        let sevenDaysAverage =
          value.name != "Body Temperature"
            ? Math.round(_.mean(checkThis))
            : _.mean(checkThis).toFixed(2);
        if (value.name == "Body Temperature") {
          latestResult = ((latestResult * 9) / 5 + 32).toFixed(2);
          sevenDaysLowest = ((sevenDaysLowest * 9) / 5 + 32).toFixed(2);
          sevenDaysHighest = ((sevenDaysHighest * 9) / 5 + 32).toFixed(2);
          sevenDaysAverage = ((sevenDaysAverage * 9) / 5 + 32).toFixed(2);
        }
        if (value.name == "Weight") {
          if(value.abbrev == "BT_WS"){
            latestResult = Math.round(latestResult);
            sevenDaysLowest = Math.round(sevenDaysLowest);
            sevenDaysHighest = Math.round(sevenDaysHighest);
            sevenDaysAverage = Math.round(sevenDaysAverage);
          }else{
            latestResult = Math.round(latestResult * 2.2046);
            sevenDaysLowest = Math.round(sevenDaysLowest * 2.2046);
            sevenDaysHighest = Math.round(sevenDaysHighest * 2.2046);
            sevenDaysAverage = Math.round(sevenDaysAverage * 2.2046);
          }
        } else {
          latestResult = Math.round(latestResult)
        }
        return (
          <Col sm="4" className="mt-2">
            <Card>
              <CardHeader className="bg-info text-white">
                {value.name} - ({value.abbrev})
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="8">No. of Test taken in last 7 days:</Col>
                  <Col sm="4" className="font-weight-bold">
                    {lastSevenDays}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="8">Last Test Taken:</Col>
                  <Col sm="4" className="font-weight-bold">
                    {lastTestTaken}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="8">Last Test Result:</Col>
                  <Col sm="4" className="font-weight-bold">
                    {latestResult}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="8">7 days lowest:</Col>
                  <Col sm="4" className="font-weight-bold">
                    {sevenDaysLowest}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="8">7 days highest:</Col>
                  <Col sm="4" className="font-weight-bold">
                    {sevenDaysHighest}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="8">7 days average:</Col>
                  <Col sm="4" className="font-weight-bold">
                    {sevenDaysAverage}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  </div>
);
const PatientList = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const NoDataIndication = () => (
    <div>      
      <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={150}
          Lwidth={150}
          titleSmall={"Sorry! No patient found :("}
          subTitleSmall={"We can't find any patient."}
        />              
    </div>
  );
  const options = {
    page: 4,
    sizePerPage: 10,
    totalSize: props.usersGivenAccessMyReading.length,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: props.usersGivenAccessMyReading.length == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: props.usersGivenAccessMyReading.length == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "All",
        value: props.usersGivenAccessMyReading.length
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };
  const readingSubType = props.readingSubTypes.filter((item) => item.description !== "Weight Difference");
  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      keyField="_id"
      noDataIndication={ () => <NoDataIndication /> }
      data={props.usersGivenAccessMyReading}
      expandRow={{
        renderer: row => rowComponent(row, readingSubType),
        showExpandColumn: true
      }}
      filter={filterFactory()}
      pagination={paginationFactory(options)}
      filterPosition="top"
      columns={[
        {
          dataField: "fname",
          text: "Name",
          formatter: fullname,
          sort: true,
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Search by fname"
          }),
          filterValue: (cell, row) => {
            return row.fname + " " + row.lname;
          },
          headerFormatter: priceFormatter
        },
        {
          dataField: "organization",
          text: "Organization",
          sort: true,
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Search by organization"
          }),
          headerFormatter: priceFormatter
        },
        {
          dataField: "subOrganization",
          text: "SubOrganization",
          sort: true,
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Search by sub organization"
          }),
          headerFormatter: priceFormatter
        },
        {
          dataField: "email",
          text: "Email Address",
          sort: true,
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Search by email"
          }),
          headerFormatter: priceFormatter
        },
        {
          dataField: "username",
          text: "Username",
          sort: true,
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Search by username"
          }),
          headerFormatter: priceFormatter
        },

        {
          dataField: "bday",
          text: "Date of Birth",
          formatter: formatDate,
          sort: true
        }
      ]}
    />
  );
};

export default PatientList;
