import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactLoading from "react-loading";
import PatientList from "./PatientList";
import Heading from "./Heading";
import CreateUserModal from "./CreateUserModal";
import supportTeamImage from "../../../files/support-team.png";
const _ = require("lodash");
class AdminSupport extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      modalUser: false,
      emailOffer: "",
      emailAccess: "",
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      showAlertSuccess: false,
      errorPassword: false,
      isStaff: false,
      isCareProvider: false,
      role: "support"
    };
    this.toggleUser = this.toggleUser.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }

  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalUser: false,
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      role: "",
      isClient: true,
      isCareProvider: true
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_GET_SUPPORTS",
      payload: { client: true }
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
  }
  submitUser(e) {
    e.preventDefault();
    const cleanUser = _.omit(this.state, [
      "activeTab",
      "modal",
      "modal2",
      "modalUser",
      "emailOffer",
      "confirmpassword",
      "emailAccess",
      "showAlertSuccess",
      "errorPassword"
    ]);
    this.props.dispatch({
      type: "DO_SAVE_SUPPORT_USER",
      payload: cleanUser
    });
  }
  toggleUser() {
    
    this.setState({
      modalUser: !this.state.modalUser
    });
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_REMOVE_PROFILE_DATA"
    });
    this.props.dispatch({
      type: "DO_GET_SUPPORTS",
      payload: { client: true }
    });

    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
  }

  render() {
    return (
      <div>
        <div className="row">
          <Heading
            title="SUPPORTS"
            description=""
            toggleModal={this.toggleModal}
            backbutton="d-none"
            mainbutton="d-block"
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Heading>

          <div className="col-12 grid-margin">
            <div className="card overflow-hidden">{/*Shrey Added */}
              <Nav tabs className="justify-content-center"> {/*Shrey Added */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <span>
                        SUPPORT USERS
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              <div className="card-body project-tab pt-2">
                <Row>
                  <div className="grid-margin mt-4 w-100">{/*Shrey Added */}
                    {!this.props.supports && (
                      <ReactLoading className="mx-auto" color={"#007bff"} />
                    )}
                    {this.props.supports &&
                      this.props.supports.length === 0 && (
                        <ReactLoading className="mx-auto" color={"#007bff"} />
                      )}

                    {this.props.supports && this.props.supports.length > 0 && (
                      <PatientList
                        usersGivenAccessMyReading={this.props.supports}
                        readingSubTypes={this.props.readingSubTypes}
                        userId={this.props.userId}
                        deletePermission={this.deletePermission}
                        approvePermission={this.approvePermission}
                        receivedFrom={false}
                      />
                    )}
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Create a New Support
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <h5 className="text-center" />
            <div className="row">
              {/* <div className="col-6">
                <NavLink
                  className="text-center d-block"
                  onClick={this.openInvite}
                >
                  <img
                    src="https://image.flaticon.com/icons/svg/201/201618.svg"
                    style={{ height: "180px" }}
                  />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    INVITE A USER
                  </span>
                  <small>Send a sign up link</small>
                </NavLink>
              </div> */}
              <div className="col-6 mx-auto">
                <NavLink
                  className="text-center d-block"
                  onClick={this.toggleUser}
                >
                  <img src={supportTeamImage} style={{ height: "180px" }} />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    CREATE A NEW SUPPORT ACCOUNT
                  </span>
                  <small>You will input everything</small>
                </NavLink>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          {this.state.bday && <div>Support User Created Successfully</div>}
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
        <CreateUserModal
          modalUser={this.state.modalUser}
          toggleUser={this.toggleUser}
          errorPassword={this.state.errorPassword}
          submitUser={this.submitUser}
          handleInputChange={this.handleInputChange}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth, client }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    usersOfferedAccessOrganization
  } = careprovider;
  const { readingSubTypes } = auth;
  const { supports } = client;
  const userId = user.userDetails._id;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    usersOfferedAccessOrganization,
    userId,
    supports
  };
};

export default connect(mapStateToProps)(AdminSupport);
