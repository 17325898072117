import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";

import {
  VitalsIcon,
  TimeIcon,
  AdherenceIcon,
  ReminderIcon,
  TrendingIcon
} from "./RuleIcon";
import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";

const PickRuleModal = props => (
  <Modal
    isOpen={props.modal}
    toggle={props.toggleModal}
    className="bastaLang modal-md"
  >
    <Form onSubmit={props.onSubmitOffer}>
      <ModalHeader toggle={props.toggleModal}>Pick A Expectation Type</ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <h5 className="text-center" />
        <div className="row">
          <div className="col-4">
            <NavLink
              tag={Link}
              to={`/${props.name}/${props.id}/create/vitals`}
              className="text-center d-block"
            >
              <img src={VitalsIcon} style={{ height: "70px" }} />
              <span className="menu-title font-weight-bold d-block text-dark">
                VITALS
              </span>
            </NavLink>
          </div>
          <div className="col-4">
            <NavLink
              tag={Link}
              to="/create/time-adherence"
              className="text-center d-block"
            >
              <img src={TimeIcon} style={{ height: "70px" }} />
              <span className="menu-title font-weight-bold d-block text-dark">
                TIME ADHERENCE
              </span>
            </NavLink>
          </div>
          <div className="col-4">
            <NavLink
              tag={Link}
              to="/create/adherence"
              className="text-center d-block"
            >
              <img src={AdherenceIcon} style={{ height: "70px" }} />
              <span className="menu-title font-weight-bold d-block text-dark">
                ADHERENCE
              </span>
            </NavLink>
          </div>
        </div>
        <div className="row ">
          <div className="col-4">
            <NavLink
              tag={Link}
              to="/create/reminder"
              className="text-center d-block"
            >
              <img src={ReminderIcon} style={{ height: "70px" }} />
              <span className="menu-title font-weight-bold d-block text-dark">
                REMINDER
              </span>
            </NavLink>
          </div>
          <div className="col-4">
            <NavLink
              tag={Link}
              to="/create/trending-violation"
              className="text-center d-block"
            >
              <img src={TrendingIcon} style={{ height: "70px" }} />
              <span className="menu-title font-weight-bold d-block text-dark">
                TRENDING DATA
              </span>
            </NavLink>
          </div>
          <div className="col-4">
            <NavLink
              tag={Link}
              to="/create/weight-difference"
              className="text-center d-block"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/249/249236.png"
                style={{ height: "70px" }}
              />
              <span className="menu-title font-weight-bold d-block text-dark">
                WEIGHT CHANGE
              </span>
            </NavLink>
          </div>
        </div>
      </ModalBody>
    </Form>
  </Modal>
);

export default PickRuleModal;
