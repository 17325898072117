import React, { useEffect } from "react";

const SuccessIntegration = () => {
  useEffect(() => {
    setTimeout(() => {
      var daddy = window.self;
      daddy.opener = window.self;
      daddy.close();
    }, 2000);
  }, []);
  return <h1 className="text-center mx-auto">Integrated Successfully</h1>;
};

export default SuccessIntegration;
