import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import ExpandedRule from "./ExpandedRule";
import VitalsIcon from "../../../../files/vitals.jpg";
import TimeIcon from "../../../../files/timeadherence.jpg";
import AdherenceIcon from "../../../../files/adherence.svg";
import ReminderIcon from "../../../../files/reminder.jpg";
import TrendingIcon from "../../../../files/trending.svg";
import _ from "lodash";

function withIcon(cell) {
  let icon;
  switch (cell) {
    case "Vitals":
      icon = VitalsIcon;
      break;
    case "Time Adherence":
      icon = TimeIcon;
      break;
    case "Adherence":
      icon = AdherenceIcon;
      break;
    case "Reminder":
      icon = ReminderIcon;
      break;
    case "Trending Violation":
      icon = TrendingIcon;
      break;
    case "Weight":
      icon = "https://cdn-icons-png.flaticon.com/512/249/249236.png";
      break;

    default:
      break;
  }
  return (
    <span>
      <img src={icon} className="mr-2" alt="image" />
      {cell == "Weight" ? "Weight Difference" : cell}
    </span>
  );
}

function formatDate(cell) {
  return moment(cell).format("MMMM DD, YYYY hh:mm A");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  const authenticatedUser = formatExtraData.userId;
  if (row) {
    const isSameUser = authenticatedUser !== row.receivedBy._id;
    const approvedButton =
      isSameUser && !row.isApproved ? (
        <button
          type="button"
          onClick={() => formatExtraData.approve(row._id)}
          className="btn btn-success btn-fw"
        >
          Accept Request
        </button>
      ) : (
        ""
      );
    return (
      <span>
        {row.isApproved ? (
          <button
            type="button"
            onClick={() => formatExtraData.delete(row._id)}
            className="btn btn-danger btn-fw"
          >
            Delete
          </button>
        ) : (
          approvedButton
        )}
      </span>
    );
  }
}
function checkSubType(cell, row, index, myRules) {
  if (row.ruleType === "Trending Violation") {
    const rule = _.find(myRules, { _id: row.rule[0].ruleId });
    if (rule) {
      return <div>Expectation Name: {rule.name}</div>;
    }
    return "";
  }

  return row.ruleType == "Weight" ? "Weight" : cell;
}
function ruleCheck(cell, row, index) {
  const { sharedUsers = [], conditionGroup = [] } = row;
  if (_.size(sharedUsers) === 0 && _.size(conditionGroup) === 0) {
    return (
      <div>
        <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target={"test" + row._id}
        >
          <PopoverHeader>Need Setup</PopoverHeader>
          <PopoverBody>
            This expectation is a default one that needs to add a patient to
            monitor or a condition group which has multiple patients, you can
            modify it and delete if you want.
          </PopoverBody>
        </UncontrolledPopover>
        {cell} {"  "}
        <Button
          id={"test" + row._id}
          type="button"
          className="text-danger btn btn-dark btn-rounded float-right"
        >
          <i class="mdi mdi-alert-decagram btn-icon-prepend text-danger"></i>
        </Button>
      </div>
    );
  }
  return cell;
}
const expandRow = {
  renderer: (row) => (
    <div>
      <ExpandedRule ruleDetails={row} key={row._id + "water"} />
    </div>
  ),
  showExpandColumn: true,
};
const RuleList = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: props.myRules.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <div className="scrollbar-table-cls w-100">{/* Shrey Added */}
      <BootstrapTable
        wrapperClasses="table-responsive"
        keyField="_id"
        noDataIndication="No Data Found"
        bordered={false}
        data={props.myRules}
        filter={filterFactory()}
        expandRow={expandRow}
        pagination={paginationFactory(options)}
        columns={[
          {
            dataField: "name",
            text: "Expectation Name",
            sort: true,
            filter: textFilter(),
            formatter: ruleCheck,
            headerFormatter: priceFormatter,
            // headerStyle: {
            //   minWidth: '195px' // Set your desired width  // Shrey Added
            // },
            // style: {
            //   minWidth: '195px' // Set your desired width  // Shrey Added
            // }
          },
          {
            dataField: "ruleType",
            text: "Expectation Type",
            sort: true,
            filter: textFilter(),
            formatter: withIcon,
            headerFormatter: priceFormatter,
            // headerStyle: {
            //   minWidth: '195px' // Set your desired width  // Shrey Added
            // },
            // style: {
            //   minWidth: '195px' // Set your desired width  // Shrey Added
            // }
          },
          {
            dataField: "readingSubType",
            text: "Reading Type",
            sort: true,
            filter: textFilter(),
            formatter: checkSubType,
            headerFormatter: priceFormatter,
            formatExtraData: props.myRules,
            // headerStyle: {
            //   minWidth: '195px' // Set your desired width  // Shrey Added
            // },
            // style: {
            //   minWidth: '195px' // Set your desired width  // Shrey Added
            // }
          }
        ]}
      />
      </div>
    </>
  );
};

export default RuleList;
