import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";

const OfferAccessModal = props => (
  <Modal
    isOpen={props.modal2}
    toggle={props.toggleModal2}
    className="bastaLang"
  >
    <Form onSubmit={props.onSubmitOffer}>
      <ModalHeader toggle={props.toggleModal2}>
        Offer Access to My Reading
      </ModalHeader>

      <ModalBody>
        <h5 className="text-center">
          Type the email of careprovider you want to offer Reading
        </h5>

        <FormGroup>
          <Input
            type="email"
            name="email"
            id="exampleEmail"
            required
            placeholder="Email Address"
            onChange={props.emailOfferChange}
          />
        </FormGroup>

        <small className="text-center">
          Note: Offering your data to a CareProvider will be also be shared to
          his personnel
        </small>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="info">
          Offer Your Reading Data Now
        </Button>
        <Button color="secondary" onClick={props.toggleModal2}>
          Cancel
        </Button>
      </ModalFooter>
    </Form>
  </Modal>
);

export default OfferAccessModal;
