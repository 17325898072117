import React, { useEffect, useMemo, useRef, useState } from "react";
import "./../../css/css/pdf.css";
import logo from "./../../files/ic_ccm_form_logo.jpg"
import { useForm } from "react-hook-form";
import { Input,Label } from "reactstrap";
import moment from "moment";
import ErrorDisplay from "../Reusable/ErrorDisplay";
import InputFields from "../Reusable/InputFields";
import AddConditionSection from "./AddConditionSection";
import isNoSignature from "../../files/ic_no_signature.svg";
import SignatureWithName from "../Reusable/SignatureWithName";
const _ = require("lodash");

const CCMForm = React.forwardRef((props, ref) => {
  const { isEditable } = props;
  const [radioFluDate, setRadioFluDate] = useState(props.latestSubmission && props.latestSubmission.radiofluDate ? props.latestSubmission.radiofluDate : "")
  const [radioCancerBreast, setRadioCancerBreast] = useState(props.latestSubmission && props.latestSubmission.radioCancerBreast ? props.latestSubmission.radioCancerBreast : "")
  const [radioAnnualWellnessVisit1, setRadioAnnualWellnessVisit1] = useState(props.latestSubmission && props.latestSubmission.radioAnnualWellnessVisit1 ? props.latestSubmission.radioAnnualWellnessVisit1 : "")
  const [radioPneumonia, setRadioPneumonia] = useState(props.latestSubmission && props.latestSubmission.radioPneumonia ? props.latestSubmission.radioPneumonia : "")
  const [radioColon, setRadioColon] = useState(props.latestSubmission && props.latestSubmission.radioColon ? props.latestSubmission.radioColon : "")
  const [radioAnnualWellnessVisit2, setAnnualWellnessVisit2] = useState(props.latestSubmission && props.latestSubmission.radioAnnualWellnessVisit2 ? props.latestSubmission.radioAnnualWellnessVisit2 : "")
  const [radioTetanus, setRadioTetanus] = useState(props.latestSubmission && props.latestSubmission.radioTetanus ? props.latestSubmission.radioTetanus : "")
  const [radioCancerBlank1, setRadioCancerBlank1] = useState(props.latestSubmission && props.latestSubmission.radioCancerBlank1 ? props.latestSubmission.radioCancerBlank1 : "")
  const [radioAnnualWellnessVisit3, setRadioAnnualWellnessVisit3] = useState(props.latestSubmission && props.latestSubmission.radioAnnualWellnessVisit3 ? props.latestSubmission.radioAnnualWellnessVisit3 : "")
  const [radioCovid, setRadioCovid] = useState(props.latestSubmission && props.latestSubmission.radioCovid ? props.latestSubmission.radioCovid : "")
  const [radioCancerBlank2, setRadioCancerBlank2] = useState(props.latestSubmission && props.latestSubmission.radioCancerBlank2 ? props.latestSubmission.radioCancerBlank2 : "")
  const [radioAnnualWellnessVisit4, setRadioAnnualWellnessVisit4] = useState(props.latestSubmission && props.latestSubmission.radioAnnualWellnessVisit4 ? props.latestSubmission.radioAnnualWellnessVisit4 : "")
  const [carePlanInitValue, setCarePlanInitValue] = useState(props.latestSubmission && props.latestSubmission.initiationDate ? props.latestSubmission.initiationDate : null)
  const [carePlanRevision, setCarePlanRevision] = useState(props.latestSubmission && props.latestSubmission.dateOfRevision ? props.latestSubmission.dateOfRevision : null)
  const [height, setHeight] = useState('auto');
  const [errorSignature, setErrorSignature] = useState('');

  const { register, control, getValues, setValue, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: useMemo(() => {
      return props.latestSubmission;
    }, [props])
  });

  const defaultValues = {
    test: [
      {
        name: "useFieldArray1",
        nestedArray: [{ field1: "field1", field2: "field2" }]
      },
      {
        name: "useFieldArray2",
        nestedArray: [{ field1: "field1", field2: "field2" }]
      }
    ]
  };

  const registerOptions = {
    dateValidationRequired: {
      required: "This field is required!",
    },
    dateValidationOptional: {
      required: false,
    },
    requiredField: {
      required: "This field is required!",
      pattern: {
        value: /^\s*(\S(.*\S)?)\s*$/,
        message: "Must be value without any white space!"
      },
      minLength: {
        value: 2,
        message: "Must include at least 2 characters!"
      }
    },
    optionalFieldLimit: {
      required: false,
      pattern: {
        value: /^\s*(\S(.*\S)?)\s*$/,
        message: "Must be value without any white space!"
      },
      minLength: {
        value: 2,
        message: "Must be min 2 and max 100 characters"
      },
      maxLength: {
        value: 100,
        message: "Must be min 2 and max 100 characters"
      }
    },
    requiredFieldLimit: {
      required: "This field is required!",
      pattern: {
        value: /^\s*(\S(.*\S)?)\s*$/,
        message: "Must be value without any white space!"
      },
      minLength: {
        value: 2,
        message: "Must be min 2 and max 100 characters"
      },
      maxLength: {
        value: 100,
        message: "Must be min 2 and max 100 characters"
      }
    },
    optional: {
      required: false,
      minLength: {
        value: 2,
        message: "Must include at least 2 characters!"
      },
      pattern: {
        value: /^\s*(\S(.*\S)?)\s*$/,
        message: "Must be value without any white space!"
      },
    }
  };
  useEffect(() => {
    reset(props.latestSubmission);
  }, [props.latestSubmission]);

  /**
   * Submit form details
   * @param {*} data 
   */
  const onSubmit = (data) => {
    props.saveCCMDetails(data);
  };

  /**
   * Submit form details
   * @param {*} data 
   */
  const onAddCondition = () => {
    //console.log("Click Add Condition");
  };

  function getStype() {
    let names = [];
    if (isEditable) names.push('input-box-style-editbale');
    if (!isEditable) names.push('disabled', 'input-box-style-editbale-disable');
    return names.join(' ');
  }
  function getStypeForRadio() {
    let names = [];
    if (!isEditable) names.push('disabled', 'input-box-style-editbale-disable-radio');
    return names.join(' ');
  }
  /**
   * Root CCS
   * @returns 
   */
  function getLogsCss() {
    let names = [];
    if (props.isAttestationPrint) {
      return names.join(' ');
    } else {      
      if (!props.saveCCMDetails) names.push('logs-view');
      names.push('App')
      return names.join(' ');
    }
  }

  function getPrintBrack() {
    let names = [];
    if (props.isAttestationPrint) {
      return names.join(' ');
    } else {      
      names.push('pdf-main-wrapper');
      names.push('pagebreak');
      return names.join(' ');
    }
  }
  function getPrintWithBrack() {
    let names = [];
    //names.push('pdf-main-wrapper');    
    if (props.isAttestationPrint) {
      return names.join(' ');
    } else {      
      names.push('pdf-main-wrapper');
      names.push('pagebreak');
      return names.join(' ');
    }
  }

  const onChangeDate = (date) => {
    if (date.target.name == "initiationDate") {
      if (date.target.value) {
        setCarePlanInitValue(date.target.value);
      } else {
        setCarePlanInitValue(undefined);
      }
    }
    if (date.target.name == "dateOfRevision") {
      if (date.target.value) {
        setCarePlanRevision(date.target.value);
      } else {
        setCarePlanRevision(undefined);
      }
    }
  };
  
    var signatureLink = undefined;
    var name = "";
    if (props.submisionModel) {
      const signatureImage = props.submisionModel.modifyBy ? props.submisionModel.modifyBy.signatureImage : props.submisionModel.createdBy ? props.submisionModel.createdBy.signatureImage : undefined;
      name = props.submisionModel.modifyBy ? props.submisionModel.modifyBy.lname+" "+props.submisionModel.modifyBy.fname : props.submisionModel.createdBy ? props.submisionModel.createdBy.lname+" "+props.submisionModel.createdBy.fname : undefined;
      signatureLink = signatureImage ? signatureImage : undefined;
      if (signatureLink && !signatureLink.indexOf("http") == 0) {
          signatureLink = "/" + signatureLink
      }
    } else {
        const signatureImage = props.profile.signatureImage ? props.profile.signatureImage : undefined;
        name = props.profile.lname + " " + props.profile.fname;
        signatureLink = signatureImage ? signatureImage : undefined;
        if (signatureLink && !signatureLink.indexOf("http") == 0) {
            signatureLink = "/" + signatureLink
        }
    }
  return (
    <>
      <div className={getLogsCss()} ref={ref ? ref.scrollRef : undefined}>
      <div ref={ref ? ref.print : undefined}>
            <section className={getPrintBrack()}>
              <hr className="pdf-hr" style={{ marginTop: "10px !important" }} />
              <div className="pdf-logobar">
                <img className="img-ccm-form" src={logo}></img>
              </div>

              <h2 className="text-center chronic-care-title">
                Chronic Care Management (CCM) Comprehensive Care Plan Template
              </h2>
              <p className="chronic-care-content">
                The CCM Comprehensive Care Plan Template is designed to assist
                qualified healthcare professionals with proper documentation of the
                CCM services provided to their Medicare patients. Ensure that your
                electronic health record (EHR) system includes the following data
                elements listed in this document. Make the electronic version of this
                care plan available within and outside the billing practice to
                individuals involved in the patient’s care. Provide patients and/or
                caregivers with a copy of the care plan.
              </p>
              <div className="d-md-flex-column"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  flexWrap: "wrap",
                  marginBottom: "12px",
                }}
              >
                <p style={{ fontSize: "16px", marginBottom: "0px" }}>Care Plan Initiation Date</p>
                <div>
                  {isEditable && (
                    <>
                      <input
                        type="date"
                        className={getStype()}
                        style={{ borderBottom: "1px solid #333", width: "150px", outline: "none", fontWeight: "bold" }}
                        max={moment().format("YYYY-MM-DD")}
                        name="initiationDate"
                        aria-invalid={errors.initiationDate ? "true" : "false"}
                        onChange={(e) => onChangeDate(e)}
                        ref={register((carePlanInitValue && _.size(carePlanInitValue) > 0 || carePlanRevision && _.size(carePlanRevision) > 0) ? registerOptions.dateValidationOptional : registerOptions.dateValidationRequired)}
                      />
                    </>
                  )}
                  {!isEditable && (
                    <p className="mb-0 view-mode-display-value-bold ptag-border-letter-spacing">
                      {props.latestSubmission.initiationDate ? moment(props.latestSubmission.initiationDate).format("MM/DD/YYYY") : ""}
                    </p>
                  )}
                </div>
                <p style={{ fontSize: "16px", marginBottom: "0px" }}>or Date of Revision</p>
                <div>
                  {isEditable && (
                    <>
                      <input
                        type="date"
                        className={getStype()}
                        max={moment().format("YYYY-MM-DD")}
                        aria-invalid={errors.dateOfRevision ? "true" : "false"}
                        style={{ borderBottom: "1px solid #333", width: "150px", outline: "none", fontWeight: "bold" }}
                        name="dateOfRevision"
                        onChange={(e) => onChangeDate(e)}
                        ref={register((carePlanInitValue && _.size(carePlanInitValue) >= 0 || carePlanRevision && _.size(carePlanRevision) >= 0) ? registerOptions.dateValidationOptional : registerOptions.dateValidationRequired)}
                      />
                    </>
                  )}
                  {!isEditable && (
                    <p className="mb-0 view-mode-display-value-bold ptag-border-letter-spacing">
                      {props.latestSubmission.dateOfRevision ? moment(props.latestSubmission.dateOfRevision).format("MM/DD/YYYY") : ""}
                    </p>
                  )}
                </div>
              </div>
              <div>
                {(carePlanInitValue != null && _.size(carePlanInitValue) <= 0) || (carePlanRevision != null && _.size(carePlanRevision) <= 0) || (errors.initiationDate?.message || errors.dateOfRevision?.message) && (
                  <ErrorDisplay message={"Fill out either the initial date or the revision date."} />
                )}
              </div>
              {/* Patient information table  */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">Patient Information</h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr className="">
                        <th style={{ width: "25%" }} scope="row">
                          Name
                        </th>
                        <td style={{ width: "75%", padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.name ? "true" : "false"}
                                  name="name"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.name?.message && (
                                  <ErrorDisplay message={errors.name?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.name}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Date of birth</th>
                        <td style={{ padding: "0px" }}>
                            {isEditable && (
                              <>
                                <input
                                  type="date"
                                  className={getStype()}
                                  max={moment().format("YYYY-MM-DD")}
                                  aria-invalid={errors.dateOfBirth ? "true" : "false"}
                                  style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                  name="dateOfBirth"
                                  ref={register(registerOptions.dateValidationRequired)}
                                />
                                {errors.dateOfBirth?.message && (
                                  <ErrorDisplay message={errors.dateOfBirth?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.dateOfBirth ? moment(props.latestSubmission.dateOfBirth).format("MM/DD/YYYY") : ""}
                              </p>
                            )}                          
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Primary care physician</th>
                        <td style={{ padding: "0px" }}>
                          <div>                            
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.primaryCarePhysician ? "true" : "false"}
                                  name="primaryCarePhysician"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.primaryCarePhysician?.message && (
                                  <ErrorDisplay message={errors.primaryCarePhysician?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.primaryCarePhysician}
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Complete Problem List table  */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">
                      Complete Problem List <span className="pdf-light-font"> (You can elaborate on page 3.)</span>
                    </h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr className="">
                        <th style={{ width: "25%" }} scope="row">
                          Chronic health conditions{" "}
                        </th>
                        <td style={{ width: "75%", padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.chronicHealthConditions ? "true" : "false"}
                                  name="chronicHealthConditions"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.chronicHealthConditions?.message && (
                                  <ErrorDisplay message={errors.chronicHealthConditions?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.chronicHealthConditions}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Surgeries</th>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.surgeries ? "true" : "false"}
                                  name="surgeries"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.surgeries?.message && (
                                  <ErrorDisplay message={errors.surgeries?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.surgeries}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Tests/Procedures</th>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.testsProcedures ? "true" : "false"}
                                  name="testsProcedures"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.testsProcedures?.message && (
                                  <ErrorDisplay message={errors.testsProcedures?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.testsProcedures}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Current Medications table  */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">
                      Current Medications <span className="pdf-light-font">(List scheduled/PRN*/complementary or
                        alternative medications.)</span>
                    </h4>
                  </div>
                  <table class="table table-bordered table-responsive" style={{ marginBottom: "0px" }}>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          className="text-center"
                          style={{ width: "33%" }}
                        >
                          Medication{" "}
                        </th>
                        <th className="text-center" style={{ width: "33%" }}>
                          Dose{" "}
                        </th>
                        <th className="text-center" style={{ width: "33%" }}>
                          Frequency{" "}
                        </th>
                      </tr>
                      <tr>
                        <td scope="row" style={{ padding: "0px" }}>
                          {" "}
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.medication1 ? "true" : "false"}
                                  name="medication1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.medication1?.message && (
                                  <ErrorDisplay message={errors.medication1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.medication1}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.dose1 ? "true" : "false"}
                                name="dose1"
                                ref={register(registerOptions.requiredField)}
                              />
                              {errors.dose1?.message && (
                                <ErrorDisplay message={errors.dose1?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.dose1}
                            </p>
                          )}                          
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.frequency1 ? "true" : "false"}
                                name="frequency1"
                                ref={register(registerOptions.requiredField)}
                              />
                              {errors.frequency1?.message && (
                                <ErrorDisplay message={errors.frequency1?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.frequency1}
                            </p>
                          )}                          
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" style={{ padding: "0px" }}>
                          {" "}
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.medication2 ? "true" : "false"}
                                  name="medication2"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.medication2?.message && (
                                  <ErrorDisplay message={errors.medication2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.medication2}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.dose2 ? "true" : "false"}
                                name="dose2"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.dose2?.message && (
                                <ErrorDisplay message={errors.dose2?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.dose2}
                            </p>
                          )}                          
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.frequency2 ? "true" : "false"}
                                name="frequency2"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.frequency2?.message && (
                                <ErrorDisplay message={errors.frequency2?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.frequency2}
                            </p>
                          )}                          
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" style={{ padding: "0px" }}>
                          {" "}
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.medication3 ? "true" : "false"}
                                  name="medication3"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.medication3?.message && (
                                  <ErrorDisplay message={errors.medication3?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.medication3}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.dose3 ? "true" : "false"}
                                name="dose3"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.dose3?.message && (
                                <ErrorDisplay message={errors.dose3?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.dose3}
                            </p>
                          )}                          
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.frequency3 ? "true" : "false"}
                                name="frequency3"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.frequency3?.message && (
                                <ErrorDisplay message={errors.frequency3?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.frequency3}
                            </p>
                          )}                          
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" style={{ padding: "0px" }}>
                          {" "}
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.medication4 ? "true" : "false"}
                                  name="medication4"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.medication4?.message && (
                                  <ErrorDisplay message={errors.medication4?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.medication4}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.dose4 ? "true" : "false"}
                                name="dose4"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.dose4?.message && (
                                <ErrorDisplay message={errors.dose4?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.dose4}
                            </p>
                          )}                          
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.frequency4 ? "true" : "false"}
                                name="frequency4"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.frequency4?.message && (
                                <ErrorDisplay message={errors.frequency4?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.frequency4}
                            </p>
                          )}                          
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" style={{ padding: "0px" }}>
                          {" "}
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.medication5 ? "true" : "false"}
                                  name="medication5"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.medication5?.message && (
                                  <ErrorDisplay message={errors.medication5?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.medication5}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.dose5 ? "true" : "false"}
                                name="dose5"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.dose5?.message && (
                                <ErrorDisplay message={errors.dose5?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.dose5}
                            </p>
                          )}                          
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.frequency5 ? "true" : "false"}
                                name="frequency5"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.frequency5?.message && (
                                <ErrorDisplay message={errors.frequency5?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.frequency5}
                            </p>
                          )}                          
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" style={{ padding: "0px" }}>
                          {" "}
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.medication6 ? "true" : "false"}
                                  name="medication6"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.medication6?.message && (
                                  <ErrorDisplay message={errors.medication6?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.medication6}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.dose6 ? "true" : "false"}
                                name="dose6"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.dose6?.message && (
                                <ErrorDisplay message={errors.dose6?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.dose6}
                            </p>
                          )}                          
                        </td>
                        <td style={{ padding: "0px" }}>
                          {" "}
                          {isEditable && (
                            <>
                              <textarea
                                className={getStype()}
                                aria-invalid={errors.frequency6 ? "true" : "false"}
                                name="frequency6"
                                ref={register(registerOptions.optional)}
                              />
                              {errors.frequency6?.message && (
                                <ErrorDisplay message={errors.frequency6?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.frequency6}
                            </p>
                          )}                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>*PRN = as needed </p>
                </div>
              </div>
            </section>
            <section className={getPrintWithBrack()}>
              <hr className="pdf-hr" />
              <div className="pdf-logobar">
                <img className="img-ccm-form" src={logo}></img>
              </div>
              {/* Allergies table  */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">Allergies</h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td scope="row" style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.allergies1 ? "true" : "false"}
                                  name="allergies1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.allergies1?.message && (
                                  <ErrorDisplay message={errors.allergies1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.allergies1}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.allergies2 ? "true" : "false"}
                                  name="allergies2"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.allergies2?.message && (
                                  <ErrorDisplay message={errors.allergies2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.allergies2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.allergies3 ? "true" : "false"}
                                  name="allergies3"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.allergies3?.message && (
                                  <ErrorDisplay message={errors.allergies3?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.allergies3}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Preventive Care (Enter dates.) table  */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">
                      Preventive Care<span className="pdf-light-font"> (Enter dates.)</span>
                    </h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <th style={{ width: "33%" }}>Vaccination</th>
                        <th style={{ width: "33%" }} scope="row">
                          Cancer / Other Screenings
                        </th>
                        <th style={{ width: "33%" }} scope="row">
                          Annual Wellness / Other Visits{" "}
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-0 view-mode-display-value">Flu:</p>
                          <div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioFluDate(e.target.value) }}
                                label="N/A"
                                value="N/A"
                                style={{ width: "20px" }}
                                aria-invalid={errors.radiofluDate ? "true" : "false"}
                                name="radiofluDate"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">N/A</label>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioFluDate(e.target.value) }}
                                label="Date"
                                style={{ width: "20px" }}
                                value="Date"
                                aria-invalid={errors.radiofluDate ? "true" : "false"}
                                name="radiofluDate"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">Date</label>
                            </div>
                            {errors.radiofluDate?.message && (
                              <ErrorDisplay message={errors.radiofluDate?.message} />
                            )}
                            {radioFluDate == "Date" && (
                              <div style={{ marginTop: "5px" }}>
                                {isEditable && (
                                  <>
                                    <input
                                      type="date"
                                      className={getStype()}
                                      style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                      max={moment().format("YYYY-MM-DD")}
                                      aria-invalid={errors.vaccinationFlu ? "true" : "false"}
                                      name="vaccinationFlu"
                                      ref={register(registerOptions.dateValidationRequired)}
                                    />
                                    {errors.vaccinationFlu?.message && (
                                      <ErrorDisplay message={errors.vaccinationFlu?.message} />
                                    )}
                                  </>
                                )}
                                {!isEditable && (
                                  <p className="mb-0 view-mode-display-value ptag-border">
                                    {props.latestSubmission.vaccinationFlu ? moment(props.latestSubmission.vaccinationFlu).format("MM/DD/YYYY") : ""}
                                  </p>
                                )}                                
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <p className="mb-0 view-mode-display-value">Breast:</p>
                          <div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioCancerBreast(e.target.value) }}
                                label="N/A"
                                value="N/A"
                                style={{ width: "20px" }}
                                aria-invalid={errors.radioCancerBreast ? "true" : "false"}
                                name="radioCancerBreast"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">N/A</label>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioCancerBreast(e.target.value) }}
                                label="Date"
                                style={{ width: "20px" }}
                                value="Date"
                                aria-invalid={errors.radioCancerBreast ? "true" : "false"}
                                name="radioCancerBreast"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">Date</label>
                            </div>
                            {errors.radioCancerBreast?.message && (
                              <ErrorDisplay message={errors.radioCancerBreast?.message} />
                            )}
                            {radioCancerBreast == "Date" && (
                              <div style={{ marginTop: "5px" }}>
                                {isEditable && (
                                  <>
                                    <input
                                      type="date"
                                      className={getStype()}
                                      style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                      max={moment().format("YYYY-MM-DD")}
                                      aria-invalid={errors.cancerBreast ? "true" : "false"}
                                      name="cancerBreast"
                                      ref={register(registerOptions.dateValidationRequired)}
                                    />
                                    {errors.cancerBreast?.message && (
                                      <ErrorDisplay message={errors.cancerBreast?.message} />
                                    )}
                                  </>
                                )}
                                {!isEditable && (
                                  <p className="mb-0 view-mode-display-value ptag-border">
                                    {props.latestSubmission.cancerBreast ? moment(props.latestSubmission.cancerBreast).format("MM/DD/YYYY") : ""}
                                  </p>
                                )}                                
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div>
                            {/* {isEditable && (
                              <>
                                <textarea
                                  type="text"
                                  className={getStype()}
                                  style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                  aria-invalid={errors.inputAnnualWellnessVisit1 ? "true" : "false"}
                                  name="inputAnnualWellnessVisit1"
                                  ref={radioAnnualWellnessVisit1 == "Date" ? register(registerOptions.requiredFieldLimit) : register(registerOptions.optionalFieldLimit)}
                                />
                                {errors.inputAnnualWellnessVisit1?.message && radioAnnualWellnessVisit1 == "Date" && (
                                  <ErrorDisplay message={errors.inputAnnualWellnessVisit1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && props.latestSubmission.inputAnnualWellnessVisit1 && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.inputAnnualWellnessVisit1 ? props.latestSubmission.inputAnnualWellnessVisit1 : ""}
                              </p>
                            )}*/}
                            <p className="mb-0 view-mode-display-value">Annual Wellness:</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioAnnualWellnessVisit1(e.target.value) }}
                                label="N/A"
                                value="N/A"
                                style={{ width: "20px" }}
                                aria-invalid={errors.radioAnnualWellnessVisit1 ? "true" : "false"}
                                name="radioAnnualWellnessVisit1"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">N/A</label>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioAnnualWellnessVisit1(e.target.value) }}
                                label="Date"
                                style={{ width: "20px" }}
                                value="Date"
                                aria-invalid={errors.radioAnnualWellnessVisit1 ? "true" : "false"}
                                name="radioAnnualWellnessVisit1"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">Date</label>
                            </div>
                            {errors.radioAnnualWellnessVisit1?.message && (
                              <ErrorDisplay message={errors.radioAnnualWellnessVisit1?.message} />
                            )}
                            {radioAnnualWellnessVisit1 == "Date" && (
                              <div style={{ marginTop: "5px" }}>
                                {isEditable && (
                                  <>
                                    <input
                                      type="date"
                                      className={getStype()}
                                      style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                      max={moment().format("YYYY-MM-DD")}
                                      aria-invalid={errors.annualWellnessVisit1 ? "true" : "false"}
                                      name="annualWellnessVisit1"
                                      ref={register(registerOptions.dateValidationRequired)}
                                    />
                                    {errors.annualWellnessVisit1?.message && (
                                      <ErrorDisplay message={errors.annualWellnessVisit1?.message} />
                                    )}
                                  </>
                                )}
                                {!isEditable && (
                                  <p className="mb-0 view-mode-display-value ptag-border">
                                    {props.latestSubmission.annualWellnessVisit1 ? moment(props.latestSubmission.annualWellnessVisit1).format("MM/DD/YYYY") : ""}
                                  </p>
                                )}                                
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-0 view-mode-display-value">Pneumonia:</p>
                          <div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioPneumonia(e.target.value) }}
                                label="N/A"
                                value="N/A"
                                style={{ width: "20px" }}
                                aria-invalid={errors.radioPneumonia ? "true" : "false"}
                                name="radioPneumonia"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">N/A</label>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioPneumonia(e.target.value) }}
                                label="Date"
                                style={{ width: "20px" }}
                                value="Date"
                                aria-invalid={errors.radioPneumonia ? "true" : "false"}
                                name="radioPneumonia"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">Date</label>
                            </div>
                            {errors.radioPneumonia?.message && (
                              <ErrorDisplay message={errors.radioPneumonia?.message} />
                            )}
                            {radioPneumonia == "Date" && (
                              <div style={{ marginTop: "5px" }}>
                                {isEditable && (
                                  <>
                                    <input
                                      type="date"
                                      className={getStype()}
                                      style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                      max={moment().format("YYYY-MM-DD")}
                                      aria-invalid={errors.pneumonia ? "true" : "false"}
                                      name="pneumonia"
                                      ref={register(registerOptions.dateValidationRequired)}
                                    />
                                    {errors.pneumonia?.message && (
                                      <ErrorDisplay message={errors.pneumonia?.message} />
                                    )}
                                  </>
                                )}
                                {!isEditable && (
                                  <p className="mb-0 view-mode-display-value ptag-border">
                                    {props.latestSubmission.pneumonia ? moment(props.latestSubmission.pneumonia).format("MM/DD/YYYY") : ""}
                                  </p>
                                )}                                
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <p className="mb-0 view-mode-display-value">Colon:</p>
                          <div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioColon(e.target.value) }}
                                label="N/A"
                                value="N/A"
                                style={{ width: "20px" }}
                                aria-invalid={errors.radioColon ? "true" : "false"}
                                name="radioColon"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">N/A</label>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setRadioColon(e.target.value) }}
                                label="Date"
                                style={{ width: "20px" }}
                                value="Date"
                                aria-invalid={errors.radioColon ? "true" : "false"}
                                name="radioColon"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">Date</label>
                            </div>
                            {errors.radioColon?.message && (
                              <ErrorDisplay message={errors.radioColon?.message} />
                            )}
                            {radioColon == "Date" && (
                              <div style={{ marginTop: "5px" }}>
                                {isEditable && (
                                  <>
                                    <input
                                      type="date"
                                      className={getStype()}
                                      style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                      max={moment().format("YYYY-MM-DD")}
                                      aria-invalid={errors.colon ? "true" : "false"}
                                      name="colon"
                                      ref={register(registerOptions.dateValidationRequired)}
                                    />
                                    {errors.colon?.message && (
                                      <ErrorDisplay message={errors.colon?.message} />
                                    )}
                                  </>
                                )}
                                {!isEditable && (
                                  <p className="mb-0 view-mode-display-value ptag-border">
                                    {props.latestSubmission.colon ? moment(props.latestSubmission.colon).format("MM/DD/YYYY") : ""}
                                  </p>
                                )}                                
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  type="text"
                                  className={getStype()}
                                  style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                  aria-invalid={errors.inputAnnualWellnessVisit2 ? "true" : "false"}
                                  name="inputAnnualWellnessVisit2"
                                  ref={radioAnnualWellnessVisit2 == "Date" ? register(registerOptions.requiredFieldLimit) : register(registerOptions.optionalFieldLimit)}
                                />
                                {errors.inputAnnualWellnessVisit2?.message && radioAnnualWellnessVisit2 == "Date" && (
                                  <ErrorDisplay message={errors.inputAnnualWellnessVisit2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && props.latestSubmission.inputAnnualWellnessVisit2 && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.inputAnnualWellnessVisit2 ? props.latestSubmission.inputAnnualWellnessVisit2 : ""}
                              </p>
                            )}                            
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setAnnualWellnessVisit2(e.target.value) }}
                                label="N/A"
                                value="N/A"
                                style={{ width: "20px" }}
                                aria-invalid={errors.radioAnnualWellnessVisit2 ? "true" : "false"}
                                name="radioAnnualWellnessVisit2"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">N/A</label>
                              <input
                                type="radio"
                                className={getStypeForRadio()}
                                onChange={(e) => { setAnnualWellnessVisit2(e.target.value) }}
                                label="Date"
                                style={{ width: "20px" }}
                                value="Date"
                                aria-invalid={errors.radioAnnualWellnessVisit2 ? "true" : "false"}
                                name="radioAnnualWellnessVisit2"
                                ref={register(registerOptions.requiredField)}></input>
                              <label className="radio-label">Date</label>
                            </div>
                            {errors.radioAnnualWellnessVisit2?.message && (
                              <ErrorDisplay message={errors.radioAnnualWellnessVisit2?.message} />
                            )}
                            {radioAnnualWellnessVisit2 == "Date" && (
                              <div style={{ marginTop: "5px" }}>
                                {isEditable && (
                                  <>
                                    <input
                                      type="date"
                                      className={getStype()}
                                      style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                      max={moment().format("YYYY-MM-DD")}
                                      aria-invalid={errors.annualWellnessVisit2 ? "true" : "false"}
                                      name="annualWellnessVisit2"
                                      ref={register(registerOptions.dateValidationRequired)}
                                    />
                                    {errors.annualWellnessVisit2?.message && (
                                      <ErrorDisplay message={errors.annualWellnessVisit2?.message} />
                                    )}
                                  </>
                                )}
                                {!isEditable && (
                                  <p className="mb-0 view-mode-display-value ptag-border">
                                    {props.latestSubmission.annualWellnessVisit2 ? moment(props.latestSubmission.annualWellnessVisit2).format("MM/DD/YYYY") : ""}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-0 view-mode-display-value">Tetanus:</p>
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioTetanus(e.target.value) }}
                              label="N/A"
                              value="N/A"
                              style={{ width: "20px" }}
                              aria-invalid={errors.radioTetanus ? "true" : "false"}
                              name="radioTetanus"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">N/A</label>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioTetanus(e.target.value) }}
                              label="Date"
                              style={{ width: "20px" }}
                              value="Date"
                              aria-invalid={errors.radioTetanus ? "true" : "false"}
                              name="radioTetanus"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">Date</label>
                          </div>
                          {errors.radioTetanus?.message && (
                            <ErrorDisplay message={errors.radioTetanus?.message} />
                          )}
                          {radioTetanus == "Date" && (
                            <div style={{ marginTop: "5px" }}>
                              {isEditable && (
                                <>
                                  <input
                                    type="date"
                                    className={getStype()}
                                    style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                    max={moment().format("YYYY-MM-DD")}
                                    aria-invalid={errors.tetanus ? "true" : "false"}
                                    name="tetanus"
                                    ref={register(registerOptions.dateValidationRequired)}
                                  />
                                  {errors.tetanus?.message && (
                                    <ErrorDisplay message={errors.tetanus?.message} />
                                  )}
                                </>
                              )}
                              {!isEditable && (
                                <p className="mb-0 view-mode-display-value ptag-border">
                                  {props.latestSubmission.tetanus ? moment(props.latestSubmission.tetanus).format("MM/DD/YYYY") : ""}
                                </p>
                              )}                              
                            </div>
                          )}
                        </td>
                        <td>
                          {isEditable && (
                            <>
                              <textarea
                                type="text"
                                className={getStype()}
                                style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                aria-invalid={errors.inputCancerBlank1 ? "true" : "false"}
                                name="inputCancerBlank1"
                                ref={radioCancerBlank1 == "Date" ? register(registerOptions.requiredFieldLimit) : register(registerOptions.optionalFieldLimit)}
                              />
                              {errors.inputCancerBlank1?.message && radioCancerBlank1 == "Date" && (
                                <ErrorDisplay message={errors.inputCancerBlank1?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && props.latestSubmission.inputCancerBlank1 && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.inputCancerBlank1 ? props.latestSubmission.inputCancerBlank1 : ""}
                            </p>
                          )}
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioCancerBlank1(e.target.value) }}
                              label="N/A"
                              value="N/A"
                              style={{ width: "20px" }}
                              aria-invalid={errors.radioCancerBlank1 ? "true" : "false"}
                              name="radioCancerBlank1"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">N/A</label>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioCancerBlank1(e.target.value) }}
                              label="Date"
                              style={{ width: "20px" }}
                              value="Date"
                              aria-invalid={errors.radioCancerBlank1 ? "true" : "false"}
                              name="radioCancerBlank1"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">Date</label>
                          </div>
                          {errors.radioCancerBlank1?.message && (
                            <ErrorDisplay message={errors.radioCancerBlank1?.message} />
                          )}
                          {radioCancerBlank1 == "Date" && (
                            <div style={{ marginTop: "5px" }}>
                              {isEditable && (
                                <>
                                  <input
                                    type="date"
                                    className={getStype()}
                                    style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                    max={moment().format("YYYY-MM-DD")}
                                    aria-invalid={errors.cancerBlank1 ? "true" : "false"}
                                    name="cancerBlank1"
                                    ref={register(registerOptions.dateValidationRequired)}
                                  />
                                  {errors.cancerBlank1?.message && (
                                    <ErrorDisplay message={errors.cancerBlank1?.message} />
                                  )}
                                </>
                              )}
                              {!isEditable && (
                                <p className="mb-0 view-mode-display-value ptag-border">
                                  {props.latestSubmission.cancerBlank1 ? moment(props.latestSubmission.cancerBlank1).format("MM/DD/YYYY") : ""}
                                </p>
                              )}                              
                            </div>
                          )}
                        </td>
                        <td>
                          {isEditable && (
                            <>
                              <textarea
                                type="text"
                                className={getStype()}
                                style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                aria-invalid={errors.inputAnnualWellnessVisit3 ? "true" : "false"}
                                name="inputAnnualWellnessVisit3"
                                ref={radioAnnualWellnessVisit3 == "Date" ? register(registerOptions.requiredFieldLimit) : register(registerOptions.optionalFieldLimit)}
                              />
                              {errors.inputAnnualWellnessVisit3?.message && radioAnnualWellnessVisit3 == "Date" && (
                                <ErrorDisplay message={errors.inputAnnualWellnessVisit3?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && props.latestSubmission.inputAnnualWellnessVisit3 && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.inputAnnualWellnessVisit3 ? props.latestSubmission.inputAnnualWellnessVisit3 : ""}
                            </p>
                          )}
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioAnnualWellnessVisit3(e.target.value) }}
                              label="N/A"
                              value="N/A"
                              style={{ width: "20px" }}
                              aria-invalid={errors.radioAnnualWellnessVisit3 ? "true" : "false"}
                              name="radioAnnualWellnessVisit3"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">N/A</label>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioAnnualWellnessVisit3(e.target.value) }}
                              label="Date"
                              style={{ width: "20px" }}
                              value="Date"
                              aria-invalid={errors.radioAnnualWellnessVisit3 ? "true" : "false"}
                              name="radioAnnualWellnessVisit3"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">Date</label>
                          </div>
                          {errors.radioAnnualWellnessVisit3?.message && (
                            <ErrorDisplay message={errors.radioAnnualWellnessVisit3?.message} />
                          )}
                          {radioAnnualWellnessVisit3 == "Date" && (
                            <div style={{ marginTop: "5px" }}>
                              {isEditable && (
                                <>
                                  <input
                                    type="date"
                                    className={getStype()}
                                    style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                    max={moment().format("YYYY-MM-DD")}
                                    aria-invalid={errors.annualWellnessVisit3 ? "true" : "false"}
                                    name="annualWellnessVisit3"
                                    ref={register(registerOptions.dateValidationRequired)}
                                  />
                                  {errors.annualWellnessVisit3?.message && (
                                    <ErrorDisplay message={errors.annualWellnessVisit3?.message} />
                                  )}
                                </>
                              )}
                              {!isEditable && (
                                <p className="mb-0 view-mode-display-value ptag-border">
                                  {props.latestSubmission.annualWellnessVisit3 ? moment(props.latestSubmission.annualWellnessVisit3).format("MM/DD/YYYY") : ""}
                                </p>
                              )}                              
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>                          
                          <p className="mb-0 view-mode-display-value">COVID-19:</p>
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioCovid(e.target.value) }}
                              label="N/A"
                              value="N/A"
                              style={{ width: "20px" }}
                              aria-invalid={errors.radioCovid ? "true" : "false"}
                              name="radioCovid"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">N/A</label>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioCovid(e.target.value) }}
                              label="Date"
                              style={{ width: "20px" }}
                              value="Date"
                              aria-invalid={errors.radioCovid ? "true" : "false"}
                              name="radioCovid"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">Date</label>
                          </div>
                          {errors.radioCovid?.message && (
                            <ErrorDisplay message={errors.radioCovid?.message} />
                          )}
                          {radioCovid == "Date" && (
                            <div style={{ marginTop: "5px" }}>
                              {isEditable && (
                                <>
                                  <input
                                    type="date"
                                    className={getStype()}
                                    style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                    max={moment().format("YYYY-MM-DD")}
                                    aria-invalid={errors.covid ? "true" : "false"}
                                    name="covid"
                                    ref={register(registerOptions.dateValidationRequired)}
                                  />
                                  {errors.covid?.message && (
                                    <ErrorDisplay message={errors.covid?.message} />
                                  )}
                                </>
                              )}
                              {!isEditable && (
                                <p className="mb-0 view-mode-display-value ptag-border">
                                  {props.latestSubmission.covid ? moment(props.latestSubmission.covid).format("MM/DD/YYYY") : ""}
                                </p>
                              )}                              
                            </div>
                          )}
                        </td>
                        <td>
                          {isEditable && (
                            <>
                              <textarea
                                type="text"
                                className={getStype()}
                                style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                aria-invalid={errors.inputCancerBlank2 ? "true" : "false"}
                                name="inputCancerBlank2"
                                ref={radioCancerBlank2 == "Date" ? register(registerOptions.requiredFieldLimit) : register(registerOptions.optionalFieldLimit)}
                              />
                              {errors.inputCancerBlank2?.message && radioCancerBlank2 == "Date" && (
                                <ErrorDisplay message={errors.inputCancerBlank2?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && props.latestSubmission.inputCancerBlank2 && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.inputCancerBlank2 ? props.latestSubmission.inputCancerBlank2 : ""}
                            </p>
                          )}
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioCancerBlank2(e.target.value) }}
                              label="N/A"
                              value="N/A"
                              style={{ width: "20px" }}
                              aria-invalid={errors.radioCancerBlank2 ? "true" : "false"}
                              name="radioCancerBlank2"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">N/A</label>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioCancerBlank2(e.target.value) }}
                              label="Date"
                              style={{ width: "20px" }}
                              value="Date"
                              aria-invalid={errors.radioCancerBlank2 ? "true" : "false"}
                              name="radioCancerBlank2"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">Date</label>
                          </div>
                          {errors.radioCancerBlank2?.message && (
                            <ErrorDisplay message={errors.radioCancerBlank2?.message} />
                          )}
                          {radioCancerBlank2 == "Date" && (
                            <div style={{ marginTop: "5px" }}>
                              {isEditable && (
                                <>
                                  <input
                                    type="date"
                                    className={getStype()}
                                    style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                    max={moment().format("YYYY-MM-DD")}
                                    aria-invalid={errors.cancerBlank2 ? "true" : "false"}
                                    name="cancerBlank2"
                                    ref={register(registerOptions.dateValidationRequired)}
                                  />
                                  {errors.cancerBlank2?.message && (
                                    <ErrorDisplay message={errors.cancerBlank2?.message} />
                                  )}
                                </>
                              )}
                              {!isEditable && (
                                <p className="mb-0 view-mode-display-value ptag-border">
                                  {props.latestSubmission.cancerBlank2 ? moment(props.latestSubmission.cancerBlank2).format("MM/DD/YYYY") : ""}
                                </p>
                              )}                              
                            </div>
                          )}
                        </td>
                        <td>
                          {isEditable && (
                            <>
                              <textarea
                                type="text"
                                className={getStype()}
                                style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                aria-invalid={errors.inputAnnualWellnessVisit4 ? "true" : "false"}
                                name="inputAnnualWellnessVisit4"
                                ref={radioAnnualWellnessVisit4 == "Date" ? register(registerOptions.requiredFieldLimit) : register(registerOptions.optionalFieldLimit)}
                              />
                              {errors.inputAnnualWellnessVisit4?.message && radioAnnualWellnessVisit4 == "Date" && (
                                <ErrorDisplay message={errors.inputAnnualWellnessVisit4?.message} />
                              )}
                            </>
                          )}
                          {!isEditable && props.latestSubmission.inputAnnualWellnessVisit4 && (
                            <p className="mb-0 view-mode-display-value">
                              {props.latestSubmission.inputAnnualWellnessVisit4 ? props.latestSubmission.inputAnnualWellnessVisit4 : ""}
                            </p>
                          )}
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioAnnualWellnessVisit4(e.target.value) }}
                              label="N/A"
                              value="N/A"
                              style={{ width: "20px" }}
                              aria-invalid={errors.radioAnnualWellnessVisit4 ? "true" : "false"}
                              name="radioAnnualWellnessVisit4"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">N/A</label>
                            <input
                              type="radio"
                              className={getStypeForRadio()}
                              onChange={(e) => { setRadioAnnualWellnessVisit4(e.target.value) }}
                              label="Date"
                              style={{ width: "20px" }}
                              value="Date"
                              aria-invalid={errors.radioAnnualWellnessVisit4 ? "true" : "false"}
                              name="radioAnnualWellnessVisit4"
                              ref={register(registerOptions.requiredField)}></input>
                            <label className="radio-label">Date</label>
                          </div>
                          {errors.radioAnnualWellnessVisit4?.message && (
                            <ErrorDisplay message={errors.radioAnnualWellnessVisit4?.message} />
                          )}
                          {radioAnnualWellnessVisit4 == "Date" && (
                            <div style={{ marginTop: "5px" }}>
                              {isEditable && (
                                <>
                                  <input
                                    type="date"
                                    className={getStype()}
                                    style={{ borderBottom: "1px solid #333", width: "150px", outline: "none" }}
                                    max={moment().format("YYYY-MM-DD")}
                                    aria-invalid={errors.annualWellnessVisit4 ? "true" : "false"}
                                    name="annualWellnessVisit4"
                                    ref={register(registerOptions.dateValidationRequired)}
                                  />
                                  {errors.annualWellnessVisit4?.message && (
                                    <ErrorDisplay message={errors.annualWellnessVisit4?.message} />
                                  )}
                                </>
                              )}
                              {!isEditable && (
                                <p className="mb-0 view-mode-display-value ptag-border">
                                  {props.latestSubmission.annualWellnessVisit4 ? moment(props.latestSubmission.annualWellnessVisit4).format("MM/DD/YYYY") : ""}
                                </p>
                              )}                              
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Psychosocial Assessment table  */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">Psychosocial Assessment</h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <th style={{ width: "50%" }}>
                          Psychological and neuropsychological testing (i.e.,
                          assessment/patient health questionnaire 2 [PHQ-2])
                        </th>
                        <th style={{ width: "50%", padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.psychologicalTesting ? "true" : "false"}
                                  name="psychologicalTesting"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.psychologicalTesting?.message && (
                                  <ErrorDisplay message={errors.psychologicalTesting?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.psychologicalTesting}
                              </p>
                            )}                            
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>Current employment status</td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.employmentStatus ? "true" : "false"}
                                  name="employmentStatus"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.employmentStatus?.message && (
                                  <ErrorDisplay message={errors.employmentStatus?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.employmentStatus}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>Household composition</td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.householdComposition ? "true" : "false"}
                                  name="householdComposition"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.householdComposition?.message && (
                                  <ErrorDisplay message={errors.householdComposition?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.householdComposition}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>Environmental evaluation </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.environmentalEvaluation ? "true" : "false"}
                                  name="environmentalEvaluation"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.environmentalEvaluation?.message && (
                                  <ErrorDisplay message={errors.environmentalEvaluation?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.environmentalEvaluation}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>Threats of violence/injury</td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.threatsOfViolence ? "true" : "false"}
                                  name="threatsOfViolence"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.threatsOfViolence?.message && (
                                  <ErrorDisplay message={errors.threatsOfViolence?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.threatsOfViolence}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>



              {/* Functional Assessment  table  */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">Functional Assessment </h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <th style={{ width: "25%" }}>
                          Activities of daily living
                        </th>
                        <th style={{ width: "75%", padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.dailyLiving ? "true" : "false"}
                                  name="dailyLiving"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.dailyLiving?.message && (
                                  <ErrorDisplay message={errors.dailyLiving?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.dailyLiving}
                              </p>
                            )}
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>Caregiver assessment</td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.caregiverAssessment ? "true" : "false"}
                                  name="caregiverAssessment"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.caregiverAssessment?.message && (
                                  <ErrorDisplay message={errors.caregiverAssessment?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.caregiverAssessment}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <section className={getPrintWithBrack()}>
              <hr className="pdf-hr" />
              <div className="pdf-logobar">
                <img className="img-ccm-form" src={logo}></img>                
              </div>

              {/* Chronic Condition #1—Goals and Interventions   */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">
                      Chronic Condition #1—Goals and Interventions{" "}
                    </h4>
                  </div>
                  <table class="table table-bordered table-responsive pdf-third-table">
                    <tbody>
                      <tr>
                        <td scope="row" style={{ width: "25%", fontWeight: "700" }}>Chronic condition #1</td>
                        <td scope="row" style={{ width: "75%", padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.chronicCondition1 ? "true" : "false"}
                                  name="chronicCondition1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.chronicCondition1?.message && (
                                  <ErrorDisplay message={errors.chronicCondition1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.chronicCondition1}
                              </p>
                            )}                           
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>Prognosis</td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.prognosis1 ? "true" : "false"}
                                  name="prognosis1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.prognosis1?.message && (
                                  <ErrorDisplay message={errors.prognosis1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.prognosis1}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Symptom management (Include any educational resources
                          provided.)
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.symptomManagement1 ? "true" : "false"}
                                  name="symptomManagement1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.symptomManagement1?.message && (
                                  <ErrorDisplay message={errors.symptomManagement1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.symptomManagement1}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Measurable treatment goals
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.measurableTreatmentGoals1 ? "true" : "false"}
                                  name="measurableTreatmentGoals1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.measurableTreatmentGoals1?.message && (
                                  <ErrorDisplay message={errors.measurableTreatmentGoals1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.measurableTreatmentGoals1}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Planned interventions
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.plannedInterventions1 ? "true" : "false"}
                                  name="plannedInterventions1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.plannedInterventions1?.message && (
                                  <ErrorDisplay message={errors.plannedInterventions1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.plannedInterventions1}
                              </p>
                            )}                            
                          </div></td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Coordination of care
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.coordinationOfCare1 ? "true" : "false"}
                                  name="coordinationOfCare1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.coordinationOfCare1?.message && (
                                  <ErrorDisplay message={errors.coordinationOfCare1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.coordinationOfCare1}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Chronic Condition #2—Goals and Interventions   */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">
                      Chronic Condition #2—Goals and Interventions
                    </h4>
                  </div>
                  <table class="table table-bordered table-responsive pdf-third-table">
                    <tbody>
                      <tr>
                        <td scope="row" style={{ width: "25%", fontWeight: "700" }}>Chronic condition #2</td>
                        <td scope="row" style={{ width: "75%", padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.chronicCondition2 ? "true" : "false"}
                                  name="chronicCondition2"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.chronicCondition2?.message && (
                                  <ErrorDisplay message={errors.chronicCondition2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.chronicCondition2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>Prognosis</td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.prognosis2 ? "true" : "false"}
                                  name="prognosis2"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.prognosis2?.message && (
                                  <ErrorDisplay message={errors.prognosis2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.prognosis2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Symptom management (Include any educational resources
                          provided.)
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.symptomManagement2 ? "true" : "false"}
                                  name="symptomManagement2"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.symptomManagement2?.message && (
                                  <ErrorDisplay message={errors.symptomManagement2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.symptomManagement2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Measurable treatment goals
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.measurableTreatmentGoals2 ? "true" : "false"}
                                  name="measurableTreatmentGoals2"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.measurableTreatmentGoals2?.message && (
                                  <ErrorDisplay message={errors.measurableTreatmentGoals2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.measurableTreatmentGoals2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Planned interventions
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.plannedInterventions2 ? "true" : "false"}
                                  name="plannedInterventions2"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.plannedInterventions2?.message && (
                                  <ErrorDisplay message={errors.plannedInterventions2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.plannedInterventions2}
                              </p>
                            )}                            
                          </div></td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "700" }}>
                          Coordination of care
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.coordinationOfCare2 ? "true" : "false"}
                                  name="coordinationOfCare2"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.coordinationOfCare2?.message && (
                                  <ErrorDisplay message={errors.coordinationOfCare2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.coordinationOfCare2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
              <AddConditionSection
                  {...{ control, register, defaultValues, getValues, setValue, reset, errors, registerOptions, isEditable,props }}
                />
              </div>
            </section>
            <section className={getPrintWithBrack()}>
              <hr className="pdf-hr" />
              <div className="pdf-logobar">
                <img className="img-ccm-form" src={logo}></img>
              </div>
              <h5>
                Community and/or social services ordered{" "}
                <span>(Check the appropriate box.)</span>{" "}
              </h5>
              <div className="mb-2">
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <input
                    type="radio"
                    className={getStypeForRadio()}
                    label="Yes"
                    value="Yes"
                    style={{ width: "40px" }}
                    aria-invalid={errors.communityAndSocialServices ? "true" : "false"}
                    name="communityAndSocialServices"
                    ref={register(registerOptions.requiredField)}></input>
                  <label className="radio-label">Yes</label>
                  <input
                    type="radio"
                    className={getStypeForRadio()}
                    label="No"
                    style={{ width: "40px" }}
                    value="No"
                    aria-invalid={errors.communityAndSocialServices ? "true" : "false"}
                    name="communityAndSocialServices"
                    ref={register(registerOptions.requiredField)}></input>
                  <label className="radio-label">No</label>
                </div>
                {errors.communityAndSocialServices?.message && (
                  <ErrorDisplay message={errors.communityAndSocialServices?.message} />
                )}
              </div>
              <h5>If yes, please list the services ordered:</h5>
              <div>
                {isEditable && (
                  <>
                    {isEditable && (
                      <>
                        <textarea
                          id="resize_textarea"
                          className={getStype() + " input-border"}
                          style={{ width: "100%", marginBottom: "0px", minHeight: `${height} !important`, height: "unset !important" }}
                          aria-invalid={errors.servicesOrdered ? "true" : "false"}
                          name="servicesOrdered"
                          ref={register(getValues("communityAndSocialServices") == "Yes" ? registerOptions.requiredField : registerOptions.optional)}
                        />
                        {errors.servicesOrdered?.message && (
                          <ErrorDisplay message={errors.servicesOrdered?.message} />
                        )}
                      </>
                    )}
                    {!isEditable && (
                      <p className="mb-0 view-mode-display-value">
                        {props.latestSubmission.servicesOrdered ? moment(props.latestSubmission.servicesOrdered).format("MM/DD/YYYY") : ""}
                      </p>
                    )}                    
                  </>
                )}
                {!isEditable && (
                  <p className="mb-0 view-mode-display-value">
                    {props.latestSubmission.servicesOrdered}
                  </p>
                )}
              </div>
              <b></b>
              {/* Care team (Include roles and responsibilities.)    */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">
                      Care team <span className="pdf-light-font">(Include roles and responsibilities.)</span>
                    </h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <th scope="row" style={{ width: "50%" }}>
                          Role
                        </th>
                        <th scope="row" style={{ width: "50%" }}>
                          Responsibilities
                        </th>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.role1 ? "true" : "false"}
                                  name="role1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.role1?.message && (
                                  <ErrorDisplay message={errors.role1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.role1}
                              </p>
                            )}
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.responsibilities1 ? "true" : "false"}
                                  name="responsibilities1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.responsibilities1?.message && (
                                  <ErrorDisplay message={errors.responsibilities1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.responsibilities1}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.role2 ? "true" : "false"}
                                  name="role2"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.role2?.message && (
                                  <ErrorDisplay message={errors.role2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.role2}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.responsibilities2 ? "true" : "false"}
                                  name="responsibilities2"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.responsibilities2?.message && (
                                  <ErrorDisplay message={errors.responsibilities2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.responsibilities2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.role3 ? "true" : "false"}
                                  name="role3"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.role3?.message && (
                                  <ErrorDisplay message={errors.role3?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.role3}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.responsibilities3 ? "true" : "false"}
                                  name="responsibilities3"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.responsibilities3?.message && (
                                  <ErrorDisplay message={errors.responsibilities3?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.responsibilities3}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.role4 ? "true" : "false"}
                                  name="role4"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.role4?.message && (
                                  <ErrorDisplay message={errors.role4?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.role4}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  rows={2}
                                  className={getStype()}
                                  aria-invalid={errors.responsibilities4 ? "true" : "false"}
                                  name="responsibilities4"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.responsibilities4?.message && (
                                  <ErrorDisplay message={errors.responsibilities4?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.responsibilities4}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <h5>Medication list reviewed:</h5>
              <div className="mb-2">
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <input
                    type="radio"
                    label="Yes"
                    value="Yes"
                    className={getStypeForRadio()}
                    style={{ width: "40px" }}
                    aria-invalid={errors.medicationListReviewed ? "true" : "false"}
                    name="medicationListReviewed"
                    ref={register(registerOptions.requiredField)}></input>
                  <label className="radio-label">Yes</label>
                  <input
                    type="radio"
                    label="No"
                    style={{ width: "40px" }}
                    value="No"
                    className={getStypeForRadio()}
                    aria-invalid={errors.medicationListReviewed ? "true" : "false"}
                    name="medicationListReviewed"
                    ref={register(registerOptions.requiredField)}></input>
                  <label className="radio-label">No</label>
                </div>
                {errors.medicationListReviewed?.message && (
                  <ErrorDisplay message={errors.medicationListReviewed?.message} />
                )}
              </div>

              <h5>Medication reconciliation last completed date:</h5>
              <div>
                {isEditable && (
                  <>
                    <input
                      type="date"
                      className={getStype()}
                      max={moment().format("YYYY-MM-DD")}
                      style={{ width: "100%", marginBottom: "0px" }}
                      aria-invalid={errors.medicationReconciliationLastDate ? "true" : "false"}
                      name="medicationReconciliationLastDate"
                      ref={register(registerOptions.dateValidationOptional)}
                    />
                    {errors.medicationReconciliationLastDate?.message && (
                      <ErrorDisplay message={errors.medicationReconciliationLastDate?.message} />
                    )}
                  </>
                )}
                {!isEditable && (
                  <p className="mb-0 view-mode-display-value ptag-border">
                    {props.latestSubmission.medicationReconciliationLastDate ? moment(props.latestSubmission.medicationReconciliationLastDate).format("MM/DD/YYYY") : ""}
                  </p>
                )}
                
              </div>

              <h5>Care plan reviewed and shared with patient:</h5>
              <div className="mb-2">
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <input
                    type="radio"
                    label="Yes"
                    value="Yes"
                    className={getStypeForRadio()}
                    style={{ width: "40px" }}
                    aria-invalid={errors.carePlanReviewedSharePatient ? "true" : "false"}
                    name="carePlanReviewedSharePatient"
                    ref={register(registerOptions.requiredField)}></input>
                  <label className="radio-label">Yes</label>
                  <input
                    type="radio"
                    label="No"
                    style={{ width: "40px" }}
                    value="No"
                    className={getStypeForRadio()}
                    aria-invalid={errors.carePlanReviewedSharePatient ? "true" : "false"}
                    name="carePlanReviewedSharePatient"
                    ref={register(registerOptions.requiredField)}></input>
                  <label className="radio-label">No</label>
                </div>
                {errors.carePlanReviewedSharePatient?.message && (
                  <ErrorDisplay message={errors.carePlanReviewedSharePatient?.message} />
                )}
              </div>

              <h5>Care plan reviewed and shared date:</h5>
              <div>
                {isEditable && (
                  <>
                    <input
                      type="date"
                      className={getStype()}
                      max={moment().format("YYYY-MM-DD")}
                      style={{ width: "100%", marginBottom: "0px" }}
                      aria-invalid={errors.carePlanReviewedDate ? "true" : "false"}
                      name="carePlanReviewedDate"
                      ref={register(registerOptions.dateValidationOptional)}
                    />
                    {errors.carePlanReviewedDate?.message && (
                      <ErrorDisplay message={errors.carePlanReviewedDate?.message} />
                    )}
                  </>
                )}
                {!isEditable && (
                  <p className="mb-0 view-mode-display-value ptag-border">
                    {props.latestSubmission.carePlanReviewedDate ? moment(props.latestSubmission.carePlanReviewedDate).format("MM/DD/YYYY") : ""}
                  </p>
                )}                
              </div>
              <br></br>
              {/* Care Management Follow-up Activities    */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "680px" }}>
                  <div className="common-heading-wrapper">
                    <h4 className="common-heading-title">
                      Care Management Follow-up Activities
                    </h4>
                  </div>
                  <table class="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <th scope="row" style={{ width: "50%" }}>
                          Activity/task description
                        </th>
                        <th scope="row" style={{ width: "50%" }}>
                          Time spent (in minutes){" "}
                        </th>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.activityDescription1 ? "true" : "false"}
                                  name="activityDescription1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.activityDescription1?.message && (
                                  <ErrorDisplay message={errors.activityDescription1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.activityDescription1}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.timeSpent1 ? "true" : "false"}
                                  name="timeSpent1"
                                  ref={register(registerOptions.requiredField)}
                                />
                                {errors.timeSpent1?.message && (
                                  <ErrorDisplay message={errors.timeSpent1?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.timeSpent1}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.activityDescription2 ? "true" : "false"}
                                  name="activityDescription2"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.activityDescription2?.message && (
                                  <ErrorDisplay message={errors.activityDescription2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.activityDescription2}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.timeSpent2 ? "true" : "false"}
                                  name="timeSpent2"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.timeSpent2?.message && (
                                  <ErrorDisplay message={errors.timeSpent2?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.timeSpent2}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.activityDescription3 ? "true" : "false"}
                                  name="activityDescription3"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.activityDescription3?.message && (
                                  <ErrorDisplay message={errors.activityDescription3?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.activityDescription3}
                              </p>
                            )}                            
                          </div>
                        </td>
                        <td style={{ padding: "0px" }}>
                          <div>
                            {isEditable && (
                              <>
                                <textarea
                                  className={getStype()}
                                  aria-invalid={errors.timeSpent3 ? "true" : "false"}
                                  name="timeSpent3"
                                  ref={register(registerOptions.optional)}
                                />
                                {errors.timeSpent3?.message && (
                                  <ErrorDisplay message={errors.timeSpent3?.message} />
                                )}
                              </>
                            )}
                            {!isEditable && (
                              <p className="mb-0 view-mode-display-value">
                                {props.latestSubmission.timeSpent3}
                              </p>
                            )}                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <section className={getPrintWithBrack()}>
              <br />
              <hr className="pdf-hr" />
              <div className="pdf-logobar">
                <img className="img-ccm-form" src={logo}></img>
              </div>
              <div>
                <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>References:</h4>
                <ol>
                  <li>
                    Centers for Medicare & Medicaid Services (CMS). Chronic Care
                    Management Services. Accessed on June 7, 2022. Available at
                    <a> https://www.cms.gov/outreach-and-education/medicare-learning-network-
                      mln/mlnproducts/downloads/chroniccaremanagement.pdf.</a>
                  </li>
                  <li>
                    Home Centered Care Institute. Chronic Care Management Care Plan
                    Requirements. Accessed on June 7, 2022. Available at
                    <a> https://www.hccinstitute.org/app/uploads/2019/09/CCM-Care-Plan-Requirements-190911.pdf?x41850.</a>
                  </li>
                  <li>
                    CEUfast Nursing CE. Psychosocial Assessment: A Nursing
                    Perspective. Accessed on June 7, 2022. Available at
                    <a>  https://ceufast.com/course/psychosocial-assessment-a-nursing-perspective.</a>
                  </li>
                  <li>
                    American Academy of Family Physicians (AAFP). The Geriatric
                    Assessment. American Family Physician. 2011: 83(1): 48–56.
                    Available at
                    <a>  https://www.aafp.org/pubs/afp/issues/2011/0101/p48.html.</a>
                  </li>
                </ol>
                <br></br>
                <p>
                  This material was adapted by Health Services Advisory Group (HSAG),
                  a Quality Innovation Network-Quality Improvement Organization under
                  contract with the Centers for Medicare & Medicaid Services (CMS), an
                  agency of the U.S. Department of Health and Human Services (HHS),
                  based on content created by Health Quality Innovators, a Hospital
                  Quality Innovation Network (HQIC) under contract with CMS. Views
                  expressed in this material do not necessarily reflect the official
                  views or policy of CMS or HHS, and any reference to a specific
                  product or entity herein does not constitute endorsement of that
                  product or entity by CMS or HHS. Publication No.
                  QN-12SOW-XC-06082022-01{" "}
                </p>
              </div>
            </section>                        
            
            <SignatureWithName
              signature={signatureLink}
              isBorder={true}
              name={name}
            />
            <br />
            <br />
            {isEditable && (
              <div className="submit-div">
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)} 
                  style={{ float: 'right', width:"250px" }}
                  className="btn btn-info ml-2 save-click-cls" >
                  <i class="mdi mdi-content-save" style={{
                    fontSize: 20
                  }}></i>
                  Save
                </button>
              </div>
            )}            
          </div>
      </div>
    </>
  );
});

export default CCMForm;
