import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import ViolationList from "./ViolationList";
import Heading from "./Heading";
import moment from "moment";
import size from "lodash/size";
import { filter } from "lodash";
class Violations extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      display: "Events&Exceptions"
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.datesForException = this.datesForException.bind(this);
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  // Perform for patient's login only
  datesForException(value) {
    this.props.dispatch({
      type: "DO_GET_MY_VIOLATIONS",
      payload: { ...value }
    });
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_MY_VIOLATIONS"
    });
    this.props.dispatch({
      type: "DO_GET_USER_VIOLATIONS"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_YOU_HAVE_ACCESS"
    });
    if (this.props.isCareProvider) {
      this.toggle("2");
    } else {
      this.toggle("1");
    }
  }

  render() {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const startYear = moment("September 2018").startOf("month").format("YYYY");
    const endYear = moment().startOf("month").format("YYYY");
    const startMonth = moment("September 2018").startOf("month").format("YYYY");
    const endMonth = moment().startOf("month").format("YYYY");
    var years = moment(endYear).diff(startYear, "years");
    var yearList = [];
    for (var i = 2018; i <= endYear; i++) yearList.push(i);

    return (
      <div>
        <div className="row">
          <Heading
            title={
              !this.props.isCareProvider ? "EXCEPTIONS" : "PATIENTS EXCEPTIONS"
            }
            description=""
            backbutton="d-none"
            mainbutton="d-none"
            toggleModal={this.toggleModal}
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Heading>

          <div className="col-12 grid-margin">
            <div className="card overflow-hidden">{/*Shrey Added */}
                <Nav tabs className="justify-content-center">{/*Shrey Added */}
                  {!this.props.isCareProvider && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1"
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        MY EXCEPTIONS
                      </NavLink>
                    </NavItem>
                  )}
                  {this.props.isCareProvider && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2"
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        EVENTS/EXCEPTIONS
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              <div className="card-body project-tab pt-2">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12" className="grid-margin">
                        {this.props.myViolations && (
                          <ViolationList
                            myRules={this.props.myViolations}
                            userRole={this.props.userRole}
                            datesForException={this.datesForException}
                            userId={this.props.userId}
                          />
                        )}
                        {/* {size(this.props.myViolations) === 0 && (
                          <h2 className="text-center">No Exceptions Found</h2>
                        )} */}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12" className="grid-margin">
                        <div className={`text-center col-md-6 mx-auto mt-5`}>
                          <h5 className="mb-4">Date Range</h5>
                          {/* <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="exampleEmail">Pick the Month</Label>
                                <select
                                  className="form-control"
                                  value={this.state.month}
                                  onChange={this.handleInputChange}
                                  name="month"
                                >
                                  <option value="" />
                                  {monthNames.map((value, index) => {
                                    return (
                                      <option value={value}>{value}</option>
                                    );
                                  })}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="exampleEmail">Pick the Year</Label>
                                <select
                                  className="form-control"
                                  value={this.state.year}
                                  onChange={this.handleInputChange}
                                  name="year"
                                >
                                  <option value="" />
                                  {yearList.map((value, index) => {
                                    return (
                                      <option value={value}>{value}</option>
                                    );
                                  })}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row> */}
                        </div>
                        {this.props.usersViolations && (
                          <ViolationList
                            myRules={this.props.usersViolations}
                            userId={this.props.userId}
                          />
                        )}
                        {!this.props.usersViolations && (
                          <h2 className="text-center">Loading Exceptions</h2>
                        )}
                        {/* {this.props.usersViolations &&
                          size(this.props.usersViolations) === 0 && (
                            <h2 className="text-center">No Exceptions Found</h2>
                          )} */}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myViolations,
    usersViolations,
    usersOfferedAccess
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;
  const userRole = user.userDetails.role;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myViolations,
    usersViolations,
    userId,
    isCareProvider,
    usersOfferedAccess,
    userRole
  };
};

export default connect(mapStateToProps)(Violations);
