import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter
} from "react-bootstrap-table2-filter";
import userImage from "../../files/user.png";
import doctorImage from "../../files/doctor.png";
import moment from "moment";
function fullname(cell) {
  return (
    <span>
      {!cell.isCareProvider && (
        <img src={userImage} className="mr-2" alt="image" />
      )}
      {cell.isCareProvider && (
        <img src={doctorImage} className="mr-2" alt="image" />
      )}
      {cell.fname} {cell.lname}
    </span>
  );
}
function isApproved(cell, row) {
  return (
    <span>
      {row.isApproved ? (
        <label className="badge badge-gradient-success">APPROVED</label>
      ) : (
        <label className="badge badge-gradient-warning">PENDING</label>
      )}
    </span>
  );
}
function formatDate(cell) {
  return moment(cell).format("MMMM DD, YYYY hh:mm A");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  const authenticatedUser = formatExtraData.userId;
  if (row) {
    const receivedById = row.receivedBy ? row.receivedBy._id : "";
    const isSameUser = authenticatedUser !== receivedById;
    const approvedButton =
      isSameUser && !row.isApproved ? (
        <button
          type="button"
          onClick={() => formatExtraData.approve(row._id)}
          className="btn btn-success btn-fw"
        >
          Accept Request
        </button>
      ) : (
        ""
      );
    return (
      <span>
        {row.isApproved && !isSameUser ? (
          <button
            type="button"
            onClick={() => formatExtraData.delete(row._id)}
            className="btn btn-danger btn-fw"
          >
            Delete
          </button>
        ) : (
          approvedButton
        )}
      </span>
    );
  }
}

const YouShared = (props, context) => (
  <BootstrapTable
    wrapperClasses="table-responsive"
    keyField="_id"
    noDataIndication="No Data Found"
    bordered={false}
    hover={true}
    data={props.usersGivenAccessMyReading}
    filter={filterFactory()}
    columns={[
      {
        dataField: props.receivedFrom ? "receivedBy" : "receivedFrom",
        text: "Name",
        formatter: fullname,
        sort: true,
        filter: textFilter(),
        filterValue: (cell, row) => {
          return cell.fname + cell.lname + cell.organization;
        },
        headerFormatter: priceFormatter
      },
      {
        dataField: props.receivedFrom ? "receivedBy" : "receivedFrom",
        text: "Nickname",
        formatter: (cell, row) => {
          return (cell.nickname ? cell.nickname :"-");
        },
        sort: true,
        filter: textFilter(),
        filterValue: (cell, row) => {
          return cell.nickname ? cell.nickname : '-';
        },
        headerFormatter: priceFormatter     
      },
      {
        dataField: props.receivedFrom
          ? "receivedBy.organization"
          : "receivedFrom.organization",
        text: "Organization",
        sort: true,
        filter: textFilter(),
        headerFormatter: priceFormatter
      },
      {
        dataField: props.receivedFrom
          ? "receivedBy.email"
          : "receivedFrom.email",
        text: "Email Address",
        sort: true,
        filter: textFilter(),
        headerFormatter: priceFormatter
      },
      {
        dataField: "updatedAt",
        text: "Status",
        formatter: isApproved
      },
      {
        dataField: "createdAt",
        text: "Date Created",
        formatter: formatDate,
        sort: true
      },
      {
        dataField: "isApproved",
        text: "Action",
        formatter: showButtons,
        formatExtraData: {
          delete: props.deletePermission,
          approve: props.approvePermission,
          receivedFrom: props.receivedFrom,
          userId: props.userId
        }
      }
    ]}
  />
);

export default YouShared;
