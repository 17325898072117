import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";

import {
  VitalsIcon,
  TimeIcon,
  AdherenceIcon,
  ReminderIcon,
  TrendingIcon
} from "../Rules/RuleIcon";
import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";
import DateTime from "react-datetime";
import moment from "moment";
import { useDispatch } from "react-redux";
import { some, isEmpty } from "lodash";
import manualPMIcon from "../../files/manualPMIcon.svg";
import ccmModeIcon from "../../files/ccmModeIcon.png";
import rpmModeIcon from "../../files/rpmModeIcon.png";
import _ from "lodash";
import Swal from 'sweetalert2';
function formatDuration(duration) {
  return [
    ("0" + Math.floor(duration.asHours())).slice(-2),
    ("0" + duration.minutes()).slice(-2),
    ("0" + duration.seconds()).slice(-2)
  ].join(":");
}

const PickCPM = (props) => {
  const dispatch = useDispatch();
  const { patient: patientData, careprovider = "" } = props;
  const [startDateError, setStartDateError] = useState(null);
  const [canclePopup, setCanclePopup] = useState(false);
  const [errorComment, setErrorComment] = useState(true);
  const [comment, setComment] = useState("");
  const [noteTitle, setNoteTitle] = useState(false);
  const { _id, fname, lname } = patientData ? patientData[0] : undefined;  
  const [form, setValues] = useState({
    startTime: moment().format("MM/DD/YYYY"),
    patient: _id ? _id : undefined,
    careProvider: careprovider,
    endTime: null,
    time: "00:01:00",
    pmMode: null,
    isManual: true,
    comment: ""
  });
  useEffect(() => {
    if (
      form.startTime &&
      form.endTime &&
      form.startTime instanceof moment &&
      form.endTime instanceof moment
    ) {
      const dateDifference = moment.duration(form.endTime.diff(form.startTime));
      const timeDifference = formatDuration(dateDifference);
      setValues({
        ...form,
        time: timeDifference
      });
    }
  }, [form.startTime, form.endTime]);

  /**
   * DEFAULT MESSAGE TITLE AND MESSAGE'S
   */
  const mList = [
    {
      name: "Patient Alert- Asymptomatic",
      message: "Alert received. Patient denies any symptoms. Encouraged patient to recheck measurement. Follow up measurement ____________ . Reviewed when to call MD/seek emergency care for any concerning symptoms, medical concerns, concerning symptoms accompanying _________________________ . Verbalized understanding."
    },
    {
      name: "Patient Alert- Symptomatic",
      message: "Alert received. Symptoms: _________________ . Encouraged patient to call their provider office and schedule a virtual visit or in office visit. PCP office notified."
    }, {
      name: "Patient Alert- Will continue to monitor",
      message: "Patient reiterated that even with HIGH/LOW numbers, they still feel fine. Will continue to monitor."
    }, {
      name: "RPM Monthly Phone Consultation",
      message: "ER visits, hospitalizations or falls in the last month ___________ . Specialty visits in the last 30 days ______________ . Any new Health concerns at the moment _____________________ . Any Symptoms (extreme fatigue, SOB, chest pain, dizziness, swelling, HR- racing/ fluttering) _______________ . Last PCP visit was _____________ . Medication changes in the last 30 days ______________ . We discussed their vital readings for the last month. Total Readings ______________ . Patient has had Equal to or more than 16 unique days with vital uploads ______________ ."
    },
    {
      name: "CCM Monthly Phone Consultation",
      message: "Patient answered the CCM questionnaire. ER visits, hospitalizations or falls in the last month __________________ . Any specialty visits in the last 30 days _______________ CCM Diagnosis: _____________ . SYMPTOMS: ______________ . MEDICATIONS: ______________ . Takes medications as directed _____________ . Denies barriers, changes or refills needed  _____________ . MONTHLY CONCENTRATION: ______________ . EDUCATION: ____________ . Watch for symptoms of ______________ . Reviewed when to call MD/seek emergency care for any concerning symptoms. Verbalized understanding. PATIENT’S CARE GOALS: Minimize progression of ______________ and reduce symptoms. Summary Statement: CCM monthly call performed. 20 minutes of activity has been spent reviewing the patient chart for labs, referrals, changes in condition, medication review, HEDIS review, Care plan and goals review, education to the patient regarding their health status and health care needs."
    },
    {
      name: "Chart / Adherence Review",
      message: "Chart / Adherence Review"
    },
    {
      name: "EHR Review to Follow Up on Patient Cases",
      message: "EHR Review to Follow Up on Patient Cases"
    },
    {
      name: "Follow Up for Tech Issues, Supplies etc",
      message: "Follow Up for Tech Issues, Supplies etc"
    },
    {
      name: "Left Voicemail",
      message: "Left Voicemail"
    },
    {
      name: "Other",
      message: "Other"
    },
  ];

  /**
   * INPUT CHANGE EVENT
   * @param {*} event EVENT DETAILS
   */
  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name == "subject") {
      const model = _.find(mList, model => model.name == value);
      if (model && model.message && model.message.length > 0) {
        setErrorComment(null);
        setNoteTitle(model.name);
        setValues({
          ...form,
          comment:model.message
        });
      } else {
        setNoteTitle("");
        setValues({
          ...form,
          comment:""
        });
      }
    }
  }
  const updateField = (e) => {    
    if(e.target.name == "startTime"){
      isFutureDate(e);
    }    
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
    if(e.target.value == "RPM MODE" || e.target.value == "CCM MODE"){
      setIsValid(true);
    } else {
      if(e.target.name == "pmMode" && e.target.value == "-1"){
        setValues({
          ...form,
          [e.target.name]: null
        });
        setIsValid(false);
      }      
    }    
  };

  const submitPM = (e) => {
    e.preventDefault();    
    if(startDateError){
      return;
    }
    setIsValid(form.pmMode != null ? true : false);
    setIsValid(form.pmMode != null ? true : false);
    e.preventDefault();
    const finalForm = { ...form, patient: _id };
    finalForm.isPublic = isPublic;
    const dateNow = new Date();

    const startTime = moment(dateNow)
      .date(moment(finalForm.startTime).date())
      .month(moment(finalForm.startTime).month())
      .year(moment(finalForm.startTime).year())
      .utc();

    const endTime = moment(dateNow)
      .date(moment(finalForm.startTime).date())
      .month(moment(finalForm.startTime).month())
      .year(moment(finalForm.startTime).year())
      .add(moment.duration(finalForm.time).asMinutes(), "minutes")
      .utc();

    finalForm.startTime = startTime;
    finalForm.endTime = endTime;
    finalForm.noteTitle = noteTitle;
    finalForm.hadPhoneConsultation = hadPhoneConsultation;

    const dateDifference = moment.duration(endTime.diff(startTime));

    const checkValue = some(finalForm, function (value) {
      return value === null;
    });

    if (
      !checkValue &&
      dateDifference.asMinutes() == moment.duration(finalForm.time).asMinutes()
    ) {
      finalForm.isManual = true;
      dispatch({
        type: "DO_SAVE_TIMER",
        payload: finalForm
      });
      setmanualModal(false);
      props.toggleModal();
      setValues({
        startTime: moment().format("MM/DD/YYYY"),
        patient: _id,
        careProvider: careprovider,
        endTime: null,
        time: "00:01:00",
        pmMode: null,
        isManual: true,
        comment: "",
        isPublic: true,
        hadPhoneConsultation: false
      });
    }
  };
  const [manualModal, setmanualModal] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [hadPhoneConsultation, setHadPhoneConsultation] = useState(false);
  const [isValid, setIsValid] = useState(undefined);
  let durationOptions = [];
  for (let i = 1; i <= 120; i++) {
    if (i < 10) {
      durationOptions.push(<option value={`00:0${i}:00`}>{i} minutes</option>);
    } else {
      durationOptions.push(<option value={`00:${i}:00`}>{i} minutes</option>);
    }
  }
/**
   * Validation of the Start Date field
   * @param {*} value Entered value
   * @returns validation status
   */
function isFutureDate(value) {    
  var date = moment(new Date(), 'MM/DD/YYYY');
  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  var dateEntered = new Date(value.target.value);
  var today = moment(date, 'MM/DD/YYYY');
  var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
  var dDiff = today.diff(enteredDate);
  if (dDiff < 0) {
    setStartDateError("The selected date is future date. Please enter the correct date!");
    return false;
  } else {
    setStartDateError(null);
    return true;
  }
};
  return (
    <>
      <Modal
        isOpen={props.modal}
        toggle={props.toggleModal}
        className="bastaLang modal-md"
      >
        <ModalHeader toggle={props.toggleModal} className="bg-info text-white">
          Choose a Patient Monitoring Mode
        </ModalHeader>

        <ModalBody style={{ backgroundColor: "white" }}>
          <h5 className="text-center" />
          <div className="row">
            <div className="col-6">
              <NavLink onClick={props.countRPM} className="text-center d-block">
                <img src={rpmModeIcon} style={{ height: "180px" }} />
                <span className="menu-title font-weight-bold d-block text-dark">
                  RPM MODE
                </span>
                <small>Remote Patient Monitoring</small>
              </NavLink>
            </div>
            <div className="col-6">
              <NavLink onClick={props.countCCM} className="text-center d-block">
                <img src={ccmModeIcon} style={{ height: "180px" }} />
                <span className="menu-title font-weight-bold d-block text-dark">
                  CCM MODE
                </span>
                <small>Chronic Care Monitoring</small>
              </NavLink>
            </div>
            <div className="col-6 mx-auto">
              <NavLink
                onClick={() => {
                  setmanualModal(!manualModal);
                  setValues({
                    startTime: moment().format("MM/DD/YYYY"),
                    patient: _id,
                    careProvider: careprovider,
                    endTime: null,
                    time: "00:01:00",
                    pmMode: null,
                    isManual: true,
                    comment: "",
                    isPublic: true,
                    hadPhoneConsultation: false
                  });
                }}
                className="text-center d-block"
              >
                <span class="notify-badge bg-info">
                  <i className="mdi mdi-plus"></i>
                </span>
                <img src={manualPMIcon} style={{ height: "180px" }} />
                <span className="menu-title font-weight-bold d-block text-dark">
                  MANUAL INPUT RPM or CCM MODE
                </span>
                <small>You can manually add here your sessions</small>
              </NavLink>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        backdrop="static"
        isOpen={manualModal}
        toggle={() => {
          setmanualModal(!manualModal);
          setValues({
            startTime: moment().format("MM/DD/YYYY"),
            patient: _id,
            careProvider: careprovider,
            endTime: null,
            time: "00:01:00",
            pmMode: null,
            isManual: true,
            comment: ""
          });
        }}
      >
        <ModalHeader
          className="close_button" style={{ float: 'right' }}
          toggle={() => {
            setmanualModal(!manualModal);
            setValues({
              startTime: moment().format("MM/DD/YYYY"),
              patient: _id,
              careProvider: careprovider,
              endTime: null,
              time: "00:01:00",
              pmMode: null,
              isManual: true,
              comment: ""
            });
          }}>          
          <Label className="align-items-center justify-content-center text-center">Add Manual PM Session</Label>
          <button
            style={{ float: 'right' }}
            onClick={() => {
              setmanualModal(!manualModal);
            }}
            className="btn btn-info ml-2 close-click-cls"
          >
            <i class="mdi mdi-close" style={{
              fontSize: 20
            }}></i>
            Close
          </button>
        </ModalHeader>

        <ModalBody style={{ backgroundColor: "white" }}>
          <Form onSubmit={submitPM} autocomplete="off">
            <FormGroup>
              <Label for="exampleSelect">Patient</Label>
              <Input 
              className="input-date-filter remove-bottom-margin"
              readOnly 
              value={fname + " " + lname} />
            </FormGroup>
            <FormGroup inline>
              <Label for="exampleSelect">Date <span style={{color:"red"}}>*</span></Label>
              <DateTime
                onChange={(value) =>
                  updateField({ target: { name: "startTime", value: value } })
                }
                required
                inputProps={{
                  name: "startTime",
                  required: "required",
                  autocomplete: "off"
                }}
                timeFormat={false}
                // initialValue={new Date().toString()}
                defaultValue={moment().format("MM/DD/YYYY")}
              />
              <Label 
                style={{color: "red", marginTop:5}}>{startDateError}</Label>
              {/* <Label for="exampleSelect">End Date And Time*</Label>
              <DateTime
                onChange={(value) =>
                  updateField({ target: { name: "endTime", value: value } })
                }
                inputProps={{
                  name: "endTime",
                  required: "required",
                  autocomplete: "off"
                }}
              /> */}
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">Select which Program <span style={{color:"red"}}>*</span></Label>
              <select
                className="form-control input-date-filter remove-bottom-margin"
                name="pmMode"
                value={form.pmMode}
                onChange={updateField}
                required
              >
                <option value="-1">Please select one...</option>
                <option value="RPM MODE">RPM</option>
                <option value="CCM MODE">CCM</option>
              </select>
              {!isValid && <p style={{color:"red"}}>You must select the PM mode.</p>}
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">Total Duration</Label>
              <Input 
              className="input-date-filter remove-bottom-margin"
              type="select" 
              name="time" 
              onChange={updateField}>{durationOptions}
              </Input>
            </FormGroup>
            <FormGroup>
              <Input
                className="input-date-filter remove-bottom-margin"
                type="select"
                name="subject"
                required={false}
                onChange={handleInputChange}
                id="exampleSelect">
                <option value="">Select Subject</option>
                {mList.map((value, index) => {
                  return <option value={value.name}>{value.name}</option>;
                })}
              </Input>
            </FormGroup>           
            <FormGroup>
              <Input
                className="input-date-filter remove-bottom-margin input-message-style"
                required
                placeholder="Write a comment or reason here."
                type="textarea"
                name="comment"
                onChange={updateField}
                value={form.comment}
                rows={10}
              />
            </FormGroup>
            <FormGroup check inline>
              <div style={{
                display: "flex"
              }}>
                <input
                  className="input-date-filter remove-bottom-margin"
                  type="checkbox"
                  name="isPublic"
                  checked={isPublic}
                  style={{
                    width: "20px",
                    height: "20px"
                  }}
                  onChange={(e) => setIsPublic(e.target.checked)}
                />
                <Label className="font-weight-bold" style={{
                  marginLeft: 10,
                  marginTop: 5
                }}>
                  Public
                </Label>
              </div>
            </FormGroup>
            <FormGroup check className="mb-3" controlId="formBasicCheckbox">
              <div style={{
                display: "flex"
              }}>
                <input
                  className="input-date-filter remove-bottom-margin"
                  type="checkbox"
                  name="hadPhoneConsultation"
                  checked={hadPhoneConsultation}
                  style={{
                    width: "20px",
                    height: "20px"
                  }}
                  onChange={(e) => {
                    setHadPhoneConsultation(e.target.checked);
                  }}
                />
                <Label className="font-weight-bold" style={{
                  marginLeft: 10,
                  marginTop: 5
                }}>
                  PM Session included a phone consultation
                </Label>
              </div>
            </FormGroup>
            
            <div className="div-row-center">
              <button type="submit" class="btn btn-block btn-info save-click-cls">
                <i class="mdi mdi-content-save"></i>Save
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PickCPM;
