import React, { Component } from 'react';
import _, { filter } from "lodash";
import { connect } from 'react-redux';
import DevicesListDataTable from './DevicesListDataTable';
import moment from "moment";
import {
  Col,
  Label,
  Row,
  FormGroup,
  Input
} from "reactstrap";

class DevicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      search: "",
      enrolledPrograms: "",
      displayActiveList: true,
      displayDemoList: null,
      displayCellularConnectivityList: false,
      fileLoading: false,
      startDate: "",
      endDate: "",
      endDateError: null,
      link: undefined,
      status: undefined,
      device: undefined,
      deviceModel: undefined
    };
    this.timeout =  null;
    this.onNavigatePage = this.onNavigatePage.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.filterDeviceStatus = this.filterDeviceStatus.bind(this);
    this.fetchDataFromServer = this.fetchDataFromServer.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
  }

  onNavigatePage(page, limit) {
    console.log("page : " + page)
  }
  handleDeviceChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;    
    this.setState({
      device: value && value != "-1" ? value : undefined,
    }, () => {
      this.fetchData();
    });  
  }
  handleTableChange(type, { page, sizePerPage, filters, sortField, sortOrder }) {
    var sortNewOrder;
    if(!sortField && !sortOrder){
      this.setState({
        page: page, limit: sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder
      }, () => {
        this.fetchData();
      });
    } else if(this.state.sortOrder == "desc"){
      sortNewOrder = "asc"
      this.setState({
        page: page, limit: sizePerPage, sortField: sortField ? sortField : this.state.sortField ? this.state.sortField : "", sortOrder: sortNewOrder
      }, () => {
        this.fetchData();
      });
    } else{
      sortNewOrder = "desc"
      this.setState({
        page: page, limit: sizePerPage, sortField: sortField ? sortField : this.state.sortField ? this.state.sortField : "", sortOrder: sortNewOrder
      }, () => {
        this.fetchData();
      });
    }
  }
  filterDeviceStatus(event) {
    if (event.target.value) {
      this.setState({
        page: 1,
        sortField: "",
        sortOrder: "",
        status: event.target.value
      }, () => {
        this.fetchData();
      });
    } else {
      this.setState({
        status: undefined
      }, () => {
        this.fetchData();
      });
    }
  }
  componentDidMount() {
    this.props.fetchDataFromServer(this.fetchDataFromServer);
  }
  fetchDataFromServer() {
    this.setState({
      page: 1,
      limit: 10,
      search: "",
      status: "",
      endDateError: null,
      device: "",
      startDate:"",
      endDate:"",
      sortField: "",
      sortOrder: "",
      deviceModel: ""
    }, () => {
      //this.fetchData();
    });
  }
  fetchData() {
    this.props.dispatch({
      type: "DO_GET_ALL_DEVICE_REPORT_DATA",
      payload: {
        page: this.state.page,
        limit: this.state.limit,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        sortField: this.state.sortField ? this.state.sortField : "",
        sortOrder: this.state.sortOrder ? this.state.sortOrder : "",
        search: this.state.search ? this.state.search : "",  
        device: this.state.device ? this.state.device : "",
        status : this.state.status ? this.state.status : "",
      }
    });
  }
  MyExportCSV = (props) => {
    const handleClick = () => {
      this.props.dispatch({
        type: "DO_REMOVE_PATIENT_LINK_PATH",
        payload: undefined
      });
      var tz = moment.tz.guess();
      this.props.dispatch({
        type: "DO_EXPORT_DEVICE_LIST_LIST",
        payload: {
          page: this.state.page,
          limit: 1000000,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          sortField: this.state.sortField ? this.state.sortField : "",
          sortOrder: this.state.sortOrder ? this.state.sortOrder : "",
          search: this.state.search ? this.state.search : "",  
          device: this.state.device ? this.state.device : "",
          status : this.state.status ? this.state.status : "",
        }
      });
    };
    return (
      <Row>
        <Col>
          <button 
          style={{ marginTop: 5,width:164, height:43 }} 
          className="btn btn-info ml-2 download-click-cls"
          onClick={handleClick}>
            <i class="mdi mdi-download" style={{
                fontSize: 20
              }}></i> Export to CSV
          </button>
        </Col>
      </Row>
    );
  };
  MySearch = () => {
    let input;
    const handleChange = (e) => {
      e.preventDefault();
      const val = e.target.value
      this.setState({
        page: 1,
          search: val,
          sortField: "",
          sortOrder: "",
      });
    };
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        this.fetchData();        
      }, 200)
      // if this effect run again, because `value` changed, we remove the previous timeout
      return () => clearTimeout(timeout)
    }, [this.state.search])
    return (
      <div>
        <div class="form-group">
          <input
            type="text"
            class="form-control input-date-filter remove-bottom-margin"
            id="exampleInputName1Test"
            style={{ fontSize: "20px" }}
            placeholder="Search Device Mac Address, Last Name, First Name, Full Name"
            ref={(n) => (input = n)}
            value={this.state.search}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };
  downloadDocument(imgUrl,filename){
    fetch(imgUrl)
    .then(response => response.blob())
    .then(blob => {
      // Create a URL for the blob data.
      const blobUrl = window.URL.createObjectURL(blob);      
      // Create an anchor element to trigger the download.
      const a = document.createElement('a');
      a.href = blobUrl;      
      // Specify the file's name for the user to download.
      a.download = filename;      
      // Simulate a click on the anchor element to start the download.
      a.click();      
      // Clean up by revoking the blob URL and removing the anchor element.
      window.URL.revokeObjectURL(blobUrl);
      a.remove();
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
  }
  /**
   * Questionnaire date inputs
   * @param {*} event 
   */
  handleInputDateChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      { [name]: value }, () => {
        this.isEndDateValid(this.state.endDate)
        this.fetchData()
      }
    );
  }
  isEndDateValid(value) {
    if (value === undefined || value === null || value.trim().length == 0) {
      return false;
    } else {
      var date = moment(new Date(this.state.startDate), 'MM/DD/YYYY');
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      var dateEntered = new Date(value);
      var today = moment(date, 'MM/DD/YYYY');
      var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
      var dDiff = today.isSameOrAfter(enteredDate);
      if (dDiff) {
        this.setState({
          endDateError: "Please select end date is greater than equal to start date."
        });
        return false;
      } else if (today.isSame(enteredDate)) {
        this.setState({
          endDateError: null
        });
        return true;
      } else {
        this.setState({
          endDateError: null
        });
        return true;
      }
    }
  };
  render() {
    if (this.props.patientFilePath) {
      if (this.state.link === undefined) {
        this.setState({
          link: this.props.patientFilePath,
        }, () => {
          this.props.dispatch({
            type: "DO_REMOVE_PATIENT_LINK_PATH",
            payload: undefined
          });
          window.open(this.state.link, "_self");
        }
        );
      }

    } else {
      this.state.link = undefined;
    }
    return (
      <Row className="col-sm-12 main-cls-modal remove-table-padding">
        <Col sm="12" className="mt-12">
          <div>
            <Row className={`col-md-12 mx-auto float-right remove-table-padding`}>
              <Col md={3}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="exampleEmail">Filter by Start Date (Reading Sent)</Label>
                  <Input
                    className="input-date-filter remove-bottom-margin"
                    type="date"
                    name="startDate"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={this.handleInputDateChange}
                    value={this.state.startDate}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">Filter by End Date (Reading Sent)</Label>
                  <Input
                    className="input-date-filter remove-bottom-margin"
                    type="date"
                    name="endDate"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={this.handleInputDateChange}
                    value={this.state.endDate}
                  />
                  <Label
                    style={{
                      color: "red",
                      textAlign: "left",
                      padding: 5
                    }}>{this.state.endDateError}</Label>
                </FormGroup>
              </Col>     
              {/* <Col md={2}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">Filter by Status:&nbsp;&nbsp;</Label>
                  <select className="filter-Questionnaire" onChange={this.filterDeviceStatus} value={this.state.status}>
                    <option value="">Select Status</option>
                    <option value="">All</option>
                    <option value="true">Active</option>
                    <option value="false">InActive</option>
                  </select>
                </FormGroup>                
              </Col> */}
              <Col md={3}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">Filter by Device:&nbsp;&nbsp;</Label>                  
                  {this.props.defaultDevices && this.props.defaultDevices.length > 0 && (
                    <>
                      <select className="filter-Questionnaire" onChange={this.handleDeviceChange} value={this.state.device}>
                        <option value="-1"> Select Device </option>
                        {this.props.defaultDevices && this.props.defaultDevices.map((model) =>
                          <option value={model["_id"]}>{model["description"]}</option>)}
                      </select>
                    </>
                  )}
                </FormGroup>                
              </Col>   
              <Col md={3}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">&nbsp;&nbsp;</Label>
                  {(this.state.startDate || this.state.endDate || this.state.device) && (
                    <button
                      style={{ float: 'left', marginTop: 20, width:150, height:37 }}
                      onClick={() => {
                        this.setState({
                          page: 1,
                          limit: 10,
                          search: "",
                          status: "",
                          endDateError: null,
                          device: "",
                          startDate:"",
                          endDate:"",
                          sortField: "",
                          sortOrder: "",
                          deviceModel: ""
                        }, () => {
                          this.fetchData();
                        })
                      }}
                      className="btn btn-info ml-2 close-click-cls"
                    >
                      <i class="mdi mdi-close" style={{
                        fontSize: 20
                      }}></i>
                      Clear Filters
                    </button>
                  )}
                </FormGroup>
              </Col>                            
            </Row>
            <Row className={`text-center col-md-12 mx-auto float-right remove-table-padding`}>
              <Col md={10} style={{ marginLeft: 0 }}>
                <this.MySearch />
              </Col>

              <Col md={2}>
                <this.MyExportCSV>
                  Export CSV
                </this.MyExportCSV>
              </Col>

            </Row>
          </div>
          {this.props.ongoingRequestForDevice && (
            <DevicesListDataTable
              data={[]}
              page={this.state.page}
              loading={this.props.ongoingRequestForDevice}
              sizePerPage={this.state.limit}
              profileOwner={this.props.profileOwner}
              readingSubTypes={this.props.readingSubTypes}
              userId={this.props.userId}
              totalSize={0}
              onNavigatePage={this.onNavigatePage}
              onTableChange={this.handleTableChange}    
              downloadDocument={this.downloadDocument}          
            />
          )}
          {!this.props.ongoingRequestForDevice && this.props.allDeviceReport && (
            <DevicesListDataTable
              data={this.props.allDeviceReport}
              page={this.state.page}
              loading={this.props.ongoingRequestForDevice}
              sizePerPage={this.state.limit}  
              profileOwner={this.props.profileOwner}
              readingSubTypes={this.props.readingSubTypes}
              userId={this.props.userId}
              totalSize={this.props.totalRecode}
              onNavigatePage={this.onNavigatePage}
              onTableChange={this.handleTableChange}    
              downloadDocument={this.downloadDocument}          
            />
          )}
        </Col>
      </Row>
    );
  }
}

// function mapStateToProps(state, ownProps) {
//   return {
//     products: state.products
//   };
// }

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    allDeviceReport,
    defaultDevices,
    totalRecode,
    ongoingRequest,
    ongoingRequestForDevice,
    patientFilePath
  } = careprovider;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    totalRecode,
    defaultDevices,
    allDeviceReport,
    userId,
    ongoingRequest,
    ongoingRequestForDevice,
    profileOwner,
    patientFilePath
  };
};

export default connect(mapStateToProps)(DevicesList);
