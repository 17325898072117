import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import _ from "lodash";
import LottieLoader from '../../LottieLoader';
import lottieLoaderFile from '../../../files/animation/loading.json';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
class ThirtyDaysSummary extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    this.props.dispatch({
        type: "DO_GET_THIRTY_DAYS_SUMMARY",
        payload: { user: this.props.currentUserId }
    });
    this.props.dispatch({
        type: "DO_GET_EXPECTATION_CAREPLAN_PATIENT",
        payload: { user: this.props.currentUserId }
    });
  }
  render() {
    const readingSubType = this.props.readingSubTypes.filter((item) => item.description !== "Weight Difference");
    let readingsThirtyDaysFilter = this.props.readingsThirtyDays;
    return(
        <div className={`content-wrap mx-auto`}>
        {!this.props.ongoingThirtyDaysRequest && ((this.props.bodyTraceReadings && this.props.bodyTraceReadings.length > 0) || (readingsThirtyDaysFilter  && readingsThirtyDaysFilter.length > 0)) && (
        <>
        <Row className="header-device-list">
        <Col sm="6" className="center-label remove-table-padding">
            <h3 className="text-center title-device">
            <i class="mdi mdi-account"></i> 30 Day Summary ({this.props.fname} {this.props.lname})
            </h3>
        </Col>
        <Col sm="6" className="remove-table-padding right-row">
            <h3 className="text-center title-device right-row">
            <i class="mdi mdi-calendar-multiple-check"></i> Non Adherence : {this.props.nonAdhenrenceDay} Days
            </h3>
        </Col>
        </Row> 
        <Row>
        {readingSubType.map((value, i) => {
            var result = _.includes(value.abbrev, "BT_");
            var readings = undefined;
            var readingValue = undefined
            var checkThis = undefined
            if (result) {
                readings = _.filter(this.props.bodyTraceReadings, (reading) => {
                    if (_.includes(value.abbrev, "WS") && reading.values.unit == 0) {
                        return false;
                    } else if (value.name.toLowerCase() in reading.values) {
                        return true;
                    }
                    return false;
                });
                readingValue = _.map(readings, "values");
                checkThis = _.map(readingValue, (readingIndex, i) => {
                    return _.get(readingValue[i], value.name.toLowerCase());
                });

            } else {
                readings = _.filter(readingsThirtyDaysFilter, {
                    readingType: value.reading
                });
                readingValue = _.map(readings, "value");
                checkThis = _.map(readingValue, (readingIndex) => {
                    return readingIndex[value.index];
                });
            }
            if (value.name === "Mean Arterial") {
                checkThis = _.map(readingValue, (readingIndex) => {
                    return _.round(
                        (1 / 3) * readingIndex[0] + (2 / 3) * readingIndex[1],
                        2
                    );
                });
            }
            var checkNan = isNaN(_.min(checkThis));

            if (readings.length === 0 || checkNan) return;
            var latestReading = _.first(_.orderBy(readings, ['deviceReadingDate'], ['desc']));
            const isBloodPressureReading = this.props.isBloodPressureReadingThirtyDaysSummary
            if(isBloodPressureReading && value && value.name && value.name.replace(/\s+/g, '').toLowerCase() == 'heartrate' && value.description && value.description.replace(/\s+/g, '').toLowerCase() == 'bloodoxygen'){
                return;
            }
            var getTheValueLatest;
            if (_.has(latestReading, "value")) {
                getTheValueLatest = _.pick(latestReading, "value");
            } else {
                getTheValueLatest = _.pick(latestReading, "values");
            }

            var latestResult
            if (_.has(latestReading, "value")) {
                latestResult = getTheValueLatest.value[value.index];
                if (value.name === "Mean Arterial") {
                    latestResult = Math.round(
                        _.round(
                            (1 / 3) * getTheValueLatest.value[0] +
                            (2 / 3) * getTheValueLatest.value[1],
                            2
                        )
                    );
                }
            } else {
                latestResult = _.get(getTheValueLatest.values, value.name.toLowerCase());
                if (value.name === "Mean Arterial") {
                    latestResult = Math.round(
                        _.round(
                            (1 / 3) * _.get(getTheValueLatest.values, "systolic") +
                            (2 / 3) * _.get(getTheValueLatest.values, "diastolic"),
                            2
                        )
                    );
                }
            }
            var lastSevenDays = _.size(readings);
            var lastTestTaken =
                readings.length > 0 &&
                moment(latestReading.deviceReadingDate).format("MM/DD/YY"); // hh:mm A

            var matchingExpectationsBreached = _.filter(this.props.patientCarePlanExpectations,
                function (o) {
                    return (
                        o &&
                        o && o.readingSubType.toLowerCase() == _.replace(_.replace(value.name, "Pulse", "Heart Rate").toLowerCase(), "bpm", "Heart Rate").toLowerCase() &&
                        !o.name.toLowerCase().includes("Event".toLowerCase())
                    );
                }
            );

            // Checked all of the readings to see which had a low threshold.
            var lowExpectation = _.flatMap(_(matchingExpectationsBreached)
                .filter((o) => o && o.condition == "<=" || o.condition == "between")
                .flatMap((_value, i) => _.pick(_value, "valueProperty"))
                .value(), "valueProperty");

            var highExpectation = _.flatMap(_(matchingExpectationsBreached)
                .filter((o) => o && o.condition == ">=" || o.condition == "between")
                .flatMap((_value, i) => _.pick(_value, "valueProperty"))
                .value(), "valueProperty");

            var lowMinThreshold = _.maxBy(_.map(lowExpectation, i => i.minValue || i.value), function (o) {
                return o && parseInt(o)
            });
            var highMaxThreshold = _.minBy(_.map(highExpectation, i => i.maxValue || i.value), function (o) {
                return o && parseInt(o)
            });

            var lowThreshold = _.filter(checkThis, (reading, i) => {
                if (lowMinThreshold != undefined) {
                    return lowMinThreshold != undefined ? reading <= lowMinThreshold : reading <= lowMinThreshold;
                } else {
                    return false;
                }
            });
            // Checked all of the readings to see which had a high threshold.
            var highThreshold = _.filter(checkThis, (reading, i) => {
                if (highMaxThreshold != undefined) {
                    return highMaxThreshold != undefined ? reading >= highMaxThreshold : reading >= highMaxThreshold;
                } else {
                    return false;
                }
            });

            var countLowThreshold = Math.round(
                (_.size(lowThreshold) / lastSevenDays) * 100
            );
            var countHighThreshold = Math.round(
                (_.size(highThreshold) / lastSevenDays) * 100
            );
            var goodThreshold = Math.round(
                100 -
                (parseFloat(countLowThreshold) + parseFloat(countHighThreshold))
            );
            var sevenDaysLowest =
                value.name != "Body Temperature" ?
                Math.round(_.min(checkThis)) :
                _.min(checkThis).toFixed(2);
            var sevenDaysHighest =
                value.name != "Body Temperature" ?
                Math.round(_.max(checkThis)) :
                _.max(checkThis).toFixed(2);
            var sevenDaysAverage =
                value.name != "Body Temperature" ?
                Math.round(_.mean(checkThis)) :
                _.mean(checkThis).toFixed(2);
            if (value.name == "Body Temperature") {
                latestResult = ((latestResult * 9) / 5 + 32).toFixed(2);
                sevenDaysLowest = ((sevenDaysLowest * 9) / 5 + 32).toFixed(2);
                sevenDaysHighest = ((sevenDaysHighest * 9) / 5 + 32).toFixed(2);
                sevenDaysAverage = ((sevenDaysAverage * 9) / 5 + 32).toFixed(2);
            }
            if (value.name == "Weight") {
                if (value.abbrev == "BT_WS") {
                    latestResult = Math.round(latestResult);
                    sevenDaysLowest = Math.round(sevenDaysLowest);
                    sevenDaysHighest = Math.round(sevenDaysHighest);
                    sevenDaysAverage = Math.round(sevenDaysAverage);
                } else {
                    latestResult = Math.round(latestResult * 2.2046);
                    sevenDaysLowest = Math.round(sevenDaysLowest * 2.2046);
                    sevenDaysHighest = Math.round(sevenDaysHighest * 2.2046);
                    sevenDaysAverage = Math.round(sevenDaysAverage * 2.2046);
                }
            } else {
                latestResult = Math.round(latestResult);
            }
            var readingName = _.replace(value.name, "Pulse", "Heart Rate");
            return (
            <Col md="4" className="mt-2 remove-summary-paddding">{/*Shrey Added */}
                <Card style={{ border: "0px 10px 10px 0px" }}>
                <CardHeader className="text-white" style={{ padding: "3px 0px" }}>
                    <div class="col-12">
                    <div>
                        <div className="text-left reading-card-for-all-reading">
                        <img
                            src={require(`../../../files/Reading/${value.abbrev}.svg`)}
                            alt="profile-image"
                            className="profile rounded-circle img-fluid mr-2 reading-icon-summary"
                        /> <div>
                            <h4 class="reading-card-title" style={{ color: "black", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "700" }}>{readingName}</h4>
                            <h6 class="reading-card-subtitle" style={{ color: "#787878", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "500" }}>{`${value.description}`}</h6>
                        </div>
                        </div>
                    </div>
                    </div>
                </CardHeader>
                <CardBody className='padding-card-body'>
                    <Row className="mb-2">
                    <Col sm="8">No. of Test taken in last 30 days:</Col>
                    <Col sm="4" className="font-weight-bold">
                        {lastSevenDays || 0}
                    </Col>
                    </Row>
                    <Row className="mb-2">
                    <Col sm="8">Last Test Taken:</Col>
                    <Col sm="4" className="font-weight-bold">
                        {lastTestTaken}
                    </Col>
                    </Row>
                    <Row className="mb-2">
                    <Col sm="8">Last Test Result:</Col>
                    <Col sm="4" className="font-weight-bold">
                        {latestResult}
                    </Col>
                    </Row>
                    <Row className="mb-2">
                    <Col sm="8">30 days lowest:</Col>
                    <Col sm="4" className="font-weight-bold">
                        {sevenDaysLowest || 0}
                    </Col>
                    </Row>
                    <Row className="mb-2">
                    <Col sm="8">30 days highest:</Col>
                    <Col sm="4" className="font-weight-bold">
                        {sevenDaysHighest || 0}
                    </Col>
                    </Row>
                    <Row className="mb-2">
                    <Col sm="8">30 days average:</Col>
                    <Col sm="4" className="font-weight-bold">
                        {sevenDaysAverage || 0}
                    </Col>
                    </Row>
                    <>
                    {matchingExpectationsBreached == undefined || matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                        <Col sm="8">% High:</Col>
                        <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                        >
                            No care plan
                        </Col>
                        </Row>
                    )}
                    {matchingExpectationsBreached !== undefined && matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                        <Col sm="8">% High:</Col>
                        <Col sm="4" className="font-weight-bold">
                            {countHighThreshold || 0}%
                        </Col>
                        </Row>
                    )}
                    {matchingExpectationsBreached == undefined || matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                        <Col sm="8">% Low:</Col>
                        <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                        >
                            No care plan
                        </Col>
                        </Row>
                    )}
                    {matchingExpectationsBreached !== undefined && matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                        <Col sm="8">% Low</Col>
                        <Col sm="4" className="font-weight-bold">
                            {countLowThreshold || 0}%
                        </Col>
                        </Row>
                    )}
                    {matchingExpectationsBreached == undefined || matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                        <Col sm="8">% Good(InRange):</Col>
                        <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                        >
                            No care plan
                        </Col>
                        </Row>
                    )}
                    {matchingExpectationsBreached !== undefined && matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                        <Col sm="8">% Good(InRange)</Col>
                        <Col sm="4" className="font-weight-bold">
                            {goodThreshold || 0}%
                        </Col>
                        </Row>
                    )}
                    </>
                </CardBody>
                </Card>
            </Col>
            );
            })}
        </Row>
        </>
        )}
        {(!this.props.ongoingThirtyDaysRequest && (this.props.bodyTraceReadings && this.props.bodyTraceReadings.length == 0) && (readingsThirtyDaysFilter && readingsThirtyDaysFilter.length == 0)) &&(
            <div className="text-center">
                <LottieLoader
                loadLoop={false}
                autoplayLoop={true}
                source={lottieEmptyFile}
                Lheight={300}
                Lwidth={300}
                title={"Sorry! No data found :("}
                subTitle={"We can't find any summary data."}
            />
          </div>
        )}
        {this.props.ongoingThirtyDaysRequest && (
            <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieLoaderFile}
            Lheight={150}
            Lwidth={150}
            subTitle={"Please Wait ..."}
          />
        )}
    </div>
    );
    }
}

function mapStateToProps({ careprovider, auth }) {
  const { 
    bodyTraceReadings,
    readingsThirtyDays, 
    nonAdhenrenceDay,
    patientCarePlanExpectations,
    isGenericSuccess, 
    isGenericError,
    ongoingThirtyDaysRequest, 
    genericErrorMessage,
    isBloodPressureReadingThirtyDaysSummary } = careprovider;
    const { readingSubTypes, readingType } = auth;
  return {
    bodyTraceReadings,
    readingsThirtyDays, 
    nonAdhenrenceDay,
    patientCarePlanExpectations,
    isGenericSuccess, 
    isGenericError, 
    genericErrorMessage,
    readingSubTypes,
    readingType,
    ongoingThirtyDaysRequest,
    isBloodPressureReadingThirtyDaysSummary
  };
}
export default connect(mapStateToProps)(ThirtyDaysSummary);
