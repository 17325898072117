import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_USER_FAIL,
  REGISTER_USER_FAIL,
  FIRSTNAME_CHANGED,
  LASTNAME_CHANGED,
  NICKNAME_CHANGED,
  PHONE_CHANGED,
  REGISTER_USER_SUCCESS,
  CODE_CHANGED,
  RESET_USER,
  VERIFY_USER_SUCCESS,
  RESET_USER_SUCCESS,
  FORGOT_USER_SUCCESS,
  LOADING_START,
  LOADING_STOP,
  LOGOUT_USER,
  REGISTER_CLIENT_SUCCESS,
  GET_CLIENT_SUCCESS,
  REGISTER_CLIENT_FAIL,
  RESET_SUCCESS
} from "../actions/types";
import subType from "./readingSubType.json";
import subTypeForExpectation from "./readingSubTypeForExpectation.json";
import readingType from "./readingType.json";
import readingTypeForExpectation from "./readingTypeForExpectation.json";

const INITIAL_STATE = {
  fname: "",
  lname: "",
  nickname: "",
  email: "",
  password: "",
  errorLogin: "",
  phone: "",
  verifyCode: "",
  readingSubTypes: subType,
  readingSubTypesForExpecation: subTypeForExpectation,
  readingType: readingType,
  readingTypeForExpectation : readingTypeForExpectation,
  firstRegister: false,
  errorForgotPassword: "",
  verifyCodeSuccess: false,
  finalRegisterSuccess: false,
  forgotPasswordSuccess: false,
  resetPasswordSuccess: false,
  loading: false,
  clientRegister: false,
  cred: ""
};

const RESET_STATE = {
  fname: "",
  lname: "",
  nickname: "",
  email: "",
  password: "",
  errorLogin: "",
  phone: "",
  verifyCode: "",
  readingSubTypes: subType,
  readingSubTypesForExpecation: subTypeForExpectation,
  readingType: readingType,
  readingTypeForExpectation : readingTypeForExpectation,
  firstRegister: false,
  errorForgotPassword: "",
  verifyCodeSuccess: false,
  finalRegisterSuccess: false,
  forgotPasswordSuccess: false,
  resetPasswordSuccess: false,
  loading: false,
  clientRegister: false,
  cred: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...state,
        ...RESET_STATE
      };
    case FIRSTNAME_CHANGED:
      return { ...state, fname: action.payload };
    case CODE_CHANGED:
      return { ...state, verifyCode: action.payload };
    case LASTNAME_CHANGED:
      return { ...state, lname: action.payload };
    case NICKNAME_CHANGED:
      return { ...state, nickname: action.payload };
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case PHONE_CHANGED:
      return { ...state, phone: action.payload };
    case LOGIN_USER_FAIL:
      return { ...state, errorLogin: action.payload };
    case REGISTER_USER_FAIL:
      return { ...state, errorRegister: action.payload };
    case REGISTER_USER_SUCCESS:
      return { ...state, firstRegister: true };
    case REGISTER_CLIENT_FAIL:
      return { ...state, errorRegister: action.payload };
    case VERIFY_USER_SUCCESS:
      return { ...state, verifyCodeSuccess: true };
    case FORGOT_USER_SUCCESS:
      return { ...state, forgotPasswordSuccess: true };
    case RESET_USER_SUCCESS:
      return { ...state, resetPasswordSuccess: true };
    case LOADING_START:
      return { ...state, loading: true };
    case LOADING_STOP:
      return { ...state, loading: false };

    // case LOGIN_STATUS_CHANGED:
    //   if (action.payload == "notloggedin" || action.payload == "logout") {
    //     return {
    //       ...state,
    //       ...INITIAL_STATE
    //     };
    //   } else {
    //     return { ...state, loginStatus: action.payload };
    //   }
    // case LOGIN_USER_SUCCESS:
    //   return {
    //     ...state,
    //     ...RESET_STATE,
    //     user: action.payload,
    //     loginStatus: "loggedin",
    //     email: "",
    //     password: ""
    //   };
    // case LOGIN_USER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     password: "",
    //     loginStatus: "loginfailed"
    //   };
    default:
      return state;
  }
};
