import React, { Component } from "react";
import Loadable from "react-loadable";
import "bootstrap-material-design/dist/css/bootstrap-material-design.min.css";
// import "shards-ui/dist/css/shards.min.css"; FIND OUT WHAT THIS IS?
import "./css/css/style.css";

import "@mdi/font/css/materialdesignicons.min.css";
import "font-awesome/css/font-awesome.min.css";
import { Link, Route, Switch, withRouter, Redirect } from "react-router-dom";
import Login from "./components/Auth/Login";
import Layout from "./components/Layout";
import { connect } from "react-redux";
import ResetPassword from "./components/Auth/ResetPassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Dashboard from "./components/Dashboard/";

import Sharing from "./components/Sharing/";
import Expectations from "./components/Rules/";
import Vitals from "./components/Rules/Vitals";
import TimeAdherence from "./components/Rules/TimeAdherence";
import Adherence from "./components/Rules/Adherence";
import Reminder from "./components/Rules/Reminder";
import TrendingViolation from "./components/Rules/TrendingViolation";
import WeightRule from "./components/Rules/WeightRule";
import Questionnaire from "./components/Questionnaire/index";
import OnBoarding from "./components/OnBoarding/index";
import OffBoarding from "./components/OffBoarding/index";
import FaxReport from "./components/FaxReport/index";
import Devices from "./components/Devices/index";
import OnBoardingCCM from "./components/OnBoardingCCM/index";
import Adminquestion from "./components/Questionnaire/question";
import AdminVitals from "./components/Admin/AdminCareProviderDashboard/Expectations/Vitals";
import AdminTimeAdherence from "./components/Admin/AdminCareProviderDashboard/Expectations/TimeAdherence";
import AdminAdherence from "./components/Admin/AdminCareProviderDashboard/Expectations/Adherence";
import AdminReminder from "./components/Admin/AdminCareProviderDashboard/Expectations/Reminder";
import AdminTrendingViolation from "./components/Admin/AdminCareProviderDashboard/Expectations/TrendingViolation";
import AdminWeightRule from "./components/Admin/AdminCareProviderDashboard/Expectations/WeightRule";

import Violations from "./components/Violations/";
import Analytics from "./components/Analytics/";
import logoImage from "files/logotext.png";
import Staff from "./components/Staff/";
import SharedAccount from "./components/SharedAccount";
import AdminSharedAccount from "./components/Admin/AdminSharedAccount";
import InviteRegister from "./components/Auth/InviteRegister";
import ClientRegister from "./components/Client/ClientRegister";
import ClientLogin from "./components/Client/ClientLogin";
import SimpleView from "./components/Client/SimpleView";
import { ToastContainer } from "react-toastify";
import IdleTimer from "react-idle-timer";
import ReactLoading from "react-loading";
import "react-toastify/dist/ReactToastify.css";
import Logout from "./components/Auth/Logout";
import ClientStaff from "./components/Client/ClientStaff";
import AdminLayout from "./components/Admin/AdminLayout";
import AdminSettings from "./components/Admin/AdminSettings";
import AdminCareProviders from "./components/Admin/AdminCareProviders";
import AdminAnalytics from "./components/Admin/AdminAnalytics";
import AdminUserDashboard from "./components/Admin/AdminUserDashboard";
import AdminCareProviderDashboard from "./components/Admin/AdminCareProviderDashboard";
import AdminSupport from "./components/Admin/AdminSupport";
import "please-wait/build/please-wait.css";
import "spinkit/css/spinners/5-pulse.css";
import { pleaseWait } from "please-wait";
import AdminPopulation from "./components/Admin/AdminPopulation";
import ClientPopulation from "./components/Client/ClientPopulation";
import AdminHome from "./components/Admin/AdminHome";
import Condition from "./components/Condition";
import SuccessIntegration from "./components/Auth/SuccessIntegration";
import ViolationDetail from "./components/Violations/ViolationDetail";
import ViolationDetailQuestion from "./components/Violations/ViolationDetail-Question";
import Reports from "./components/Reports";
import SubmissionView from "./components/CCMForm/SubmissionView"
import { BroadcastChannel } from "broadcast-channel";
import NotFound from "./NotFound";
const Settings = Loadable({
  loader: () => import("./components/Settings/"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ActionLogs = Loadable({
  loader: () => import("./components/Reusable/ActionLogs"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const AdminAccount = Loadable({
  loader: () => import("./components/Admin/AdminAccount"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const AdminClients = Loadable({
  loader: () => import("./components/Admin/AdminClients"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientAccount = Loadable({
  loader: () => import("./components/Client/ClientAccount"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientPlans = Loadable({
  loader: () => import("./components/Client/ClientPlans"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});

const ClientClients = Loadable({
  loader: () => import("./components/Client/ClientClients"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientLayout = Loadable({
  loader: () => import("./components/Client/ClientLayout"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientSettings = Loadable({
  loader: () => import("./components/Client/ClientSettings"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientAnalytics = Loadable({
  loader: () => import("./components/Client/ClientAnalytics"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientUserDashboard = Loadable({
  loader: () => import("./components/Client/ClientUserDashboard"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientCareProviderDashboard = Loadable({
  loader: () => import("./components/Client/ClientCareProviderDashboard"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const ClientCareProviders = Loadable({
  loader: () => import("./components/Client/ClientCareProviders"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
function Loading(props) {
  if (props.error) {
    return window.location.reload(true);
  } else if (props.pastDelay) {
    return (
      <div>
        {" "}
        <ReactLoading className="mx-auto" color={"#007bff"} />
      </div>
    );
  } else {
    return null;
  }
}

const Register = Loadable({
  loader: () => import("./components/Auth/Register"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});

const Verify = Loadable({
  loader: () => import("./components/Auth/Verify"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const FinalRegister = Loadable({
  loader: () => import("./components/Auth/FinalRegister"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const Inbox = Loadable({
  loader: () => import("./components/Inbox/"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const UserDashboard = Loadable({
  loader: () => import("./components/UserDashboard/"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const CCM = Loadable({
  loader: () => import("./components/CCM/"),
  loading: Loading,
  delay: 400, // 0.4 seconds,
  timeout: 10000
});
const Population = Loadable({
  loader: () => import("./components/Population/"),
  loading: Loading,
  delay: 200, // 0.4 seconds,
  timeout: 10000
});
window.loading_screen = pleaseWait({
  backgroundColor: "#00e4d0",
  template: `<div class='pg-loading-inner'>
  <div class='pg-loading-center-outer'>
    <div class='pg-loading-center-middle'>

      <div class='pg-loading-html'>
      </div>
    </div>
  </div>
</div>`,
  loadingHtml:
    "<div class='sk-spinner sk-spinner-pulse bg-white' style='height: 80px;width: 80px'></div>"
});
function defineTabID() {
  var iPageTabID = sessionStorage.getItem("tabID");
  // if it is the first time that this page is loaded
  if (iPageTabID == null) {
    var iLocalTabID = localStorage.getItem("tabID");
    // if tabID is not yet defined in localStorage it is initialized to 1
    // else tabId counter is increment by 1
    var iPageTabID = iLocalTabID == null ? 1 : Number(iLocalTabID) + 1;
    // new computed value are saved in localStorage and in sessionStorage
    localStorage.setItem("tabID", iPageTabID);
    sessionStorage.setItem("tabID", iPageTabID);
  }
}
const channel = new BroadcastChannel("resetTimer");
const pauseChannel = new BroadcastChannel("pauseTimer");
const resumeChannel = new BroadcastChannel("resumeTimer");
const logoutchannel = new BroadcastChannel("logout");
class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.reset = this._reset.bind(this);
    this.pause = this._pause.bind(this);
    this.resume = this._resume.bind(this);
    this.state = {
      idleTimerStat: null,
      timeoutTime: 900000,
      checkTime: null
    };
  }
  _onAction(e) {    
    // this.idleTimer.reset();
    if (this.idleTimer) {
      channel.postMessage(this.idleTimer.getRemainingTime());

      channel.onmessage = (msg) => {
        this.idleTimer.reset();
      };
    }
  }

  _onActive(e) {
  }

  _onIdle(e) {
    this.props.history.push("/logout");
    logoutchannel.postMessage("Logout");
  }
  _reset() {
    this.idleTimer.reset();
  }

  _pause() {
    this.idleTimer.pause();
  }

  _resume() {
    this.idleTimer.resume();
  }
  timer() {
    if (this.idleTimer) {
      channel.postMessage(this.idleTimer.getRemainingTime());
    }

    channel.onmessage = (msg) => {
      if (this.idleTimer) {
        this.setState({ checkTime: msg });
      }
    };
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
  componentDidUpdate() {
    const getCheckIfPauseMonitoring = localStorage.getItem("stopIdleTimerNow");
    if (getCheckIfPauseMonitoring === "yes") {
      if (this.idleTimer) {
        this.idleTimer.pause();
      }
    }
    if (this.props.timerMonitoring) {
      // channel.postMessage("I am not alone");
      // channel.onmessage = msg => {
      //   if (this.idleTimer) {
      //     this.idleTimer.pause();
      //   }
      // };
      this.idleTimer.pause();
    } else {
      // channel.postMessage("I am not alone");
      // channel.onmessage = msg => {
      //   if (this.idleTimer) {
      //     this.idleTimer.resume();
      //   }
      // };
      if (this.idleTimer) {
        this.idleTimer.resume();
      }
    }
    if (this.idleTimer) {
    }
  }

  componentDidMount() {
    defineTabID();
    pauseChannel.onmessage = (msg) => {
      if (msg === "yes") {
        if (this.idleTimer) {
          this.idleTimer.pause();
        }
      } else {
        if (this.idleTimer) {
          this.idleTimer.reset();
        }
      }
    };
    // if (this.idleTimer) {
    //   var intervalId = setInterval(this.timer, 1000);

    //   this.setState({ intervalId: intervalId });
    // }

    // channel.postMessage("I am not alone");
    // channel.onmessage = msg => {
    //   if (this.idleTimer) {
    //     this.idleTimer.reset();
    //   }
    // };

    logoutchannel.onmessage = (msg) => {
      if (this.idleTimer) {
        this.props.history.push("/logout");
      }
    };

    window.loading_screen.finish();
  }
  render() {
    if (this.props.role === "support" || this.props.role === "admin") {
      return (
        <div>
          <AdminLayout>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={this.state.timeoutTime}
            />
            <Switch>
              <Route path="/support" component={AdminSupport} />
              <Route path="/users" component={AdminAnalytics} />
              <Route path="/settings" component={AdminSettings} />
              <Route path="/action-logs" component={ActionLogs} />
              <Route path="/clients" component={AdminClients} />
              <Route path="/account-management" component={AdminAccount} />
              <Route path="/plans" component={ClientPlans} />
              <Route path="/quick-access" component={AdminHome} />
              <Route path="/careproviders" component={AdminCareProviders} />
              <Route path="/shared-account" component={AdminSharedAccount} />
              <Route path="/population" component={AdminPopulation} />
              <Route path="/offboarding" component={OffBoarding} />
              <Route path="/fax-report" component={FaxReport} />
              <Route path="/device-report" component={Devices} />
              <Route path="/onboarding" component={OnBoarding} />
              <Route path="/onboardingccm" component={OnBoardingCCM} />
              <Route path="/client/logout" component={Logout} />
              <Route path="/ccm-records" component={CCM} />
              <Route
                path="/violation/details"
                component={ViolationDetail}
              />
              <Route
                path="/violation/questionnairedetails"
                component={ViolationDetailQuestion}
              />
              <Route
                path="/careprovider/dashboard/:name/:id"
                component={AdminCareProviderDashboard}
              />
              <Route path="/:name/:id/create/vitals" component={AdminVitals} />
              <Route
                path="/careprovider/dashboard/:name/:id/create/time-adherence"
                component={AdminTimeAdherence}
              />
              <Route
                path="/careprovider/dashboard/:name/:id/create/weight-difference"
                component={AdminWeightRule}
              />
              <Route
                path="/careprovider/dashboard/:name/:id/create/adherence"
                component={AdminAdherence}
              />
              <Route
                path="/careprovider/dashboard/:name/:id/create/reminder"
                component={AdminReminder}
              />

              <Route path="/reports" component={Reports} />
              <Route
                path="/dashboard/:name/:id"
                component={AdminUserDashboard}
              />
              <Route path="/inbox" component={Inbox} />
              <Route exact path="/" component={AdminHome} />

              <Redirect to="/" />
            </Switch>
            <ToastContainer />
          </AdminLayout>
        </div>
      );
    }
    if (this.props.isAuthenticated && !this.props.clientRegister) {
      return (
        <Layout pauseChannel={pauseChannel}>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeoutTime}
          />
          <Switch>
            <Route
              path="/:client?/violation/details"
              component={ViolationDetail}
            />
            <Route
              path="/:client?/violation/questionnairedetails"
              component={ViolationDetailQuestion}
            />

            <Route
              path="/:client?/refer-population/dashboard/:name/:id/:start/:end"
              component={UserDashboard}
            />
            <Route
              path="/:client?/dashboard/:name/:id"
              component={UserDashboard}
            />
            <Route path="/:client?/conditions" component={Condition} />
            <Route path="/:client?/sharing" component={Sharing} />
            <Route path="/:client?/expectations" component={Expectations} />
            <Route path="/:client?/exceptions" component={Violations} />
            <Route path="/:client?/population-data" component={Population} />
            <Route path="/:client?/create/vitals" component={Vitals} />
            <Route
              path="/:client?/create/time-adherence"
              component={TimeAdherence}
            />
            <Route
              path="/:client?/create/weight-difference"
              component={WeightRule}
            />
            <Route path="/:client?/questionnaire" component={Questionnaire} />
            <Route path="/:client?/offboarding" component={OffBoarding} />
            <Route path="/:client?/onboarding" component={OnBoarding} />
            <Route path="/:client?/onboardingccm" component={OnBoardingCCM} />
            <Route path="/:client?/create/question" component={Adminquestion} />
            <Route path="/:client?/create/adherence" component={Adherence} />
            <Route path="/:client?/create/reminder" component={Reminder} />
            <Route path="/:client?/patients" component={Analytics} />
            <Route path="/:client?/ccm-records" component={CCM} />
            <Route path="/:client?/organization-staff" component={Staff} />
            <Route path="/:client?/shared-account" component={SharedAccount} />
            <Route path="/:client?/settings" component={Settings} />
            <Route path="/:client?/logout" component={Logout} />
            <Route
              path="/:client?/create/trending-violation"
              component={TrendingViolation}
            />
            <Route path="/:client?/reports" component={Reports} />
            <Route path="/:client?/inbox" component={Inbox} />
            <Route path="/success-integration" component={SuccessIntegration} />
            {this.props.userDetails &&
              this.props.userDetails.isCareProvider && (
                <Route exact path="/:client?" component={Violations} />
              )}
            {this.props.userDetails &&
              !this.props.userDetails.isCareProvider && (
                <Route exact path="/:client?" component={Dashboard} />
              )}
            {this.props.userDetails &&
              !this.props.userDetails.isCareProvider &&
              this.props.userDetails.isStaff && (
                <Route exact path="/:client?" component={Violations} />
              )}

            <Redirect to="/:client?" />
          </Switch>
          <ToastContainer />
          {/* <div>Test {this.state.checkTime}</div> */}
        </Layout>
      );
    }
    if (!this.props.isAuthenticated && !this.props.clientRegister) {
      return (
        <div>
          <Switch>
            <Route path="/:client?/login" component={Login} />
            <Route path="/success-integration" component={SuccessIntegration} />
            <Route path="/client/signup" component={ClientRegister} />
            <Route path="/client/signin" component={ClientLogin} />
            <Route path="/ccmFormSubmission/:userId" component={SubmissionView} />
            <Route
              path="/:client?/forgot-password"
              component={ForgotPassword}
            />
            <Route
              path="/:client?/reset-password/:token"
              component={ResetPassword}
            />
            <Route path="/:client?/register" component={Register} />
            <Route path="/:client?/verify-registration" component={Verify} />
            <Route
              path="/:client?/final-registration"
              component={FinalRegister}
            />{" "}
            <Route
              path="/:client?/violation/details"
              component={ViolationDetail}
            />
            <Route
              path="/:client?/violation/questionnairedetails"
              component={ViolationDetailQuestion}
            />
            <Route path="/:client?/invitation" component={InviteRegister} />
            <Route exact path="/:client?" component={Login} />
            <Route component={NotFound} />
          </Switch>
          <ToastContainer />
        </div>
      );
    }
    if (this.props.isAuthenticated && this.props.clientRegister) {
      return (
        <div>
          <ClientLayout>
            <Switch>
              <Route
                path="/careprovider/dashboard/:name/:id"
                component={ClientCareProviderDashboard}
              />
              <Route
                path="/dashboard/:name/:id"
                component={ClientUserDashboard}
              />
              <Route path="/settings" component={ClientSettings} />
              <Route path="/users" component={ClientAnalytics} />
              <Route path="/action-logs" component={ActionLogs} />
              <Route path="/careproviders" component={ClientCareProviders} />
              <Route path="/staff" component={ClientStaff} />
              <Route path="/population" component={ClientPopulation} />
              <Route path="/account-management" component={ClientAccount} />
              <Route path="/plans" component={ClientPlans} />
              <Route path="/subclients" component={ClientClients} />
              <Route path="/inbox" component={Inbox} />
              <Route path="/client/logout" component={Logout} />
              <Route
                path="/violation/details"
                component={ViolationDetail}
              />
              <Route
                path="/violation/questionnairedetails"
                component={ViolationDetailQuestion}
              />
              <Route exact path="/" component={ClientAccount} />

              <Redirect to="/" />
            </Switch>
            <ToastContainer />
          </ClientLayout>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userDetails: state.user.userDetails,
    clientRegister: state.user.clientRegister,
    isAuthenticated: state.user.authenticated,
    timerMonitoring: state.careprovider.timerStarts,
    role: state.user.userDetails.role
  };
};
export default withRouter(connect(mapStateToProps)(App));
