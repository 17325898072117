import React from "react";
import userImage from "../../files/user.png";
import { Label } from "reactstrap";
import isNoSignature from "../../files/ic_no_signature.svg";

const SignatureWithName = (props) => {
  const signature = props.signature;
  const name = props.name;
  const isBorder = props.isBorder;
  return (
    <div className={props.isCCMQuestion ? "col-md-4 div-remove-padding" : ""}>
      {isBorder && (
        <>
          <div className="signature-layout remove-margin-right">
            <p className="signature-title">Signature : </p>
            <div>
              {signature && (
                <>
                  <div className="signature-display-image-layout">
                    <img
                      src={signature}
                      className="signature-display-image"
                    />
                    <code>
                      <hr className="border-signature" />
                      <p className="signature-label"><i className="mdi mdi-account-circle signature-user-icon" />{name}</p>
                    </code>
                  </div>
                </>
              )}
              {!signature && (
                <>
                  <div className="signature-display-image-layout-no-signature">
                    <img className="no-signature"
                      src={isNoSignature}
                    />
                    <Label className="no-signature-found">
                      Signature could not be found!
                    </Label>
                    <code>
                      <hr className="border-signature" />
                      <p className="signature-label"><i className="mdi mdi-account-circle signature-user-icon" />{name}</p>
                    </code>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {!isBorder && (
        <>
          <div className="signature-layout remove-margin-right">            
            <div>
              {signature && (
                <>
                  <div className="signature-without-border">
                    <img
                      src={signature}
                      className="signature-display-image"
                    />
                    <code>
                      <hr className="border-signature" />
                      <p className="signature-label"><i className="mdi mdi-account-circle signature-user-icon" />{name}</p>
                    </code>
                  </div>
                </>
              )}
              {!signature && (
                <>
                  <div className="signature-without-border">
                    <img className="no-signature"
                      src={isNoSignature}
                    />
                    <Label className="no-signature-found">
                      Signature could not be found!
                    </Label>
                    <code>
                      <hr className="border-signature" />
                      <p className="signature-label"><i className="mdi mdi-account-circle signature-user-icon" />{name}</p>
                    </code>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignatureWithName;
