import React, { Component } from "react";
import Link from "components/ExtendLink";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import logoImage from "../../files/smarthealth.jpg";

import ReactLoading from "react-loading";
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      password: null,
      confirmpassword: null,
      errorPassword: null
    };
    this.passwordChange = this.passwordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    
    this.setState({
      token: this.props.match.params.token
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.errorPassword) {
      this.props.dispatch({
        type: "DO_RESET",
        payload: {
          password: this.state.password,
          token: this.state.token,
          errorPassword: false
        }
      });
    }
  }
  passwordChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerid);
    this.props.dispatch({
      type: "REGISTER_USER_FAIL",
      payload: ""
    });
  }
  componentDidUpdate() {
    if (this.props.resetPasswordSuccess) {
      this.timerid = setTimeout(() => {
        this.props.history.push("/");
      }, 2000);
    }
    if (
      this.props.errorRegister &&
      this.props.errorRegister.error === "expired_reset_password"
    ) {
      this.timerid = setTimeout(() => {
        this.props.history.push("/");
       
      }, 4000);
    }
  }
  render() {
    const { client } = this.props.match.params;
    const domainCheck = window.location.hostname;
    const isTNS =
      domainCheck.includes("testnshare") || domainCheck.includes("sugarmds");
    let logo, websiteName, backgroundImage;
    backgroundImage = "http://myvitalview.com/background.jpg";
    if (this.props.loading) {
      return <ReactLoading />;
    }
    if (client && this.props.clientInfo && this.props.clientInfo.error) {
      return <div>Not found</div>;
    }
    if (client && this.props.clientInfo && !this.props.clientInfo.error) {
      logo = this.props.clientInfo.websiteLogo;
      websiteName = this.props.clientInfo.websiteName;
      backgroundImage =
        this.props.clientInfo.backgroundImage ||
        "http://myvitalview.com/background.jpg";
    }
    if (isTNS && this.props.clientInfo && this.props.clientInfo.error) {
      return <div>Not found</div>;
    }
    if (isTNS && this.props.clientInfo && !this.props.clientInfo.error) {
      logo = this.props.clientInfo.websiteLogo;
      websiteName = this.props.clientInfo.websiteName;
      backgroundImage =
        this.props.clientInfo.backgroundImage ||
        "http://myvitalview.com/background.jpg";
    }
    if (!client && !isTNS) {
      logo = logoImage;
      websiteName = "MYVITALVIEW";
      backgroundImage = "http://myvitalview.com/background.jpg";
    }
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div
          className="content-wrapper d-flex align-items-center auth"
          style={{
            background: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover"
          }}
        >
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            <div className="mx-auto" style={{ width: "500px" }}>
              <div className="auth-form-light text-center p-5">
                <div className="brand-logo mx-auto">
                  <img style={{ width: "200px" }} alt="" src={logo} />
                </div>
                <h4>Reset your Password</h4>
                <h6 className="font-weight-light">Enter your new password</h6>

                <form className="pt-3" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      placeholder="New Password"
                      pattern=".{6,}"
                      required
                      title="6 characters minimum"
                      name="password"
                      onChange={this.passwordChange}
                      value={this.state.password}
                      required
                    />
                  </div>
                  <FormGroup>
                    <Input
                      invalid={this.state.errorPassword}
                      type="password"
                      className="form-control form-control-lg"
                      placeholder="Confirm Password"
                      name="confirmpassword"
                      pattern=".{6,}"
                      required
                      value={this.state.confirmpassword}
                      title="6 characters minimum"
                      onChange={this.passwordChange}
                      rows={10}
                    />

                    {this.state.errorPassword && (
                      <FormFeedback>Password does not match</FormFeedback>
                    )}
                  </FormGroup>
                  {this.props.errorRegister && (
                    <div className="alert alert-danger text-center">
                      {this.props.errorRegister.message}
                    </div>
                  )}

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                      style={{ color: "white" }}
                    >
                      RESET
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { password, errorRegister, resetPasswordSuccess } = auth;

  return { password, errorRegister, resetPasswordSuccess };
};

export default connect(mapStateToProps)(ResetPassword);
