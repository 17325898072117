import firebase from "firebase/compat/app";
export const initializeFirebase = () => {
  firebase.initializeApp({
    // messagingSenderId: "585956092736"
    apiKey: "AIzaSyBgppkRkP1aUoPC-LK4SLUSpdtlaTsBJUk",
    authDomain: "myvitalview-c9948.firebaseapp.com",
    databaseURL: "https://myvitalview-c9948.firebaseio.com",
    projectId: "myvitalview-c9948",
    storageBucket: "myvitalview-c9948.appspot.com",
    messagingSenderId: "1003366494914",
    appId: "1:1003366494914:web:a4ac26541ee64a2329c15b",
    measurementId: "G-VNCM95KT61"
  });
};
