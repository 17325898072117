import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { QuestionBody } from "./configBody";
import Header from "./Header";
import moment from "moment-timezone";
import _ from "lodash";
import Select from "react-select";
import { toast } from "react-toastify";
import { QuestionIcon } from "./ruleIcon";
import { Field, FieldArray, Formik } from "formik";
import QuestionList from "./questionList";
import RequiredSelect from "./RequiredSelect";
class Question extends Component {
  documentData;
  constructor(props) {
    super(props);
    this.initialValues = {
      is_edit_key: "",
      question: "",
      answers: [
        { answer: "", isCurrectAnswer: false },
        { answer: "", isCurrectAnswer: false },
      ],
    };
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      body: QuestionBody,
      showInputCondition: false,
      showRangeInput: false,
      showAlertSuccess: false,
      modal: false,
      arr: [],
      defaultValue: [],
      isDelete: false,
      timezoneList: [],
      questionError: "",
      isDeleteKey: undefined,
      timeDuration:"",
      startDate:"",
      startDateError: "",
      endDate:"",
      endDateError:"",
      timeDurationError: "",
      isFormSubmitted: false,
    };
    this.baseState = this.state.body;
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleCondition = this.handleCondition.bind(this);
    this.handleTimezone = this.handleTimezone.bind(this);
    this.handleRepetedCondition = this.handleRepetedCondition.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.model = this.model.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.isDelete = this.isDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.isDateValid = this.isDateValid.bind(this);
  }

  handleTimezone = (selectedOption) => {
    this.setState({
      body: {
        ...this.state.body,
        timezone: selectedOption.value,
      },
      timezone: selectedOption,
    });

    
  };
  validate = (values) => {
    const errors = {};
    if (!values.question) {
      errors.question = "Question is Required";
    }
    var status = _.filter(values.answers, function (item) {
      return item.isCurrectAnswer === true;
    });
    if (status.length == 0) {
      errors.isCurrectAnswer = "At least one correct answer must be selected.";
    }
    // check duplicate answers added
    let duplicateOption = false;
    if (values && values.answers && values.answers.length) {
      // Filter out the duplicates
      _.map(values.answers, (item, index, self) => {
        const findDuplicate = _.filter(values.answers, (element, i) => i != index && element.answer.toLowerCase().replace(/\s+/g, '').trim() == item.answer.toLowerCase().replace(/\s+/g, '').trim())
        if (findDuplicate && findDuplicate.length > 0) {
          duplicateOption = true;
        }
      });
      if (duplicateOption) {
        errors.isCurrectAnswer = "You cannot select the same option more than once.";
      }
    }
    // check duplicate question added
    let duplicateQuestion = false;
    if (this.state && this.state.arr && this.state.arr.length > 0) {
      // Filter out the duplicates
      _.map(this.state.arr, (item) => {
        const findQuestion = values.question.toLowerCase().replace(/\s+/g, '').trim() == item.question.toLowerCase().replace(/\s+/g, '').trim()
        if (findQuestion) {
          duplicateQuestion = true;
        }
      });
      if (duplicateQuestion) {
        errors.isCurrectAnswer = "You are not allowed to add the same question more than once.";
      }
    }
    const data2 = Array.from(document.getElementsByClassName(`option-value`));
    data2.map((e, index) => {
      let checkValueLength = e.value.trim().length;
      if (checkValueLength === 0) {
        if (!errors.answers) errors.answers = [];
        errors.answers[index] = {
          answer: "Option Value is Required",
        };
      }
    });
    return errors;
  };
  handleSubmit = (values, resetForm) => {
    let arr = this.state.arr;
    if (values.is_edit_key !== "") {
      arr[values.is_edit_key] = {
        question: values.question,
        answers: values.answers,
      };
    } else {
      arr.push({
        question: values.question,
        answers: values.answers,
      });
    }
    this.setState({
      arr,
      modal: false,
    });
    this.setState({
      body: {
        ...this.state.body,
        questionProperty: arr,
      },
    });
    this.setState({ questionError: "" });
    resetForm();
  };
  removeQuestion(key) {
    let questionData = this.state.arr;
    var selectedIndex = this.state.isDeleteKey;
    questionData.splice(selectedIndex, 1);
    this.setState({
      arr: questionData,
    });
    this.setState({ isDelete: false });
  }
  model(key = "") {
    this.setState({
      modal: !this.state.modal,
    });
    if (key !== "" && this.state.arr[key]) {
      let answerValue = this.state.arr[key];
      if (answerValue) {
        this.setState({
          defaultValue: answerValue,
        });
        answerValue.is_edit_key = key;
        this.initialValues = answerValue;
      }
    } else {
      this.initialValues = {
        is_edit_key: "",
        question: "",
        answers: [
          { answer: "", isCurrectAnswer: false },
          { answer: "", isCurrectAnswer: false },
        ],
      };
    }
  }
  isDelete(key) {
    this.setState({ isDelete: true });
    this.setState({ isDeleteKey: key });
  }
  cancelDelete() {
    this.setState({ isDelete: false });
  }
  resetForm() {
    this.setState({
      modal: false,
      modal2: false,
    });

    this.props.dispatch({
      type: "GENERIC_ERROR",
      payload: { isGenericSuccess: false },
    });

    this.setState({
      body: {
        ...this.state.body,
        QuestionBody,
      },
      isFormSubmitted : false
    });
    this.props.history.goBack();
  }
  handleChange = (selectedOption) => {
    this.setState({ readingSubType: selectedOption });
    // this.setState({
    //   body: {
    //     ...this.state.body,
    //     readingType: selectedOption.reading,
    //     readingSubType: selectedOption.name,
    //     readingSubTypeId: selectedOption._id,
    //   },
    // });
  };
  submit(e) {
    e.preventDefault();
    this.setState({isFormSubmitted : true}, () => {
    if(this.state && this.state.isFormSubmitted && (!this.state.timezone || (this.state.body && ((!this.state.body.name && _.isEmpty(this.state.body.name)) || (!this.state.body.repeatPer && _.isEmpty(this.state.body.repeatPer)) || (!this.state.body.messageCareprovider && _.isEmpty(this.state.body.messageCareprovider)))))){
     return;
    }
    if (this.state.arr.length === 0) {
      let message = "There should be more than one question.";
      this.setState({ questionError: message });
      return false;
    }
    if (
      this.state.body.notifyReadingOwner.length > 0 &&
      this.state.body.message.trim().length === 0
    ) {
      toast.error(
        "A notification is checked in notify reading owner, please provide a message for the Patient"
      );
    } else if ((this.state.body.notifyStaff.length > 0 ||
        this.state.body.notifySelf.length > 0) && this.state.body.messageCareprovider.trim().length === 0) {
      toast.error(
        "A notification is checked in notify me/staff, please provide a message for the care prodiver/Staff"
      );
    } 
    const result = this.isDateValid(this.state.endDate);
    if(!this.state.timeDuration){
      let message = "You have to select at least one time duration";
      this.setState({ timeDurationError: message });
      return false;
    } else if(this.state.timeDuration && (this.state.timeDuration === 'byDate') && this.state.startDate === ""){
      this.setState({ startDateError: "You have to select start date" });
      return false;
    }else {
      if(result){
        this.props.dispatch({
          type: "DO_SAVE_QUESTIONNAIRE",
          payload: this.state.body,
        });
      }
    }
  })
  }
  handleRadioChange(e) {
    this.setState({
      body: {
        ...this.state.body,
        timeDuration: e.target.value,
        startDate: "",
        endDate: "",
      },
      timeDuration: e.target.value,
      timeDurationError: "",
      startDate:"",
      startDateError: "",
      endDate:"",
      endDateError:""
    });
  };
  handleInputDateChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if(name == "startDate"){
      this.setState({
        body: {
          ...this.state.body,
          startDate: moment(value).format("YYYY-MM-DD"),
        },
        startDate: value,
        startDateError: "",
        endDateError:""
      },()=>{
        this.isDateValid(this.state.endDate);
      });
    }else{
      this.setState({
        body: {
          ...this.state.body,
          endDate: moment(value).format("YYYY-MM-DD"),
        },
        endDate: value,
        startDateError: "",
        endDateError:""
      },()=>{
        this.isDateValid(this.state.endDate);
      });
    }
  }
  isDateValid(value) {
    if(this.state.timeDuration == 'byDate'){
      if (value === undefined || value === null || value.trim().length == 0) {
        return true;
      } else {
        var date = moment(new Date(this.state.startDate), 'MM/DD/YYYY');
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        var dateEntered = new Date(value);
        var today = moment(date, 'MM/DD/YYYY');
        var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
        var dDiff = today.isSameOrAfter(enteredDate);
        if (dDiff) {
          this.setState({
            endDateError: "Please select end date is greater than equal to start date."
          });
          return false;
        } else if (today.isSame(enteredDate)) {
          this.setState({
            endDateError: null
          });
          return true;
        } else {
          this.setState({
            endDateError: null
          });
          return true;
        }
        /*
          If needed minimum one month/week gape then uncomment this
          const start_date = moment(new Date(this.state.startDate), 'YYYY-MM-DD');
          const end_date = moment(new Date(this.state.endDate), 'YYYY-MM-DD');
          if(this.state.body.repeatPer == "week"){
            var diff = end_date.diff(start_date,'days');
            if(diff < 7){
              this.setState({
                endDateError: "Please Select end date after the week duration of start date."
              });
              return false;
            }
          }
          if(this.state.body.repeatPer == "month"){
            var diff = end_date.diff(start_date,'months');
            if(diff < 1){
              this.setState({
                endDateError: "Please Select end date after the month duration of start date."
              });
              return false;
            }
          }
          if(this.state.body.repeatPer == "everyOtherDay"){
            var diff = end_date.diff(start_date,'days');
            if(diff < 3){
              this.setState({
                endDateError: "Please Select end date after the every other day duration of start date."
              });
              return false;
            }
          }
          this.setState({
            endDateError: null
          });
          return true;
        */
      }
    }else{
      return true;
    } 
  };
  handleRepetedCondition = (selectedOption) => {
    this.setState({ condition: selectedOption });
    this.setState({
      body: {
        ...this.state.body,
        repeatPer: selectedOption.value,
      },
      endDateError:"",
      startDateError:""
    },()=>{
      this.isDateValid(this.state.endDate);
    });
  };

  handleCondition = (selectedOption) => {
    this.setState({ condition: selectedOption });
    this.setState({
      body: {
        ...this.state.body,
        condition: selectedOption.value,
      },
      condition: selectedOption,
      showInputCondition: true,
    });

    if (selectedOption.value == "between") {
      this.setState({
        showRangeInput: true,
      });
    } else {
      this.setState({
        showRangeInput: false,
      });
    }
  };
  handleConditions = (selectedOption) => {
    
    this.setState({
      body: {
        ...this.state.body,
        conditionGroup: _.map(selectedOption, "_id"),
      },
    });
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.value.trim();

    const name = target.name.trim();
    const notifySelf = this.state.body.notifySelf;
    const notifyReadingOwner = this.state.body.notifyReadingOwner;
    const notifyStaff = this.state.body.notifyStaff;
    if (target.type === "checkbox") {
      let index;
      if (target.checked) {
        // add the numerical value of the checkbox to options array
        if (name === "notifySelf") {
          notifySelf.push(event.target.value);
        } else if (name === "notifyStaff") {
          notifyStaff.push(event.target.value);
        } else {
          notifyReadingOwner.push(event.target.value);
        }
      } else {
        // or remove the value from the unchecked checkbox from the array
        if (name === "notifySelf") {
          index = notifySelf.indexOf(event.target.value);
          notifySelf.splice(index, 1);
        } else if (name === "notifyStaff") {
          index = notifyStaff.indexOf(event.target.value);
          notifyStaff.splice(index, 1);
        } else {
          index = notifyReadingOwner.indexOf(event.target.value);
          notifyReadingOwner.splice(index, 1);
        }
      }
      this.setState({
        body: {
          ...this.state.body,
          notifySelf: notifySelf,
          notifyReadingOwner: notifyReadingOwner,
          notifyStaff: notifyStaff,
        },
      });
      return;
    }

    if (name == "maxValue" || name == "minValue" || name == "value") {
      this.setState({
        body: {
          ...this.state.body,
        },
      });
      return;
    }
    this.setState({ body: { ...this.state.body, [name]: value.trim() } });
  }

  handleUsers = (selectedOption) => {
    this.setState({ sharedUsers: selectedOption });
    // const receivedFrom = _.map(selectedOption, "receivedFrom");
    this.setState({
      body: {
        ...this.state.body,
        sharedUsers: _.map(selectedOption, "_id"),
      },
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const timezoneList = moment.tz.zonesForCountry("US");
    const mapTimezones = _.map(timezoneList, (v) => {
      return { label: v, value: v };
    });
    this.setState({ timezoneList: mapTimezones });

    if (!this.props.isCareProvider) {
      this.setState({
        body: {
          ...this.state.body,
          sharedUsers: [this.props.userId],
        },
      });
    }
    this.props.dispatch({
      type: "GENERIC_ERROR",
      payload: { isGenericSuccess: false },
    });
  }
  render() {
    return (
      <div>
        <div className="row">
          <Header
            backbutton="d-flex"
            mainbutton="d-none"
            isCareProvider={this.props.isCareProvider}
            title="Create New Vitals Monitoring"
          >
            <img
              src={QuestionIcon}
              className="mr-2"
              style={{ width: "40px", height: "40px" }}
              alt="image"
            />
          </Header>
          <Modal isOpen={this.state.modal} toggle={this.model}>
            <ModalHeader toggle={this.model}>Add Question</ModalHeader>
            <ModalBody>
              <Formik
                enableReinitialize={true}
                initialValues={this.initialValues}
                validate={(values) => this.validate(values)}
                onSubmit={(values, { resetForm }) =>
                  this.handleSubmit(values, resetForm)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label
                          style={{
                            fontSize: 17,
                          }}
                        >
                          Question
                        </Label>
                        <Input
                          type="text"
                          name="question"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.question}
                          placeholder="Enter question"
                          style={{ borderRadius: 7 }}
                        />
                        {errors.question && touched.question && (
                          <div className="input-feedback">
                            {errors.question}
                          </div>
                        )}
                      </FormGroup>
                      <Row>
                        <Col lg={12} md={12} xs={12}>
                          <FieldArray
                            name="answers"
                            render={(arrayHelpers) => (
                              <div>
                                {values.answers && values.answers.length > 0
                                  ? values.answers.map((answers, index) => {
                                      const option_valueErrors =
                                        (errors.answers?.length &&
                                          errors.answers[index]?.answer) ||
                                        "";
                                      const option_valueTouched =
                                        (touched.answers?.length &&
                                          touched.answers[index]?.answer) ||
                                        "";
                                      return (
                                        <>
                                          <div key={"answer-" + index}>
                                            <Row>
                                              <Col lg={12}>
                                                <FormGroup>
                                                  <Label
                                                    style={{
                                                      fontSize: 17,
                                                    }}
                                                  >
                                                    Option
                                                  </Label>
                                                  <div>
                                                    <Row>
                                                      <Col lg={8} xs={8}>
                                                        <Field
                                                          name={`answers.${index}.answer`}
                                                          placeholder={
                                                            "Enter option"
                                                          }
                                                          type="text"
                                                          className={
                                                            "form-control form-field option-value " +
                                                            (option_valueTouched &&
                                                            option_valueErrors
                                                              ? " form-field-error"
                                                              : option_valueTouched &&
                                                                !option_valueErrors
                                                              ? " form-field-success"
                                                              : "")
                                                          }
                                                          value={answers.answer}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          handleBlur={
                                                            handleBlur
                                                          }
                                                        />
                                                        <span>
                                                          {option_valueTouched &&
                                                          option_valueErrors ? (
                                                            <div className="input-feedback">
                                                              {
                                                                option_valueErrors
                                                              }
                                                            </div>
                                                          ) : null}
                                                        </span>
                                                      </Col>
                                                      <Col
                                                        lg={4}
                                                        xs={4}
                                                        className="d-flex"
                                                      >
                                                        <CustomInput
                                                          className="custom-correct-checkbox"
                                                          type="checkbox"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          value={
                                                            answers.isCurrectAnswer
                                                          }
                                                          checked={
                                                            answers.isCurrectAnswer
                                                          }
                                                          id={`answers.${index}.isCurrectAnswer`}
                                                          name={`answers.${index}.isCurrectAnswer`}
                                                          label="Is correct"
                                                          inline
                                                        />
                                                        {index >= 2 && (
                                                          <Badge
                                                            style={{
                                                              cursor: "pointer",
                                                              fontSize: 13,
                                                              borderRadius: 7,
                                                              width: 32,
                                                              height: 32,
                                                            }}
                                                            className="m-1 p-2"
                                                            color="danger"
                                                            pill
                                                            onClick={() =>
                                                              arrayHelpers.remove(
                                                                index
                                                              )
                                                            }
                                                          >
                                                            X
                                                          </Badge>
                                                        )}
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </div>
                                        </>
                                      );
                                    })
                                  : ""}
                                <Button
                                  size="sm"
                                  style={{ borderRadius: 7 }}
                                  className={"secondary-add-button"}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      answer: "",
                                      isCurrectAnswer: false,
                                    })
                                  }
                                >
                                  Add
                                </Button>
                                <div className="input-feedback">
                                  {errors.isCurrectAnswer ? (
                                    <div> Note : {errors.isCurrectAnswer}</div>
                                  ) : null}
                                  {errors.duplicatesOption ? (
                                    <div> Note : {errors.duplicatesOption}</div>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <button
                        type="submit"
                        className="btn btn-block btn-info"
                        value="Submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </ModalBody>
          </Modal>
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body project-tab pt-2 mt-4">
                <Form onSubmit={this.submit}>
                  <FormGroup>
                    <Label for="exampleSelect">Select a Timezone</Label>
                    <RequiredSelect
                      SelectComponent={Select}
                      value={this.state.timezone}
                      onChange={this.handleTimezone}
                      options={this.state.timezoneList}
                    />
                    <span>
                      {(this.state && !this.state.timezone && this.state.isFormSubmitted) ? (
                        <div className="input-feedback">
                        Timezone is required
                        </div>
                      ) : null}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Questionnaire Title </Label>
                    <Input
                      type="text"
                      name="name"
                      id="exampleEmail"
                      onChange={this.handleInputChange}
                      placeholder="Questionnaire Title"
                      className="question-input-field input-date-filter"
                    />
                    <span>
                      {(this.state && this.state.isFormSubmitted && this.state.body && !this.state.body.name && _.isEmpty(this.state.body.name)) ? (
                        <div className="input-feedback">
                        Questionnaire title is required
                        </div>
                      ) : null}
                    </span>
                  </FormGroup>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="exampleSelect">Select Frequency</Label>
                        <RequiredSelect
                          SelectComponent={Select}
                          value={[
                            { value: "day", label: "Day" },
                            {
                              value: "everyOtherDay",
                              label: "Every Other Day",
                            },
                            { value: "week", label: "Week" },
                            { value: "month", label: "Month" },
                          ].filter(
                            ({ value }) => value === this.state.body.repeatPer
                          )}
                          onChange={this.handleRepetedCondition}
                          options={[
                            { value: "day", label: "Day" },
                            {
                              value: "everyOtherDay",
                              label: "Every Other Day",
                            },
                            { value: "week", label: "Week" },
                            { value: "month", label: "Month" },
                          ]}
                        />
                        <span>
                          {(this.state && this.state.isFormSubmitted && this.state.body && !this.state.body.repeatPer && _.isEmpty(this.state.body.repeatPer)) ? (
                            <div className="input-feedback">
                            Frequency is required
                            </div>
                          ) : null}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={4} className="p-2">
                      <FormGroup>
                      <Label for="exampleSelect">Select Time Duration</Label>
                         <div className="radio-group">
                          <Label> 
                            <Input
                            name="timeDuration"
                            type="radio"
                            value="byDate"
                            onChange={this.handleRadioChange}
                            className="radio-questionnarie-input"
                            />
                            <span className="radio-questionnarie-text">By Date</span> 
                          </Label>
                        <Label>
                          <Input
                          name="timeDuration"
                          type="radio"
                          value="noEndDate"
                          onChange={this.handleRadioChange}
                          className="radio-questionnarie-input"
                          />
                          <span className="radio-questionnarie-text">No End Date</span>
                        </Label>
                        </div>
                        <span>
                          {this.state.timeDurationError !== "" ? (
                            <div className="input-feedback">
                              {this.state.timeDurationError}
                            </div>
                          ) : null}
                        </span>
                      </FormGroup>
                    </Col>
                    {(this.state.timeDuration === 'byDate') &&(
                    <Col md={4} className="p-2">
                      <FormGroup className="remove-bottom-margin form-group-patient">
                        <Label for="exampleStartDate">Start Date:</Label>
                          <Input
                            className="input-date-filter remove-bottom-margin"
                            type="date"
                            name="startDate"
                            min={moment().format("YYYY-MM-DD")}
                            onChange={this.handleInputDateChange}
                            value={this.state.startDate}
                            style={{"min-height": "56px"}}
                          />
                           <span>
                            {this.state.startDateError !== "" ? (
                              <div className="input-feedback">
                                {this.state.startDateError}
                              </div>
                            ) : null}
                            </span>
                      </FormGroup>
                    </Col>
                    )}
                    {this.state.timeDuration === 'byDate' && (
                      <Col md={4} className="p-2">
                      <FormGroup className="remove-bottom-margin form-group-patient">
                        <Label for="exampleEndDate">End Date:</Label>
                        <Input
                          className="input-date-filter remove-bottom-margin pd-4"
                          type="date"
                          name="endDate"
                          min={moment().format("YYYY-MM-DD")}
                          onChange={this.handleInputDateChange}
                          value={this.state.endDate}
                          style={{"min-height": "56px"}}
                        />
                        <span>
                        {this.state.endDateError !== "" ? (
                          <div className="input-feedback">
                            {this.state.endDateError}
                          </div>
                        ) : null}
                        </span>
                      </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row form>
                    <Col md={12}>
                  <FormGroup>
                    {/* <div className="col-12" style={{ padding: 0 }}> */}
                      <Label for="exampleEmail" style={{ lineHeight: 3 }}>
                        Questions
                      </Label>
                      <Button
                        className="float-right"
                        color="danger"
                        size="sm"
                        style={{ alignItems: "flex-end", borderRadius: 7 }}
                        onClick={this.model}
                      >
                        Add Question
                      </Button>
                    {/* </div> */}
                    <QuestionList
                      actions={[
                        { click: this.model, label: "Edit" },
                        { click: this.isDelete, label: "Delete" },
                      ]}
                      question_list={this.state.body.questionProperty}
                    />
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel={true}
                      show={this.state.isDelete}
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      cancelBtnBsStyle="default"
                      onConfirm={this.removeQuestion}
                      onCancel={this.cancelDelete}
                    >
                      Your Question is Not Show After Delete
                    </SweetAlert>
                    <span style={{ padding: 7, fontSize:15 }}>
                      {this.state.questionError !== "" ? (
                        <div className="input-feedback">
                          {this.state.questionError}
                        </div>
                      ) : null}
                    </span>
                  </FormGroup>
                  </Col></Row>
                  {/* <FormGroup>
                    <Label for="exampleText">
                      Notification Message (Patient)
                    </Label>
                    <Input
                      type="textarea"
                      name="message"
                      required={true}
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup> */}
                  <FormGroup>
                    <Label for="exampleText">
                      Notification Message (Careprovider)
                    </Label>
                    <Input
                      type="textarea"
                      name="messageCareprovider"
                      onChange={this.handleInputChange}
                      rows={10}
                      className="input-date-filter"
                    />
                    <span>
                      {(this.state && this.state.isFormSubmitted && this.state.body && !this.state.body.messageCareprovider && _.isEmpty(this.state.body.messageCareprovider)) ? (
                        <div className="input-feedback">
                        Notification message is required
                        </div>
                      ) : null}
                    </span>
                  </FormGroup>
                  <Row
                    form
                    className={!this.state.showInputCondition ? "d-none" : ""}
                  >
                    <Col
                      md={6}
                      className={!this.state.showRangeInput ? "d-none" : ""}
                    >
                      <FormGroup>
                        <Label for="exampleEmail">Min Value</Label>
                        <Input
                          type="text"
                          name="minValue"
                          onChange={this.handleInputChange}
                          placeholder="Input Reading Min Value"
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      md={6}
                      className={!this.state.showRangeInput ? "d-none" : ""}
                    >
                      <FormGroup>
                        <Label for="examplePassword">Max Value</Label>
                        <Input
                          type="text"
                          name="maxValue"
                          onChange={this.handleInputChange}
                          placeholder="Input Reading Max Value"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.props.isCareProvider && (
                    <>
                      <FormGroup>
                        <Label for="exampleSelect">
                          Select a Condition to Monitor
                        </Label>
                        <RequiredSelect
                          SelectComponent={Select}
                          required={false}
                          // value={this.state.sharedUsers}
                          onChange={this.handleConditions}
                          options={this.props.conditions}
                          isMulti={true}
                          getOptionLabel={(option) =>
                            `${option["condition"]}  (${option["description"]})`
                          }
                          getOptionValue={(option) => option["_id"]}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleSelect">
                          Select a Patient to Monitor
                        </Label>
                        <RequiredSelect
                          SelectComponent={Select}                          
                          value={this.state.sharedUsers}
                          onChange={this.handleUsers}
                          options={this.props.assignedUsersList}
                          isMulti={true}
                          getOptionLabel={(option) =>
                            option["fname"] + " " + option["lname"]
                          }
                          getOptionValue={(option) => option}
                        />
                      </FormGroup>
                    </>
                  )}
                  {this.props.isCareProvider && (
                    <FormGroup>
                      <Label for="exampleCheckbox">Notify Me</Label>
                      <div>
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          value="inbox"
                          label="Inbox"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline2"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="SMS"
                          value="sms"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline3"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="VOICE"
                          value="voice"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline4"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="EMAIL"
                          value="email"
                          inline
                        />
                      </div>
                    </FormGroup>
                  )}

                  {/* <FormGroup>
                    {this.props.isCareProvider && (
                      <Label for="exampleCheckbox">Notify Readings Owner</Label>
                    )}

                    <div>
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline6"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        value="inbox"
                        label="Inbox"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline7"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="SMS"
                        value="sms"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline8"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="VOICE"
                        value="voice"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline9"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="EMAIL"
                        value="email"
                        inline
                      />
                    </div>
                  </FormGroup> */}

                  <FormGroup>
                    {this.props.isCareProvider && (
                      <Label for="exampleCheckbox">Notify Staff</Label>
                    )}

                    <div>
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline10"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        value="inbox"
                        label="Inbox"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline11"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        label="SMS"
                        value="sms"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline12"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        label="VOICE"
                        value="voice"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline13"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        label="EMAIL"
                        value="email"
                        inline
                      />
                    </div>
                  </FormGroup>

                  <Button
                    type="submit"
                    className="btn btn-info btn-lg btn-block px-10"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          Submitted Successfully
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR",
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    readingSubTypes,
    assignedUsersList,
    isGenericSuccess,
    isGenericError,
    conditions,
    genericErrorMessage,
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;
  return {
    readingSubTypes,
    assignedUsersList,
    userId,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    conditions,
    isCareProvider,
  };
};

export default connect(mapStateToProps)(Question);
